import styled from 'styled-components';
import { palette } from 'styled-tools';

import { ButtonSizes, fontWeight, FontWeights, SecondaryButton, units } from '@calm-web/design-system';

import { Title } from '@/components/ui/CellTitle/styles';

export const TableTitle = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	${Title} {
		width: auto;
		flex-grow: 1;
	}
`;

export const CancelButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })`
	margin-right: ${units(2)};
`;

export const ErrorMessage = styled.div`
	color: ${palette('errorRed')};
	${fontWeight(FontWeights.Medium)};
`;
