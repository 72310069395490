import styled from 'styled-components';

import { Text, units } from '@calm-web/design-system';

export const RuleWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: ${units(1, -0.5)};
	& > * {
		margin: ${units(0.5)};
	}
`;

export const RuleLogicalOperatorWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const OperatorText = styled(Text).attrs({ el: 'div' })`
	min-width: ${units(4.5)};
`;
