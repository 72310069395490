import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';

import InAppBrandingPreview from '@/components/pages/Account/InAppBrandingPreview';
import PartnerBranding from '@/components/pages/Account/PartnerBranding';
import EapPromotion from '@/components/pages/EapPromotion';
import useEap from '@/hooks/api/useEap';
import { GetDtcImageResponse } from '@/hooks/api/useUnifiedBrandingUpload';
import { getThemeUrl } from '@/hooks/forms/useEapForm';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';

import messages from './messages';
import { ConfigWrapper, Wrapper } from './styles';

interface BrandingAndBenefitsProps {
	partner: Partner;
	formProps: EditPartnerFormProps;
	isShowingPartnerSave: boolean;
	getDtcImage: () => GetDtcImageResponse;
}

const BrandingAndBenefits: FC<BrandingAndBenefitsProps> = ({
	partner,
	formProps,
	isShowingPartnerSave,
	getDtcImage,
}) => {
	const { formatMessage } = useIntl();
	const partnerId = partner.id;
	const [revalidateBenefits, { data: eapData, loading }] = useEap(partner);
	const fallbackImageUrl = getThemeUrl();
	const [imageUrl, setImageUrl] = useState(fallbackImageUrl);
	const dtcLogoUrl = partner?.dtc_logo_url;

	return (
		<section>
			<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
				{formatMessage(messages.brandingAndBenefitsTitle)}
			</Text>
			<Wrapper>
				<ConfigWrapper>
					{partnerId && <PartnerBranding formProps={formProps} />}
					{loading ? (
						<div data-testid="eap-loader">
							<Loader color="gray1" data-testid="eap-loader" />
						</div>
					) : (
						<EapPromotion
							partner={partner}
							eapData={eapData}
							setImageUrl={setImageUrl}
							imageUrl={imageUrl}
							revalidateBenefits={revalidateBenefits}
							isShowingPartnerSave={isShowingPartnerSave}
						/>
					)}
				</ConfigWrapper>
				<InAppBrandingPreview imageUrl={imageUrl} dtcLogoUrl={dtcLogoUrl} getDtcImage={getDtcImage} />
			</Wrapper>
		</section>
	);
};

export default BrandingAndBenefits;
