import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { Collapsible, FontSizes, FontWeights, Text, TextElements, units } from '@calm-web/design-system';

import { LinkWrapper } from '@/components/ui/ResourceLink/styles';

export const LaunchBackground = styled.div`
	display: flex;
	width: 100%;
	background: ${palette('gradientCalmBrand')};
	align-items: center;
	padding: ${units(3)};
	border-radius: ${theme('layout.borderRadius')};
`;

export const LaunchTextWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const LaunchTitle = styled.h5`
	color: white;
	width: 100%;
`;

export const LaunchSubtitle = styled.div`
	color: white;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const StyledCollapsibleSectionInner = styled(Collapsible)`
	&:not(:last-child),
	.Collapsible__trigger.is-open div {
		border-bottom: 1px solid ${palette('gray1')};
	}

	${/** :has does not yet work in all browsers */ ''}
	&:has(> .Collapsible__trigger.is-open) {
		border-bottom: 1px solid ${palette('gray1')};
	}

	.Collapsible__trigger {
		span:has(> svg) {
			margin-left: ${units(0.25)};
			margin-right: ${units(1.5)};
		}
	}

	${LinkWrapper} {
		padding-left: ${units(3.5)};
	}
`;

const StyledCollapsibleSectionHeader = styled(Text).attrs({
	size: FontSizes.sm,
	el: TextElements.H4,
	weight: FontWeights.Medium,
})``;

export const StyledCollapsibleSection = ({
	headerText,
	children,
}: PropsWithChildren<{ headerText: string }>): JSX.Element => {
	return (
		<StyledCollapsibleSectionInner
			trigger={<StyledCollapsibleSectionHeader>{headerText}</StyledCollapsibleSectionHeader>}
		>
			{children}
		</StyledCollapsibleSectionInner>
	);
};
