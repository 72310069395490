import { forwardRef, ReactElement, Ref } from 'react';

import { Card } from '@calm-web/design-system';

import {
	Loading,
	LoadingContainer,
	Shape1,
	Shape2,
	Shape3,
	Shape4,
	Shape5,
	Shape6,
	Shape7,
	Shape8,
	Shape9,
	Shape10,
	Shape11,
	Shape12,
	Shape13,
	Shape14,
	Shape15,
	Shape16,
	Shape17,
	Shape18,
	Shape19,
	Shape20,
	Shape21,
	Shape22,
	Shape23,
	Shape24,
	Shape25,
	Shape26,
	Shape27,
	Shape28,
	Shape29,
	Shape30,
	Shape31,
	Shape32,
	Shape33,
	Shape34,
	Shape35,
	Shape36,
} from './styles';

function LoadingAnimation(_props: {}, ref?: Ref<HTMLDivElement>): ReactElement {
	return (
		<Card data-testid="loading-animation" ref={ref}>
			<LoadingContainer data-testid="loader">
				<Loading>
					<Shape1 />
					<Shape2 />
					<Shape3 />
					<Shape4 />
					<Shape5 />
					<Shape6 />
					<Shape7 />
					<Shape8 />
					<Shape9 />
					<Shape10 />
					<Shape11 />
					<Shape12 />
					<Shape13 />
					<Shape14 />
					<Shape15 />
					<Shape16 />
					<Shape17 />
					<Shape18 />
					<Shape19 />
					<Shape20 />
					<Shape21 />
					<Shape22 />
					<Shape23 />
					<Shape24 />
					<Shape25 />
					<Shape26 />
					<Shape27 />
					<Shape28 />
					<Shape29 />
					<Shape30 />
					<Shape31 />
					<Shape32 />
					<Shape33 />
					<Shape34 />
					<Shape35 />
					<Shape36 />
				</Loading>
			</LoadingContainer>
		</Card>
	);
}

export default forwardRef(LoadingAnimation);
