import { FC } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';

import { useShouldShowSftp } from '@/hooks/api/useSftpConfig';

import messages from './messages';
import SftpIntegration from './SftpIntegration';

const Integration: FC = () => {
	const { formatMessage } = useIntl();
	const { data: shouldShowSftp, loading } = useShouldShowSftp();

	if (loading) {
		return <Loader color="gray1" />;
	}

	if (!shouldShowSftp) {
		return null;
	}

	return (
		<section>
			<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
				{formatMessage(messages.integrationTitle)}
			</Text>
			<SftpIntegration />
		</section>
	);
};

export default Integration;
