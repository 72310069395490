import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';

type FieldNames = 'ssh_key' | 'email_recipients';

export type EditSftpFormProps = FormProps<FieldNames>;

export const useSftpForm = (
	sftpEmailRecipients: string,
): {
	formProps: EditSftpFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps = useForm('sftp-form', {
		initialModel: {
			ssh_key: '',
			email_recipients: sftpEmailRecipients ?? '',
		},
		validation: {
			ssh_key: validation.validateOrFail([
				{
					rules: [
						value => {
							const sshKey = stringFromModelValue(value) ?? '';
							return sshKey.length >= 4 || sshKey.length === 0;
						},
					],
					errorResult: 'Please enter a valid SSH key.',
				},
			]),
			email_recipients: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please enter at least 1 email address',
				},
				{
					rules: [
						value => {
							const emailArray =
								stringFromModelValue(value)
									?.split(',')
									.map(email => email.trim())
									.filter(Boolean) ?? [];
							return emailArray.length <= 10;
						},
					],
					errorResult: 'Please enter fewer than 11 email addresses.',
				},
				{
					rules: [
						value => {
							const emailArray =
								stringFromModelValue(value)
									?.split(',')
									.map(email => email.trim())
									.filter(Boolean) ?? [];
							return emailArray.every(email => validation.email(email));
						},
					],
					errorResult: 'Make sure all email addresses are valid.',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

const getErrorMessage = (props: EditSftpFormProps): string | undefined => {
	return stringFromModelValue(
		(Object.keys(props.validation.fields) as FieldNames[])
			.map(fieldName => stringFromModelValue(props.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useSftpSubmitData = (
	sftpProps: EditSftpFormProps,
): {
	getSftpSubmitData: () => {
		ssh_key: string;
		email_recipients: string[];
	};
	showValidationErrors: () => boolean;
} => {
	const dispatch = useDispatch();

	const getSftpSubmitData = useCallback((): { ssh_key: string; email_recipients: string[] } => {
		return {
			ssh_key: stringFromModelValue(sftpProps.model.ssh_key) ?? '',
			email_recipients:
				stringFromModelValue(sftpProps.model.email_recipients)
					?.split(',')
					.map(email => email.trim())
					.filter(Boolean) ?? [],
		};
	}, [sftpProps]);

	const showValidationErrors = useCallback((): boolean => {
		if (sftpProps.validation.isValid) {
			return false;
		}
		const errorMessage = getErrorMessage(sftpProps) ?? 'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, sftpProps]);

	return { getSftpSubmitData, showValidationErrors };
};
