import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSWRConfig } from 'swr';

import useForm, { FormProps, stringFromModelValue } from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { HealthConfig } from '@/types/health';

import { useApi } from '../api';
import { usePermissions } from '../auth';

const getErrorMessage = (props: HealthConfigLogosFormProps): string | undefined => {
	const genericProps = props as FormProps<string>;
	return stringFromModelValue(
		Object.keys(genericProps.validation.fields)
			.map(fieldName => stringFromModelValue(genericProps.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useSubmitHealthConfigLogos = (
	formProps: HealthConfigLogosFormProps,
): {
	submitHealthConfigLogos: (partnerId: string) => Promise<void>;
	showValidationErrors: () => boolean;
	loading: boolean;
} => {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();
	const [hasValidPermissions, actions] = usePermissions();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const showValidationErrors = useCallback((): boolean => {
		if (formProps.validation.isValid) {
			return false;
		}
		const errorMessage =
			stringFromModelValue(getErrorMessage(formProps)) ??
			'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, formProps]);

	const getHealthConfigLogosSubmitData = useCallback((): FormData => {
		const data = new FormData();
		if (hasValidPermissions('light_logo_url', [actions.UPDATE]) && formProps.model.lightLogoFile?.[0]) {
			data.append('lightLogo', formProps.model.lightLogoFile?.[0] as File);
		}
		if (hasValidPermissions('dark_logo_url', [actions.UPDATE]) && formProps.model.darkLogoFile?.[0]) {
			data.append('darkLogo', formProps.model.darkLogoFile?.[0] as File);
		}
		if (hasValidPermissions('square_logo_url', [actions.UPDATE]) && formProps.model.squareLogoFile?.[0]) {
			data.append('squareLogo', formProps.model.squareLogoFile?.[0] as File);
		}
		return data;
	}, [formProps.model, actions.UPDATE, hasValidPermissions]);

	const submitHealthConfigLogos = useCallback(
		async (partnerId: string): Promise<void> => {
			try {
				setIsSubmitting(true);
				const data = getHealthConfigLogosSubmitData();
				const res = await apiRequest({
					endpoint: `b2b/partners/${partnerId}/health-config`,
					method: 'PATCH',
					body: data,
				});
				const healthConfig = res.data.health_config as HealthConfig;
				formProps.setModel({
					lightLogoUrl: healthConfig?.light_logo_url ?? '',
					lightLogoFile: [],
					darkLogoUrl: healthConfig?.dark_logo_url ?? '',
					darkLogoFile: [],
					squareLogoUrl: healthConfig?.square_logo_url ?? '',
					squareLogoFile: [],
				});
				formProps.resetAllDirtyStates();
				await mutate(`b2b/partners/${partnerId}/health-config`, healthConfig);
				dispatch(
					setBannerMessage({
						message: 'Successfully updated partner health config',
						flash: true,
						isError: false,
					}),
				);
			} catch (error) {
				dispatch(
					setBannerMessage({
						message: 'An error has occurred updating partner health config!',
						flash: true,
						isError: true,
					}),
				);
			} finally {
				setIsSubmitting(false);
			}
		},
		[apiRequest, formProps, dispatch, getHealthConfigLogosSubmitData, mutate],
	);

	return { submitHealthConfigLogos, showValidationErrors, loading: isSubmitting };
};

export const useHealthConfigLogosForm = (
	healthConfig: Pick<HealthConfig, 'light_logo_url' | 'dark_logo_url' | 'square_logo_url'>,
): {
	formProps: HealthConfigLogosFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps: HealthConfigLogosFormProps = useForm('healthConfigForm', {
		initialModel: {
			lightLogoUrl: healthConfig?.light_logo_url ?? '',
			lightLogoFile: [],
			darkLogoUrl: healthConfig?.dark_logo_url ?? '',
			darkLogoFile: [],
			squareLogoUrl: healthConfig?.square_logo_url ?? '',
			squareLogoFile: [],
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export type FieldNames =
	| 'lightLogoUrl'
	| 'lightLogoFile'
	| 'darkLogoUrl'
	| 'darkLogoFile'
	| 'squareLogoUrl'
	| 'squareLogoFile';

export type HealthConfigLogosFormProps = FormProps<FieldNames>;
