import {
	Dispatch,
	ReactElement,
	ReactNode,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';

import {
	Card,
	ChipButton,
	FontSizes,
	PaletteColor,
	PrimaryButton,
	Text,
	TextButton,
	TextElements,
} from '@calm-web/design-system';
import { BindWithErrorProps, FocusEventHandler } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePermissions } from '@/hooks/auth';
import { HealthSponsorshipOrderFormProps } from '@/hooks/forms/useHealthSponsorshipOrderForm';
import { HealthSponsorship } from '@/types/health';
import { ALLOWED_COUNTRIES } from '@/utils/allowedCountries';
import { dateFromIso8601DateForDatePicker, utcToIso8601Date } from '@/utils/helpers';

import { isDefaultSponsorship, lowerValue, raiseValue } from '../helpers';
import OrderingTool from './OrderingTool';
import {
	DescriptionWrapper,
	EditLink,
	MetadataTileWrapper,
	MetadataWrapper,
	OptionRow,
	OptionsContainer,
	OptionsTile,
	Overlay,
	SponsorshipDescription,
	Wrapper,
} from './styles';

export const INTERNATIONAL_CONFIGS_FEATURE_FLAG = 'b2b-ch-international-configs';
export const SPONSORSHIP_ENHANCEMENTS_FEATURE_FLAG = 'b2b-ch-sponsorship-enhancements';

function HealthSponsorshipMetadataTile({
	description,
	value,
	color = 'text',
	flexGrow = 1,
	isSponsorshipEnhancementsFlagOn,
	children,
}: {
	description: string;
	value: string;
	color?: PaletteColor;
	flexGrow?: number;
	isSponsorshipEnhancementsFlagOn: boolean;
	children?: ReactNode;
}): ReactElement {
	return isSponsorshipEnhancementsFlagOn ? (
		<MetadataTileWrapper $flexGrow={flexGrow}>
			<Text color="gray5" size={FontSizes.sm} el={TextElements.DescriptionTerm}>
				{description}
			</Text>
			<Text color={color} el={TextElements.DescriptionDetails}>
				{value}
			</Text>
			{children}
		</MetadataTileWrapper>
	) : (
		<div>
			<Text color="gray5" size={FontSizes.sm} el={TextElements.DescriptionTerm}>
				{description}
			</Text>
			<Text color={color} el={TextElements.DescriptionDetails}>
				{value}
			</Text>
			{children}
		</div>
	);
}

function HealthSponsorshipOverview({
	sponsorship,
	index,
	isDependentGroup,
	orderErrorProps,
	setLastChanged,
	isSponsorshipEnhancementsFlagOn,
}: {
	sponsorship: HealthSponsorship;
	index: number;
	isDependentGroup?: boolean;
	orderErrorProps?: ReturnType<BindWithErrorProps<string>>;
	setLastChanged?: Dispatch<
		SetStateAction<{
			id: string;
			prev: string;
			new: string;
		}>
	>;
	isSponsorshipEnhancementsFlagOn: boolean;
}): ReactElement {
	const isDefault = isDefaultSponsorship(sponsorship);
	const { logEvent } = useAnalytics();
	const [hasValidPermissions, actions] = usePermissions();
	const [showOptions, setShowOptions] = useState(false);
	const { activeValue, statusColor, startsAtFormatted, endsAtFormatted } = useMemo(() => {
		const startsAt = new Date(sponsorship.starts_at);
		const startsAtDateOnly = utcToIso8601Date(startsAt);
		const startsAtFormatted = dateFromIso8601DateForDatePicker(startsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const endsAt = new Date(sponsorship.ends_at);
		const endsAtDateOnly = utcToIso8601Date(endsAt);
		const endsAtFormatted = dateFromIso8601DateForDatePicker(endsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const now = new Date();
		const activeValue =
			now >= startsAt && now < endsAt
				? 'Active'
				: isSponsorshipEnhancementsFlagOn
				? now < startsAt
					? 'Upcoming'
					: 'Expired'
				: 'Inactive';
		const statusColor = activeValue === 'Active' ? 'success' : activeValue === 'Upcoming' ? 'gray5' : 'error';
		return { activeValue, statusColor, startsAtFormatted, endsAtFormatted };
	}, [sponsorship.starts_at, sponsorship.ends_at, isSponsorshipEnhancementsFlagOn]);

	const description = sponsorship.description
		? sponsorship.description
		: isDefault
		? 'Default'
		: sponsorship?.assignment_rules?.[0]?.assignment_rule?.attributes
				?.map(rule => {
					return `${rule.type} = ${rule.value}`;
				})
				.join(` ${sponsorship.assignment_rules[0].operator} `);

	function openOption(): void {
		setShowOptions(true);
	}

	function closeOption(): void {
		setShowOptions(false);
	}

	const sponsorGroupTitle = isSponsorshipEnhancementsFlagOn
		? 'Sponsor Group'
		: isDefault
		? 'Default'
		: `Sponsor Group ${index + 1}`;

	return (
		<Wrapper $isDefault={isDefault}>
			<MetadataWrapper $setFlex={!isSponsorshipEnhancementsFlagOn}>
				{orderErrorProps && (
					<OrderingTool
						sponsorship={sponsorship}
						orderErrorProps={orderErrorProps}
						setLastChanged={setLastChanged}
					/>
				)}
				<HealthSponsorshipMetadataTile
					description={sponsorGroupTitle}
					value={sponsorship.display_name}
					flexGrow={2}
					isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
				>
					<DescriptionWrapper>
						{isSponsorshipEnhancementsFlagOn ? (
							description ? (
								<SponsorshipDescription>{description}</SponsorshipDescription>
							) : null
						) : sponsorship.description ? (
							<SponsorshipDescription>{sponsorship.description}</SponsorshipDescription>
						) : null}
					</DescriptionWrapper>
				</HealthSponsorshipMetadataTile>
				{isDependentGroup ? (
					<>
						<HealthSponsorshipMetadataTile
							description=""
							value=""
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
						<HealthSponsorshipMetadataTile
							description=""
							value=""
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
					</>
				) : (
					<>
						<HealthSponsorshipMetadataTile
							description="Start Date"
							value={startsAtFormatted}
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
						<HealthSponsorshipMetadataTile
							description="End Date"
							value={endsAtFormatted}
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
					</>
				)}
				{isSponsorshipEnhancementsFlagOn && isDependentGroup && (
					<>
						<HealthSponsorshipMetadataTile
							description=""
							value=""
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
						<HealthSponsorshipMetadataTile
							description=""
							value=""
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
						<HealthSponsorshipMetadataTile
							description=""
							value=""
							isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						/>
					</>
				)}
				{isSponsorshipEnhancementsFlagOn && !isDependentGroup && (
					<HealthSponsorshipMetadataTile
						description="Status"
						value={activeValue}
						color={statusColor as PaletteColor}
						isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
					/>
				)}
				{!isSponsorshipEnhancementsFlagOn && (
					<HealthSponsorshipMetadataTile
						description="Status"
						value={activeValue}
						color={statusColor as PaletteColor}
						isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
					>
						<>
							<Link
								to={`/${sponsorship.partner_id}/account/health-sponsorships/${sponsorship.id}`}
								component={EditLink}
							>
								{hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE])
									? 'Edit Group Details'
									: 'View Group Details'}
							</Link>
						</>
					</HealthSponsorshipMetadataTile>
				)}

				{isSponsorshipEnhancementsFlagOn && (
					<OptionsTile>
						<div>
							<TextButton
								type="button"
								textColor="blue3"
								onClick={openOption}
								size={FontSizes.sm}
								hideUnderline
							>
								Options
							</TextButton>
							{showOptions && (
								<OptionsContainer>
									<Overlay onClick={closeOption} />
									<Link
										to={`/${sponsorship.partner_id}/account/health-sponsorships/${sponsorship.id}`}
										component={OptionRow}
									>
										{hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE])
											? 'Edit'
											: 'View'}
									</Link>
									{hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE]) &&
										!isDependentGroup && (
											<Link
												to={`/${sponsorship.partner_id}/account/health-sponsorships/${sponsorship.id}/duplicate`}
												component={OptionRow}
												onClick={() =>
													logEvent('Partner Portal : Health Sponsorships : Duplicate : Clicked', {
														sponsorshipId: sponsorship.id,
													})
												}
											>
												Duplicate
											</Link>
										)}
								</OptionsContainer>
							)}
						</div>
					</OptionsTile>
				)}
			</MetadataWrapper>
		</Wrapper>
	);
}

function HealthSponsorshipsInner({
	healthSponsorships,
	orderFormProps,
	isSponsorshipEnhancementsFlagOn,
}: {
	healthSponsorships: HealthSponsorship[];
	orderFormProps?: HealthSponsorshipOrderFormProps;
	isSponsorshipEnhancementsFlagOn: boolean;
}): ReactElement {
	const { logEvent } = useAnalytics();
	const [showActive, setShowActive] = useState(true);
	const [showUpcoming, setShowUpcoming] = useState(true);
	const [showExpired, setShowExpired] = useState(false);
	const [lastChanged, setLastChanged] = useState({ id: '', prev: '', new: '' });
	const [isFocused, setIsFocused] = useState(false);
	const [sortedSponsors, setSortedSponsors] = useState<HealthSponsorship[]>(
		healthSponsorships.filter(sponsorship => !isDefaultSponsorship(sponsorship)),
	);
	const [displayedSponsors, setDisplayedSponsors] = useState<HealthSponsorship[]>([]);
	const defaultSponsorship = healthSponsorships?.find(
		sponsorship => sponsorship?.assignment_rules?.[0].operator === 'true',
	);

	const [activeSponsorsLength, upcomingSponsorsLength, expiredSponsorsLength] = useMemo(() => {
		const now = new Date();
		let activeCount = 0;
		let upcomingCount = 0;
		let expiredCount = 0;

		healthSponsorships?.forEach(sponsorship => {
			if (isDefaultSponsorship(sponsorship)) {
				return;
			}
			const startsAt = new Date(sponsorship.starts_at);
			const endsAt = new Date(sponsorship.ends_at);
			if (now >= startsAt && now < endsAt) {
				activeCount++;
			} else if (now < startsAt) {
				upcomingCount++;
			} else if (now >= endsAt) {
				expiredCount++;
			}
		});
		return [activeCount, upcomingCount, expiredCount];
	}, [healthSponsorships]);

	const updateValueOnTie = useCallback(
		(errorProps: ReturnType<BindWithErrorProps<string>>, value: string, shouldRaise: boolean): void => {
			const newValue = shouldRaise ? raiseValue(value) : lowerValue(value);
			errorProps.onChange({
				target: {
					value: newValue,
					name: errorProps.name,
					type: 'text',
				},
			});
		},
		[],
	);

	const filterSponsors = useCallback(
		(sponsorship: HealthSponsorship): boolean => {
			const now = new Date();
			const startsAt = new Date(sponsorship.starts_at);
			const endsAt = new Date(sponsorship.ends_at);
			if (isDefaultSponsorship(sponsorship)) {
				return false;
			}
			if (!showActive && !showUpcoming && !showExpired) {
				return true;
			}
			if (showActive && now >= startsAt && now < endsAt) {
				return true;
			}
			if (showUpcoming && now < startsAt) {
				return true;
			}
			if (showExpired && now >= endsAt) {
				return true;
			}
			return false;
		},
		[showActive, showExpired, showUpcoming],
	);

	useEffect(() => {
		if (isFocused || !orderFormProps || !orderFormProps.validation.isValid) {
			return;
		}

		const sorted = sortedSponsors.slice().sort((a, b) => {
			if (orderFormProps.model[a.id] && orderFormProps.model[b.id]) {
				const difference =
					parseInt(orderFormProps.model[a.id] as string) - parseInt(orderFormProps.model[b.id] as string);
				if (difference !== 0) {
					return difference;
				}
				if (lastChanged.id === a.id) {
					if (lastChanged.prev > lastChanged.new) {
						return -1;
					}
					return 1;
				}
				if (lastChanged.id === b.id) {
					if (lastChanged.prev > lastChanged.new) {
						return 1;
					}
					return -1;
				}
			}
			return 0;
		});

		let normalized;

		// if there are any ties, we need to update the order of the tied sponsors by adding/subtracting 1
		const shouldRaise = lastChanged.prev > lastChanged.new;
		if (shouldRaise) {
			normalized = sorted.map((sponsorship, index) => {
				if (index > 0) {
					const previous = sorted[index - 1];
					if (orderFormProps.model[sponsorship.id] === orderFormProps.model[previous.id]) {
						const errorProps = orderFormProps.bindWithErrorProps(sponsorship.id, 'text');
						updateValueOnTie(errorProps, orderFormProps.model[sponsorship.id] as string, shouldRaise);
					}
				}
				return sponsorship;
			});
		} else {
			const reverseSorted = sorted.slice().reverse();
			normalized = reverseSorted
				.map((sponsorship, index) => {
					if (index > 0) {
						const previous = reverseSorted[index - 1];
						if (orderFormProps.model[sponsorship.id] === orderFormProps.model[previous.id]) {
							const errorProps = orderFormProps.bindWithErrorProps(sponsorship.id, 'text');
							updateValueOnTie(errorProps, orderFormProps.model[sponsorship.id] as string, shouldRaise);
						}
					}
					return sponsorship;
				})
				.reverse();
		}
		setSortedSponsors(normalized);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormProps, lastChanged, healthSponsorships, updateValueOnTie, isFocused]);

	useEffect(() => {
		setDisplayedSponsors(sortedSponsors.filter(filterSponsors));
	}, [filterSponsors, sortedSponsors]);
	return (
		<>
			{defaultSponsorship && (
				<HealthSponsorshipOverview
					sponsorship={defaultSponsorship}
					index={0}
					isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
				/>
			)}
			{Boolean(sortedSponsors.length) && (
				<>
					Rearrange the order of the following sponsor groups from highest to lowest priority.
					<div style={{ display: 'flex', gap: '8px', margin: '16px 0' }}>
						<ChipButton
							isActive={showActive}
							onPress={() => {
								setShowActive(prev => !prev);
							}}
						>
							Active ({activeSponsorsLength})
						</ChipButton>
						<ChipButton
							isActive={showUpcoming}
							onPress={() => {
								setShowUpcoming(prev => !prev);
							}}
						>
							Upcoming ({upcomingSponsorsLength})
						</ChipButton>
						<ChipButton
							isActive={showExpired}
							onPress={() => {
								setShowExpired(prev => !prev);
							}}
						>
							Expired ({expiredSponsorsLength})
						</ChipButton>
					</div>
					{displayedSponsors?.map((sponsorship, index) => {
						if (!orderFormProps) {
							return (
								<HealthSponsorshipOverview
									key={sponsorship.id}
									sponsorship={sponsorship}
									index={index + (defaultSponsorship ? 1 : 0)}
									isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
								/>
							);
						}
						const errorProps = orderFormProps.bindWithErrorProps(sponsorship.id, 'text');
						const { onFocus, onBlur } = errorProps;
						const myOnFocus: FocusEventHandler = e => {
							setLastChanged({
								id: sponsorship.id,
								prev: errorProps.value,
								new: '',
							});
							setIsFocused(true);
							onFocus(e);
						};
						const myOnBlur: FocusEventHandler = e => {
							if (lastChanged.prev !== errorProps.value) {
								logEvent('Partner Portal : Health Sponsorships : Order : Changed', {
									sponsorshipId: sponsorship.id,
									via: 'input',
								});
							}
							setLastChanged(prev => {
								return { ...prev, new: errorProps.value };
							});
							setIsFocused(false);
							onBlur(e);
						};
						errorProps.onFocus = myOnFocus;
						errorProps.onBlur = myOnBlur;
						return (
							<HealthSponsorshipOverview
								key={sponsorship.id}
								sponsorship={sponsorship}
								index={index + (defaultSponsorship ? 1 : 0)}
								orderErrorProps={errorProps}
								setLastChanged={setLastChanged}
								isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
							/>
						);
					})}
				</>
			)}
		</>
	);
}

function HealthSponsorships({
	partnerId,
	healthSponsorships,
	orderFormProps,
	isDependentGroup = false,
	onFocus,
}: {
	partnerId: string;
	healthSponsorships: HealthSponsorship[] | undefined;
	orderFormProps?: HealthSponsorshipOrderFormProps;
	isDependentGroup?: boolean;
	onFocus: () => void;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(INTERNATIONAL_CONFIGS_FEATURE_FLAG, SPONSORSHIP_ENHANCEMENTS_FEATURE_FLAG);

	const isInternationalConfigsFlagOn =
		!flagLoading && !flagError && flagValues?.[INTERNATIONAL_CONFIGS_FEATURE_FLAG] === true;
	const isSponsorshipEnhancementsFlagOn =
		!flagLoading && !flagError && flagValues?.[SPONSORSHIP_ENHANCEMENTS_FEATURE_FLAG] === true;

	const allowedDependentGroupLength = isInternationalConfigsFlagOn ? ALLOWED_COUNTRIES.length : 1;

	const shouldShowCreateButton =
		!isDependentGroup ||
		(isDependentGroup &&
			allowedDependentGroupLength &&
			allowedDependentGroupLength > (healthSponsorships?.length || 0));

	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>
				{isDependentGroup ? 'Friends & Family Sponsor Group Configurations' : 'Sponsor Group Configurations'}
			</CellTitle>
			{isSponsorshipEnhancementsFlagOn && !isDependentGroup ? (
				<HealthSponsorshipsInner
					healthSponsorships={healthSponsorships ?? []}
					orderFormProps={
						hasValidPermissions('health_sponsorship_order', [actions.READ]) ? orderFormProps : undefined
					}
					isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
				/>
			) : (
				healthSponsorships?.map((sponsorship, index) => (
					<HealthSponsorshipOverview
						key={sponsorship.id}
						sponsorship={sponsorship}
						index={index}
						isSponsorshipEnhancementsFlagOn={isSponsorshipEnhancementsFlagOn}
						isDependentGroup={isDependentGroup}
					/>
				))
			)}
			{hasValidPermissions('health_sponsorship_display_name', [actions.CREATE]) && shouldShowCreateButton && (
				<PrimaryButton
					href={`/${partnerId}/account/health-sponsorships${
						isDependentGroup ? '?isDependentGroup=true' : ''
					}`}
				>
					Create New Sponsor Group
				</PrimaryButton>
			)}
		</Card>
	);
}

export default HealthSponsorships;
