import { useDispatch } from 'react-redux';

import { setBannerMessage } from '@/store/actions';
import { calmLogger } from '@/utils/calmLogger';

import { useApi } from './useApi';

interface Response {
	sendAnnouncementEmail: (customMessage?: string) => Promise<void>;
}

const useAnnouncementEmail = (partnerId: string): Response => {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const sendAnnouncementEmail = async (customMessage?: string): Promise<void> => {
		try {
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/notifications/announcement`,
				method: 'POST',
				body: { ...(customMessage && { custom_text: customMessage }) },
			});

			dispatch(
				setBannerMessage({
					message: 'Launch announcement email has successfully been sent.',
					isError: false,
					flash: true,
				}),
			);
		} catch (error) {
			calmLogger.error('Error when trying to send launch announcement email', { partnerId }, error);

			dispatch(
				setBannerMessage({
					message: 'Failed to send launch announcement email',
					isError: true,
					flash: true,
				}),
			);
		}
	};

	return { sendAnnouncementEmail };
};

export default useAnnouncementEmail;
