import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	Card,
	fontSize,
	FontSizes,
	FontWeights,
	PrimaryButton,
	Text,
	TextElements,
	units,
} from '@calm-web/design-system';

export const LoadingContainer = styled.div`
	width: auto;
	height: auto;
	margin: 0;
`;

export const DownloadButton = styled(PrimaryButton)`
	width: ${units(32)};
`;

export const HeaderRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const TableContainer = styled.div`
	max-width: ${theme('layout.mainMaxWidth')};
`;

export const HeaderContainer = styled.div`
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom: 0px;
	margin-bottom: 0px;
`;

export const SearchRow = styled.div`
	margin-top: ${units(2)};
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const SearchWrapper = styled.div`
	width: ${units(32)};
`;

export const AccessCodesTableCard = styled(Card).attrs({ noPadding: true })`
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	margin-top: 0px;
`;

export const CounterText = styled(Text).attrs({
	size: FontSizes.lg,
	weight: FontWeights.Medium,
	el: TextElements.H2,
})``;

export const MobileLabel = styled.span`
	color: ${palette('gray5')};
	text-transform: uppercase;
	white-space: nowrap;
	${fontSize(FontSizes.sm)};
`;
export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
`;

export const MobileUsersRow = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	padding: ${units(1, 2)};
	width: 100%;
	gap: ${units(2)};
`;
export const MobileUsersWrapper = styled.div`
	${MobileUsersRow}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray1')};
	}
`;
