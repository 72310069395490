import { forwardRef, ReactElement, Ref, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@calm-web/design-system';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import DocumentIcon from 'icons/tos.svg';

import LoadingAnimation from '../LoadingAnimation';
import ReportingPlaceholder from '../ReportingPlaceholder';
import messages from './messages';
import {
	ArticleLink,
	BenchmarkContainer,
	BenchmarkPercentage,
	BenchmarkText,
	DataContainer,
	DataLeft,
	FlexWrapper,
	Heading,
	InfoIcon,
	LinkReferences,
	PrimaryContainer,
	PrimaryPercentage,
	PrimaryText,
	ResourceDescription,
	ResourceTitle,
	SecondaryContainer,
	SecondaryPercentage,
	SecondaryText,
	StyledCard,
	SubHeading,
	Wrapper,
} from './styles';

interface CardProps {
	isPrivacyError?: boolean;
	isNoDataError?: boolean;
	isLoading?: boolean;
	heading?: string;
	subHeading?: string;
	primaryPercentage?: string | number;
	primaryText?: ReactElement;
	secondaryPercentage?: number | string;
	secondaryText?: string | null;
	benchmark?: number;
	benchmarkRef?: Ref<HTMLDivElement>;
	content?: ReactElement;
	resourceDescription?: string;
	resourceData?: { title: string; link: string; openInNewTab?: boolean }[];
	placeholderTitle?: string;
	placeholderSubtitle?: string;
	removeDataContainer?: boolean;
}

export const B2B_INDUSTRY_BENCHMARK_FF = 'b2b-industry-benchmark';

function WidgetCard(
	{
		isPrivacyError,
		isNoDataError,
		isLoading,
		heading,
		subHeading,
		primaryPercentage,
		primaryText,
		secondaryPercentage,
		secondaryText,
		benchmark,
		content,
		resourceDescription,
		resourceData,
		benchmarkRef,
		placeholderTitle,
		placeholderSubtitle,
		removeDataContainer,
	}: CardProps,
	ref?: Ref<HTMLDivElement>,
): ReactElement {
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();

	const { data: flagValues } = useFeatureFlags(B2B_INDUSTRY_BENCHMARK_FF);
	const canShowBenchmark =
		useMemo(() => flagValues && flagValues[B2B_INDUSTRY_BENCHMARK_FF] === true, [flagValues]) &&
		primaryPercentage;
	const { isReportDownloading } = useContext(ReportingContext);

	// Show loading animation before report data is populated
	if (isLoading) {
		return <LoadingAnimation ref={ref} />;
	}
	// If no data returned (user count may be < 10), show error placeholder
	else if (isPrivacyError) {
		return (
			<ReportingPlaceholder
				ref={ref}
				title={heading}
				graphType="privacyError"
				placeholderTitle={placeholderTitle}
				placeholderSubtitle={placeholderSubtitle}
			/>
		);
	} else if (isNoDataError) {
		return (
			<ReportingPlaceholder
				ref={ref}
				title={heading}
				graphType="getStarted"
				placeholderTitle={placeholderTitle}
				placeholderSubtitle={placeholderSubtitle}
			/>
		);
	}

	return (
		<StyledCard ref={ref}>
			<FlexWrapper>
				<Heading data-testid="heading">{heading}</Heading>
				{Boolean(subHeading) && <SubHeading>{subHeading}</SubHeading>}
			</FlexWrapper>
			<Wrapper>
				{!removeDataContainer && (
					<DataContainer>
						<DataLeft>
							<PrimaryContainer>
								{primaryPercentage && (
									<PrimaryPercentage data-testid="primaryPercentage">{primaryPercentage}%</PrimaryPercentage>
								)}
								<PrimaryText data-testid="primaryText">{primaryText}</PrimaryText>
							</PrimaryContainer>
							{typeof secondaryPercentage !== 'undefined' ? (
								<div>
									<SecondaryContainer>
										<SecondaryPercentage
											data-testid="secondaryPercentage"
											$isNegative={
												typeof secondaryPercentage === 'number'
													? secondaryPercentage < 0
													: secondaryPercentage.startsWith('-')
											}
										>
											{secondaryPercentage}%
										</SecondaryPercentage>
										<SecondaryText data-testid="secondaryText">{secondaryText}</SecondaryText>
									</SecondaryContainer>
								</div>
							) : null}
						</DataLeft>
						{benchmark !== undefined && canShowBenchmark && (
							<BenchmarkContainer data-testid="benchmark" ref={benchmarkRef}>
								<BenchmarkPercentage data-testid="benchmarkPercentage">{benchmark}%</BenchmarkPercentage>
								<BenchmarkText>
									{formatMessage(messages.benchmarkTitle)}
									<Tooltip placement="right" content={formatMessage(messages.benchmarkTooltip)}>
										<div style={{ display: 'inline-block' }}>
											<InfoIcon data-html2canvas-ignore="true" />
										</div>
									</Tooltip>
								</BenchmarkText>
							</BenchmarkContainer>
						)}
					</DataContainer>
				)}
				{content}
				{resourceData && !isReportDownloading && (
					<>
						<ResourceTitle>Top Resources</ResourceTitle>
						<ResourceDescription>{resourceDescription}</ResourceDescription>
						<LinkReferences data-testid="resources">
							{resourceData.map((resource, index) => {
								return (
									<ArticleLink
										key={index}
										href={resource.link}
										data-html2canvas-ignore="true"
										openInNewTab={resource.openInNewTab}
										Icon={DocumentIcon}
										onPress={() =>
											logEvent('Partner Portal : Reporting : Signup and Engagement : Resource Link : Clicked')
										}
									>
										{resource.title}
									</ArticleLink>
								);
							})}
						</LinkReferences>
					</>
				)}
			</Wrapper>
		</StyledCard>
	);
}

export default forwardRef(WidgetCard);
