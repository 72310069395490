import React, { ReactElement, ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Invoice } from '@/hooks/api/useInvoice';

import messages from './messages';
import { CreditMessage, Warning, WarningContainer, WebLink } from './styles';

function determineMessage(
	isAccountManager: boolean,
	coveredLives: number,
	previousCoveredLives: number,
): MessageDescriptor {
	const isCoverageUnchanged = coveredLives === previousCoveredLives;
	if (isCoverageUnchanged && isAccountManager) {
		return messages.coverageUnchanged;
	}

	if (isAccountManager) {
		return messages.calculateConfirmText;
	}

	const isNotIncreasingCoverage = coveredLives <= previousCoveredLives;
	if (isNotIncreasingCoverage) {
		return messages.numberOfCoveredLivesError;
	}

	return messages.calculateConfirmText;
}

interface Props {
	isUncalculated: boolean;
	coveredLives: number;
	previousCoveredLives: number;
	isAccountManager: boolean;
	proratedInvoice: Invoice | undefined;
	proratedLoading: boolean;
}
export function WarningMessage({
	isUncalculated,
	coveredLives,
	previousCoveredLives,
	isAccountManager,
	proratedInvoice,
	proratedLoading,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const message = determineMessage(isAccountManager, coveredLives, previousCoveredLives);
	const supportLink = 'https://support.calm.com/hc/en-us?solvvyProvidedByEmployer';

	// if prorationAmount is 0 (this is from amount_due on the invoice object from stripe and this is used when the b2b-stripe-prorate-invoice-endpoint is on)
	// if prorationAmount is less than 0 (this is from our own calculations when b2b-stripe-prorate-invoice-endpoint is off)
	//the invoice has a credit when they move from one price tier to another
	const isCredit = Boolean(proratedInvoice && proratedInvoice?.prorationAmount <= 0);

	return (
		<WarningContainer data-testid="warning-container">
			{proratedLoading ? null : isUncalculated ? (
				<>
					<Warning />
					<div>{formatMessage(message)}</div>
				</>
			) : (
				isCredit && (
					<CreditMessage>
						{formatMessage(messages.creditMessage, {
							link: (...chunks: ReactNode[]) => (
								<WebLink
									data-testid="contact-support"
									href={supportLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									{chunks}
								</WebLink>
							),
						})}
					</CreditMessage>
				)
			)}
		</WarningContainer>
	);
}
