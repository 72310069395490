import { FC } from 'react';
import { useIntl } from 'react-intl';

import { FormInput, RadioGroup } from '@calm-web/design-system';

import Subtitle from '@/components/ui/CellSubtitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { UserIdType } from '@/utils/UserIdType';

import messages from '../messages';
import { IndentedParagraph, RadioButtonWrapper, UserIdentifierLabel } from './styles';

interface Props {
	isEdit: boolean;
	formProps: EditPartnerFormProps;
	disabled?: boolean;
}

const UserIdentifier: FC<Props> = ({ formProps, disabled }) => {
	const { formatMessage } = useIntl();
	const options = [
		{
			value: UserIdType.Email,
			id: 'Email',
			label: 'Email address',
		},
		{
			value: UserIdType.Custom,
			id: 'Other',
			label: 'Other',
		},
	];

	return (
		<>
			<UserIdentifierLabel>{formatMessage(messages.uniqueIdTitle)}:</UserIdentifierLabel>
			<RadioButtonWrapper>
				<RadioGroup
					{...formProps.bindWithErrorProps('eligibilityType', 'radio')}
					aria-label={formatMessage(messages.uniqueIdTitle)}
					options={options}
					disabled={disabled}
				/>
			</RadioButtonWrapper>
			{formProps.model.eligibilityType === UserIdType.Custom ? (
				<IndentedParagraph>
					<Subtitle>
						Customize the name of your User Identifier to best describe the ID type you use in your
						eligibility file. For example, “Employee ID” or “Prize Code”.
					</Subtitle>
					<FormInput
						{...formProps.bindWithErrorProps('userIdDescriptor', 'text')}
						placeholder="e.g. Employee ID"
						aria-label="User ID Descriptor"
						disabled={disabled}
					/>
				</IndentedParagraph>
			) : null}
		</>
	);
};

export default UserIdentifier;
