import debounce from 'lodash/debounce';
import { ChangeEvent, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { FormInput, PaletteColor } from '@calm-web/design-system';
import { OnChange } from '@calm-web/use-form';

import Table from '@/components/ui/Table';
import usePartners from '@/hooks/api/usePartners';
import { Partner, PartnerCategoryType } from '@/types/store/reducers';
import { calmLogger } from '@/utils/calmLogger';
import Search from 'icons/user-search.svg';

import {
	ActionButton,
	CategorySelect,
	customRowStyles,
	FormInputWrapper,
	HeaderWrapper,
	ManageColumn,
	ManageText,
	NameColumn,
	NameText,
	ParentColumn,
	ParentText,
	SlugColumn,
	SlugText,
} from './styles';

interface PartnerRow {
	name: ReactNode;
	parent?: ReactNode;
	slug: ReactNode;
	manage: ReactNode;
}
interface SelectPartnerFn {
	(partnerId: string): Promise<void>;
}

const PAGE_SIZE = 50;

const generatePartnerRows = (
	data: Array<Partner>,
	showParentColumn?: boolean,
	actionButtons?: {
		label: string;
		onClick: (partner: Partner) => void;
		color: PaletteColor;
		ariaLabel?: string;
	}[],
): Array<PartnerRow> => {
	return data.map((partner: Partner): PartnerRow => {
		const parent = showParentColumn && {
			parent: (
				<ParentColumn>
					<ParentText>{partner.parent?.name}</ParentText>
				</ParentColumn>
			),
		};
		return {
			name: (
				<NameColumn>
					<NameText>{partner?.name}</NameText>
				</NameColumn>
			),
			...parent,
			slug: (
				<SlugColumn>
					<SlugText>{partner?.slug}</SlugText>
				</SlugColumn>
			),
			manage: (
				<ManageColumn>
					{actionButtons ? (
						actionButtons?.map((action, index) => (
							<ActionButton
								key={index}
								onClick={async () => {
									await action.onClick(partner);
								}}
								textColor={action.color}
								aria-label={action.ariaLabel}
							>
								{action.label}
							</ActionButton>
						))
					) : (
						<ManageText>manage</ManageText>
					)}
				</ManageColumn>
			),
		};
	});
};

const emptyArray: Partner[] = [];

const categories = ['none', ...Object.values(PartnerCategoryType)];

export default function PartnersTable({
	HeaderButton,
	onSelectPartner,
	additionalFilters,
	hideCategorySelector,
	showParentColumn,
	showSlugColumn,
	actionButtons,
}: {
	HeaderButton?: () => JSX.Element;
	onSelectPartner: SelectPartnerFn;
	additionalFilters?: Record<string, string | Record<string, string>>;
	hideCategorySelector?: boolean;
	showParentColumn?: boolean;
	showSlugColumn?: boolean;
	actionButtons?: {
		label: string;
		onClick: (partner: Partner) => void;
		color: PaletteColor;
		ariaLabel?: string;
	}[];
}): ReactElement {
	const [pageIndex, setPageIndex] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [category, setCategory] = useState<PartnerCategoryType | undefined>();
	const [apiSearchValue, setApiSearchValue] = useState(searchValue);
	const filters = useMemo(() => {
		return {
			...additionalFilters,
			...(category ? { categories: category } : {}),
		};
	}, [additionalFilters, category]);
	const { data, loading } = usePartners({
		page: pageIndex,
		query: apiSearchValue,
		filters,
		searchParents: showParentColumn,
	});
	const partners = data?.partners ?? emptyArray;

	const columns: {
		Header: JSX.Element | string;
		accessor: keyof PartnerRow;
	}[] = useMemo(() => {
		const cols: {
			Header: JSX.Element | string;
			accessor: keyof PartnerRow;
		}[] = [{ Header: 'Partner Name', accessor: 'name' }];
		if (showParentColumn) {
			cols.push({ Header: <ParentText>Parent</ParentText>, accessor: 'parent' });
		}
		if (showSlugColumn) {
			cols.push({ Header: <SlugText>Partner Slug</SlugText>, accessor: 'slug' });
		}
		cols.push({ Header: actionButtons ? <ManageColumn>Actions</ManageColumn> : '', accessor: 'manage' });
		return cols;
	}, [actionButtons, showParentColumn, showSlugColumn]);

	const onRowClick = (index: number): void => {
		onSelectPartner(partners[index].id).catch(err =>
			calmLogger.error(`Error selecting partner ${partners[index].id}`, {}, err),
		);
	};

	// useRef to avoid function memory address change on rerender, which would lead to unnecessary filtering
	const debouncePartnerFilter = useRef(
		debounce((newSearchVal: string) => {
			setApiSearchValue(newSearchVal);
		}, 300),
	).current;

	// Clean up any pending filtering when unmount occurs
	useEffect(() => {
		return () => {
			debouncePartnerFilter.cancel();
		};
	}, [debouncePartnerFilter]);

	const onSearchChange: OnChange = e => {
		const newSearchVal = e.target.value || '';
		setSearchValue(newSearchVal);
		debouncePartnerFilter(newSearchVal);
	};

	useEffect(() => {
		// Go back to the first page when searching
		if (apiSearchValue) {
			setPageIndex(0);
		}
	}, [apiSearchValue]);

	const updateDisplayedPartners = ({ pageIndex }: { pageIndex: number }): void => {
		setPageIndex(pageIndex);
	};

	const partnerRows = useMemo(() => {
		return generatePartnerRows(partners, showParentColumn, actionButtons);
	}, [partners, showParentColumn, actionButtons]);

	const totalPartnerCount = data?.meta.pagination.total;
	const pageCount = Math.ceil((totalPartnerCount ?? 0) / PAGE_SIZE);

	const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>): void => {
		const newCategory = e.currentTarget.value;
		if (newCategory === 'none') {
			setCategory(undefined);
			return;
		}
		setCategory(newCategory as PartnerCategoryType);
	};

	return (
		<Table
			columns={columns}
			data={partnerRows}
			fetchData={updateDisplayedPartners}
			loading={loading}
			pageCount={pageCount}
			pageSize={PAGE_SIZE}
			cellNoPadding
			forcePageIndex={pageIndex}
			onRowClick={!actionButtons ? onRowClick : undefined}
			customRowStyles={!actionButtons ? customRowStyles : undefined}
		>
			<HeaderWrapper>
				<FormInputWrapper>
					<FormInput
						label="Search"
						onChange={onSearchChange}
						name="search"
						value={searchValue}
						Icon={Search}
						noValidation
						noMargin
					/>
				</FormInputWrapper>
				{hideCategorySelector ? null : (
					<CategorySelect
						name="category"
						value={category ?? ''}
						onChange={handleCategoryChange}
						placeholder="Category"
						options={categories}
						data-testid="partner-category-role"
						aria-label="Partner category role"
					/>
				)}
				{HeaderButton ? <HeaderButton /> : null}
			</HeaderWrapper>
		</Table>
	);
}
