import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import pathwaysOnboarding1 from '@/../public/_n/images/pathways-descriptions/onboarding-1.png';
import pathwaysOnboarding2 from '@/../public/_n/images/pathways-descriptions/onboarding-2.png';
import pathwaysOnboarding3 from '@/../public/_n/images/pathways-descriptions/onboarding-3.png';

import messages from './messages';
import { FinishButton, InfoContent, InfoDescription, InfoImage, InfoPageWrapper, NextButton } from './styles';

interface PathwaysInfoPagesProps {
	onFinish: () => void;
}

const PathwaysInfoPages: React.FC<PathwaysInfoPagesProps> = ({ onFinish }) => {
	const { formatMessage } = useIntl();
	const pages = [
		{
			description: formatMessage(messages.infoPageDescription1),
			image: pathwaysOnboarding1,
		},
		{
			description: formatMessage(messages.infoPageDescription2),
			image: pathwaysOnboarding2,
		},
		{
			description: formatMessage(messages.infoPageDescription3),
			image: pathwaysOnboarding3,
		},
	];
	const [currentPage, setCurrentPage] = useState(0);

	const handleNext = (): void => {
		setCurrentPage(prevPage => (prevPage + 1) % pages.length);
	};

	const handleFinish = (): void => {
		onFinish();
	};

	return (
		<InfoPageWrapper>
			<InfoImage src={pages[currentPage].image.src} alt={`Pathways Image ${currentPage + 1}`} />
			<InfoContent>
				<InfoDescription>{pages[currentPage].description}</InfoDescription>
				{currentPage < pages.length - 1 ? (
					<NextButton backgroundColor="blue3" onPress={handleNext}>
						{formatMessage(messages.nextButton)}
					</NextButton>
				) : (
					<FinishButton backgroundColor="blue3" onPress={handleFinish}>
						{formatMessage(messages.finishButton)}
					</FinishButton>
				)}
			</InfoContent>
		</InfoPageWrapper>
	);
};

export default PathwaysInfoPages;
