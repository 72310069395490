// Third Party
// Components

import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { FontWeights, Text } from '@calm-web/design-system';

import { BoldText, ButtonContainer, ExampleEmailContainer, GradientButton } from '../styles';
// Messages
import messages from './messages';

const TemplateEmail = (): ReactElement => {
	const { formatMessage } = useIntl();
	return (
		<ExampleEmailContainer>
			<Text el="h5" noMargin weight={FontWeights.Medium}>
				{formatMessage(messages.hello)}
			</Text>
			<br />
			{formatMessage(messages.intro)}
			<br />
			<br />
			{formatMessage(messages.description)}
			<br />
			<br />
			<ButtonContainer>
				<div>
					<GradientButton>{formatMessage(messages.sendFeedback)}</GradientButton>
				</div>
			</ButtonContainer>
			<br />
			<BoldText>{formatMessage(messages.anonymous)}</BoldText>
			<br />
			{formatMessage(messages.thankYou)}
			<br />
			{formatMessage(messages.signature)}
		</ExampleEmailContainer>
	);
};

export default TemplateEmail;
