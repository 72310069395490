import { Dispatch, ReactElement, SetStateAction } from 'react';

import { BindWithErrorProps } from '@calm-web/use-form';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { HealthSponsorship } from '@/types/health';

import { lowerValue, raiseValue } from '../../helpers';
import { LowerPriorityIcon, OrderBox, OrderInput, RaisePriorityIcon, SmallIconButton } from './styles';

function OrderingTool({
	sponsorship,
	orderErrorProps,
	setLastChanged,
}: {
	sponsorship: HealthSponsorship;
	orderErrorProps: ReturnType<BindWithErrorProps<string>>;
	setLastChanged?: Dispatch<
		SetStateAction<{
			id: string;
			prev: string;
			new: string;
		}>
	>;
}): ReactElement {
	const { logEvent } = useAnalytics();
	return (
		<OrderBox>
			<SmallIconButton
				Icon={RaisePriorityIcon}
				isDisabled={orderErrorProps.value === '0'}
				aria-label="Raise Priority"
				onPress={() => {
					logEvent('Partner Portal : Health Sponsorships : Order : Updated', {
						sponsorshipId: sponsorship.id,
						via: 'click',
						direction: 'raise',
					});
					if (setLastChanged) {
						setLastChanged({
							id: sponsorship.id,
							prev: orderErrorProps.value,
							new: '',
						});
					}
					const newValue = lowerValue(orderErrorProps.value);
					orderErrorProps.onChange({
						target: {
							value: newValue,
							name: orderErrorProps.name,
							type: 'text',
						},
					});
					if (setLastChanged) {
						setLastChanged(prev => {
							return { ...prev, new: newValue };
						});
					}
				}}
			/>
			<OrderInput aria-label={`Order for Sponsor Group ${sponsorship.display_name}`} {...orderErrorProps} />
			<SmallIconButton
				Icon={LowerPriorityIcon}
				isDisabled={orderErrorProps.value === '99'}
				aria-label="Lower Priority"
				onPress={() => {
					logEvent('Partner Portal : Health Sponsorships : Order : Updated', {
						sponsorshipId: sponsorship.id,
						via: 'click',
						direction: 'lower',
					});
					if (setLastChanged) {
						setLastChanged({
							id: sponsorship.id,
							prev: orderErrorProps.value,
							new: '',
						});
					}
					const newValue = raiseValue(orderErrorProps.value);
					orderErrorProps.onChange({
						target: {
							value: newValue,
							name: orderErrorProps.name,
							type: 'text',
						},
					});
					if (setLastChanged) {
						setLastChanged(prev => {
							return { ...prev, new: newValue };
						});
					}
				}}
			/>
		</OrderBox>
	);
}

export default OrderingTool;
