import Image from 'next/image';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Card, FontSizes, FontWeights, Hr, Text, TextElements, units } from '@calm-web/design-system';
import { ChevronRight, Gear, GoogleAuth } from '@calm-web/icons';

const scale = 0.75;
export const ViewPort = styled.div`
	z-index: -1;
	position: relative;
	overflow-y: hidden;
	overflow-x: hidden;
	transform: scale(${scale});
	height: calc((100% - 18px) / ${scale});
	width: calc((100% - 18px) / ${scale});
	margin: calc(9px / ${scale});
	left: -${(100 / scale - 100) / 2}%;
	top: -${(100 / scale - 100) / 2}%;
	padding: ${units(2.5)};
	background: linear-gradient(#e2f6ff, #fdf7f9);
`;

export const NavBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${units(1.5, 0)};
	margin-top: ${units(3)};
	margin-bottom: ${units(2.5)};
`;

export const NavTitle = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Medium,
	size: FontSizes.xl,
})``;

export const GearIcon = styled(Gear)`
	padding: ${units(1.25)};
	width: ${units(5)};
	height: ${units(5)};
	border-radius: 100%;
	background-color: ${palette('white')};

	path {
		fill: #0060a9;
	}
`;

export const ProfileCard = styled(Card)`
	text-align: center;
`;

export const EditButton = styled(Text).attrs({ size: FontSizes.sm })`
	position: absolute;
	top: ${units(1)};
	right: ${units(1)};
	color: #0060a9;
`;

export const ProfilePicture = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Medium,
	size: FontSizes.lg,
})`
	width: ${units(8)};
	height: ${units(8)};
	margin: auto;
	padding-top: ${units(2)};
	color: #0060a9;
	border: 1px solid;
	border-color: #0060a9;
	border-radius: 100%;
`;

export const ProfileName = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Demi,
})`
	margin: ${units(1.5, 0)};
`;

export const AccountInfo = styled(Text).attrs({ size: FontSizes.sm })``;

export const GoogleIcon = styled(GoogleAuth)`
	width: ${units(3)};
	height: ${units(3)};
	margin-right: ${units(0.5)};
`;

export const AccountEmail = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Demi,
	size: FontSizes.sm,
})`
	display: inline-flex;
	align-items: center;
	margin-top: ${units(0.5)};
`;

export const HealthSponsorshipCard = styled(Card)``;

export const HealthSponsorshipCardContents = styled.div`
	display: flex;
	align-items: center;
`;

export const CompanyLogo = styled(Image).attrs(props => ({
	width: 64,
	height: 64,
	src: props.src ?? '/_n/images/employerco-square.png',
	alt: 'Your square logo',
}))`
	border-radius: ${units(1.5)};
	flex: 0 0 auto;
	margin-right: ${units(1.5)};
`;

export const SponsorInfo = styled.div`
	flex-grow: 1;
	overflow-x: hidden;
`;

export const SponsorName = styled(Text).attrs({ el: TextElements.Div, weight: FontWeights.Demi })`
	overflow-x: hidden;
	text-overflow: ellipsis;
`;

export const SponsorSubtitle = styled(Text).attrs({ el: TextElements.Div })``;

export const RightChevron = styled(ChevronRight)`
	flex: 0 0 auto;
	path {
		fill: ${palette('black')};
	}
`;

export const GrayHr = styled(Hr).attrs({ color: 'black', opacity: 0.1 })``;

export const ScreeningSectionHeader = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Medium,
	size: FontSizes.lg,
})`
	padding: ${units(3, 0)};
`;

export const ScreeningCard = styled(Card)``;

export const ScreeningBanner = styled(Image).attrs(props => ({
	width: 299,
	height: 196.5,
	src: props.src ?? '/_n/images/mental-health-screening-banner.png',
	alt: 'Your square logo',
}))`
	background-color: #a0b4f0;
	border-radius: ${units(1)};
`;

export const SenderInfo = styled.div`
	margin: ${units(1.5, 0)};
`;

export const SenderProfilePicture = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.sm,
	weight: FontWeights.Demi,
})`
	display: inline-flex;
	color: ${palette('white')};
	background-color: #00658e;
	border-radius: 100%;
	width: ${units(2)};
	height: ${units(2)};
	align-items: center;
	justify-content: center;
	/* Centering the letter */
	padding: 1px 1px 0 0;
	margin-right: ${units(0.5)};
`;

export const SenderName = styled(Text).attrs({ size: FontSizes.sm, weight: FontWeights.Demi })`
	color: #00658e;
`;

export const ScreeningMessageHeader = styled(Text).attrs({ el: 'div', weight: FontWeights.Demi })``;
