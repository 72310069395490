import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export const SectionContainer = styled.div`
	background: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	box-sizing: border-box;
	${boxShadow}
	border-radius: ${units(0.5)};
	padding: ${units(3)};
	width: 100%;
	color: ${palette('gray7')};
	margin-bottom: ${units(3)};
`;

export const Title = styled.div`
	${fontSize(FontSizes.lg)};
	margin-bottom: ${units(2)};
	display: inline-flex;
	align-items: center;
	svg {
		display: block;
		width: ${units(6)};
	}
`;

export const MinHeightCell = styled.div`
	min-height: ${units(5)};
`;

export const MobileWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: ${units(2)};
	padding: ${units(2, 0)};
`;
export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${units(2)};
`;

export const MobileLabel = styled.span`
	color: ${palette('gray5')};
	text-transform: uppercase;
	${fontSize(FontSizes.sm)};
`;
export const BillingHistoryDataWrapper = styled.div`
	${MobileWrapper}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray1')};
	}
`;
