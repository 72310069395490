import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Card, FormInput } from '@calm-web/design-system';

import Subtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';

import { HelperLink } from '../HelperDocs/styles';
import messages from './messages';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit: boolean;
}

const MsftTeamsTenant: FC<Props> = ({ formProps, isEdit }) => {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const canEdit = hasValidPermissions('msft_teams_tenant_id', requiredPermissions);
	const inputProps = formProps.bindWithErrorProps('tenantId', 'text');

	return (
		<Card>
			<CellTitle id="tenant-id-field-label">{formatMessage(messages.tenantIdLabel)}</CellTitle>
			<Subtitle>
				{formatMessage(messages.tenantIdDescription1)}
				<br />
				{formatMessage(messages.tenantIdDescription2, {
					tenantdetails: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://learn.microsoft.com/en-us/sharepoint/find-your-office-365-tenant-id"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</HelperLink>
					),
				})}
			</Subtitle>
			<FormInput
				{...inputProps}
				aria-labelledby="tenant-id-field-label"
				placeholder={formatMessage(messages.tenantIdPlaceholder)}
				disabled={!canEdit}
				noMargin
			/>
		</Card>
	);
};

export default MsftTeamsTenant;
