import styled from 'styled-components';

import { FontSizes, FontWeights, Text, TextElements, units } from '@calm-web/design-system';

export const Subtitle = styled(Text).attrs({
	color: 'gray8',
	el: TextElements.H4,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	margin-top: 0;
	margin-bottom: ${units(1.5)};
	width: 100%;
`;
