import NextJSImage from 'next/image';
import styled from 'styled-components';

import { FontSizes, FontWeights, Text, TextElements, units } from '@calm-web/design-system';

import CheckmarkSvg from 'icons/check-simple.svg';

const scale = 0.75;
export const ViewPort = styled.div`
	position: relative;
	overflow-y: hidden;
	overflow-x: hidden;
	left: -${(100 / scale - 100) / 2}%;
	top: -${(100 / scale - 100) / 2}%;
	transform: scale(${scale});
	height: calc((100% - 18px) / ${scale});
	width: calc((100% - 18px) / ${scale});
	margin: calc(9px / ${scale});
	padding: ${units(0, 2)};
	z-index: -1;
`;

export const Header = styled.div`
	width: 100%;
	margin-top: 50px;
	margin-bottom: ${units(2.5)};
	top: ${units(5)};
	left: ${units(13)};
	text-align: center;
`;

export const Background = styled.div`
	border-radius: ${units(6)};
	height: 100%;
	width: 98%;
	background: linear-gradient(180deg, #e2f6ff 0%, #fdf7f9 100%);
	position: absolute;
	z-index: -1;
	margin: 0 auto;
	left: 0;
	right: 0;
`;

export const WhiteBox = styled.div`
	position: relative;
	background: white;
	text-align: center;
	border-radius: ${units(2)};
	padding: ${units(2.5)};
	margin-bottom: ${units(3)};
`;

export const Image = styled(NextJSImage).attrs({
	fill: true,
})`
	object-fit: contain;
`;

export const ImageContainer = styled.div`
	position: relative;
	height: ${units(4.5)};
	margin: 0 auto ${units(1)};
`;

export const Link = styled(Text).attrs({
	color: 'linkBlue',
	size: FontSizes.base,
	weight: FontWeights.Demi,
})`
	display: block;
	margin: ${units(2, 0)};
`;

export const SubmitButton = styled(Text).attrs({
	size: FontSizes.sm,
})`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0060a9;
	color: white;
	border-radius: ${units(4)};
	padding: ${units(1, 2)};
	flex-grow: 1;
	font-weight: 600;
`;

export const Checkmark = styled(CheckmarkSvg)`
	width: ${units(2)};
	height: ${units(2)};
	margin-right: ${units(1)};
	font-size: ${units(1)};
`;

export const SkipButton = styled(Text).attrs({
	size: FontSizes.sm,
})`
	color: #0060a9;
	background: white;
	border: 2px solid #0060a9;
	border-radius: ${units(4)};
	padding: ${units(1, 2)};
	margin-right: ${units(1.5)};
	font-weight: 600;
`;

export const PageTitle = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Demi,
})`
	margin-bottom: ${units(1)};
`;

export const StepIndicatorWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	margin-bottom: ${units(2)};
`;

export const StepIndicatorBarBlue = styled.div`
	width: 20px;
	height: 3px;
	background: #007fb2;
`;

export const StepIndicatorBarGrey = styled.div`
	width: 20px;
	height: 3px;
	background: #8a9498;
`;

export const EligibilityTitle = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.base,
	weight: FontWeights.Demi,
})``;

export const EligibilityBody = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.sm,
})`
	overflow-wrap: break-word;
`;

export const BreakText = styled(Text).attrs({ el: TextElements.Label })`
	overflow-wrap: break-word;
`;

export const RequiredText = styled(Text).attrs({ weight: FontWeights.Medium })`
	color: #0d006a;
`;
