import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, Text, units } from '@calm-web/design-system';

import LockSvg from 'icons/lock-filled.svg';

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: ${units(2)};
`;

export const PercentText = styled(Text)`
	z-index: 1;
	text-shadow: 1px 1px 2px ${palette('blackAlpha60')};
`;

export const ProgressBarContainer = styled.div`
	display: flex;
	justify-content: center;
	width: ${units(8)};
	overflow: hidden;
	position: relative;
	border-radius: ${units(1)};
	background-color: ${palette('blackAlpha60')};
`;

export const ProgressBar = styled.div<{ $percentComplete: string }>`
	position: absolute;
	left: 0;
	height: 100%;
	color: ${palette('white')};
	background: ${palette('gradientBrightGreen')};
	width: ${p => (p.$percentComplete ? `${p.$percentComplete}%` : 0)};
`;

export const ActionItemsGroup = styled.div`
	border-radius: ${units(2)};
`;
export const TabButton = styled(Button).attrs({
	textColor: 'black',
	backgroundColor: 'transparent',
	hideShadow: true,
})<{ $isActive: boolean }>`
	display: flex;
	align-items: center;
	gap: ${units(1.5)};
	border-bottom: ${p => (p.$isActive ? '1px solid black' : 'none')};
	border-radius: 0;
	color: ${p => (p.$isActive ? palette('black') : palette('gray4'))};
	margin-bottom: ${units(1)};
	transition: none;
	padding-left: 0;
	padding-right: 0;
`;

export const LockIconInline = styled(LockSvg)`
	height: ${units(3)};
	width: ${units(3)};

	path {
		fill: ${p => (p.isActive ? palette('black') : palette('gray4'))};
	}
`;
export const LockIcon = styled(LockSvg)`
	height: ${units(6)};
	width: ${units(6)};
	margin-bottom: ${units(1)};
	path {
		fill: ${palette('blackAlpha20')};
	}
`;
