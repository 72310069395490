import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { Text, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export const Wrapper = styled.div<{ $isDefault: boolean }>`
	border: 1px solid ${palette('gray1')};
	border-radius: 6px;
	background-color: ${p => (p.$isDefault ? palette('gray0')(p) : 'transparent')};
	margin-bottom: ${units(2)};
`;

export const OptionsTile = styled.div`
	display: flex;
	flex: 0.7;
	align-items: center;
	justify-content: center;
`;

export const MetadataTileWrapper = styled.div<{ $flexGrow?: number }>`
	flex: 1;
	flex-grow: ${p => p.$flexGrow ?? 1};
`;

export const MetadataWrapper = styled.dl<{ $setFlex?: boolean }>`
	padding: 0;
	display: flex;

	& > div {
		${ifProp('$setFlex', 'flex: 1;')}
		min-width: 0;
		margin: ${units(1)};
	}

	dt,
	dd {
		margin: 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const SponsorshipDescription = styled(Text).attrs({ color: 'gray5' })`
	flex: 3;
	min-width: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const EditLink = styled.a`
	color: ${palette('blue3')};
	flex: 1;
	min-width: 0;
	margin: ${units(1)} 0;
	text-decoration: none;
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	align-items: baseline;
`;

export const OptionsContainer = styled.div`
	position: absolute;
	display: none;
	background-color: ${palette('white')};
	z-index: 2;
	overflow: hidden;
	flex-direction: column;
	bottom: 200;
	background-color: ${palette('white')};
	border-radius: ${theme('layout.borderRadius')};
	border: 1px solid ${palette('gray2')};
	${boxShadow}
	min-width: ${units(24)};
	display: flex;
`;

export const OptionRow = styled.a`
	cursor: pointer;
	&:hover {
		background-color: ${palette('gray1')};
	}
	padding: ${units(1)} ${units(2)};
	text-decoration: none;
`;

export const Overlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100% !important;
	left: 0;
	top: 0;
	z-index: -1;
`;
