import styled from 'styled-components';

import { maxWidth, units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	display: flex;
	gap: ${units(2)};

	${maxWidth('desktopLarge')} {
		flex-wrap: wrap;
	}
`;
export const ConfigWrapper = styled.div`
	max-width: ${units(85)};
`;
