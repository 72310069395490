import 'tippy.js/dist/tippy.css';

import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	Banner,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	Text,
	TextElements,
	units,
} from '@calm-web/design-system';

export const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	position: relative;
`;

export const StyledBanner = styled(Banner)`
	font-style: italic;
	margin-bottom: ${units(2)};
	box-shadow: none;
`;

export const LoaderContainer = styled.div`
	width: auto;
	height: 80px;
	margin: 0 auto;
`;

export const TooltipTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	td:not(:first-child) {
		padding-left: ${units(1)};
		text-align: right;
	}
`;

export const TopContentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
	width: 100%;
`;

export const MoreContentContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	flex-wrap: wrap;
	color: ${palette('gray5')};
	padding: ${units(2)} 0; // TODO: in the future we should set a min height for the content cell and never add padding to the container itself

	svg {
		width: ${units(7)};
		height: ${units(7)};
	}
`;

export const MoreContentTitle = styled.div`
	width: 100%;
	text-align: center;
`;

export const MoreContentSubtitle = styled.div`
	width: 100%;
	text-align: center;
	${fontSize(FontSizes.sm)};
`;

export const DatumDivider = styled.div`
	width: 100%;
	margin: ${units(3)} 0;
	background: ${palette('gray1')};
	height: 1px;
`;

export const ModalText = styled.div`
	text-align: center;
`;

export const ErrorMessage = styled.p`
	a {
		color: ${palette('blue3')};
		text-decoration: underline;
	}
	max-width: ${units(85)};
`;

export const IncreaseCoverageContainer = styled.div`
	text-align: center;
	margin-bottom: ${units(2)};
`;

export const IncreaseCoverageHeader = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const HelpMessage = styled.div`
	a {
		color: ${palette('blue3')};
	}
	div {
		display: block;
	}
`;

export const SecondaryHeader = styled(Text).attrs({
	el: TextElements.H2,
	size: FontSizes.lg,
	weight: FontWeights.Medium,
})``;
