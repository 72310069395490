import { CalmError } from '@/utils/apiRequest/errors';

import { FeatureFlagNames, useFeatureFlags } from '../api/useFeatureFlags';

export const useIsChPremiumEnabled = (): {
	isChPremiumEnabled: boolean;
	loading: boolean;
	error: Error | CalmError | undefined;
} => {
	const { data: flagValues, loading, error } = useFeatureFlags(FeatureFlagNames.B2B_CH_PREMIUM_PLAN_TIER);
	const isChPremiumEnabled = Boolean(flagValues?.[FeatureFlagNames.B2B_CH_PREMIUM_PLAN_TIER]);

	return { isChPremiumEnabled, loading, error };
};
