import styled from 'styled-components';

import {
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	SecondaryButton,
	units,
} from '@calm-web/design-system';

export const ModalCancelButton = styled(SecondaryButton)`
	margin-right: ${units(4)};
	box-shadow: none;
`;

export const Paragraph = styled.p`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
`;

export const List = styled.li`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
`;
