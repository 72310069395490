import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, FormSelect, Text, TextButton, units } from '@calm-web/design-system';

export const RowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	${fontSize(FontSizes.sm)};
	cursor: pointer;
	padding: ${units(1, 3)};
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover {
		// blue1 with alpha 0.16
		background: rgb(59, 190, 236, 0.16);
		text-decoration: underline;
		color: ${palette('blue3')};
	}
`;

const HEADER_SPACE_BETWEEN = 0.5;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin: ${units(-HEADER_SPACE_BETWEEN)};
	align-items: center;
	& > * {
		margin: ${units(HEADER_SPACE_BETWEEN)};
	}
`;

export const FormInputWrapper = styled.div`
	flex: 1 0 calc(33% - ${units(2 * HEADER_SPACE_BETWEEN)});
`;

export const CategorySelect = styled(FormSelect)`
	flex: 1 0 calc(33% - ${units(2 * HEADER_SPACE_BETWEEN)});
`;

export const NameColumn = styled.div`
	padding: ${units(1)};
	padding-left: ${units(3)};
`;

export const NameText = styled(Text).attrs({
	lineHeight: 1.5,
})``;

export const ParentColumn = styled.div`
	padding: ${units(1)};
`;

export const ParentText = styled(Text).attrs({
	lineHeight: 1.5,
	color: 'gray6',
})`
	font-size: ${units(1.5)};
`;

export const SlugColumn = styled.div`
	padding: ${units(1)};
`;

export const SlugText = styled(Text).attrs({
	lineHeight: 1.5,
	color: 'gray6',
})`
	font-size: ${units(1.5)};
`;

export const ManageColumn = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ManageText = styled(Text).attrs({
	lineHeight: 1.5,
	size: FontSizes.sm,
	align: 'right',
	color: 'blue3',
})`
	flex: 1;
`;

export const ActionButton = styled(TextButton).attrs({
	lineHeight: 1.5,
	size: FontSizes.sm,
	align: 'right',
	hideUnderline: true,
})<{ $isFirst?: boolean }>`
	flex: 1;
	flex: 0 1 auto;
	${props => (props.$isFirst ? '' : `padding-left: ${units(3)};`)}
	&:hover {
		text-decoration: underline;
	}
`;

export const customRowStyles = `
cursor: pointer;
transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
&:hover {
	// blue1 with alpha 0.16
	background: rgb(59, 190, 236, 0.16);
	text-decoration: underline;
	color: ${palette('blue3')};
`;
