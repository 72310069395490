import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	Banner,
	Button,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	maxWidth,
	minWidth,
	TextButton,
	units,
} from '@calm-web/design-system';

const SectionStyle = styled.div`
	background-color: ${palette('white')};
	border-radius: ${units(2)};
	padding: ${units(3)};
	margin-bottom: ${units(2)};
`;

export const Wrapper = styled.div`
	${minWidth('desktop')} {
		margin-bottom: ${units(13)};
	}
`;

export const Header = styled.div<{ customMargin?: number }>`
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${p => (p.customMargin ? units(p.customMargin) : 0)};
`;

export const ReviewBanner = styled(SectionStyle)``;

export const ReviewGrid = styled.div`
	display: flex;
	gap: ${units(3)};

	${maxWidth('tablet')} {
		flex-wrap: wrap;
	}
`;

export const ReviewColumn = styled.div`
	width: 100%;
	${minWidth('mobileLarge')} {
		:not(:first-child) {
			border-left: 1px solid ${palette('gray3')};
			padding-left: ${units(2)};
		}
	}

	${minWidth('tablet')} {
		width: 25%;
	}
`;

export const ColumnHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${palette('gray5')};
	margin-bottom: ${units(1)};

	${minWidth('desktop')} {
		gap: ${units(8)};
	}
`;

export const ColumnValue = styled.div`
	${fontWeight(FontWeights.Medium)};
	// Create breathing room for the edit button
	padding-right: ${units(4)};
`;

export const EditButton = styled(TextButton)`
	text-decoration: none;
	color: ${palette('blue3')};
	${fontSize(FontSizes.sm)};
`;

export const Section = styled.div<{ width: number }>`
	width: ${p => `${p.width}%`};
`;

export const PreviewContainer = styled(SectionStyle)``;

export const TopContent = styled.div`
	display: flex;
	align-items: center;
	grid-gap: ${units(2)};
	justify-content: space-between;
`;

export const PreviewContent = styled.div`
	background-color: ${palette('gray0')};
	padding: ${units(2)};
	width: 100%;
	margin-top: ${units(2)};
	overflow-x: scroll;
`;

export const ContentContainer = styled.div`
	display: grid;
	grid-gap: ${units(3)};
	grid-auto-flow: column;
	grid-auto-columns: 160px;
	margin-top: ${units(2)};
`;

export const Item = styled.div``;

export const Title = styled.div`
	color: ${palette('gray5')};
`;

export const DateValue = styled.div`
	color: ${palette('gray5')};
	margin-bottom: ${units(1.5)};
`;

export const ResourceLink = styled(Button).attrs({
	iconColor: 'blue3',
	backgroundColor: 'gray0',
	textColor: 'blue3',
	hideShadow: true,
	padding: 0,
})`
	margin-top: ${units(1)};
	text-decoration: none;
	padding-left: 0;
	white-space: nowrap;
`;

export const SampleContentContainer = styled.div<{ hasEmails: boolean }>`
	${ifProp(
		'hasEmails',
		css`
			margin-left: 23rem;
		`,
	)}
`;

export const ResourceContainer = styled(SectionStyle)``;

export const CloseButton = styled(Button)``;

export const EditBanner = styled(Banner).attrs({
	textColor: 'bannerTextSuccess',
	backgroundColor: 'bannerBgSuccess',
})`
	padding: ${units(4)};
	${fontSize(FontSizes.base)};
	margin-bottom: ${units(2)};
	border-radius: ${units(1)};
`;

export const TestEmailSendContainer = styled(SectionStyle)``;
