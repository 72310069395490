import styled, { css } from 'styled-components';
import { ifNotProp, palette } from 'styled-tools';

import { fontSize, FontSizes, FontWeights, maxWidth, TextButton, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';
import InfoOutline from 'icons/info-outline.svg';

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2)};
	height: ${units(2)};
	margin-right: ${units(1)};
`;

export const Banner = styled.div<{ fullWidth: boolean }>`
	height: ${units(4)};
	padding: ${units(0, 2)};
	${ifNotProp(
		'fullWidth',
		css`
			margin: ${units(1)};
			${boxShadow}
			border-radius: 4px;
		`,
	)};
	font-weight: ${FontWeights.Medium};
	box-sizing: border-box;
	display: flex;
	align-items: center;
	background: ${palette('gold')};
	${fontSize(FontSizes.sm)};

	${maxWidth('desktop')} {
		padding: ${units(2.5)};
	}
`;

export const BannerTextButton = styled(TextButton).attrs({ size: FontSizes.sm, textColor: 'text' })``;
