import { ChangeEvent, ReactElement } from 'react';

import { Card, FormSelect } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { ClientType as ClientTypeEnum, Partner } from '@/types/store/reducers';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit?: boolean;
	partner?: Partner;
}

const employerClientTypeOption = {
	label: 'Employer',
	value: ClientTypeEnum.EMPLOYER,
};
const payorClientTypeOption = {
	label: 'Payor',
	value: ClientTypeEnum.PAYOR,
};
const payorBuyUpClientTypeOption = {
	label: 'Payor buy-up',
	value: ClientTypeEnum.PAYOR_BUYUP,
};
const eapClientTypeOption = {
	label: 'EAP',
	value: ClientTypeEnum.EAP,
};

const clientTypeOptions = [
	payorClientTypeOption,
	payorBuyUpClientTypeOption,
	eapClientTypeOption,
	employerClientTypeOption,
];

export default function ClientType({ formProps }: Props): ReactElement {
	const handleChange = (newClientType: string): void => {
		return formProps.setProperty('clientType', newClientType);
	};

	return (
		<>
			<Card>
				<CellTitle showAdminFlag id="client-type-label">
					Client Type
				</CellTitle>
				<div>
					<FormSelect
						{...formProps.bindWithErrorProps('clientType', 'select')}
						data-testid="select-client-type"
						name="client-type"
						placeholder="Select a client type"
						aria-labelledby="client-type-label"
						onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.value)}
						options={clientTypeOptions}
					/>
				</div>
			</Card>
		</>
	);
}
