import { rgba } from 'polished';
import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { fontWeight, FontWeights, TextArea, units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: ${units(51)};
	text-align: center;
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	@media only screen and (min-height: 800px) {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const Container = styled.div`
	width: 100%;
	background: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	border-radius: ${theme('inputs.borderRadius')};
	padding: ${units(3)};

	button {
		margin-top: ${units(3)};
	}
`;

export const Title = styled.h5``;

export const Subtitle = styled.div`
	margin-top: ${units(1)};
	font-size: ${units(1.75)};
`;

export const HelpLink = styled.a`
	color: ${palette('blue3')};
	font-size: ${units(1.5)};
	margin-top: ${units(2)};
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: ${(props): string => rgba(palette('black')(props), 0.7)};
	margin: ${units(3, 0)};
`;

export const TextAreaWrapper = styled.div`
	position: relative;
`;

export const StyledTextArea = styled(TextArea)`
	min-height: ${units(22.5)};
	max-height: ${units(40)};
	${fontWeight(FontWeights.Medium)};
`;

export const CharCount = styled.div`
	color: ${palette('gray3')};
	${fontWeight(FontWeights.Medium)};
	position: absolute;
	right: ${units(2)};
	bottom: ${units(1.5)};
	z-index: 1;
	opacity: 0.7;
`;
