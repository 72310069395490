import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearEligibilityCache } from '@/hooks/api/useEligibility';
import { updateStats } from '@/hooks/api/usePartnerStats';
import { setBannerMessage } from '@/store/actions';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { ELIGIBILITY_ERRORS } from '@/utils/errors';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export interface UploadResults {
	totalCount: number;
	deletedCount: number;
	deletedSegmentCount: number;
	duplicateCount: number;
	addedCount: number;
	addedSegmentCount: number;
	changedSegmentValueCount: number;
	isSignificantOverage: boolean;
}

// BIPS-2424: Max File Size allowed is set in the API Repo
export const MAX_FILE_SIZE_LIMIT = 1e7;

const THREE_MINUTES = 1000 * 60 * 3;

export function useUploadEligibilityFile(
	partnerId: string,
	isPreview = false,
): [(file: File) => Promise<ApiResponse<UploadResults>>, ApiResponse<UploadResults>] {
	const apiRequest = useApi();
	const [uploadResults, setUploadResults] = useState<UploadResults>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const upload = async (file: File): Promise<ApiResponse<UploadResults>> => {
		setError(undefined);

		if (loading) return { loading: true };
		try {
			setLoading(true);
			if (file.size >= MAX_FILE_SIZE_LIMIT) {
				throw new Error(ELIGIBILITY_ERRORS.FILE_TOO_LARGE);
			}
			const formData = new FormData();
			formData.append('data', file);

			const queryParams = new URLSearchParams({
				preview: String(isPreview).toLowerCase(),
			}).toString();

			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/eligibility/upload?${queryParams}`,
				method: 'POST',
				body: formData,
				timeout: THREE_MINUTES,
			});
			const { data } = response;

			setUploadResults(data);
			if (!isPreview) {
				await Promise.all([clearEligibilityCache(partnerId), updateStats(partnerId)]);
			}
			return {
				data,
				loading: false,
			};
		} catch (err) {
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [upload, { data: uploadResults, loading, error }];
}

export function useSyncEligibility(
	partnerId: string,
	isPreview?: boolean,
): [() => Promise<void>, ApiResponse<UploadResults> & { clearData: () => void }] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<UploadResults | undefined>();

	async function sync(): Promise<void> {
		try {
			setError(undefined);
			setLoading(true);
			const query = isPreview ? '?preview=true' : '';
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/eligibility/sync${query}`,
				method: 'POST',
			});
			if (!isPreview) {
				await clearEligibilityCache(partnerId);
			}
			setData(response.data);
		} catch (err) {
			setError(getCalmErrorOrError(err));

			dispatch(
				setBannerMessage({
					message: 'Unable to sync eligibility list',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}

	function clearData(): void {
		setData(undefined);
		setLoading(false);
		setError(undefined);
	}

	return [sync, { error, loading, data, clearData }];
}
