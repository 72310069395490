import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	fontWeight,
	FontWeights,
	maxWidth,
	minWidth,
	PrimaryButton,
	SecondaryButton,
	units,
} from '@calm-web/design-system';

export const Wrapper = styled.div`
	margin-top: ${units(2)};
`;

export const Banner = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: ${units(2)};
	> :first-child {
		border-top-left-radius: ${units(2)};
		border-bottom-left-radius: ${units(2)};
	}
	> :last-child {
		border-top-right-radius: ${units(2)};
		border-bottom-right-radius: ${units(2)};
	}
`;

export const CreateContainer = styled.div``;

export const ButtonContainer = styled.div`
	display: flex;
	opacity: 1;
	width: max-content;
	right: 0;
	bottom: ${units(2)};
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 8px 16px rgb(0 0 0 / 15%);
	border-radius: 8px;
	margin: ${units(2)};
	padding: ${units(2)} 0 ${units(2)} ${units(2)};

	${maxWidth('tablet')} {
		position: static;
	}

	${minWidth('desktop')} {
		position: fixed;
	}
`;

export const SuccessModalDescription = styled.div`
	margin-bottom: ${units(4)};
	color: ${palette('gray6')};
`;

export const SuccessModalHeader = styled.div`
	color: ${palette('gray6')};
`;

export const SuccessModalSection = styled.div`
	margin-bottom: ${units(4)};
`;

export const SuccessModalSchedule = styled.div`
	margin-bottom: ${units(2)};
`;

export const ActionButton = styled(PrimaryButton)`
	margin-right: ${units(2)};
`;

export const BackButton = styled(SecondaryButton)`
	margin-right: ${units(2)};
	border: 1px solid ${palette('blue3')};
`;

export const CancelButton = styled(BackButton)``;

export const ModalCancelButton = styled(SecondaryButton)`
	margin-right: ${units(4)};
	box-shadow: none;
`;

export const ErrorMessage = styled.div`
	padding-top: ${units(6)};
	${fontWeight(FontWeights.Medium)};
	text-align: center;
`;
