import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	Button,
	ButtonSizes,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	maxWidth,
	SecondaryButton,
	Text,
	TextButton,
	units,
} from '@calm-web/design-system';

import { CalloutBase } from '@/components/ui/Callout/styles';
import { boxShadow } from '@/utils/ui/boxShadow';
import CircleMinusSvg from 'icons/circle-minus.svg';
import CirclePlusSvg from 'icons/circle-plus.svg';
import InfoOutline from 'icons/info-outline.svg';
import TosSvg from 'icons/tos.svg';
import WarningSvg from 'icons/warning-filled.svg';

import ExampleSegmentedSvg from './eligibility-file-example-segmented.svg';

export const FileTooLargeError = styled.div`
	margin-top: ${units(2)};
	margin-bottom: ${units(2)};
	color: ${palette('heartRed')};
	${fontWeight(FontWeights.Demi)};
	font-size: ${units(2)};
	text-align: left;
`;

export const WarningIcon = styled(WarningSvg)`
	margin: ${units(0, 1, 0, 2)};
	width: ${units(4)};
	height: auto;
	vertical-align: middle;
	position: relative;
	top: -1px;
`;

export const FileInputButton = styled.input`
	width: 100%;
	height: 100%;
	/* All this nonsense is to get the cursor:pointer working properly on the VanityButton */
	opacity: 0;
	font-size: 0;
	text-indent: -100px;
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;

	&:hover + button {
		&:before {
			opacity: 1;
		}
	}
`;

export const EligibilityFileContainer = styled.div`
	display: flex;
	margin-bottom: ${units(3)};
`;

export const EligibilityFileNameContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const NewFileLabel = styled.span`
	flex: 0 1 0;
	${fontWeight(FontWeights.Medium)};
	white-space: nowrap;
	margin-right: ${units(1)};
`;

export const FileIcon = styled(TosSvg)`
	path {
		fill: ${palette('blue2')};
	}
	flex: 0 0 auto;
	height: ${units(6)};
	width: auto;
`;

export const DifferentFileButton = styled(TextButton).attrs({ size: FontSizes.sm, hideUnderline: true })``;

export const OverwriteCallout = styled(CalloutBase)`
	background-color: ${(props): string => rgba(palette('gold')(props), 0.1)};
	border: 4px solid ${palette('gold')};

	width: 100%;
	${fontSize(FontSizes.sm)}
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

export const OverwriteDetail = styled.div`
	&:not(:last-of-type) {
		margin-bottom: ${units(1)};
	}
	display: flex;
	align-items: center;
`;

const overwriteSvgCss = css`
	height: ${units(2.5)};
	margin-right: ${units(1)};
	width: auto;
`;

export const OverwriteAdd = styled(CirclePlusSvg)`
	path {
		fill: ${palette('buttonGreen')};
	}
	${overwriteSvgCss};
`;

export const OverwriteRemove = styled(CircleMinusSvg)`
	path {
		fill: ${palette('errorRed')};
	}
	${overwriteSvgCss};
`;

export const OverwriteWarning = styled(WarningSvg)`
	path {
		fill: ${palette('errorRed')};
	}
	${overwriteSvgCss};
`;

export const PurchaseMoreMessageContainer = styled.div`
	margin-top: ${units(3)};

	div {
		margin-bottom: ${units(1)};
	}
`;

export const UploadErrorMessage = styled(Text).attrs({
	el: 'p',
})``;

export const CancelButton = styled(Button)`
	margin-right: ${units(1)};
`;

export const FooterButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: ${units(1)};
`;

export const ShowExampleButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })`
	margin: ${units(3)} 0;
`;

export const ExampleSegmentedSvgStyled = styled(ExampleSegmentedSvg)`
	width: 100%;
	${boxShadow}
`;

export const ExampleContainer = styled.div`
	text-align: center;

	${ExampleSegmentedSvgStyled} {
		margin-bottom: ${units(2)};
	}
`;

export const Description = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const DescriptionContainer = styled.div<{ $centerAlignItems?: boolean; $fullRow?: boolean }>`
	${fontSize(FontSizes.sm)}
	flex: 1 1 0;
	border-right: 1px solid ${palette('gray1')};
	padding-right: ${units(2)};
	${ifProp('$fullRow', 'border-right: none; padding-right: 0')};
	${ifProp('$centerAlignItems', 'align-items: center')};

	${maxWidth('tablet')} {
		border-right: none;
		border-bottom: 1px solid ${palette('gray1')};
		padding-bottom: ${units(2)};
	}

	${Description} {
		margin-bottom: ${units(1)};
	}
`;

export const DescriptionContainerInner = styled.div`
	display: flex;
	flex-direction: row;
`;

export const InfoOutlineStyled = styled(InfoOutline)`
	width: ${units(2)};
	height: ${units(2)};
`;

export const BodySegmentedVariation = styled.div`
	${ShowExampleButton} {
		display: flex;
		margin-top: ${units(3)};
	}

	${InfoOutlineStyled} {
		margin-right: ${units(0.5)};
	}
`;

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	${maxWidth('tablet')} {
		flex-direction: column;
	}
`;

export const FormStack = styled.div`
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	padding: ${units(2)};
`;

export const HelperLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: none;
`;

export const SegmentedDescription = styled.div`
	${fontSize(FontSizes.sm)};
	text-align: left;

	ul {
		padding-left: ${units(2)};
		margin: ${units(0.5, 0)};
	}
`;

export const AnnouncementBody = styled.div`
	padding: ${units(2)};
	background: linear-gradient(white, white) padding-box, ${palette('gradientPink')} border-box;
	border: 4px solid transparent;
	padding: ${units(2)};
	margin-bottom: ${units(3)};
`;

export const AnnouncementTitle = styled.div`
	${fontWeight(FontWeights.Regular)};
	width: 100%;
`;

export const AnnouncementDescription = styled.div`
	${fontSize(FontSizes.sm)}
`;

export const UploadErrorStyled = styled.div`
	margin-bottom: ${units(2)};
	color: ${palette('heartRed')};
`;

export const TitleWrapper = styled.div`
	margin-bottom: ${units(2)};
`;

export const Title = styled(Text).attrs({
	size: FontSizes.lg,
})``;
