import { ReactElement, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { ProgramOrGuideItem } from '@/types/content';
import redirect from '@/utils/redirect';
import DocumentIcon from 'icons/tos.svg';

import Resources from '../../Resources';
import SampleContentCards from '../../SampleContentCards';
import AnnouncementEmailPreview from '../AnnouncementEmailPreview';
import PreviewNotificationTemplate from '../PushNotificationPreview';
import ReminderEmailPreview from '../ReminderEmailPreview';
import messages from './messages';
import {
	CloseButton,
	ColumnHeader,
	ColumnValue,
	ContentContainer,
	DateValue,
	EditBanner,
	EditButton,
	Header,
	Item,
	PreviewContainer,
	PreviewContent,
	ResourceContainer,
	ResourceLink,
	ReviewBanner,
	ReviewColumn,
	ReviewGrid,
	SampleContentContainer,
	TestEmailSendContainer,
	Title,
	TopContent,
	Wrapper,
} from './styles';
import TestEmailSend from './TestEmailSend';

function Review({ canSeeCohorts }: { canSeeCohorts?: boolean }): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const {
		selectedPathway,
		partnerId,
		startDate,
		endDate,
		announcementDate,
		reminderDate,
		cohortCount,
		timelineData,
		isEdit,
		isReadOnly,
		nextTabRouting,
		serverTimeZone,
		emailSettings,
		shouldUsePathwaysV2,
	} = useContext(PathwaysContext);
	const [isAnnouncementPreviewModalOpen, setIsAnnouncementPreviewModalOpen] = useState<boolean>(false);
	const [isReminderPreviewModalOpen, setIsReminderPreviewModalOpen] = useState<boolean>(false);
	const [isNotificationPreviewModalOpen, setIsNotificationPreviewModalOpen] = useState<boolean>(false);
	const [notificationDaySelected, setNotificationDaySelected] = useState<number>(0);
	const isCompleted = isEdit && endDate && endDate < new Date();
	const hasEmailSettings = !shouldUsePathwaysV2 || (shouldUsePathwaysV2 && emailSettings === 'on');

	const currentDateMilliseconds = new Date().getTime();
	const diffTimeAnnounce =
		!isCompleted && !isReadOnly && announcementDate
			? Math.abs(currentDateMilliseconds - announcementDate?.getTime())
			: 0;
	const diffTimeStart =
		!isCompleted && !isReadOnly && startDate ? Math.abs(currentDateMilliseconds - startDate?.getTime()) : 0;

	const announceDayDelta = Math.ceil(diffTimeAnnounce / (1000 * 60 * 60 * 24));
	const startDayDelta = Math.ceil(diffTimeStart / (1000 * 60 * 60 * 24));

	const logEventName = 'Partner Portal : Pathways : Edit Button : Clicked';
	const logEventProps = {
		step: 'selection',
		is_edit: isEdit,
		pathway_id: selectedPathway?.id || '',
		selected_pathway: selectedPathway?.pack_class || '',
	};

	return (
		<Wrapper>
			<Modal
				isOpen={isAnnouncementPreviewModalOpen}
				closeModal={() => setIsAnnouncementPreviewModalOpen(false)}
				title={formatMessage(messages.announcementPreviewTitle)}
				footer={
					<CloseButton
						backgroundColor="blue3"
						onPress={() => setIsAnnouncementPreviewModalOpen(false)}
						type="button"
					>
						{formatMessage(messages.closeText)}
					</CloseButton>
				}
			>
				<AnnouncementEmailPreview />
			</Modal>
			<Modal
				isOpen={isReminderPreviewModalOpen}
				closeModal={() => setIsReminderPreviewModalOpen(false)}
				title={formatMessage(messages.reminderPreviewTitle)}
				footer={
					<CloseButton
						backgroundColor="blue3"
						onPress={() => setIsReminderPreviewModalOpen(false)}
						type="button"
					>
						{formatMessage(messages.closeText)}
					</CloseButton>
				}
			>
				<ReminderEmailPreview />
			</Modal>
			<Modal
				isOpen={isNotificationPreviewModalOpen}
				closeModal={() => setIsNotificationPreviewModalOpen(false)}
				title={formatMessage(messages.notificationPreviewTitle)}
				footer={
					<CloseButton
						backgroundColor="blue3"
						onPress={() => setIsNotificationPreviewModalOpen(false)}
						type="button"
					>
						{formatMessage(messages.closeText)}
					</CloseButton>
				}
			>
				<PreviewNotificationTemplate daySelected={notificationDaySelected} />
			</Modal>
			{(isReadOnly || isCompleted) && (
				<EditBanner fullPageWidth Icon={null}>
					{isReadOnly && !isCompleted && formatMessage(messages.pathwayStartedText)}
					{isCompleted &&
						formatMessage(messages.pathwayCompletedText, {
							endDate: endDate?.toLocaleDateString(undefined, {
								month: 'numeric',
								day: 'numeric',
								year: '2-digit',
							}),
						})}
				</EditBanner>
			)}
			<ReviewBanner>
				{!isEdit && <Header customMargin={3}>{formatMessage(messages.reviewBannerTitle)}</Header>}
				{isEdit && !isReadOnly && !isCompleted && (
					<Header customMargin={5}>
						{hasEmailSettings
							? formatMessage(messages.canEditMessaging, {
									dayDelta: `${announceDayDelta} ${announceDayDelta === 1 ? 'day' : 'days'}`,
							  })
							: formatMessage(messages.canEditMessagingNoEmails, {
									dayDelta: `${startDayDelta} ${startDayDelta === 1 ? 'day' : 'days'}`,
							  })}
					</Header>
				)}
				<ReviewGrid>
					<ReviewColumn>
						<ColumnHeader>
							<div>{formatMessage(messages.reviewPathwayText)}</div>
							{!isReadOnly && (
								<EditButton
									onClick={() => {
										logEvent(logEventName, logEventProps);
										redirect(`/${partnerId}/pathways/${nextTabRouting}#selection`);
									}}
								>
									{formatMessage(messages.editText)}
								</EditButton>
							)}
						</ColumnHeader>
						<ColumnValue>{selectedPathway?.title}</ColumnValue>
					</ReviewColumn>
					{canSeeCohorts && (
						<ReviewColumn>
							<ColumnHeader>
								<div>{formatMessage(messages.reviewAudienceText)}</div>
								<EditButton
									onClick={() => {
										redirect(`/${partnerId}/pathways/${nextTabRouting}#audience`);
									}}
								>
									{isReadOnly ? formatMessage(messages.viewText) : formatMessage(messages.editText)}
								</EditButton>
							</ColumnHeader>
							<ColumnValue>{cohortCount}</ColumnValue>
						</ReviewColumn>
					)}
					{hasEmailSettings && (
						<ReviewColumn>
							<ColumnHeader>
								<div>{formatMessage(messages.reviewAnnouncementText)}</div>
								{!isReadOnly && (
									<EditButton
										onClick={() => {
											logEvent(logEventName, logEventProps);
											redirect(`/${partnerId}/pathways/${nextTabRouting}#schedule`);
										}}
									>
										{formatMessage(messages.editText)}
									</EditButton>
								)}
							</ColumnHeader>
							<ColumnValue>
								{announcementDate &&
									`${announcementDate.toLocaleDateString(undefined, {
										month: 'numeric',
										day: 'numeric',
										year: '2-digit',
									})}`}
							</ColumnValue>
						</ReviewColumn>
					)}
					<ReviewColumn>
						<ColumnHeader>
							<div>{formatMessage(messages.reviewDurationText)}</div>
							{!isReadOnly && (
								<EditButton
									onClick={() => {
										logEvent(logEventName, logEventProps);
										redirect(`/${partnerId}/pathways/${nextTabRouting}#schedule`);
									}}
								>
									{formatMessage(messages.editText)}
								</EditButton>
							)}
						</ColumnHeader>
						<ColumnValue>
							{startDate && endDate
								? `${startDate.toLocaleDateString(undefined, {
										month: 'numeric',
										day: 'numeric',
										year: '2-digit',
										timeZone: serverTimeZone,
								  })} - ${endDate.toLocaleDateString(undefined, {
										month: 'numeric',
										day: 'numeric',
										year: '2-digit',
										timeZone: serverTimeZone,
								  })}`
								: null}
						</ColumnValue>
					</ReviewColumn>
				</ReviewGrid>
			</ReviewBanner>
			<PreviewContainer>
				<TopContent>
					<Header customMargin={0}>{formatMessage(messages.previewTitle)}</Header>
				</TopContent>
				<PreviewContent>
					<ContentContainer>
						{hasEmailSettings && (
							<>
								<Item>
									<Title>{formatMessage(messages.announcementDateText)}</Title>
									{announcementDate ? (
										<DateValue>
											{announcementDate.toLocaleDateString(undefined, {
												month: 'long',
												day: 'numeric',
												weekday: 'short',
											})}
										</DateValue>
									) : null}
									<ResourceLink Icon={DocumentIcon} onPress={() => setIsAnnouncementPreviewModalOpen(true)}>
										{formatMessage(messages.signupAnnouncement)}
									</ResourceLink>
								</Item>
								<Item>
									<Title>{formatMessage(messages.reminderDateText)}</Title>
									{reminderDate ? (
										<DateValue>
											{reminderDate.toLocaleDateString(undefined, {
												month: 'long',
												day: 'numeric',
												weekday: 'short',
											})}
										</DateValue>
									) : null}
									<ResourceLink Icon={DocumentIcon} onPress={() => setIsReminderPreviewModalOpen(true)}>
										{formatMessage(messages.signupReminder)}
									</ResourceLink>
								</Item>
							</>
						)}
						{timelineData?.map((date: Date, index: number) => (
							<Item key={index}>
								<Title>
									{formatMessage(messages.dayText)} {index + 1}
								</Title>
								{date ? (
									<DateValue>
										{date.toLocaleDateString(undefined, { month: 'long', day: 'numeric', weekday: 'short' })}
									</DateValue>
								) : null}
								<ResourceLink
									Icon={DocumentIcon}
									onPress={() => {
										setIsNotificationPreviewModalOpen(true);
										setNotificationDaySelected(index);
									}}
								>
									{formatMessage(messages.pushNotification)}
								</ResourceLink>
							</Item>
						))}
					</ContentContainer>
					{selectedPathway && (
						<SampleContentContainer hasEmails={hasEmailSettings}>
							<SampleContentCards items={selectedPathway.items as ProgramOrGuideItem[]} />
						</SampleContentContainer>
					)}
				</PreviewContent>
			</PreviewContainer>
			{hasEmailSettings && (
				<TestEmailSendContainer>
					<TestEmailSend />
				</TestEmailSendContainer>
			)}
			<ResourceContainer>
				<Resources header={formatMessage(messages.resourcesTitle)} />
			</ResourceContainer>
		</Wrapper>
	);
}

export default Review;
