import { ReactElement, useEffect, useState } from 'react';

import { Tooltip } from '@calm-web/design-system';

import { MoodValue, ReportMood } from '@/hooks/api/reporting/useMoodReport';
import Angry from 'icons/emojis/angry.svg';
import Anxious from 'icons/emojis/anxious.svg';
import Bored from 'icons/emojis/bored.svg';
import Content from 'icons/emojis/content.svg';
import Excited from 'icons/emojis/excited.svg';
import Grateful from 'icons/emojis/grateful.svg';
import Happy from 'icons/emojis/happy.svg';
import Relaxed from 'icons/emojis/relaxed.svg';
import Sad from 'icons/emojis/sad.svg';
import Stressed from 'icons/emojis/stressed.svg';
import Tired from 'icons/emojis/tired.svg';
import Unsure from 'icons/emojis/unsure.svg';

import {
	GraphContainer,
	Mood,
	MoodContainer,
	MoodEmoji,
	MoodPercentage,
	MoodText,
	TooltipTable,
} from './styles';

interface Props {
	data: ReportMood | undefined;
}

export const staticMoodMapping = [
	{ mood_id: 'happy', color: 'gradientHappy', emoji: <Happy title="happySVG" /> },
	{ mood_id: 'excited', color: 'gradientExcited', emoji: <Excited title="excitedSVG" /> },
	{ mood_id: 'grateful', color: 'gradientGrateful', emoji: <Grateful title="gratefulSVG" /> },
	{ mood_id: 'relaxed', color: 'gradientRelaxed', emoji: <Relaxed title="relaxedSVG" /> },
	{ mood_id: 'content', color: 'gradientContent', emoji: <Content title="contentSVG" /> },
	{ mood_id: 'tired', color: 'gradientTired', emoji: <Tired title="tiredSVG" /> },
	{ mood_id: 'unsure', color: 'gradientUnsure', emoji: <Unsure title="unsureSVG" /> },
	{ mood_id: 'bored', color: 'gradientBored', emoji: <Bored title="boredSVG" /> },
	{ mood_id: 'anxious', color: 'gradientAnxious', emoji: <Anxious title="anxiousSVG" /> },
	{ mood_id: 'angry', color: 'gradientAngry', emoji: <Angry title="angrySVG" /> },
	{ mood_id: 'stressed', color: 'gradientStressed', emoji: <Stressed title="stressedSVG" /> },
	{ mood_id: 'sad', color: 'gradientSad', emoji: <Sad title="sadSVG" /> },
];

const defaultMoodPercentage = 4;

function MoodCheckinGraph({ data }: Props): ReactElement {
	const [moodsToDisplay, setMoodsToDisplay] = useState<MoodValue[] | undefined>();
	const [moodColumnWidths, setMoodColumnWidths] = useState<string>();

	useEffect(() => {
		if (data) {
			let widthString = '';
			const finalMoodMapping: MoodValue[] = [];
			const totalMoodCount = data.total_moods_collected;

			// Set the same mood order regardless of how it comes through from the db
			staticMoodMapping.forEach(({ mood_id, color, emoji }) => {
				const matchedMood = data.mood_counts.find(moods => moods.mood_id === mood_id);

				// Add gradient color mapping and percentage to each mood for display
				if (matchedMood) {
					matchedMood.mood_id = matchedMood.mood_id[0].toUpperCase() + matchedMood.mood_id.substring(1);
					matchedMood.gradient = color;
					matchedMood.percentage = (matchedMood.mood_count / totalMoodCount) * 100;
					matchedMood.emoji = emoji;
					finalMoodMapping.push(matchedMood);
				} else {
					const unmatchedMood = {
						mood_id: mood_id[0].toUpperCase() + mood_id.substring(1),
						mood_count: 0,
						gradient: color,
						percentage: 0,
						emoji,
					};
					finalMoodMapping.push(unmatchedMood);
				}
			});
			const numMoods = finalMoodMapping.length;
			const remainingSpace = 100 - numMoods * defaultMoodPercentage;
			finalMoodMapping.forEach(mood => {
				const countPercentage = (mood.mood_count / totalMoodCount) * remainingSpace;
				widthString +=
					mood.mood_count === 0
						? `${defaultMoodPercentage}% `
						: `${countPercentage + defaultMoodPercentage}% `;
			});
			setMoodColumnWidths(widthString);
			setMoodsToDisplay(finalMoodMapping);
		}
	}, [data]);

	return (
		<GraphContainer>
			<MoodContainer data-testid="grid" columnWidths={moodColumnWidths}>
				{moodsToDisplay &&
					moodsToDisplay.map((mood: MoodValue, index: number) => {
						const tooltipContent = (
							<TooltipTable data-testid="tooltip-content">
								<tbody>
									<tr key={1}>
										<td>{mood.mood_id}</td>
									</tr>
									<tr key={2}>
										<td>{mood.mood_count.toLocaleString()}</td>
									</tr>
									<tr key={3}>
										<td>({mood.percentage?.toFixed(1) + '%'}) of total</td>
									</tr>
								</tbody>
							</TooltipTable>
						);
						return (
							<Tooltip key={index} placement="top" content={tooltipContent}>
								<div style={{ position: 'relative' }}>
									<Mood data-testid={`mood-${mood.mood_id}`} gradientColor={mood.gradient}>
										<MoodText>{mood.mood_id}</MoodText>
										<MoodEmoji>{mood.emoji}</MoodEmoji>
										<MoodPercentage>{mood.percentage?.toFixed(0)}%</MoodPercentage>
									</Mood>
								</div>
							</Tooltip>
						);
					})}
			</MoodContainer>
		</GraphContainer>
	);
}

export default MoodCheckinGraph;
