/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	downloadButtonText: {
		id: 'accessCodes.download',
		defaultMessage: 'Download Access Code List (.csv)',
		description: 'Text of the button for downloading all current access codes file.',
	},
	downloadButtonTextLoading: {
		id: 'accessCodes.downloadButtonTextLoading',
		defaultMessage: 'Downloading...',
		description: 'Text to display while waiting for csv file to download.',
	},
	downloadButtonTextError: {
		id: 'accessCodes.downloadButtonTextError',
		defaultMessage: 'Error downloading access codes',
		description: 'Text to display when there is an unexpected error while downloading access codes csv file.',
	},
	statsLoadingError: {
		id: 'accessCodes.statsLoadingError',
		defaultMessage: 'Unable to fetch access codes',
		description:
			'Text to display when unable to loading stats for required before rendering a partners eligibility list.',
	},
	searchPlaceholder: {
		id: 'accessCodes.search.placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder for access codes search input field',
	},
	eligibleUsersErrorMessage: {
		id: 'accessCodes.eligibleUsersErrorMessage',
		defaultMessage: 'Failed to retrieve access codes. Please refresh your browser to try again.',
		description:
			'Text to display when unable to fetch eligible users before rendering a partners eligibility list.',
	},
	eligibilityBlockedErrorMessage: {
		id: 'users.eligibilityBlockedErrorMessage',
		defaultMessage: 'Please talk to your account manager to access this data',
		description:
			'Text to display when we are manually blocking a partner from accessing the users/access codes page. Only shows up if user manually puts in /users or /access_codes in their url',
	},
});
