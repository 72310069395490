import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const useNavToPlanPageAndOpenUpdatePaymentDetailsModal = (
	updatePayment: boolean = true,
): (() => void) => {
	const history = useHistory();

	const navToPlanPageAndOpenUpdatePaymentDetailsModal = useCallback(() => {
		history.push({
			pathname: 'plan',
			search: `?updatePayment=${updatePayment}`,
		});
	}, [history, updatePayment]);

	return navToPlanPageAndOpenUpdatePaymentDetailsModal;
};
