import styled from 'styled-components';
import { palette } from 'styled-tools';

import { maxWidth, minWidth, PrimaryButton, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export const CtaButton = styled(PrimaryButton)`
	white-space: nowrap;
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-self: end;
	gap: ${units(1.5)};

	${minWidth('desktop')} {
		align-items: center;
		margin-left: ${units(1)};
	}
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${units(2)};
	background-color: ${palette('white')};
	position: relative; // needed to allow the shadow to show on top of adjacent items
	transition: box-shadow 0.3s ease-in-out;

	&:first-child {
		border-top-left-radius: ${units(2)};
		border-top-right-radius: ${units(2)};
	}
	&:last-child {
		border-bottom-left-radius: ${units(2)};
		border-bottom-right-radius: ${units(2)};
	}

	&:focus-within,
	&:hover {
		${boxShadow};
		z-index: 1;
	}

	${maxWidth('desktop')} {
		flex-direction: column;
		align-items: start;
		gap: ${units(1)};

		&:not(:last-child) {
			border-bottom: 1px solid ${palette('gray1')};
		}
	}
`;
