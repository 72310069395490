import Image from 'next/legacy/image';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { FontWeights, Text } from '@calm-web/design-system';

import pianoJpg from '@/../public/_n/images/piano-image.jpg';
import { srcLoader } from '@/utils/ui/imageLoader';

import { ButtonContainer, ExampleEmailContainer, GradientButton, ImageContainer } from '../styles';
import messages from './messages';

const TemplateEmail = ({ partnerName }: { partnerName?: string }): ReactElement => {
	const { formatMessage } = useIntl();
	return (
		<ExampleEmailContainer>
			<Text el="h5" noMargin weight={FontWeights.Medium}>
				{formatMessage(messages.hello)}
			</Text>
			<br />
			{formatMessage(messages.intro, {
				partnerName: partnerName || 'Your organization',
			})}
			<br />
			<br />
			{formatMessage(messages.description)}
			<br />
			<br />
			<ButtonContainer>
				<div>
					<GradientButton>{formatMessage(messages.activateAccount)}</GradientButton>
				</div>
			</ButtonContainer>
			<br />
			<Text el="h5" align="center" noMargin weight={FontWeights.Medium}>
				{formatMessage(messages.startExploring)}
			</Text>
			<br />
			{formatMessage(messages.kickstart)}
			<br />
			<ImageContainer>
				<Image src={pianoJpg} alt="Piano" loader={srcLoader} />
			</ImageContainer>
			<br />
			<Text el="h5" align="center" noMargin weight={FontWeights.Medium}>
				{formatMessage(messages.pianoForSleep)}
			</Text>
			<br />
			{formatMessage(messages.pianoForSleepDescription)}
			<br />
			<br />
			<ButtonContainer>
				<div>
					<GradientButton>{formatMessage(messages.listenTonight)}</GradientButton>
				</div>
			</ButtonContainer>
			<br />
			{formatMessage(messages.thankYou)}
			<br />
			{formatMessage(messages.signature)}
		</ExampleEmailContainer>
	);
};

export default TemplateEmail;
