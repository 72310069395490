import { ReactElement } from 'react';

import { HealthReferralDefinitions } from '@/types/health';

import { PhoneOutline, Wrapper } from '../styles';
import {
	CalmHealthLogo,
	Disclaimer,
	InfoIcon,
	NavBar,
	ReferralCard,
	ReferralCardContents,
	ReferralInfo,
	ReferralLogo,
	ReferralSubtitle,
	ReferralTitle,
	ResultsCard,
	ResultsReadoutBar,
	ResultsReadoutBarBody,
	ResultsReadoutBarScore,
	ResultsReadoutBarScores,
	ResultsReadoutContainer,
	ResultsReadoutHeader,
	ResultsReadoutHeaderText,
	ResultsReadoutTooltip,
	ResultsReadoutTooltipDescription,
	ResultsReadoutTooltipScore,
	TestOutcome,
	UpdateButton,
	ViewPort,
} from './styles';

const healthReferralDefinitionBannerCopy: Record<HealthReferralDefinitions, string> = {
	[HealthReferralDefinitions.PHQ_0]:
		'Based on your screening results you may have minimal anxiety and minimal depression',
	[HealthReferralDefinitions.PHQ_1_4]:
		'Based on your screening results you may have mild anxiety and mild depression',
	[HealthReferralDefinitions.PHQ_5_9]:
		'Based on your screening results you may have moderate anxiety and moderate depression',
	[HealthReferralDefinitions.PHQ_10_14]:
		'Based on your screening results you may have moderately severe anxiety and moderately severe depression',
	[HealthReferralDefinitions.PHQ_15]:
		'Based on your screening results you may have severe anxiety and severe depression',
	[HealthReferralDefinitions.CRISIS]:
		'Based on your screening results you may have severe anxiety and severe depression',
};
const healthReferralDefinitionTooltipCopy: Record<HealthReferralDefinitions, string> = {
	[HealthReferralDefinitions.PHQ_0]: 'Minimal',
	[HealthReferralDefinitions.PHQ_1_4]: 'Mild',
	[HealthReferralDefinitions.PHQ_5_9]: 'Moderate',
	[HealthReferralDefinitions.PHQ_10_14]: 'Moderately Severe',
	[HealthReferralDefinitions.PHQ_15]: 'Severe',
	[HealthReferralDefinitions.CRISIS]: 'Severe',
};
const healthReferralDefinitionTooltipScore: Record<HealthReferralDefinitions, string> = {
	[HealthReferralDefinitions.PHQ_0]: '0',
	[HealthReferralDefinitions.PHQ_1_4]: '2',
	[HealthReferralDefinitions.PHQ_5_9]: '7',
	[HealthReferralDefinitions.PHQ_10_14]: '11',
	[HealthReferralDefinitions.PHQ_15]: '15',
	[HealthReferralDefinitions.CRISIS]: '21',
};

const ResultsReadout = ({
	title,
	referralDefinition,
}: {
	title: string;
	referralDefinition: HealthReferralDefinitions;
}): ReactElement => {
	return (
		<ResultsReadoutContainer>
			<ResultsReadoutHeader>
				<ResultsReadoutHeaderText>{title}</ResultsReadoutHeaderText>
				<InfoIcon />
			</ResultsReadoutHeader>
			<ResultsReadoutBar>
				<ResultsReadoutTooltip referralDefinition={referralDefinition}>
					<ResultsReadoutTooltipDescription data-testid="referral-overview-readout-description">
						{healthReferralDefinitionTooltipCopy[referralDefinition]}
					</ResultsReadoutTooltipDescription>
					<ResultsReadoutTooltipScore data-testid="referral-overview-readout-score">
						{healthReferralDefinitionTooltipScore[referralDefinition]}
					</ResultsReadoutTooltipScore>
				</ResultsReadoutTooltip>
				<ResultsReadoutBarBody />
				<ResultsReadoutBarScores>
					<ResultsReadoutBarScore>0</ResultsReadoutBarScore>
					<ResultsReadoutBarScore>5</ResultsReadoutBarScore>
					<ResultsReadoutBarScore>10</ResultsReadoutBarScore>
					<ResultsReadoutBarScore>15</ResultsReadoutBarScore>
					<ResultsReadoutBarScore>21</ResultsReadoutBarScore>
				</ResultsReadoutBarScores>
			</ResultsReadoutBar>
		</ResultsReadoutContainer>
	);
};

const ReferralOverviewPreview = ({
	title,
	subtitle,
	referralDefinition,
	logoSrc,
	children,
	['data-testid']: dataTestId = 'referral-overview-preview',
}: {
	title: string;
	subtitle: string;
	referralDefinition: HealthReferralDefinitions;
	logoSrc?: string;
	children?: ReactElement;
	['data-testid']?: string;
}): ReactElement => {
	return (
		<Wrapper data-testid={dataTestId}>
			<PhoneOutline />
			<ViewPort>
				<NavBar>
					<CalmHealthLogo />
				</NavBar>
				<TestOutcome data-testid="referral-overview-outcome">
					{healthReferralDefinitionBannerCopy[referralDefinition]}
				</TestOutcome>
				<ReferralCard>
					<ReferralCardContents>
						{logoSrc ? <ReferralLogo src={logoSrc} data-testid="referral-overview-logo" /> : null}
						<ReferralInfo>
							<ReferralTitle data-testid="referral-overview-title">{title}</ReferralTitle>
							<ReferralSubtitle data-testid="referral-overview-subtitle">{subtitle}</ReferralSubtitle>
						</ReferralInfo>
					</ReferralCardContents>
				</ReferralCard>
				<Disclaimer>*Results are not diagnoses</Disclaimer>
				<ResultsCard>
					<ResultsReadout title="Anxiety" referralDefinition={referralDefinition} />
					<ResultsReadout title="Depression" referralDefinition={referralDefinition} />
				</ResultsCard>
				<UpdateButton>Update Screening</UpdateButton>
				{children}
			</ViewPort>
		</Wrapper>
	);
};

export default ReferralOverviewPreview;
