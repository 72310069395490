import { defineMessages } from 'react-intl';

export default defineMessages({
	partnerBrandingTitle: {
		id: 'createPartner.partnerPortalBranding.title',
		defaultMessage: 'Partner Portal Branding',
		description:
			'A title above a section where the user can add a logo for themselves to see in the partner portal',
	},
	customBrandingTitle: {
		id: 'createPartner.customBranding.title',
		defaultMessage: 'Custom Branding',
		description:
			'A title above a section where the user can add a logo for themselves to see in the partner portal and in-app.',
	},
	customBrandingSubtitle: {
		id: 'createPartner.customBranding.subtitle',
		defaultMessage:
			'Your logo will appear in the “For Work” section of the Calm mobile app for your organization as well as in the sign-up invite email and account creation.',
		description:
			'A description for a section where the user can add a logo for themselves to see in the partner portal',
	},
	customBrandingCalmHealthSubtitle: {
		id: 'createPartner.customBrandingCalmHealth.subtitle',
		defaultMessage: 'What logo would you like to use for co-branding?',
		description:
			'A description for a section where the user can add a logo for their Calm Health partner for co branding in partner portal',
	},
	partnerBrandingSubtitle: {
		id: 'createPartner.partnerPortalBranding.subtitle',
		defaultMessage: 'This logo will appear on the top left corner of the Partner Portal.',
		description:
			'A description for a section where the user can add a logo for themselves to see in the partner portal',
	},
	partnerBrandingSubtitleWithSubscribeText: {
		id: 'createPartner.partnerPortalBranding.subtitleWithSubscribeText',
		defaultMessage:
			"This logo will appear on the top left corner of the Partner Portal and your partner email or website communications. Don't forget to check your <subscribe>subscribe page</subscribe> after saving your changes.",
		description:
			'A description for a section where the user can add a logo for themselves to see in the partner portal and subscribe page',
	},
	partnerDtcLogoTitle: {
		id: 'createPartner.partnerDtcBranding.title',
		defaultMessage: 'Partner In-App Branding',
		description: 'A title above a section where the user can add a logo for their members to see in the app',
	},
	partnerDtcLogoSubtitle: {
		id: 'createPartner.partnerPortalBranding.subtitle',
		defaultMessage:
			'This logo will appear in the "For Work" section of the Calm mobile app for your covered lives.',
		description:
			'A description for a section where the user can add a logo for their members to see in the app',
	},
	partnerBrandingLogo: {
		id: 'createPartner.partnerBranding.logo',
		defaultMessage: 'Your Logo',
		description: 'Default text where a users logo should be',
	},
	uploadFailed: {
		id: 'createPartner.partnerBranding.uploadFailed',
		defaultMessage: 'Image failed to upload. Please try again.',
		description: 'image upload fails, banner message to try again',
	},
	uploadButton: {
		id: 'createPartner.partnerBranding.uploadButton',
		defaultMessage: 'Upload',
		description: 'Button to upload image',
	},
	deleteButton: {
		id: 'createPartner.partnerBranding.deleteButton',
		defaultMessage: 'Delete',
		description: 'Button to delete image',
	},
	imageRequirements: {
		id: 'createPartner.partnerBranding.imageRequirements',
		defaultMessage: 'Must be PNG or SVG and cannot exceed {maxImageSizeMB}MB.',
		description: 'Explains image upload requirements',
	},
	imageRequirementsNoPNG: {
		id: 'createPartner.partnerBranding.imageRequirementsNoPNG',
		defaultMessage: 'Must be SVG and cannot exceed {maxImageSizeMB}MB.',
		description: 'Explains image upload requirements',
	},
	dtcImageRequirementsV1: {
		id: 'createPartner.partnerBranding.dtcImageRequirements.v1',
		defaultMessage:
			'Logo must be white with transparent background, PNG or SVG format, and does not exceed 2MB. If selected, you will receive an email from our team with next steps outlined.',
		description: 'Explains image upload requirements',
	},
});
