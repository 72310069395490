import { useId } from '@react-aria/utils';
import { ChangeEvent, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import {
	ButtonsWrapper,
	ButtonWrapper,
	DifferentFileButton,
	FileContainer,
	FileIcon,
	FileInputButton,
	FileNameContainer,
	NewFileLabel,
	SegmentedVariationContainer,
	UploadFileButton,
	VanityButton,
} from './styles';

export function FileUploadWithPlaceholder({
	file,
	onChange,
	id,
	accept,
	buttonText,
	loading,
	name,
	includeSubmitButton = false,
}: {
	file?: File;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	id: string;
	accept: string[];
	buttonText?: string;
	loading?: boolean;
	name: string;
	includeSubmitButton?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<SegmentedVariationContainer $includeSubmitButton={includeSubmitButton}>
			<FileContainer $noMarginBottom={includeSubmitButton}>
				<NewFileLabel htmlFor={id}>{formatMessage(messages.newFileLabel)}</NewFileLabel>
				<FileIcon />
				<ButtonsWrapper>
					<ButtonWrapper>
						{file && <div>{file.name}</div>}
						<FileInputButton type="file" name={name} accept={accept.join(',')} id={id} onChange={onChange} />
						{file ? (
							<DifferentFileButton isDisabled={loading} onClick={() => {}}>
								{formatMessage(loading ? messages.loading : messages.pickDifferentFile)}
							</DifferentFileButton>
						) : (
							<VanityButton backgroundColor="blue3">
								{buttonText || formatMessage(messages.pickFile)}
							</VanityButton>
						)}
					</ButtonWrapper>
					{includeSubmitButton && (
						<UploadFileButton
							type="submit"
							isDisabled={!file || loading}
							isLoading={loading}
							data-testid="ef-upload-confirm"
						>
							{formatMessage(messages.uploadButton)}
						</UploadFileButton>
					)}
				</ButtonsWrapper>
			</FileContainer>
		</SegmentedVariationContainer>
	);
}

// TODO If the segmented reporting variation is rolled out to all partners, then
// this component can be deprecated in favor of the version above that has a
// placeholder. That work is tracked here https://calmdotcom.atlassian.net/browse/BIPS-2166
export default function FileUpload({
	file,
	onChange,
	id,
	accept,
	buttonText,
	loading,
	name,
	showNewFileLabel = true,
	testid,
}: {
	file?: File;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	id: string;
	accept: string[];
	buttonText?: string;
	loading?: boolean;
	name: string;
	showNewFileLabel?: boolean;
	testid?: string;
}): ReactElement {
	const { formatMessage } = useIntl();
	const labelId = useId();

	if (!file) {
		const labelText = buttonText || formatMessage(messages.pickFile);
		return (
			<ButtonWrapper>
				<FileInputButton
					type="file"
					name={name}
					accept={accept.join(',')}
					id={id}
					onChange={onChange}
					aria-label={labelText}
					data-testid={testid}
				/>
				<VanityButton backgroundColor="blue3" aria-hidden>
					{labelText}
				</VanityButton>
			</ButtonWrapper>
		);
	}

	return (
		<FileContainer>
			{showNewFileLabel ? <NewFileLabel>{formatMessage(messages.newFileLabel)}</NewFileLabel> : null}
			<FileIcon />
			<FileNameContainer>
				<span>{file.name}</span>
				<ButtonWrapper>
					<FileInputButton
						type="file"
						name={name}
						accept={accept.join(',')}
						id={id}
						onChange={onChange}
						aria-labelledby={labelId}
						data-testid={testid}
					/>
					<DifferentFileButton isDisabled={loading} id={labelId} onClick={() => {}}>
						{formatMessage(loading ? messages.loading : messages.pickDifferentFile)}
					</DifferentFileButton>
				</ButtonWrapper>
			</FileNameContainer>
		</FileContainer>
	);
}
