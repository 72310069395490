import styled from 'styled-components';

import { FontSizes, FontWeights, Text, TextElements, units } from '@calm-web/design-system';

import WarningSVG from 'icons/warning-outline.svg';

export const ViewPort = styled.div`
	position: relative;
	overflow: hidden;
	height: 508px;
	border-bottom-left-radius: ${units(4)};
	border-bottom-right-radius: ${units(4)};
	margin: ${units(0, 1)};
`;

export const Background = styled.div`
	border-radius: ${units(6)};
	height: 100%;
	background: ${'linear-gradient(180deg, #e2f6ff 0%, #fdf7f9 100%)'};
	position: absolute;
	z-index: -1;
	margin: 0 auto;
	left: 0;
	right: 0;
`;

export const Content = styled.div`
	padding: ${units(16, 1.5)};
	height: 100%;
	overflow-wrap: break-word;
	text-align: center;
`;

export const WarningIcon = styled(WarningSVG)`
	height: ${units(7)};
	width: ${units(7)};
	path {
		fill: #b4611b;
	}
`;

export const WarningIconWrapper = styled.div`
	border-radius: 50%;
	display: inline-block;
	background-color: white;

	& svg {
		padding: 5px;
	}
`;

export const Title = styled(Text).attrs({
	size: FontSizes.lg,
	weight: FontWeights.Medium,
	el: TextElements.H1,
})``;

export const Body = styled(Text).attrs({
	size: FontSizes.sm,
})``;

export const ErrorButton = styled(Text).attrs({
	size: FontSizes.sm,
	weight: FontWeights.Demi,
})`
	color: #0060a9;
`;

export const ErrorButtonWrapper = styled.div`
	padding-top: ${units(0.5)};
`;
