import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { CalmError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';

export const SALESFORCE_ID_LENGTH = 18;

interface SalesforceAccountInfo {
	id: string;
	name: string;
	hasCalmWorkshops: boolean;
}

export function useSalesforceAccount(salesforceAccountId?: string): ApiResponse<SalesforceAccountInfo> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const requestUrl =
		salesforceAccountId?.length === SALESFORCE_ID_LENGTH
			? `b2b/salesforce/accounts/${salesforceAccountId}`
			: '';

	const { data, error, isLoading } = useSWR(
		requestUrl,
		async endpoint => {
			try {
				if (!salesforceAccountId) {
					return undefined;
				}
				const response = await apiRequest({ endpoint, method: 'GET' });
				return response.data;
			} catch (err) {
				if (err) {
					const {
						data: {
							error: { code },
						},
					} = err as CalmError;
					// Don't show a banner for not found errors
					if (code !== 'salesforce_resource_not_found') {
						dispatch(
							setBannerMessage({
								message: 'Failed to retrieve salesforce account name',
								isError: true,
								flash: true,
							}),
						);
					}
					throw err;
				}
			}
		},
		{ errorRetryCount: 0 },
	);

	return {
		data,
		error,
		loading: isLoading,
	};
}
