import { FC } from 'react';

import { PhoneOutline, Wrapper } from '../styles';
import {
	Background,
	Body,
	Content,
	ErrorButton,
	ErrorButtonWrapper,
	Title,
	ViewPort,
	WarningIcon,
	WarningIconWrapper,
} from './styles';

interface Props {
	errorBody?: string;
	errorButtonText?: string;
}

const DEFAULT_ERROR_BODY =
	'Please check your account details to make sure you have access to Calm. If you need help, contact your HR Dept.';

const ErrorCustomizationPreview: FC<Props> = ({ errorBody, errorButtonText }) => {
	return (
		<Wrapper data-testid="error-customization-preview">
			<Background />
			<PhoneOutline />
			<ViewPort>
				<Content>
					<WarningIconWrapper>
						<WarningIcon />
					</WarningIconWrapper>
					<Title>Your eligibility has not been confirmed</Title>
					<Body>{errorBody || DEFAULT_ERROR_BODY}</Body>
					<ErrorButtonWrapper>
						<ErrorButton>{errorButtonText}</ErrorButton>
					</ErrorButtonWrapper>
				</Content>
			</ViewPort>
		</Wrapper>
	);
};

export default ErrorCustomizationPreview;
