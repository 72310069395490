import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const LogoContainer = styled.div`
	text-align: center;
	margin-bottom: ${units(2)};
`;

export const Title = styled.div`
	color: ${palette('blue3')};
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Demi)};
	text-transform: uppercase;
	padding-top: ${units(2)};
	letter-spacing: 2px;
`;

export const PreviewWrapper = styled.div``;

export const Header = styled.div`
	${fontSize(FontSizes.xl)};
	color: ${palette('gray7')};
	${fontWeight(FontWeights.Medium)};
	padding: ${units(4, 0, 3, 1)};
`;

export const Description = styled.div`
	color: ${palette('gray7')};
	padding-left: ${units(1)};
`;

export const DescriptionBox = styled.div`
	background-color: ${palette('gray0')};
	padding: ${units(0, 2, 6, 2)};
	margin-top: ${units(4)};
	margin-bottom: ${units(2)};
`;

export const KeyInfoHeader = styled.div`
	color: ${palette('blue3')};
	${fontWeight(FontWeights.Demi)};
	padding: ${units(2, 0, 3, 0)};
`;

export const ListContainer = styled.ul`
	margin-top: 0;
`;

export const ListItem = styled.li`
	padding: ${units(1, 0)};
	line-height: 25px;
`;

export const BoldedText = styled.span`
	${fontWeight(FontWeights.Demi)};
	color: ${palette('gray7')};
`;

export const NotificationSupport = styled.div`
	margin-left: ${units(2)};
	font-style: italic;
`;

export const SupportLink = styled.a`
	color: ${palette('blue2')};
`;

export const ButtonWrapper = styled.div`
	text-align: center;
	margin-bottom: ${units(6)};
`;

export const OptInButton = styled(Button)``;
