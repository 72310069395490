/* eslint-disable max-len */
// Third Party

import { defineMessages } from 'react-intl';

export default defineMessages({
	showSurveySample: {
		id: 'emailPreferences.showSurveySample',
		defaultMessage: 'See a sample email',
		description: 'Link text for showing email sample',
	},
	title: {
		id: 'emailPreferences.title',
		defaultMessage: 'Example e-mail',
		description: 'Title for modal showing survey email sample',
	},
});
