import pluralize from 'pluralize';
import { Dispatch, FC, ReactElement, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import uuid from 'uuid';

import { Hr } from '@calm-web/design-system';

import MobilePaginator from '@/components/ui/MobilePaginator';
import Table from '@/components/ui/Table';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import {
	getLocalStoragePendingFiles,
	removeLocalStoragePendingFiles,
} from '@/hooks/api/useSftpEligibilityUpload';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { EligibilityUploadsResponse, PreviousUploadResults } from '@/types/eligibility';
import { getErrorMessage } from '@/utils/errorMessages';
import { addCommasToNumber } from '@/utils/helpers';

import { MobileDataColumn, MobileLabel, MobileRow, MobileWrapper } from '../styles';
import messages from './messages';
import {
	BoldText,
	DividerContainer,
	FailureIcon,
	PartialIcon,
	PendingIcon,
	StyledLink,
	SuccessIcon,
	TableColumn,
	TableWrapper,
	Title,
	TitleWrapper,
} from './styles';

const TEN_MINUTES = 10 * 60 * 1000;
const PENDING_PREFIX = 'pending-';

function NameCell({ value }: { value: string | null }): ReactElement {
	return <TableColumn>{value}</TableColumn>;
}

function StatusCell({ value, isMobile }: { value: string; isMobile?: boolean }): ReactElement {
	let innerIcon: ReactElement;

	switch (value) {
		case 'pending':
			innerIcon = <PendingIcon data-testid="eligibility-upload-log-pending-icon" />;
			break;
		case 'failed':
			innerIcon = <FailureIcon data-testid="eligibility-upload-log-failure-icon" />;
			break;
		case 'completed_with_errors':
			innerIcon = <PartialIcon data-testid="eligibility-upload-log-partial-icon" />;
			break;
		default:
			innerIcon = <SuccessIcon data-testid="eligibility-upload-log-success-icon" />;
	}
	return <TableColumn isMobile={isMobile}>{innerIcon}</TableColumn>;
}

function CreatedCell({ value }: { value: string }): ReactElement {
	const formattedDate = new Date(value).toLocaleString();
	return <TableColumn>{formattedDate.toLowerCase()} </TableColumn>;
}

function RowCell({ value }: { value?: number | null }): ReactElement {
	return (
		<TableColumn>
			{!value ? (
				<DividerContainer>
					<Hr opacity={1} />
				</DividerContainer>
			) : (
				addCommasToNumber(value ?? 0)
			)}
		</TableColumn>
	);
}

export function LogsTable({
	pageIndex,
	setPageIndex,
	eligibilityUploadData,
	loading,
	pageSize,
}: {
	pageIndex: number;
	setPageIndex: Dispatch<SetStateAction<number>>;
	eligibilityUploadData?: EligibilityUploadsResponse;
	loading: boolean;
	pageSize: number;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();
	const partner = useDefinedPartner();
	const [eligibilityUploads, setEligibilityUploads] = useState<PreviousUploadResults[]>([]);
	const [isLoadingLocalStorage, setIsLoadingLocalStorage] = useState(false);

	function DescriptionCell({ row }: { row: { original: PreviousUploadResults } }): ReactElement {
		const { status, error } = row.original;
		const { code, subcode, message } = error?.[0] ?? {};
		const errorName = subcode ?? code;
		const errorMessage = getErrorMessage(formatMessage, code, subcode, message);
		const numberOfErrors = error?.length ?? 0;

		switch (status) {
			case 'failed':
				if (numberOfErrors > 1) {
					return (
						<TableColumn>
							<BoldText>{pluralize('error', numberOfErrors, true)}</BoldText>
						</TableColumn>
					);
				}
				return (
					<TableColumn>
						{error &&
							formatMessage(messages.errorDescription, {
								errorName: errorName,
								errorMessage: errorMessage,
								bold: (...chunks: ReactNode[]) => <b>{chunks}</b>,
							})}
					</TableColumn>
				);
			case 'pending':
				return <TableColumn>{formatMessage(messages.processingMessage)}</TableColumn>;
			case 'completed_with_errors':
				return (
					<TableColumn>
						{formatMessage(messages.partialSuccessMessage)}, {pluralize('error', numberOfErrors, true)}
					</TableColumn>
				);
			default:
				return <TableColumn>{formatMessage(messages.successfulUploadMessage)}</TableColumn>;
		}
	}

	function EditCell({ value }: { value: string }): ReactElement {
		return (
			<TableColumn>
				{!value.startsWith(PENDING_PREFIX) && (
					<StyledLink to={`users?logid=${value}#eligibility`}>
						{formatMessage(messages.detailsLink)}
					</StyledLink>
				)}
			</TableColumn>
		);
	}

	useEffect(() => {
		setIsLoadingLocalStorage(true);
		const pendingFiles = getLocalStoragePendingFiles(partner.id);
		const eligibilityUploads: PreviousUploadResults[] =
			eligibilityUploadData?.eligibility_uploads?.map(log => ({
				...log,
				name: log.name.split('/').pop() ?? '',
			})) ?? [];
		const prePendingFiles: PreviousUploadResults[] = pendingFiles
			.reverse()
			.map((file: { fileName: string; uploadTime: string }) => {
				const { fileName, uploadTime } = file;
				if (
					eligibilityUploads.some(log => {
						return (
							log.name === fileName && new Date(uploadTime) <= new Date(log.processing_start_at) // file has been processed
						);
					}) ||
					new Date(uploadTime) < new Date(Date.now() - TEN_MINUTES) // file was uploaded more than ten minutes ago
				) {
					removeLocalStoragePendingFiles(partner.id, file);
					return;
				}
				return {
					processing_start_at: uploadTime,
					processing_end_at: uploadTime,
					md5: null,
					name: fileName,
					id: `${PENDING_PREFIX}${uuid()}`,
					status: 'pending',
					error: null,
				} as PreviousUploadResults;
			})
			.filter((log): log is PreviousUploadResults => log !== undefined);

		if (prePendingFiles.length > 0 && pageIndex === 0) {
			eligibilityUploads.unshift(...prePendingFiles);
		}
		setEligibilityUploads(eligibilityUploads);
		setIsLoadingLocalStorage(false);
	}, [eligibilityUploadData, pageIndex, partner.id]);

	const totalCount = eligibilityUploadData?.meta?.pagination.total ?? 0;

	const columns: Column<PreviousUploadResults>[] = [
		{
			Header: formatMessage(messages.dateHeader),
			accessor: 'processing_start_at',
			Cell: CreatedCell,
			width: '20%',
		},
		{
			Header: formatMessage(messages.fileNameHeader),
			accessor: 'name',
			Cell: NameCell,
			width: '22%',
		},
		{
			Header: formatMessage(messages.rowsHeader),
			accessor: 'rows_total',
			Cell: RowCell,
			width: '10%',
		},
		{
			Header: formatMessage(messages.statusHeader),
			accessor: 'status',
			Cell: StatusCell,
			width: '5%',
		},
		{
			Header: formatMessage(messages.descriptionHeader),
			accessor: 'error',
			Cell: DescriptionCell,
		},
		{
			Header: formatMessage(messages.actionHeader),
			accessor: 'id',
			Cell: EditCell,
			minWidth: 100,
			width: '5%',
		},
	];

	const pageCount = Math.max(1, Math.ceil(totalCount / pageSize));
	const isOnlyOnePage = pageCount === 1;
	const showLoading = loading || isLoadingLocalStorage;

	return (
		<TableWrapper isOnlyOnePage={isOnlyOnePage} isMobile={isMobile}>
			<TitleWrapper>
				<Title>
					{formatMessage(messages.title, {
						numberOfLogs: totalCount,
					})}
				</Title>
			</TitleWrapper>
			{isMobile ? (
				<>
					<MobileWrapper data-testid="mobile-eligibility-upload-logs-table">
						{eligibilityUploads?.map(log => (
							<MobileRow key={log.id}>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.dateHeader)}</MobileLabel>
									<CreatedCell value={log.processing_start_at} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.statusHeader)}</MobileLabel>
									<StatusCell value={log.status} isMobile={isMobile} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.fileNameHeader)}</MobileLabel>
									<NameCell value={log.name} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.rowsHeader)}</MobileLabel>
									<RowCell value={log.rows_total} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.descriptionHeader)}</MobileLabel>
									<DescriptionCell row={{ original: log }} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.actionHeader)}</MobileLabel>
									<EditCell value={log.id} />
								</MobileDataColumn>
							</MobileRow>
						))}
					</MobileWrapper>
					<MobilePaginator
						fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
							setPageIndex(newPageIndex)
						}
						loading={showLoading}
						pageCount={pageCount}
						pageSize={pageSize}
					/>
				</>
			) : (
				<>
					<Table
						dataTestId="eligibility-upload-logs-table"
						columns={columns}
						data={eligibilityUploads ?? []}
						fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
							setPageIndex(newPageIndex)
						}
						forcePageIndex={pageIndex}
						pageCount={pageCount}
						pageSize={pageSize}
						loading={showLoading}
						alignHeadingsToText
						cellVerticalAlign="middle"
					/>
				</>
			)}
		</TableWrapper>
	);
}
