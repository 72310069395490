/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	popupTitle: {
		id: 'referralPopup.title',
		defaultMessage: 'Make the world happier and healthier',
		description: 'Title text for the referral popup.',
	},
	popupBody: {
		id: 'referralPopup.body',
		defaultMessage:
			'Share a lifetime of Calm with a fellow HR professional. If they bring Calm to their organization we’ll thank you with a $500 Amazon.com Gift Card to spend on self care.',
		description: 'Body text for the referral popup.',
	},
	popupMore: {
		id: 'referralPopup.more',
		defaultMessage: 'Learn more',
		description: 'Learn more text for the referral popup.',
	},
	popupV2Title: {
		id: 'referralPopupV2.title',
		defaultMessage: 'Refer your colleagues to Calm and get {giftValue}',
		description: 'Title of v2 refreral popup',
	},
	popupV2Body: {
		id: 'referralPopupV2.body',
		defaultMessage: `Give a <b>lifetime Calm Premium membership</b> to a fellow HR professional, benefits administrator or executive.
{linebreak}
{linebreak}
If they bring Calm to their organization we’ll thank you with a <b>{giftValue} Amazon.com gift card</b> to spend on self care.`,
		description: 'Body of v2 refreral popup',
	},
	popupV2Cta: {
		id: 'referralPopupV2.cta',
		defaultMessage: 'Get started',
		description: 'CTA button for referrals',
	},
	popupV2Dismiss: {
		id: 'referralPopupV2.dismiss',
		defaultMessage: "I'll do it later",
		description: 'Button text for dismissing HR referral',
	},
});
