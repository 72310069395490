import qs from 'qs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { updateStats } from '@/hooks/api/usePartnerStats';
import { useUser } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';
import {
	EligibilityResponse,
	EligibilityUploadsDetailsResponse,
	EligibilityUploadsResponse,
	PreviousUploadResults,
} from '@/types/eligibility';
import { CalmError, getCalmErrorOrError, isCalmError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';
import { downloadPartnerDataViaATag } from './utils';

export enum EligibilityErrors {
	NotAuthorizedToFetchPartner = 'not_authorized_to_fetch_partner',
	UnableToFetchEligibility = 'unable_to_fetch_partner_eligibility',
}

export function useEligibility(
	partnerId: string,
	pageIndex: number,
	pageSize: number,
	searchValue?: string,
): ApiResponse<EligibilityResponse> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { user } = useUser();
	const isAdmin = user?.accessPolicy?.isAdmin;
	const allowedPartners = user?.accessPolicy?.allowedPartners;
	const isAllowedPartner = allowedPartners?.includes(partnerId);
	const isAbleToFetchPartner = isAdmin || isAllowedPartner;

	const offset = pageIndex * pageSize;

	let baseEndpoint = `b2b/partners/${partnerId}/eligibility?offset=${offset}&limit=${pageSize}`;
	if (searchValue) {
		const trimmedSearchValue = searchValue.trim();
		baseEndpoint += `&filter[partner_user_id][like]=${trimmedSearchValue}`;
	}

	const { data, error } = useSWR(
		baseEndpoint,
		async endpoint => {
			try {
				if (!isAbleToFetchPartner) {
					throw new Error(EligibilityErrors.NotAuthorizedToFetchPartner);
				}

				const response = await apiRequest({
					endpoint,
					method: 'GET',
				});

				if (!response.data) {
					throw new Error(EligibilityErrors.UnableToFetchEligibility);
				}

				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Oops! We were unable to read the eligibility file for ${partnerId}.`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	if (!data) {
		return {
			data: undefined,
			loading: !error,
			error,
		};
	}

	return {
		data,
		error: undefined,
		loading: false,
	};
}

export function useEligibilityUploads({
	partnerId,
	status,
	pageIndex,
	pageSize,
}: {
	partnerId: string;
	status?: PreviousUploadResults['status'];
	pageIndex: number;
	pageSize: number;
}): ApiResponse<EligibilityUploadsResponse> & { clearCache: () => Promise<void> } {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const offset = pageIndex * pageSize;
	const query = {
		page: { offset, limit: pageSize },
		...(status ? { filter: { status } } : {}),
	};

	const baseEndpoint = `b2b/partners/${partnerId}/eligibility-uploads?${qs.stringify(query)}`;
	const {
		data,
		error,
		mutate: swrMutate,
	} = useSWR(
		baseEndpoint,
		async endpoint => {
			try {
				const response = await apiRequest({
					endpoint,
					method: 'GET',
				});
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Oops! We were unable to read the eligibility uploads for ${partnerId}.`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0, keepPreviousData: true },
	);

	const clearCache = async (): Promise<void> => {
		await swrMutate(undefined, true);
	};

	return { data, loading: !data && !error, error, clearCache };
}

export function useEligibilityUploadsDetails({
	partnerId,
	id,
}: {
	partnerId: string;
	id: string;
}): ApiResponse<EligibilityUploadsDetailsResponse> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const baseEndpoint = `b2b/partners/${partnerId}/eligibility-uploads/${id}`;
	const { data, error, isLoading } = useSWR(
		baseEndpoint,
		async endpoint => {
			try {
				const response = await apiRequest({
					endpoint,
					method: 'GET',
				});
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Oops! We were unable to read the eligibility upload details for upload detail id: ${id} for ${partnerId}.`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	return {
		data,
		loading: isLoading,
		error,
	};
}

export function useDownloadEligibilityCsv(
	partnerId: string,
): [() => Promise<void>, { loading: boolean; error: unknown }] {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const [error, setError] = useState<unknown>(null);
	const [loading, setLoading] = useState<boolean>(false);

	async function download(): Promise<void> {
		logEvent('Partner Portal : Download Current File : Clicked');
		try {
			setLoading(true);
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/eligibility`,
				customHeaders: {
					Accept: 'text/csv',
				},
			});
			downloadPartnerDataViaATag(response.data, partnerId);
		} catch (err) {
			setError(err);
			dispatch(
				setBannerMessage({
					message: 'Unable to download eligibility file',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}

	return [download, { error, loading }];
}

export function clearEligibilityCache(partnerId: string): Promise<undefined> {
	return mutate(`b2b/partners/${partnerId}/eligibility?offset=0&limit=10`, undefined, true);
}

export function isOverageError(err?: Error | CalmError): err is CalmError {
	if (!isCalmError(err)) {
		return false;
	}

	const {
		data: {
			error: { code },
		},
	} = err;

	if (code === 'b2b_partner_exceeded_eligibilities') {
		return true;
	}

	return false;
}

export function useAddSingleEligibility(partnerId: string): [
	(
		partnerUserId: string,
		segment1Value?: string,
		segment2Value?: string,
		segment3Value?: string,
	) => Promise<void>,
	{
		loading: boolean;
		error?: CalmError | Error;
		clearError: () => void;
	},
] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	async function addSingleEligibility(
		partnerUserId: string,
		segment1Value?: string,
		segment2Value?: string,
		segment3Value?: string,
	): Promise<void> {
		try {
			setLoading(true);
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/eligibility`,
				body: {
					partner_id: partnerId,
					partner_user_id: partnerUserId,
					segment_1_value: segment1Value,
					segment_2_value: segment2Value,
					segment_3_value: segment3Value,
				},
				method: 'POST',
			});
			await updateStats(partnerId);
			await clearEligibilityCache(partnerId);
		} catch (err) {
			const error = getCalmErrorOrError(err);
			setError(error);

			if (!isOverageError(error)) {
				dispatch(
					setBannerMessage({
						message: 'Unable to add user',
						isError: true,
						flash: true,
					}),
				);
			}
			throw error;
		} finally {
			setLoading(false);
		}
	}

	const clearError = (): void => setError(undefined);

	return [addSingleEligibility, { error, loading, clearError }];
}

export function useDeleteSingleEligibility(
	partnerId: string,
	userId: number,
): [() => Promise<void>, { loading: boolean; error: unknown }] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<unknown>(null);
	const [loading, setLoading] = useState<boolean>(false);

	async function deleteSingleEligibility(): Promise<void> {
		try {
			setLoading(true);
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/eligibility/${userId}`,
				body: {
					partner_id: partnerId,
				},
				method: 'DELETE',
			});
			await updateStats(partnerId);
			await clearEligibilityCache(partnerId);
		} catch (err) {
			setError(err);
			dispatch(
				setBannerMessage({
					message: 'Unable to add user',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}

	return [deleteSingleEligibility, { error, loading }];
}
