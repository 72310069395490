import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Badge, FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { GroupedItemState } from '@/types/actionPlan';

import ActionItem from '../ActionItem';
import EmptyStateBlock from '../EmptyStateBlock';
import messages from './messages';
import { ActionItemsGroup, Row, SmileyFaceIcon } from './styles';

interface Props {
	actionPlanStatuses?: GroupedItemState;
}

const ActionPlanSection: FC<Props> = ({ actionPlanStatuses }) => {
	const { formatMessage } = useIntl();
	const incompleteCount = actionPlanStatuses?.incomplete.length;

	return (
		<>
			<Row>
				<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
					{formatMessage(messages.heading)}
				</Text>
				{incompleteCount ? (
					<Badge backgroundColor="noticeBgError" textColor="white">
						{incompleteCount}
					</Badge>
				) : null}
			</Row>
			{incompleteCount ? (
				<ActionItemsGroup>
					{actionPlanStatuses?.incomplete.map(item => (
						<ActionItem item={item} key={item.description} />
					))}
				</ActionItemsGroup>
			) : (
				<EmptyStateBlock
					Icon={SmileyFaceIcon}
					primaryText={formatMessage(messages.allDone)}
					secondaryText={formatMessage(messages.checkBackLater)}
				/>
			)}
		</>
	);
};

export default ActionPlanSection;
