/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	last7Days: {
		id: 'reporting.timeframeFilter.last7Days',
		defaultMessage: 'Last 7 days',
		description: 'Text indicating we will show data from the last 7 days',
	},
	last28Days: {
		id: 'reporting.timeframeFilter.last28Days',
		defaultMessage: 'Last 28 days',
		description: 'Text indicating we will show data from the last 28 days',
	},
	last12Weeks: {
		id: 'reporting.timeframeFilter.last12Weeks',
		defaultMessage: 'Last 12 weeks',
		description: 'Text indicating we will show data from the last 12 weeks',
	},
	lastYear: {
		id: 'reporting.timeframeFilter.lastYear',
		defaultMessage: 'Last year',
		description: 'Text indicating we will show data from the last year',
	},
	custom: {
		id: 'reporting.timeframeFilter.custom',
		defaultMessage: 'Custom',
		description: 'Text indicating we will show data from a user-selectable timeframe',
	},
	infoTooltip: {
		id: 'reporting.timeframeFilter.infoTooltip',
		defaultMessage: 'Only select options are available during the {partnerType} experience.',
		description:
			'Info tooltip explaining not all reporting timeframe options are available when viewing a partner of type {partnerType}',
	},
	salesTrial: {
		id: 'reporting.timeframeFilter.salesTrial',
		defaultMessage: 'sales trial',
		description: 'The name of the partner type that is used to demo the product during sales',
	},
	demo: {
		id: 'reporting.timeframeFilter.demo',
		defaultMessage: 'demo',
		description:
			'The word short for demonstration. Used here in the context of providing a test experience of the product.',
	},
});
