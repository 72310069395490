import styled from 'styled-components';

import { FontSizes, SecondaryButton, Text, units } from '@calm-web/design-system';

export const MarginSecondaryButton = styled(SecondaryButton).attrs({ borderColor: 'blue3' })`
	margin-right: ${units(2)};
`;

export const CenterAlign = styled.div`
	text-align: center;
`;
export const SmallText = styled(Text).attrs({ size: FontSizes.sm })``;
