import { ReactElement, ReactNode } from 'react';

import StepIcon from '@/components/ui/StepIcon';

import { Action, Body, Checkmark, Content, InnerWrapper, StyledChecklistItem, Title } from './styles';

interface ItemProps {
	isCompleted?: boolean;
	title: ReactElement | string;
	children: ReactNode | string;
	action?: ReactElement;
	stackActions?: boolean;
}

export default function ChecklistItem({
	isCompleted,
	title,
	children,
	stepCount,
	action,
	stackActions,
}: ItemProps & { stepCount: number }): ReactElement {
	return (
		<StyledChecklistItem $stackActions={stackActions}>
			<InnerWrapper>
				{isCompleted ? <Checkmark /> : <StepIcon>{stepCount}</StepIcon>}
				<Content>
					<Title>{title}</Title>
					<Body>{children}</Body>
				</Content>
			</InnerWrapper>
			{action && <Action>{action}</Action>}
		</StyledChecklistItem>
	);
}
