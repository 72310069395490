import { FC, useContext } from 'react';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import friendlyPercentage, { friendlyActual } from '@/utils/friendlyPercentage';

import { DeltaChange, getDeltaColor, StatsWrapper, Title } from './styles';

interface Props {
	href: string;
	stat: string | number;
	title: string;
	deltaChange?: number;
	deltaLabel?: string;
	location?: 'home' | 'reporting';
	showDeltaAsPercentage?: boolean;
}

const StatsTile: FC<Props> = ({
	stat,
	title,
	href,
	location,
	deltaChange,
	deltaLabel,
	showDeltaAsPercentage,
}) => {
	const { logEvent } = useAnalytics();
	const { scrollToWidgetViaAnchor } = useContext(ReportingContext);

	const logClickEvent = async (): Promise<boolean> => {
		if (location === 'reporting') {
			scrollToWidgetViaAnchor();
		}
		logEvent(location === 'reporting' ? 'Reporting : Summary Tile Clicked' : 'Home : Stat Tile Clicked', {
			item: title,
		});
		return true;
	};

	const deltaChangeString = showDeltaAsPercentage
		? `${friendlyPercentage(deltaChange, true) || '--'}%`
		: friendlyActual(deltaChange, true) || '--';

	return (
		<StatsWrapper href={href} onClick={logClickEvent}>
			<Title el="span">{title}</Title>
			{stat !== '--%' && deltaChange !== undefined && deltaLabel && (
				<Text size={FontSizes.sm} color="gray5">
					<DeltaChange color={getDeltaColor(deltaChange)}>{deltaChangeString}</DeltaChange> {deltaLabel}
				</Text>
			)}
			<Text el="p" size={FontSizes['2xl']} weight={FontWeights.Medium} noMargin>
				{stat}
			</Text>
		</StatsWrapper>
	);
};

export default StatsTile;
