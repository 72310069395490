import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, Modal, ModalWidth } from '@calm-web/design-system';

import { EmailTemplate, executeCopyTemplate } from '@/components/ui/LaunchTemplates';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import useAnnouncementEmail from '@/hooks/api/useAnnouncementEmail';
import { useEligibility } from '@/hooks/api/useEligibility';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { setBannerMessage } from '@/store/actions';
import CalmCookie from '@/utils/CalmCookie';
import { window } from '@/utils/window';

import messages from './messages';
import {
	ButtonGroup,
	FooterRow,
	PrimaryButtonStyled,
	StyledButton,
	WarningIcon,
	WarningText,
	WarningWrapper,
} from './styles';

interface LaunchEmailProps {
	signupLink: string;
	partnerId: string;
	announceCookieName: string;
	hideSendOption?: boolean;
	showOldTemplate?: boolean;
	isDisabled?: boolean;
	// TODO: Remove these overrides and the rendered button when moving to action plan flow
	handleCloseOverride?: () => void;
	renderModalOverride?: boolean;
}

const LaunchEmailModal: FC<LaunchEmailProps> = ({
	signupLink,
	partnerId,
	announceCookieName,
	hideSendOption = false,
	showOldTemplate = false,
	isDisabled = false,
	handleCloseOverride,
	renderModalOverride,
}) => {
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [recordMilestone] = useRecordMilestone();

	const { sendAnnouncementEmail } = useAnnouncementEmail(partnerId);
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [showMessageSection, setShowMessageSection] = useState(true);
	const customMessageRef = useRef<string>('');
	const emailRef = useRef<HTMLDivElement>(null);
	const { loading, data: eligibilityData, error } = useEligibility(partnerId, 0, 1);
	const redeemedUserCount = eligibilityData?.unfiltered_redeemed_total || 0;
	const totalUploadedUsers = eligibilityData?.meta?.pagination?.unfiltered_total || 0;
	const unredeemedUserCount = totalUploadedUsers - redeemedUserCount;

	const handleClose = (): void => {
		if (handleCloseOverride) {
			handleCloseOverride();
			return;
		}
		setIsOpen(false);
		setShowMessageSection(true);
	};

	const copyEmailTemplate = async (): Promise<void> => {
		logEvent('Partner Portal : Get Set Up : Copy Launch Email Template');
		// If they're copying, don't include a custom message. They can do that on their own.
		setShowMessageSection(false);

		const emailTemplate = emailRef.current;
		if (emailTemplate) {
			try {
				await executeCopyTemplate(emailTemplate);

				dispatch(
					setBannerMessage({
						message: 'Email Template copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
				await recordMilestone({ eventName: PartnerMilestoneType.LAUNCH_ANNOUNCEMENT_SENT, partnerId });
			} catch (error) {
				dispatch(
					setBannerMessage({
						message: 'Unable to copy to the clipboard at this time',
						isError: true,
						flash: true,
					}),
				);
			}
		}

		setShowMessageSection(true);
	};

	const openModal = (): void => {
		CalmCookie.set(announceCookieName, 'true');
		setIsOpen(true);
	};

	const sendLaunchEmail = async (): Promise<void> => {
		setIsSending(true);
		logEvent('Partner Portal : Get Set Up : Send Launch Email');
		await sendAnnouncementEmail(customMessageRef.current);
		await recordMilestone({ eventName: PartnerMilestoneType.LAUNCH_ANNOUNCEMENT_SENT, partnerId });
		setIsOpen(false);
		setIsSending(false);
	};

	return (
		<>
			{!renderModalOverride && (
				<PrimaryButtonStyled
					size="sm"
					onPress={openModal}
					isDisabled={isDisabled}
					data-testid="launch-email-template-modal"
				>
					{formatMessage(messages.announceCta)}
				</PrimaryButtonStyled>
			)}
			<Modal
				isOpen={renderModalOverride || isOpen}
				closeModal={handleClose}
				canClose
				title={formatMessage(messages.title)}
				width={ModalWidth.Ultra}
				noPadding
				footer={
					<FooterRow $hideSendOption={hideSendOption}>
						{!hideSendOption && (
							<WarningWrapper>
								<WarningIcon />
								<WarningText el="span" size={FontSizes.sm} align="start" color="gray7">
									<i>
										By pressing &quot;Send launch email&quot; the announcement will immediately{' '}
										{!loading && !error && unredeemedUserCount
											? `go out to ${unredeemedUserCount} users`
											: 'be sent to all users who have not signed up'}{' '}
										in your organization.
									</i>
								</WarningText>
							</WarningWrapper>
						)}
						<ButtonGroup>
							{window.navigator.clipboard && (
								<StyledButton
									size="sm"
									hideShadow={!hideSendOption}
									backgroundColor={hideSendOption ? 'gradientCalmBrand' : 'transparent'}
									textColor={hideSendOption ? 'white' : 'blue3'}
									onPress={copyEmailTemplate}
								>
									Copy Template
								</StyledButton>
							)}
							{!hideSendOption && (
								<StyledButton
									size="sm"
									backgroundColor={'gradientCalmBrand'}
									onPress={sendLaunchEmail}
									isDisabled={isSending}
									isLoading={isSending}
								>
									Send launch email
								</StyledButton>
							)}
						</ButtonGroup>
					</FooterRow>
				}
			>
				<EmailTemplate
					showOldTemplate={showOldTemplate}
					hideSendOption={hideSendOption}
					emailRef={emailRef}
					signupLink={signupLink}
					showMessageSection={showMessageSection}
					setShowMessageSection={setShowMessageSection}
					customMessageRef={customMessageRef}
				/>
			</Modal>
		</>
	);
};

export default LaunchEmailModal;
