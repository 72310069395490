import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	ButtonSizes,
	PrimaryButton as DesignSystemPrimaryButton,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	SecondaryButton,
	TextArea,
	units,
} from '@calm-web/design-system';

import { FileContainer } from '@/components/ui/FileUpload/styles';

export const ModalRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const CloseModalButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })``;

export const ModalContent = styled.div`
	${fontSize(FontSizes.sm)};

	button {
		font-size: inherit;
	}

	${ModalRow} {
		&:not(:last-child) {
			margin-bottom: ${units(2)};
		}
	}
`;

// TODO: fix this
export const LoaderContainer = styled.div`
	width: 250px;
	margin: 0 auto;
`;

export const ModalLabel = styled.div`
	${fontWeight(FontWeights.Demi)};
`;

export const PrimaryLink = styled(Link)`
	color: ${palette('blue3')};
`;

export const Title = styled.h3`
	color: ${palette('gray7')};
	${fontWeight(FontWeights.Regular)};
	${fontSize(FontSizes.xl)};
`;

export const BackIcon = styled.div``;

export const DescriptionHeader = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const DescriptionBox = styled.div`
	padding: ${units(2)};
	background: ${palette('gold')};
	border: 1px solid ${palette('gray5')};
	border-radius: 4px;
	${fontSize(FontSizes.sm)};
`;

export const List = styled.ul`
	padding-left: ${units(2)};
`;

export const DownloadLinkContainer = styled.div``;

export const StyledTextArea = styled(TextArea)`
	resize: vertical;
	${fontSize(FontSizes.sm)};
	min-height: ${units(6)};
	height: ${units(6)};
`;

export const PrimaryButton = styled(DesignSystemPrimaryButton)``;

export const SubmitButton = styled(PrimaryButton)`
	min-width: ${units(20.5)};
`;

export const UploadButtonContainer = styled.div`
	margin-top: ${units(1)};
	${FileContainer} {
		margin-bottom: 0;
	}
`;

export const ContentContainer = styled.div`
	${Title} {
		margin-bottom: ${units(2)};
	}

	${DescriptionBox} {
		margin-bottom: ${units(2)};
	}

	${DownloadLinkContainer} {
		margin: ${units(1)} 0;
	}

	${PrimaryLink} {
		margin-left: ${units(1)};
	}

	${SubmitButton} {
		margin: ${units(2)} auto;
		display: block;
	}
`;

export const SegmentTitle = styled.div`
	margin-top: ${units(1)};
	${fontWeight(FontWeights.Demi)};
	${fontSize(FontSizes.sm)};
`;

export const SegmentRow = styled.div`
	display: flex;
`;

export const SegmentColumn = styled.div`
	margin-right: ${units(1)};
	flex: 1;
`;

export const SegmentAttributeContainer = styled.div`
	margin-right: ${units(0.5)};
	flex: 1;
`;

export const SegmentDisplayNameContainer = styled.div`
	flex: 1;
`;
