import { ReactElement, useContext, useMemo, useState } from 'react';

import { Tooltip } from '@calm-web/design-system';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useSegmentDetails } from '@/hooks/api/reporting/useSegmentDetails';
import { useSegmentRankings } from '@/hooks/api/reporting/useSegmentRankings';
import { hasSegments, SegmentSelectState } from '@/utils/segments';

import { SegmentationModal } from '../../SegmentationFilter';
import {
	Arrow,
	FilterIcon,
	FilterSegmentButton,
	FlexBox,
	RankText,
	StyledTable,
	TableHeader,
	TableHeaderRow,
	TableRow,
	TableTextGray60,
	TableTextGray80,
	TableTextMargin,
	ViewButton,
} from './styles';

function RankingsTable(): ReactElement {
	const { logEvent } = useAnalytics();
	const [showModal, setShowModal] = useState(false);
	const [segmentState, setSegmentState] = useState<SegmentSelectState>({
		segment1: undefined,
		segment2: undefined,
		segment3: undefined,
	});

	const { partner, endDateToUse, updateSegmentFilter } = useContext(ReportingContext);
	const { data: segmentDetails } = useSegmentDetails(partner?.id || '');

	const segmentNameArray = useMemo(() => {
		const totalSegments =
			(segmentDetails?.segment_names?.segment_1_display_name ? 1 : 0) +
			(segmentDetails?.segment_names?.segment_2_display_name ? 1 : 0) +
			(segmentDetails?.segment_names?.segment_3_display_name ? 1 : 0);
		const excludeOne = segmentState.segment1 !== undefined;
		const excludeTwo = segmentState.segment2 !== undefined;
		const excludeThree = segmentState.segment3 !== undefined;

		const array = [];
		if (!excludeOne && segmentDetails?.segment_names?.segment_1_display_name) {
			array.push(segmentDetails?.segment_names?.segment_1_display_name);
		}
		if (!excludeTwo && segmentDetails?.segment_names?.segment_2_display_name) {
			array.push(segmentDetails?.segment_names?.segment_2_display_name);
		}
		if (!excludeThree && segmentDetails?.segment_names?.segment_3_display_name) {
			array.push(segmentDetails?.segment_names?.segment_3_display_name);
		}
		return array.length === totalSegments || array.length === 0 ? undefined : array;
	}, [segmentState, segmentDetails]);

	const { data } = useSegmentRankings({
		partnerId: partner?.id || '',
		endDate: endDateToUse,
		segmentNames: segmentNameArray,
	});
	const segmentRanking = data?.segment_ranking;

	if (!segmentRanking || !hasSegments(segmentDetails) || segmentRanking.total_segments < 2) {
		return <></>;
	}
	const tables = ['top', 'bottom'];

	const viewSegment = (segmentName: string, segmentValue: string): void => {
		if (!updateSegmentFilter) return;
		if (segmentDetails?.segment_names?.segment_1_display_name === segmentName) {
			updateSegmentFilter({
				segment1: new Set([segmentValue]),
				segment2: undefined,
				segment3: undefined,
			});
		} else if (segmentDetails?.segment_names?.segment_2_display_name === segmentName) {
			updateSegmentFilter({
				segment1: undefined,
				segment2: new Set([segmentValue]),
				segment3: undefined,
			});
		} else if (segmentDetails?.segment_names?.segment_3_display_name === segmentName) {
			updateSegmentFilter({
				segment1: undefined,
				segment2: undefined,
				segment3: new Set([segmentValue]),
			});
		}
	};

	const submitModal = (s: SegmentSelectState): void => {
		logEvent('Partner Portal : Reporting : Signup Segment Ranking : Filter : Applied');
		setSegmentState(s);
		setShowModal(false);
	};
	return (
		<>
			<FlexBox>
				{tables.map(str => {
					const isTop = str === 'top';
					const rankings = isTop ? segmentRanking.top : segmentRanking.bottom;
					return (
						<StyledTable key={`segment${str}rankings`}>
							<TableHeaderRow $isTop={isTop}>
								<TableHeader>
									<Arrow $isTop={isTop} />
								</TableHeader>
								<TableHeader>
									<TableTextMargin color={isTop ? 'successGreen' : 'errorRed'}>
										{isTop ? 'Top' : 'Bottom'} Segment
									</TableTextMargin>
									<FilterSegmentButton
										onClick={() => {
											logEvent('Partner Portal : Reporting : Signup Segment Ranking : Filter : Viewed');
											setShowModal(true);
										}}
									>
										<div>
											<FilterIcon /> Filter
										</div>
									</FilterSegmentButton>
								</TableHeader>
								<TableHeader>
									<TableTextGray60>Participants</TableTextGray60>
								</TableHeader>
								<TableHeader>
									<TableTextGray60>Sign-up Rate</TableTextGray60>
								</TableHeader>
								<TableHeader />
							</TableHeaderRow>
							{rankings.map((ranking, index) => {
								const isViewDisabled = ranking.total_signups < 10;
								return (
									<TableRow
										key={`${ranking.segment_name} ${ranking.segment_value} ${ranking.total_signups} ${str}`}
									>
										<td>
											<RankText>
												{isTop ? index + 1 : segmentRanking.total_segments - (rankings.length - 1 - index)}
											</RankText>
										</td>
										<td>
											<TableTextGray80>{ranking.segment_value} </TableTextGray80>
											<TableTextGray60>({ranking.segment_name})</TableTextGray60>
										</td>
										<td>
											<TableTextGray80>
												{ranking.total_signups}/{ranking.total_eligible_users}
											</TableTextGray80>
										</td>
										<td>
											<TableTextGray80>{ranking.percent_signups.toFixed(1)}%</TableTextGray80>
										</td>
										<td>
											<Tooltip
												maxWidth={isViewDisabled ? 200 : 180}
												content={
													isViewDisabled
														? 'Unable to view segments with less than 10 participants'
														: 'Apply the segment filter to current Reporting'
												}
											>
												<ViewButton
													onClick={() => {
														logEvent(
															'Partner Portal : Reporting : Signup Segment Ranking : View Button : Clicked',
														);
														viewSegment(ranking.segment_name, ranking.segment_value);
													}}
													isDisabled={isViewDisabled}
												>
													View
												</ViewButton>
											</Tooltip>
										</td>
									</TableRow>
								);
							})}
						</StyledTable>
					);
				})}
			</FlexBox>
			{partner?.id && showModal && (
				<SegmentationModal
					partnerId={partner.id}
					onDismiss={() => setShowModal(false)}
					onSubmit={submitModal}
					segmentsSelected={segmentState}
					isAllOrNone={true}
				/>
			)}
		</>
	);
}

export default RankingsTable;
