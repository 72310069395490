import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal as DesignModal, SecondaryButton } from '@calm-web/design-system';

import { useDeleteSingleEligibility } from '@/hooks/api/useEligibility';

import messages from './messages';
import { ButtonRow, Container, TextBlock, Title } from './styles';

interface Props {
	partnerId: string;
	partnerUserId: string;
	userId: number;
	onClickCancel: () => void;
	onDeleteSuccess: () => void;
}

export default function DeleteModal({
	partnerId,
	partnerUserId,
	userId,
	onClickCancel,
	onDeleteSuccess,
}: Props): ReactElement {
	const { formatMessage } = useIntl();

	const [deleteSingleEligibility, { loading, error }] = useDeleteSingleEligibility(partnerId, userId);

	function handleCancel(): void {
		onClickCancel();
	}

	async function handleConfirm(): Promise<void> {
		await deleteSingleEligibility();
		onDeleteSuccess();
	}

	if (error) {
		return (
			<DesignModal
				isOpen
				closeModal={handleCancel}
				aria-label={formatMessage(messages.title)}
				canClose={false}
			>
				<Container>
					<Title>{formatMessage(messages.header)}</Title>
					<TextBlock>{formatMessage(messages.removeError)}</TextBlock>
					<ButtonRow>
						<Button
							onPress={handleConfirm}
							hideShadow
							backgroundColor="errorRed"
							isLoading={loading}
							isDisabled
						>
							{formatMessage(messages.removeUserButton)}
						</Button>
						<SecondaryButton onPress={onClickCancel}>
							{formatMessage(messages.cancelRemoveUserButton)}
						</SecondaryButton>
					</ButtonRow>
				</Container>
			</DesignModal>
		);
	}

	return (
		<DesignModal isOpen closeModal={handleCancel} aria-label={formatMessage(messages.title)} canClose={false}>
			<Container>
				<Title>{formatMessage(messages.header)}</Title>
				<TextBlock>
					{formatMessage(messages.confirm, {
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
						partnerUserId,
					})}
				</TextBlock>
				<ButtonRow>
					<Button onPress={handleConfirm} hideShadow backgroundColor="errorRed" isLoading={loading}>
						{formatMessage(messages.removeUserButton)}
					</Button>
					<SecondaryButton onPress={onClickCancel}>
						{formatMessage(messages.cancelRemoveUserButton)}
					</SecondaryButton>
				</ButtonRow>
			</Container>
		</DesignModal>
	);
}
