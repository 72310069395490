import { DateTime } from 'luxon';
import { ReactElement, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ButtonSizes, PrimaryButton, SecondaryButton } from '@calm-web/design-system';

import {
	ReportingButtonContainerContext,
	ReportingContext,
	timeframeValues,
} from '@/components/providers/ReportingContextProvider';
import DatePicker from '@/components/ui/DatePicker';
import Popover from '@/components/ui/Popover';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';

import messages from './messages';
import {
	ButtonRow,
	DatePickerGrid,
	DatePickerTitle,
	DateRangePanel,
	DateRangeTitle,
	ErrorMessage,
	PanelContainer,
} from './styles';

const CustomDateRange = (): ReactElement => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { onReportingTimeframeSelection, startDate, endDate, setStartDate, setEndDate, maxEndDate } =
		useContext(ReportingContext);
	const { dateRangePickerIsOpen, openDateRangePicker } = useContext(ReportingButtonContainerContext);
	const yesterday = DateTime.local().minus({ days: 1 }).toJSDate() as Date;

	let maxCalDate = yesterday;
	if (maxEndDate) {
		const dateObj = new Date(maxEndDate);
		maxCalDate = new Date(new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * -60000));
	}

	const handleDateChange =
		(name: 'rangeStartDate' | 'rangeEndDate'): ((d: Date) => void) =>
		(d: Date) => {
			if (name === 'rangeStartDate') {
				if (setStartDate) setStartDate(d);
			} else {
				if (setEndDate) setEndDate(d);
			}
		};
	const isValid = useMemo(() => !(startDate && endDate && startDate > endDate), [startDate, endDate]);

	const handleApplyClick = (): void => {
		// show the selected date range
		// dismiss the picker
		if (openDateRangePicker) openDateRangePicker(false);
		if (startDate && endDate) {
			logEvent('Longer Timeframes : Frame : Selected', {
				option: 'Custom',
				startDate: startDate.getTime() ?? 0,
				endDate: endDate.getTime() ?? 0,
				windowSize: endDate.getTime() - startDate.getTime(),
				timeSinceStartDate: new Date().getTime() - startDate.getTime(),
				timeSinceEndDate: new Date().getTime() - endDate.getTime(),
			});
			onReportingTimeframeSelection({
				value: timeframeValues.CUSTOM,
				startDate: startDate,
				endDate: endDate,
			});
		}
	};

	return (
		<PanelContainer>
			<Popover
				isOpen={dateRangePickerIsOpen}
				onClose={() => {
					if (openDateRangePicker) openDateRangePicker(false);
				}}
				shouldCloseOnBlur={false}
			>
				{dateRangePickerIsOpen && (
					<DateRangePanel>
						<DateRangeTitle>{formatMessage(messages.customDateRangeTitle)}</DateRangeTitle>
						<DatePickerGrid>
							<div>
								<DatePickerTitle id="start-date-label">
									{formatMessage(messages.startDateLabel)}
								</DatePickerTitle>
								<DatePicker
									startDate={startDate}
									maxDate={maxCalDate}
									onChange={handleDateChange('rangeStartDate')}
									width="100%"
									labelledBy="start-date-label"
								/>
							</div>
							<div>
								<DatePickerTitle id="end-date-label">{formatMessage(messages.endDateLabel)}</DatePickerTitle>
								<DatePicker
									startDate={endDate}
									maxDate={maxCalDate}
									onChange={handleDateChange('rangeEndDate')}
									width="100%"
									labelledBy="end-date-label"
								/>
							</div>
						</DatePickerGrid>
						{isValid ? null : (
							<ErrorMessage isSuccess={false}>Please select a start date before your end date</ErrorMessage>
						)}
						<ButtonRow>
							<SecondaryButton
								size={ButtonSizes.Sm}
								onPress={() => {
									if (openDateRangePicker) openDateRangePicker(false);
								}}
							>
								{formatMessage(messages.cancelButton)}
							</SecondaryButton>
							<PrimaryButton size={ButtonSizes.Sm} isDisabled={!isValid} onPress={handleApplyClick}>
								{formatMessage(messages.applyButton)}
							</PrimaryButton>
						</ButtonRow>
					</DateRangePanel>
				)}
			</Popover>
		</PanelContainer>
	);
};

export default CustomDateRange;
