import { DateTime } from 'luxon';
import { Key, ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
	ReportingButtonContainerContext,
	ReportingContext,
	timeframeValues,
} from '@/components/providers/ReportingContextProvider';
import DropDown from '@/components/ui/DropDown';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { PartnerCategoryType } from '@/types/store/reducers';
import CalendarOutlinedIcon from 'icons/calendar-dotted-outlined.svg';

import messages from './messages';

const ariaLabel = 'Reporting time frame filter';

const TimeFrameFilter = (): ReactElement => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const {
		isReportMocked,
		partner,
		onReportingTimeframeSelection,
		selectedLongerTimeframe,
		setStartDate,
		setEndDate,
		endDateToUse,
	} = useContext(ReportingContext);
	const { openDateRangePicker } = useContext(ReportingButtonContainerContext);
	const last12WeeksDefault = formatMessage(messages.last12Weeks);
	const custom = formatMessage(messages.custom);
	const options = [
		{ name: formatMessage(messages.last7Days) },
		{ name: formatMessage(messages.last28Days) },
		{ name: last12WeeksDefault },
		{ name: formatMessage(messages.lastYear) },
		{ name: custom },
	];
	const disabledKeysWhenReportIsMocked = options
		.filter(option => option.name !== last12WeeksDefault)
		.map(option => option.name);
	let selected = last12WeeksDefault;
	switch (selectedLongerTimeframe?.value) {
		case timeframeValues.SEVEN_DAYS:
			selected = formatMessage(messages.last7Days);
			break;
		case timeframeValues.TWENTY_EIGHT_DAYS:
			selected = formatMessage(messages.last28Days);
			break;
		case timeframeValues.TWELVE_WEEKS:
			selected = last12WeeksDefault;
			break;
		case timeframeValues.YEAR:
			selected = formatMessage(messages.lastYear);
			break;
		default:
			selected = custom;
	}

	const onSelect = (option: Key): void => {
		if (option === custom && openDateRangePicker) {
			openDateRangePicker(true);
		} else {
			logEvent('Longer Timeframes : Frame : Selected', {
				option,
			});
		}
		if (onReportingTimeframeSelection) {
			const today = DateTime.local();
			const yesterday = today.minus({ days: 1 }).toJSDate() as Date;
			const endDateOrYesterday = endDateToUse || yesterday;
			const endDateLuxon = DateTime.fromJSDate(endDateOrYesterday);
			switch (option) {
				case 'Last 7 days':
					const sevenDaysAgo = endDateLuxon.minus({ days: 6 }).toJSDate() as Date;
					onReportingTimeframeSelection({
						value: timeframeValues.SEVEN_DAYS,
						startDate: sevenDaysAgo,
						endDate: endDateOrYesterday,
					});
					break;
				case 'Last 28 days':
					const twenteyEightDaysAgo = endDateLuxon.minus({ days: 27 }).toJSDate() as Date;
					onReportingTimeframeSelection({
						value: timeframeValues.TWENTY_EIGHT_DAYS,
						startDate: twenteyEightDaysAgo,
						endDate: endDateOrYesterday,
					});
					break;
				case 'Last 12 weeks':
					const twelveWeeksAgo = endDateLuxon.minus({ weeks: 11, days: 6 }).toJSDate() as Date;
					onReportingTimeframeSelection({
						value: timeframeValues.TWELVE_WEEKS,
						startDate: twelveWeeksAgo,
						endDate: endDateOrYesterday,
					});
					break;
				case 'Last year':
					const oneYearAgo = endDateLuxon.minus({ years: 1 }).plus({ days: 1 }).toJSDate() as Date;
					onReportingTimeframeSelection({
						value: timeframeValues.YEAR,
						startDate: oneYearAgo,
						endDate: endDateOrYesterday,
					});
					break;
			}

			// Clear start and end date from custom fields if other option is selected
			if (option !== custom) {
				setStartDate(undefined);
				setEndDate(undefined);
			}
		}
	};

	return (
		<DropDown
			Icon={CalendarOutlinedIcon}
			options={options}
			selected={selected}
			setSelected={onSelect}
			ariaLabel={ariaLabel}
			disabledKeys={isReportMocked ? disabledKeysWhenReportIsMocked : undefined}
			tooltipText={
				isReportMocked
					? formatMessage(messages.infoTooltip, {
							partnerType:
								partner?.category === PartnerCategoryType.SALES_TRIAL
									? formatMessage(messages.salesTrial)
									: formatMessage(messages.demo),
					  })
					: undefined
			}
		/>
	);
};

export default TimeFrameFilter;
