import { FC, FormEvent, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import useHealthConfig, { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { useHealthSponsorships } from '@/hooks/api/useHealthSponsorships';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { useHealthConfigForm, useHealthConfigSubmitData } from '@/hooks/forms/useHealthConfigForm';
import {
	useHealthSponsorshipOrderForm,
	useHealthSponsorshipOrderSubmitData,
	useSubmitHealthSponsorshipOrderForm,
} from '@/hooks/forms/useHealthSponsorshipOrderForm';
import { useShouldShowErrorEligibilityConfig } from '@/hooks/useShouldShowErrorEligibilityConfig';
import { HealthConfig as HealthConfigType, HealthSponsorship } from '@/types/health';
import { ProductSKUDatabaseType } from '@/utils/SkuEnums';

import StickySaveButton from '../../../StickySaveButton';
import EligibilityValidators from './EligibilityValidators';
import ErrorEligibilityConfig from './ErrorEligibilityConfig';
import HealthSponsorships from './HealthSponsorships';
import { isDefaultSponsorship } from './helpers';
import InAppClientSupport from './InAppClientSupport';
import LandingPageCustomization from './LandingPageCustomization';
import messages from './messages';
import EligibilityValidatorPreview from './Previews/EligibilityValidatorPreview';
import ErrorCustomizationPreview from './Previews/ErrorCustomizationPreview';
import HealthSponsorshipPreview from './Previews/HealthSponsorshipPreview';
import InAppClientSupportPreview from './Previews/InAppClientSupportPreview';
import LandingPagePreview from './Previews/LandingPagePreview';
import { ConfigWrapper, PreviewWrapper, Wrapper } from './styles';

interface HealthConfigProps {
	partnerId: string;
	healthConfig: HealthConfigType;
	healthSponsorships: HealthSponsorship[];
	dependentHealthSponsorships: HealthSponsorship[];
}

type PreviewFocus = 'support' | 'landing' | 'eligibility' | 'sponsorship' | 'errorCustomization';

const CH_DEPENDENTS_FEATURE_FLAG = 'b2b-ch-dependents';

const HealthConfigInner: FC<HealthConfigProps> = ({
	partnerId,
	healthConfig,
	healthSponsorships,
	dependentHealthSponsorships,
}) => {
	const { formatMessage } = useIntl();

	const healthConfigForm = useHealthConfigForm(healthConfig);
	const { getHealthConfigSubmitData, showValidationErrors } = useHealthConfigSubmitData(
		healthConfigForm.baseFormProps,
		healthConfigForm.eligibilityValidatorFormProps,
		healthConfigForm.errorCustomizationFormProps,
	);
	const { shouldShowErrorEligibilityConfig } = useShouldShowErrorEligibilityConfig();
	const [submitExistingHealthConfigForm, { loading }] = useSubmitExistingHealthConfigForm(
		healthConfigForm.resetAllDirtyStates,
	);
	const healthSponsorshipOrderForm = useHealthSponsorshipOrderForm(
		healthSponsorships.filter(sponsorship => !isDefaultSponsorship(sponsorship)),
	);
	const { getHealthSponsorshipOrderSubmitData, showValidationErrors: showOrderValidationErrors } =
		useHealthSponsorshipOrderSubmitData(healthSponsorshipOrderForm.formProps);
	const [submitHealthSponsorshipOrderForm, { loading: orderFormSubmitting }] =
		useSubmitHealthSponsorshipOrderForm(healthSponsorshipOrderForm.formProps.resetAllDirtyStates);
	const [focus, setFocus] = useState<PreviewFocus>('support');
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(CH_DEPENDENTS_FEATURE_FLAG);
	const partner = useDefinedPartner();

	const shouldShowDependentGroups =
		!flagLoading &&
		!flagError &&
		flagValues?.[CH_DEPENDENTS_FEATURE_FLAG] === true &&
		partner?.product_sku !== ProductSKUDatabaseType.CH_PAYOR_BASE;

	const landingOnFocus = useCallback((): void => {
		setFocus('landing');
	}, [setFocus]);
	const clientSupportOnFocus = useCallback((): void => {
		setFocus('support');
	}, [setFocus]);
	const eligibilityOnFocus = useCallback((): void => {
		setFocus('eligibility');
	}, [setFocus]);
	const sponsorshipOnFocus = useCallback((): void => {
		setFocus('sponsorship');
	}, [setFocus]);
	const eligibilityErrorCustomizationOnFocus = useCallback((): void => {
		setFocus('errorCustomization');
	}, [setFocus]);

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors() || showOrderValidationErrors()) return;
		const healthConfigSubmitData = getHealthConfigSubmitData();
		const healthSponsorshipOrderSubmitData = getHealthSponsorshipOrderSubmitData();

		try {
			await submitExistingHealthConfigForm(healthConfigSubmitData, partnerId);
			if (healthSponsorshipOrderForm.hasChangedAny) {
				await submitHealthSponsorshipOrderForm(healthSponsorshipOrderSubmitData, partnerId);
			}
		} catch (error) {
			//...
		}
	};

	const previews: Map<PreviewFocus, ReactNode> = new Map([
		[
			'landing',
			<LandingPagePreview
				key="landing" // linter requires this for who knows what reason
				title={stringFromModelValue(healthConfigForm.baseFormProps.model.landingHeader) ?? ''}
				body={stringFromModelValue(healthConfigForm.baseFormProps.model.landingBody) ?? ''}
			/>,
		],
		[
			'support',
			<InAppClientSupportPreview
				key="support" // linter requires this for who knows what reason
				clientSupportChannel={
					stringFromModelValue(healthConfigForm.baseFormProps.model.clientSupportChannel) ?? 'phone'
				}
				healthConfig={healthConfig}
			/>,
		],
		[
			'eligibility',
			<EligibilityValidatorPreview
				key="eligiblity" // linter requires this for who knows what reason
				formProps={healthConfigForm.eligibilityValidatorFormProps}
				healthConfig={healthConfig}
			/>,
		],
		[
			'sponsorship',
			<HealthSponsorshipPreview
				key="eligiblity" // linter requires this for who knows what reason
				partnerId={partnerId}
				name="Sponsor Group 1"
			/>,
		],
		[
			'errorCustomization',
			<ErrorCustomizationPreview
				key="errorCustomization"
				errorBody={stringFromModelValue(healthConfigForm.errorCustomizationFormProps.model.error_body)}
				errorButtonText={stringFromModelValue(
					healthConfigForm.errorCustomizationFormProps.model.error_button_text,
				)}
			/>,
		],
	]);
	const focusPreview = previews.get(focus);

	return (
		<>
			<form onSubmit={onSubmit} style={{ marginBottom: '4rem' }}>
				<Wrapper>
					<ConfigWrapper>
						<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
							{formatMessage(messages.healthConfigTitle)}
						</Text>
						<LandingPageCustomization formProps={healthConfigForm.baseFormProps} onFocus={landingOnFocus} />
						<InAppClientSupport formProps={healthConfigForm.baseFormProps} onFocus={clientSupportOnFocus} />
						<EligibilityValidators
							formProps={healthConfigForm.eligibilityValidatorFormProps}
							addEligibilityValidator={healthConfigForm.addEligibilityValidator}
							removeEligibilityValidator={healthConfigForm.removeEligibilityValidator}
							onFocus={eligibilityOnFocus}
						/>
						{shouldShowErrorEligibilityConfig && (
							<ErrorEligibilityConfig
								formProps={healthConfigForm.errorCustomizationFormProps}
								onFocus={eligibilityErrorCustomizationOnFocus}
							/>
						)}
						<HealthSponsorships
							partnerId={partnerId}
							healthSponsorships={healthSponsorships}
							orderFormProps={healthSponsorshipOrderForm.formProps}
							onFocus={sponsorshipOnFocus}
						/>
						{shouldShowDependentGroups && (
							<HealthSponsorships
								partnerId={partnerId}
								healthSponsorships={dependentHealthSponsorships}
								onFocus={sponsorshipOnFocus}
								isDependentGroup={true}
							/>
						)}
					</ConfigWrapper>
					<PreviewWrapper>{focusPreview}</PreviewWrapper>
				</Wrapper>
				<StickySaveButton
					isFormDirty={healthConfigForm.hasChangedAny || healthSponsorshipOrderForm.hasChangedAny}
					hasTouchedForm={healthConfigForm.hasTouchedAny || healthSponsorshipOrderForm.hasTouchedAny}
					isLoading={loading || orderFormSubmitting}
					dataTestId="create-edit-health-config-save-btn"
				>
					Save Configuration
				</StickySaveButton>
			</form>
		</>
	);
};

const HealthConfig: FC<{ partnerId: string }> = ({ partnerId }) => {
	const { data: healthConfig, loading } = useHealthConfig({ partnerId });
	const { data: healthSponsorships, loading: sponsorshipsLoading } = useHealthSponsorships(partnerId, false);
	const { data: dependentHealthSponsorships, loading: dependentSponsorshipsLoading } = useHealthSponsorships(
		partnerId,
		true,
	);

	if (loading || sponsorshipsLoading || dependentSponsorshipsLoading) {
		return <Loader />;
	}

	if (!healthConfig || !healthSponsorships || !dependentHealthSponsorships) {
		return null;
	}

	return (
		<HealthConfigInner
			partnerId={partnerId}
			healthConfig={healthConfig}
			healthSponsorships={healthSponsorships}
			dependentHealthSponsorships={dependentHealthSponsorships}
		/>
	);
};

export default HealthConfig;
