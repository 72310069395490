import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Card } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';

import messages from './messages';
import PartnerDetails from './PartnerDetails';
import { Subtitle } from './styles';

function NameAndId({
	formProps,
	isEdit,
	partner,
	isAccountManager,
	isViewOnly = false,
}: {
	formProps: EditPartnerFormProps;
	isEdit: boolean;
	partner: Partner | undefined;
	isAccountManager: boolean;
	isViewOnly?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<Card>
			<CellTitle>{formatMessage(messages.nameAndIdCardTitle)}</CellTitle>
			{isAccountManager && !isEdit ? <Subtitle>{formatMessage(messages.instructions)}</Subtitle> : null}
			<PartnerDetails formProps={formProps} isEdit={isEdit} partner={partner} isDisabled={isViewOnly} />
		</Card>
	);
}

export default NameAndId;
