import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import useForm, {
	FormProps,
	stringFromModelValue,
	validation as validationObject,
	ValidationSchemaFn,
} from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { HealthSponsorship, HealthSponsorshipOrder } from '@/types/health';

import { useApi } from '../api';
import { ApiResponse } from '../api/types';
import { usePermissions } from '../auth';

export const useHealthSponsorshipOrderForm = (
	sponsorships: HealthSponsorship[],
): {
	formProps: HealthSponsorshipOrderFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	// build object where key is sponsorship id and value is order number
	const initialModel = useMemo(() => {
		const initialOrderMap: { [key: string]: string } = {};
		sponsorships?.forEach((sponsorship, index) => {
			initialOrderMap[sponsorship.id] = ((sponsorship.assignment_order ?? index) + 1).toString();
		});
		// form props require an object with at least one key to initialize
		return sponsorships.length ? initialOrderMap : { dummyVal: 'dummyVal' };
	}, [sponsorships]);

	const validation = useMemo(() => {
		const validationMap: { [key: string]: ValidationSchemaFn<string> } = {};
		sponsorships?.forEach(
			sponsorship => {
				validationMap[sponsorship.id] = validationObject.validateOrFail([
					{
						rules: [
							value => {
								return validationObject.digitsOnly(value);
							},
						],
						errorResult: '',
					},
				]);
			},
			[sponsorships],
		);
		return sponsorships.length ? validationMap : {};
	}, [sponsorships]);

	const formProps = useForm('healthSponsorshipOrderForm', {
		initialModel,
		validation,
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthSponsorshipOrderSubmitData = (
	formProps: HealthSponsorshipOrderFormProps,
): {
	getHealthSponsorshipOrderSubmitData: () => { order: HealthSponsorshipOrder[] };
	showValidationErrors: () => boolean;
} => {
	const dispatch = useDispatch();
	const [hasValidPermissions, actions] = usePermissions();

	const getHealthSponsorshipOrderSubmitData = useCallback((): { order: HealthSponsorshipOrder[] } => {
		if (!hasValidPermissions('health_sponsorship_order', [actions.UPDATE])) return { order: [] };
		return {
			order:
				Object.keys(formProps.model).map(sponsorshipId => {
					return {
						id: sponsorshipId,
						assignment_order: parseInt(stringFromModelValue(formProps.model[sponsorshipId]) ?? '0'),
					};
				}) ?? [],
		};
	}, [hasValidPermissions, actions.UPDATE, formProps.model]);

	const showValidationErrors = useCallback((): boolean => {
		if (formProps.validation.isValid) {
			return false;
		}
		const errorMessage = 'Please use digits only for sponsorship priority';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, formProps]);

	return { getHealthSponsorshipOrderSubmitData, showValidationErrors };
};

type UseSubmitHealthSponsorshipOrderFormResponse = [
	submitExistingHealthConfigForm: (
		healthConfigData: { order: HealthSponsorshipOrder[] },
		partnerId: string,
	) => Promise<void>,
	state: ApiResponse<HealthSponsorshipOrder>,
];

export const useSubmitHealthSponsorshipOrderForm = (
	resetAllDirtyStates: () => void,
): UseSubmitHealthSponsorshipOrderFormResponse => {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const apiRequest = useApi();
	const [hasValidPermissions, actions] = usePermissions();

	async function submitHealthSponsorshipOrderForm(
		orderData: { order: HealthSponsorshipOrder[] },
		partnerId: string,
	): Promise<void> {
		try {
			setIsSubmitting(true);
			if (!hasValidPermissions('health_sponsorship_order', [actions.UPDATE]))
				throw new Error('Invalid Permissions');
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/health-sponsorships/order`,
				method: 'PATCH',
				body: orderData,
			});

			resetAllDirtyStates();
		} catch (error) {
			dispatch(
				setBannerMessage({
					message: 'An error has occurred updating partner health sponsorship order!',
					flash: true,
					isError: true,
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	}

	return [submitHealthSponsorshipOrderForm, { data: undefined, loading: isSubmitting, error: undefined }];
};

export type HealthSponsorshipOrderFormProps = FormProps<string>;
