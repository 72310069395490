import capitalize from 'lodash/capitalize';
import { ReactElement, ReactNode, RefObject, useMemo } from 'react';
import {
	Area,
	AreaChart,
	Tooltip as RechartsTooltip,
	ResponsiveContainer,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';

import { TooltipBody } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { aggregationLevelToTimeUnit, ReportingAggregationLevel } from '@/hooks/api/reporting/utils';
import { useIsMobile } from '@/hooks/layout/useIsMobile';

import { LineChartContainer, TooltipLabel, TooltipValue } from './styles';

const friendlyFloat = (val?: number): string => (val ? val.toFixed(1).replace(/\.0$/, '') : '0');

export const localTimezoneDate = (dateString?: string): Date => {
	if (!dateString) {
		return new Date();
	}
	const dateParts = dateString.split(/\D/).map((datePart: string) => parseInt(datePart, 10));
	return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};

function SignupsOrEngagementGraph({
	data,
	tooltipLabel,
	paddingSide,
	aggregationLevel,
	hasNoSignups,
}: {
	data: { date: string; value: number }[];
	tooltipLabel: string;
	paddingSide: 'left' | 'right';
	aggregationLevel: ReportingAggregationLevel;
	hasNoSignups?: boolean;
	forwardedRef?: RefObject<HTMLDivElement>;
}): ReactElement {
	const { logEvent } = useAnalytics();
	const [isMobile] = useIsMobile();

	const [chartData, maxCount, minCount] = useMemo(() => {
		let _maxCount = 0;
		let _minCount = 0;
		const sortedData = data.sort((a, b) => {
			return localTimezoneDate(a.date) < localTimezoneDate(b.date) ? -1 : 1;
		});
		const formattedData = sortedData.map((datum: { date: string; value: number }) => {
			if (hasNoSignups) {
				/*
				 ** if the graph has no points to display, show straight line close to 0
				 ** set the min and max y axis count to position it close to the bottom but not overlapping x axis
				 */
				_minCount = 0;
				_maxCount = 30;
			} else if (datum.value > _maxCount) {
				_maxCount = Math.ceil(datum.value);
			}
			return {
				...datum,
				shortDate: localTimezoneDate(datum.date).toLocaleDateString(undefined, {
					month: 'numeric',
					day: 'numeric',
				}),
			};
		});
		return [formattedData, _maxCount, _minCount];
	}, [data, hasNoSignups]);

	const ChartTooltip = (props: TooltipProps<number, string | number>): ReactNode => {
		const { active, payload } = props;
		if (!active || !payload || !payload[0]) {
			return null;
		}
		const formattedDate = localTimezoneDate(payload?.[0].payload.date).toLocaleDateString();
		const value = payload?.[0].value;
		const aggregationLevelTimeUnit = aggregationLevelToTimeUnit[aggregationLevel];
		return (
			<TooltipBody>
				{capitalize(aggregationLevelTimeUnit)} of {formattedDate}
				<br />
				<TooltipLabel>{tooltipLabel}</TooltipLabel>
				<TooltipValue>{friendlyFloat(value)}</TooltipValue>
			</TooltipBody>
		);
	};
	const orderOfMagnitude = useMemo(() => Math.ceil(Math.log10(maxCount)), [maxCount]);

	return (
		<LineChartContainer data-testid="signups-graph" paddingSide={paddingSide}>
			<ResponsiveContainer height={200}>
				<AreaChart
					data={chartData}
					margin={{
						top: 10,
						right: 0,
						left: 0,
						bottom: 0,
					}}
				>
					<RechartsTooltip isAnimationActive={false} cursor={false} content={ChartTooltip} />
					<XAxis
						tickLine={false}
						dataKey="shortDate"
						padding={{ left: 16, right: 18 }}
						axisLine={{ stroke: '#E2E2E2' }}
						interval={0}
						angle={isMobile ? 45 : 0}
					/>
					<YAxis
						tickLine={false}
						type="number"
						domain={[minCount, maxCount]}
						allowDecimals={false}
						width={16 + orderOfMagnitude * 8}
					/>
					<Area
						dataKey="value"
						stroke="#555BE4"
						strokeOpacity={0.4}
						strokeWidth={2}
						fill="#D7D8F3"
						dot={false}
						isAnimationActive={false}
						onMouseEnter={() =>
							logEvent('Partner Portal : Reporting : Signup and Engagement : Graph : Mouse Enter')
						}
						onMouseLeave={() =>
							logEvent('Partner Portal : Reporting : Signup and Engagement : Graph : Mouse Exit')
						}
					/>
				</AreaChart>
			</ResponsiveContainer>
		</LineChartContainer>
	);
}

export default SignupsOrEngagementGraph;
