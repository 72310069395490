import useForm, { FormProps, ModelValue, OneFormReduxState, validation } from '@calm-web/use-form';

import { ErrorConfigsType, HealthConfig } from '@/types/health';

export const useHealthErrorCustomizationConfigForm = (
	healthConfig?: Pick<HealthConfig, 'error_configs'>,
): {
	formProps: EditErrorConfigFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const filteredHealthConfig =
		healthConfig?.error_configs.filter(error => error.error_type === ErrorConfigsType.Eligibility) ?? [];

	const errorButtonFieldNames = ['error_button_text', 'error_button_type', 'error_button_data'];

	const validateButtonFields = (
		value: ModelValue | undefined,
		props: OneFormReduxState<ErrorFieldNames>,
	): boolean => {
		const fields = errorButtonFieldNames.map(fieldName => props.model[fieldName as ErrorFieldNames]);
		const hasAnyValue = fields.some(Boolean);
		const hasAllValues = fields.every(Boolean);
		return !hasAnyValue || hasAllValues;
	};

	const formProps: EditErrorConfigFormProps = useForm('errorConfigForm', {
		initialModel: {
			error_type: ErrorConfigsType.Eligibility,
			error_body: filteredHealthConfig[0]?.error_body ?? '',
			error_button_text: filteredHealthConfig[0]?.error_button_text ?? '',
			error_button_type: filteredHealthConfig[0]?.error_button_type ?? '',
			error_button_data: filteredHealthConfig[0]?.error_button_data ?? '',
		},
		validation: {
			error_body: validation.validateOrFail([
				{
					rules: [validation.maxLengthOrEmpty(250)],
					errorResult: 'Please enter a body with a maximum of 250 characters',
				},
			]),
			error_button_text: validation.validateOrFail([
				{
					rules: [validateButtonFields, (value, props) => !validation.regexMatch(/^[ ]+$/)(value, props)],
					errorResult: 'Button Text, Type, and Data must all be completed or empty.',
				},
				{
					rules: [validation.maxLengthOrEmpty(25)],
					errorResult: 'Please enter a value with a maximum of 25 characters',
				},
			]),
			error_button_type: validation.validateOrFail([
				{
					rules: [validateButtonFields],
					errorResult: 'Button Text, Type, and Data must all be completed or empty.',
				},
			]),
			error_button_data: validation.validateOrFail([
				{
					rules: [validateButtonFields, (value, props) => !validation.regexMatch(/^[ ]+$/)(value, props)],
					errorResult: 'Button Text, Type, and Data must all be completed or empty.',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export type ErrorFieldNames =
	| 'error_type'
	| 'error_body'
	| 'error_button_text'
	| 'error_button_type'
	| 'error_button_data';

export type EditErrorConfigFormProps = FormProps<ErrorFieldNames>;
