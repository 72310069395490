import { ReactElement } from 'react';

import { fileFromModelValue, ModelValue } from '@calm-web/use-form';

import useHealthConfig from '@/hooks/api/useHealthConfig';
import { createUrl } from '@/utils/url';

import { PhoneOutline, Wrapper } from '../styles';
import {
	AccountEmail,
	AccountInfo,
	CompanyLogo,
	EditButton,
	GearIcon,
	GoogleIcon,
	GrayHr,
	HealthSponsorshipCard,
	HealthSponsorshipCardContents,
	NavBar,
	NavTitle,
	ProfileCard,
	ProfileName,
	ProfilePicture,
	RightChevron,
	ScreeningBanner,
	ScreeningCard,
	ScreeningMessageHeader,
	ScreeningSectionHeader,
	SenderInfo,
	SenderName,
	SenderProfilePicture,
	SponsorInfo,
	SponsorName,
	SponsorSubtitle,
	ViewPort,
} from './styles';

const HealthSponsorshipPreview = ({
	name,
	partnerId,
	squareLogoFile,
}: {
	name: string;
	partnerId: string;
	squareLogoFile?: ModelValue;
}): ReactElement => {
	const { data: healthConfig } = useHealthConfig({ partnerId });
	const updatedSquareLogoUrl = createUrl(fileFromModelValue(squareLogoFile));
	return (
		<Wrapper data-testid="health-sponsorship-preview">
			<PhoneOutline />
			<ViewPort>
				<NavBar>
					<NavTitle>Profile</NavTitle>
					<GearIcon />
				</NavBar>
				<ProfileCard>
					<EditButton>Edit</EditButton>
					<ProfilePicture>RW</ProfilePicture>
					<ProfileName>Rhonda Williams</ProfileName>
					<AccountInfo>
						You are signed in using your:
						<AccountEmail>
							<GoogleIcon />
							email@gmail.com
						</AccountEmail>
					</AccountInfo>
				</ProfileCard>
				<HealthSponsorshipCard>
					<HealthSponsorshipCardContents>
						<CompanyLogo
							data-testid="sponsorship-logo"
							src={updatedSquareLogoUrl || (healthConfig?.square_logo_url ?? undefined)}
						/>
						<SponsorInfo>
							<SponsorName data-testid="sponsorship-name">{name}</SponsorName>
							<SponsorSubtitle>Your Calm Health Sponsor</SponsorSubtitle>
						</SponsorInfo>
						<RightChevron />
					</HealthSponsorshipCardContents>
				</HealthSponsorshipCard>
				<GrayHr />
				<ScreeningSectionHeader>Mental Health Screening</ScreeningSectionHeader>
				<ScreeningCard>
					<ScreeningBanner />
					<SenderInfo>
						<SenderProfilePicture>C</SenderProfilePicture>
						<SenderName>Calm Clinical</SenderName>
					</SenderInfo>
					<ScreeningMessageHeader>Let&rsquo;s check in on your mental health</ScreeningMessageHeader>
				</ScreeningCard>
			</ViewPort>
		</Wrapper>
	);
};

export default HealthSponsorshipPreview;
