import styled, { keyframes } from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, minWidth, units } from '@calm-web/design-system';

export const Container = styled.div`
	width: 100%;
	position: relative;
`;

export const HiddenDiv = styled.div<{ $hidden: boolean }>`
	visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;

export const LoadingText = styled.div`
	text-align: center;
	color: ${palette('gray8')};
	${fontSize(FontSizes.sm)}
	${minWidth('tablet')} {
		min-width: ${units(52)};
	}
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;
export const BreatheWrapper = styled.div`
	opacity: 0;
	animation: 2s ease-in-out 0.5s forwards ${fadeIn};
	flex-direction: column;
	display: flex;
	justify-content: space-between;
`;
