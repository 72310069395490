import { Route } from 'react-router-dom';

import Pathways from '@/components/pages/Pathways';
import PathwayCreateAndEdit from '@/components/pages/Pathways/Create';
import PathwaysContextProvider from '@/components/providers/PathwaysContextProvider';

const PathwaysRoutes: React.FC = () => {
	return (
		<Route path={'/:partnerId/pathways'}>
			<PathwaysContextProvider>
				<Route exact path="/:partnerId/pathways/create" component={PathwayCreateAndEdit} />
				<Route exact path="/:partnerId/pathways/edit/:pathwayId" component={PathwayCreateAndEdit} />
				<Route exact path="/:partnerId/pathways" component={Pathways} />
			</PathwaysContextProvider>
		</Route>
	);
};

export default PathwaysRoutes;
