import { FC, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Button, FontSizes, FontWeights, Modal, Text } from '@calm-web/design-system';

import messages from './messages';
import { Checkmark, RemovedReminderText, StatsWrapper, Warning } from './styles';

interface UploadSuccessModalProps {
	added: number;
	removed: number;
	duplicates: number;
	addedSegmentCount: number;
	deletedSegmentCount: number;
	canShowStats: () => boolean;
	onClose: () => void;
	isOpen: boolean;
}

const StatsBlock: FC<{ showCheckmark?: boolean; children: ReactNode }> = ({
	showCheckmark = true,
	children,
}) => (
	<Text el="p">
		{showCheckmark ? <Checkmark /> : <Warning />}
		{children}
	</Text>
);

const UploadSuccessModal = ({
	isOpen,
	onClose,
	added,
	removed,
	duplicates,
	addedSegmentCount,
	deletedSegmentCount,
	canShowStats,
}: UploadSuccessModalProps): ReactElement => {
	const { formatMessage } = useIntl();
	const hasStatsToShow = canShowStats();

	return (
		<Modal
			isOpen={isOpen}
			canClose
			closeModal={onClose}
			title={formatMessage(messages.title)}
			footer={
				<Button
					backgroundColor="transparent"
					hideShadow
					size="md"
					textColor="blue3"
					onPress={onClose}
					data-testid="confirm-upload-success-button"
				>
					{formatMessage(messages.closeModal)}
				</Button>
			}
		>
			<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium} noMargin>
				{formatMessage(messages.heading)}
			</Text>

			<StatsWrapper>
				{!hasStatsToShow && <Text el="p">{formatMessage(messages.noChanges)}</Text>}
				{Boolean(added) && (
					<StatsBlock>
						{formatMessage(messages.usersAdded, {
							added,
							changeAction: (...chunks: ReactNode[]) => (
								<Text el="span" weight={FontWeights.Medium}>
									{chunks}
								</Text>
							),
						})}
					</StatsBlock>
				)}
				{Boolean(removed) && (
					<>
						<StatsBlock>
							{formatMessage(messages.usersRemoved, {
								removed,
								changeAction: (...chunks: ReactNode[]) => (
									<Text el="span" weight={FontWeights.Medium}>
										{chunks}
									</Text>
								),
							})}
						</StatsBlock>
						<RemovedReminderText el="p" size={FontSizes.sm}>
							{formatMessage(messages.removedReminder)}
						</RemovedReminderText>
					</>
				)}
				{Boolean(addedSegmentCount) && (
					<StatsBlock>
						{formatMessage(messages.segmentsAdded, {
							addedSegmentCount,
							changeAction: (...chunks: ReactNode[]) => (
								<Text el="span" weight={FontWeights.Medium}>
									{chunks}
								</Text>
							),
						})}
					</StatsBlock>
				)}
				{Boolean(deletedSegmentCount) && (
					<StatsBlock>
						{formatMessage(messages.segmentsRemoved, {
							deletedSegmentCount,
							changeAction: (...chunks: ReactNode[]) => (
								<Text el="span" weight={FontWeights.Medium}>
									{chunks}
								</Text>
							),
						})}
					</StatsBlock>
				)}
				{Boolean(duplicates) && (
					<StatsBlock showCheckmark={false}>{formatMessage(messages.duplicatesCopy)}</StatsBlock>
				)}
			</StatsWrapper>
		</Modal>
	);
};

export default UploadSuccessModal;
