import { FC, forwardRef, ReactNode, Ref, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Modal } from '@calm-web/design-system';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { Feedback } from '@/hooks/api/useFeedback';

import WidgetCard from '../WidgetCard';
import HighlightedFeedback from './HighlightedFeedback';
import messages from './messages';
import {
	ChatBubbles,
	EmptyStateContainer,
	InfoIcon,
	ModalText,
	ModalUl,
	SeeAllFeedbackButton,
	SeeAllFeedbackContainer,
	Subtitle,
	SubtitleContainer,
} from './styles';

function HighlightedFeedbackBox(
	{
		isLoading,
		partnerId,
		data,
	}: {
		isLoading?: boolean;
		partnerId: string;
		data?: { feedback: Feedback[]; totalCount: number };
	},
	ref?: Ref<HTMLDivElement>,
): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [showExplanationModal, setShowExplanationModal] = useState(false);
	const [expandedFeedback, setExpandedFeedback] = useState<Feedback | null>(null);
	const { isReportDownloading } = useContext(ReportingContext);

	const wrapper = (
		<>
			<Modal
				isOpen={showExplanationModal}
				closeModal={() => setShowExplanationModal(false)}
				title={formatMessage(messages.modalTitle)}
			>
				<ModalText>
					{formatMessage(messages.modalText, {
						ul: (...chunks: ReactNode[]) => <ModalUl>{chunks}</ModalUl>,
						li: (...chunks: ReactNode[]) => <li>{chunks}</li>,
					})}
				</ModalText>
			</Modal>
			<Modal
				isOpen={Boolean(expandedFeedback)}
				closeModal={() => setExpandedFeedback(null)}
				aria-label="User Feedback"
				title={expandedFeedback ? new Date(expandedFeedback.created_at).toLocaleDateString() : undefined}
			>
				<ModalText>{expandedFeedback?.response_text}</ModalText>
			</Modal>
			<SubtitleContainer onClick={() => setShowExplanationModal(true)}>
				<InfoIcon data-html2canvas-ignore="true" />
				<Subtitle data-html2canvas-ignore="true">{formatMessage(messages.subtitle)}</Subtitle>
			</SubtitleContainer>
			{data?.feedback.length ? (
				<>
					<HighlightedFeedback feedbackList={data.feedback} onClickFeedbackItem={setExpandedFeedback} />
					{data.totalCount > data.feedback.length ? (
						<SeeAllFeedbackContainer>
							{!isReportDownloading && (
								<Link data-html2canvas-ignore="true" to={`/${partnerId}/reporting/feedback`}>
									<SeeAllFeedbackButton>{formatMessage(messages.seeAllFeedback)}</SeeAllFeedbackButton>
								</Link>
							)}
						</SeeAllFeedbackContainer>
					) : null}
				</>
			) : (
				<EmptyStateContainer>
					<ChatBubbles />
					{formatMessage(messages.emptyState)}
				</EmptyStateContainer>
			)}
		</>
	);

	return (
		<WidgetCard
			ref={ref}
			isLoading={isLoading}
			heading="Member Feedback"
			subHeading={formatMessage(messages.title)}
			content={wrapper}
			removeDataContainer
		/>
	);
}

export default forwardRef(HighlightedFeedbackBox);
