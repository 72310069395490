import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';

export interface Invoice {
	subtotal: number;
	taxAmount: number;
	total: number;
}

export function useUpcomingInvoice({
	partnerId,
	shouldShowSubtotalAndTax,
}: {
	partnerId: string;
	shouldShowSubtotalAndTax: boolean;
}): ApiResponse<Invoice> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/invoice/upcoming`, async endpoint => {
		try {
			if (!shouldShowSubtotalAndTax) {
				// No reason to try & retrieve tax details if we don't have a billing address
				// so let's save the API call here & retrieve the Subscription Cost from
				// our DB Table instead.
				return { loading: false, error: undefined, data: undefined };
			}

			const response = await apiRequest({ endpoint });
			if (!response.data) {
				throw new Error('Not able to fetch invoice details');
			}
			return response.data;
		} catch (responseError) {
			calmLogger.error(
				'Error in useUpcomingInvoice',
				{
					partnerId,
					shouldShowSubtotalAndTax,
				},
				responseError,
			);

			dispatch(
				setBannerMessage({
					message: 'Failed to retrieve invoice details',
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data: {
			subtotal: data.subtotal,
			taxAmount: data.tax_amount,
			total: data.total,
		},
		error: undefined,
		loading: false,
	};
}

export function updateUpcomingInvoiceCache(partnerId: string): Promise<boolean | undefined> {
	return mutate(`b2b/partners/${partnerId}/invoice/upcoming`);
}
