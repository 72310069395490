import debounce from 'lodash/debounce';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FontSizes, FormInput, Loader, Text } from '@calm-web/design-system';
import { OnChange } from '@calm-web/use-form';

import { useNumChildren } from '@/hooks/api/usePartnerRelations';
import usePartners from '@/hooks/api/usePartners';

import { Partner } from '../../../types/store/reducers';
import { Container, ResultsContainer, StyledPartnerRow } from './styles';

function NumChildren({ partnerId }: { partnerId: string }): JSX.Element | null {
	const { numChildren } = useNumChildren(partnerId);
	return numChildren ? (
		<Text color="gray6">
			{' '}
			({numChildren} {numChildren === 1 ? 'child' : 'children'})
		</Text>
	) : null;
}

function PartnerRow({
	partner,
	onSelectPartner,
	showNumChildren,
}: {
	partner: Partner;
	onSelectPartner: (partnerId: string) => void;
	showNumChildren?: boolean;
}): JSX.Element {
	const onClick = useCallback(() => {
		onSelectPartner(partner.id);
	}, [onSelectPartner, partner.id]);

	return (
		<StyledPartnerRow role="button" onClick={onClick}>
			<Text>{partner.name}</Text>
			{showNumChildren ? <NumChildren partnerId={partner.id} /> : null}
		</StyledPartnerRow>
	);
}

const SEARCH_DEBOUNCE_TIME = process.env.NODE_ENV === 'test' ? 0 : 300;

export default function PartnerSearch({
	onSelectPartner,
	additionalFilters,
	showNumChildren,
	disabled,
}: {
	onSelectPartner: (partnerId: string) => void;
	additionalFilters?: Record<string, string | Record<string, string>>;
	showNumChildren?: boolean;
	disabled?: boolean;
}): ReactElement {
	const [searchValue, setSearchValue] = useState('');
	const [apiSearchValue, setApiSearchValue] = useState(searchValue);
	const { data, loading } = usePartners({
		page: 0,
		pageSize: 4,
		query: apiSearchValue,
		filters: additionalFilters,
	});
	const partners = useMemo(() => (searchValue ? data?.partners ?? [] : []), [data?.partners, searchValue]);

	// useRef to avoid function memory address change on rerender, which would lead to unnecessary filtering
	const debouncePartnerFilter = useRef(
		debounce((newSearchVal: string) => {
			setApiSearchValue(newSearchVal);
		}, SEARCH_DEBOUNCE_TIME),
	).current;

	// Clean up any pending filtering when unmount occurs
	useEffect(() => {
		return () => {
			debouncePartnerFilter.cancel();
		};
	}, [debouncePartnerFilter]);

	const onSearchChange: OnChange = useCallback(
		e => {
			const newSearchVal = e.target.value || '';
			setSearchValue(newSearchVal);
			debouncePartnerFilter(newSearchVal);
		},
		[debouncePartnerFilter],
	);

	return (
		<Container>
			<FormInput
				label="Partner name"
				onChange={onSearchChange}
				name="search"
				value={searchValue}
				disabled={disabled}
				noValidation
				noMargin
			/>
			{searchValue ? (
				<ResultsContainer>
					{partners.length ? (
						<>
							<Text size={FontSizes.sm}>Did you mean:</Text>
							{partners.map(partner => (
								<PartnerRow
									partner={partner}
									onSelectPartner={onSelectPartner}
									showNumChildren={showNumChildren}
									key={partner.id}
								/>
							))}
						</>
					) : loading ? (
						<Loader color="gray1" />
					) : (
						<Text>No results found</Text>
					)}
				</ResultsContainer>
			) : null}
		</Container>
	);
}
