import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useUser } from '@/hooks/store';
import { setBannerMessage, setUser } from '@/store/actions';
import { User } from '@/types/store/reducers';
import { ErrorWithMessage, isErrorWithMessage } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';
import { ILLEGAL_USER_CONFIGURATION, partnerRoles, UNKNOWN_USER_ROLE } from '@/utils/RBAC';

import Auth0AuthClient from './Auth0AuthClient';

const JWT_EXPIRATION_BUFFER = 5000;

export interface AuthClientInterface {
	signOut(): Promise<void>;
	getCalmUserFromState(): Promise<User | undefined>;
}

// forces us to have only have one active timeout set at a time.
// hooks create a new closure per invocation.
// useMemo can reduce duplication for that closure but does not operate across closures
export const buildSetTimeoutWithOneAtATimeGuarantee = (): ((
	callback: Parameters<typeof setTimeout>[0],
	delay: Parameters<typeof setTimeout>[1],
) => void) => {
	let activeTimeout: NodeJS.Timeout | undefined;

	return (callback: Parameters<typeof setTimeout>[0], delay: Parameters<typeof setTimeout>[1]) => {
		if (activeTimeout) {
			return;
		}
		const newTimeout = setTimeout(() => {
			// the callback has executed, we can clear out active timeout
			callback();
			activeTimeout = undefined;
		}, delay);
		activeTimeout = newTimeout;

		return activeTimeout;
	};
};

// create a global closure so that we can guarantee that only one timeout is running at a time
const setTimeoutWithOneAtATimeGuarantee = buildSetTimeoutWithOneAtATimeGuarantee();

export function useAuth(): [AuthClientInterface | null, User] {
	const { user } = useUser();
	const dispatch = useDispatch();

	const auth0Client = useAuth0();
	const auth0AuthFunctions = useMemo(() => {
		const auth0AuthClient = new Auth0AuthClient(auth0Client);
		return auth0AuthClient;
	}, [auth0Client]);

	useEffect(() => {
		if (!user?.expiresIn || !user?.accessToken || !auth0AuthFunctions) {
			return;
		}
		// expiresIn is in seconds. multiply by 1000 for ms
		const timeTilExpiryInMs = user.expiresIn * 1000;
		const timeTilExpiryWithBuffer = timeTilExpiryInMs - JWT_EXPIRATION_BUFFER;

		setTimeoutWithOneAtATimeGuarantee(
			async () => {
				function handleInvalidUserErrors(err: ErrorWithMessage): void {
					if (err.message === ILLEGAL_USER_CONFIGURATION) {
						dispatch(
							setBannerMessage({
								message: 'Invalid user configuration. Please contact the system administrator to resolve.',
								isError: true,
								flash: false,
							}),
						);
					} else if (err.message === UNKNOWN_USER_ROLE) {
						dispatch(
							setBannerMessage({
								message:
									'User role not recognized for this application. Please contact the system administrator to resolve.',
								isError: true,
								flash: false,
							}),
						);
					}
				}
				try {
					const refreshedUser = await auth0AuthFunctions.getCalmUserFromState();
					dispatch(setUser(refreshedUser ?? {}));
				} catch (err) {
					try {
						await auth0AuthFunctions.signOut();
					} catch (e) {
						// Do nothing here
						calmLogger.error('Failed to sign out in useAuth setTimeoutWithOneAtATimeGuarantee', {}, e);
					}
					if (isErrorWithMessage(err)) {
						handleInvalidUserErrors(err);
					}
				}
			},
			timeTilExpiryWithBuffer <= 0 ? 0 : timeTilExpiryWithBuffer,
		);
	}, [user?.accessToken, user?.expiresIn, auth0AuthFunctions, dispatch]);

	return [auth0AuthFunctions, user];
}

export function useIsAdmin(): boolean {
	const { user } = useUser();
	if (!user) {
		return false;
	}
	return (
		user.accessPolicy?.allowed_user_role === partnerRoles.accountManager ||
		user.accessPolicy?.allowed_user_role === partnerRoles.accountManagerNonUS ||
		user.accessPolicy?.allowed_user_role === partnerRoles.accountManagerPHIAuthourized
	);
}
