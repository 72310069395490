import { ReactElement, ReactNode, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
	Bar,
	BarChart,
	Legend,
	Tooltip as RechartsTooltip,
	ResponsiveContainer,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';

import { PaletteColor, Tooltip, TooltipBody } from '@calm-web/design-system';

import { ReportingContext, timeframeValues } from '@/components/providers/ReportingContextProvider';
import { isSessionType, ReportSession } from '@/hooks/api/reporting/useSessionsReport';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import useMeasureOnResize from '@/hooks/layout/useMeasureOnResize';

import GraphLegend from '../Legend';
import { sessionTypeLabels } from '../messages';
import RoundedTopCornersPath from '../RoundedTopCornersPath';
import { TooltipTable } from '../styles';
import WidgetCard from '../WidgetCard';
import messages from './messages';
import {
	BarChartContainer,
	ContentTypeBadge,
	ExplainerInfo,
	InfoIconContainer,
	TableExplainer,
	TableTooltipTitle,
} from './styles';

const defaultZero = (value?: number): number => value ?? 0;

export default function HourlyUsage({
	isLoading,
	data,
	isPrivacyError,
	isNoDataError,
}: {
	isLoading?: boolean;
	data: ReportSession[] | undefined;
	isPrivacyError?: boolean;
	isNoDataError?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { measuredWidth: chartWidth, ref: barChartContainerRef } = useMeasureOnResize<HTMLDivElement>(1166);
	const { selectedLongerTimeframe } = useContext(ReportingContext);
	const [isMobile] = useIsMobile();

	const [chartData, maxCount] = useMemo(() => {
		let _maxCount = 0;
		const dateForFormatting = new Date();
		const formattedData = data?.map((datum, index) => {
			const sum =
				defaultZero(datum.body) +
				defaultZero(datum.breathe) +
				defaultZero(datum.masterclass) +
				defaultZero(datum.meditation) +
				defaultZero(datum.music) +
				defaultZero(datum.sleep);
			if (sum > _maxCount) {
				_maxCount = sum;
			}
			dateForFormatting.setHours(index);
			return {
				name: index,
				time: dateForFormatting.toLocaleTimeString(undefined, { hour: 'numeric' }),
				...datum,
			};
		});
		return [formattedData, _maxCount];
	}, [data]);
	const orderOfMagnitude = Math.ceil(Math.log10(maxCount));

	const renderTooltip = (props: TooltipProps<number, string | number>): ReactNode => {
		if (!props.active) {
			return null;
		}
		const hourNumber = props.payload?.[0].payload.name;
		if (typeof hourNumber !== 'number') {
			return null;
		}
		const startTime = new Date();
		startTime.setHours(hourNumber);
		const endTime = new Date();
		endTime.setHours(hourNumber + 1);
		return (
			<TooltipBody maxWidth={180}>
				<TableTooltipTitle>
					{formatMessage(messages.tooltipTitle, {
						starttime: startTime.toLocaleTimeString(undefined, { hour: 'numeric' }).toLocaleLowerCase(),
						endtime: endTime.toLocaleTimeString(undefined, { hour: 'numeric' }).toLocaleLowerCase(),
					})}
				</TableTooltipTitle>
				<TooltipTable>
					<tbody>
						{props.payload?.map(payload => {
							const type = payload.name;
							if (typeof type !== 'string' || !isSessionType(type)) {
								return null;
							}
							const key = `${payload.dataKey}${type}${payload.value}`;
							return (
								<tr key={key}>
									<td>
										<ContentTypeBadge type={type} />
										{formatMessage(sessionTypeLabels[type])}
									</td>
									<td>{payload.value?.toLocaleString() ?? '0'}</td>
								</tr>
							);
						})}
					</tbody>
				</TooltipTable>
			</TooltipBody>
		);
	};

	const legendInfo = [
		{ key: 'sleep', paletteColor: 'gradientSleepDark' as PaletteColor },
		{ key: 'music', paletteColor: 'gradientCalmBrand' as PaletteColor },
		{ key: 'meditation', paletteColor: 'gradientBrightRed' as PaletteColor },
		{ key: 'masterclass', paletteColor: 'gradientBrightOrange' as PaletteColor },
		{ key: 'breathe', paletteColor: 'gradientCalmPurple' as PaletteColor },
		{ key: 'body', paletteColor: 'gradientRainbow9' as PaletteColor },
	];

	const wrapper = (
		<>
			<BarChartContainer ref={barChartContainerRef}>
				<ResponsiveContainer width="100%" height={isMobile ? chartWidth / 2 : chartWidth / 4}>
					<BarChart data={chartData}>
						<defs>
							{/*
				These gradients match the gradients used for ContentTypeBadge in ./styles.tsx,
				but need to be re-implemented here as SVG gradients.
				Note that `rotate(alpha, 0.5, 0.5)` matches the angle in the CSS, but -90°
			*/}
							<linearGradient id="sleep" gradientTransform="rotate(115.11, 0.5, 0.5)">
								<stop offset="25.19%" stopColor="#3B0E7D" />
								<stop offset="91.62%" stopColor="#5F69BA" />
							</linearGradient>
							<linearGradient id="music" gradientTransform="rotate(90, 0.5, 0.5)">
								<stop offset="0%" stopColor="#60B4E7" />
								<stop offset="100%" stopColor="#6461E0" />
							</linearGradient>
							<linearGradient id="meditation" gradientTransform="rotate(45, 0.5, 0.5)">
								<stop offset="0.06%" stopColor="#FF3B4C" />
								<stop offset="100%" stopColor="#FF7969" />
							</linearGradient>
							<linearGradient id="masterclass" gradientTransform="rotate(45, 0.5, 0.5)">
								<stop offset="0%" stopColor="#FFC52B" />
								<stop offset="100%" stopColor="#FF8B3A" />
							</linearGradient>
							<linearGradient id="breathe" gradientTransform="rotate(90, 0.5, 0.5)">
								<stop offset="0%" stopColor="#9F83FF" />
								<stop offset="100%" stopColor="#6E58FF" />
							</linearGradient>
							<linearGradient id="body" gradientTransform="rotate(45, 0.5, 0.5)">
								<stop offset="0%" stopColor="#F0758A" />
								<stop offset="100%" stopColor="#F0A875" />
							</linearGradient>
						</defs>
						<XAxis axisLine={false} tickLine={false} dataKey="time" minTickGap={16} />
						<YAxis
							axisLine={false}
							tickLine={false}
							tickFormatter={(v: string): string => parseInt(v, 10).toLocaleString()}
							width={16 + orderOfMagnitude * 8}
						/>
						<RechartsTooltip isAnimationActive={false} cursor={false} content={renderTooltip} />
						<Legend
							wrapperStyle={{ top: 0, left: 0 }}
							verticalAlign="top"
							align="left"
							content={<GraphLegend legendInfo={legendInfo} style={{ margin: '24px 0 24px 0' }} />}
						/>
						{legendInfo.map((bar, index) => {
							return (
								<Bar
									key={`${bar.key} bar`}
									dataKey={bar.key}
									stackId="a"
									fill={`url(#${bar.key})`}
									shape={index === legendInfo.length - 1 ? RoundedTopCornersPath : undefined}
								/>
							);
						})}
					</BarChart>
				</ResponsiveContainer>
			</BarChartContainer>
			<TableExplainer>
				{formatMessage(messages.tableSubtitleDynamic, {
					timeframe:
						selectedLongerTimeframe?.value === timeframeValues.CUSTOM
							? `${selectedLongerTimeframe?.value} selected`
							: `last ${selectedLongerTimeframe?.value}`,
				})}
				<Tooltip
					placement="bottom"
					content={formatMessage(messages.tableInfoTooltip)}
					hideOnClick={false}
					maxWidth={210}
				>
					<InfoIconContainer data-html2canvas-ignore="true">
						<ExplainerInfo />
					</InfoIconContainer>
				</Tooltip>
			</TableExplainer>
		</>
	);

	return (
		<WidgetCard
			isLoading={isLoading}
			heading="Usage"
			subHeading="Here’s how your members are using Calm throughout the day"
			content={wrapper}
			removeDataContainer
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError}
		/>
	);
}
