/* eslint-disable max-len */
// Third Party

import { defineMessages } from 'react-intl';

export default defineMessages({
	hello: {
		id: 'emailPreferences.surveyEmail.hello',
		defaultMessage: 'Hi there,',
		description: 'Link text for showing email sample',
	},
	intro: {
		id: 'emailPreferences.surveyEmail.intro',
		defaultMessage: 'Thank you for being part of our Calm community. We hope you are enjoying the app!  ',
		description: 'First line of text in the survey email',
	},
	description: {
		id: 'emailPreferences.surveyEmail.description',
		defaultMessage:
			'Has Calm had a positive impact on you? Sharing your experience helps us reinforce the importance of mental fitness resources in the workplace—help us highlight this feedback to your benefits administrator.',
		description: 'A prompt asking the user to give positive feedback about their app usage',
	},
	sendFeedback: {
		id: 'emailPreferences.surveyEmail.sendFeedback',
		defaultMessage: 'Send Feedback',
		description: 'CTA button text in the survey email asking users for feedback',
	},
	anonymous: {
		id: 'emailPreferences.surveyEmail.anonymous',
		defaultMessage: 'All feedback provided will remain completely anonymous.',
		description: 'Text in survey email explaning that feedback will be anonymous',
	},
	thankYou: {
		id: 'emailPreferences.surveyEmail.thankYou',
		defaultMessage: 'Warmly,',
		description: 'Thank you message in survey email',
	},
	signature: {
		id: 'emailPreferences.surveyEmail.signature',
		defaultMessage: 'The Calm Team',
		description: 'Signature line in the survey email',
	},
});
