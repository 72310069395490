import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { Button, Modal } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { ActiveStep, CompletedSteps, PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import Confetti from '@/components/ui/Confetti';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import {
	useDeletePartnerPathway,
	useEditPartnerPathway,
	useSaveSelectedPathway,
} from '@/hooks/api/usePathways';
import { setBannerMessage } from '@/store/actions';
import redirect from '@/utils/redirect';

import Audience from './Audience';
import messages from './messages';
import Review from './Review';
import Schedule from './Schedule';
import SelectProgram from './SelectProgram';
import StepsNav from './StepsNav';
import {
	ActionButton,
	BackButton,
	ButtonContainer,
	CancelButton,
	CreateContainer,
	ModalCancelButton,
	SuccessModalDescription,
	SuccessModalHeader,
	SuccessModalSchedule,
	SuccessModalSection,
	Wrapper,
} from './styles';

const getStep = (
	completedSteps: CompletedSteps,
	activeStep: ActiveStep,
	canSeeCohorts?: boolean,
	getNextStep = false,
): ActiveStep | undefined => {
	const stepOrder = canSeeCohorts
		? Object.keys(completedSteps)
		: Object.keys(completedSteps).filter(step => step !== 'audience');
	const currentIndex = stepOrder.findIndex(step => step === activeStep);
	const newIndex = getNextStep ? currentIndex + 1 : currentIndex - 1;
	if (stepOrder[newIndex]) {
		return stepOrder[newIndex] as ActiveStep;
	}
	return undefined;
};

const CreateAndEditPathway: React.FC = () => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathwayId } = useParams();
	const { pathname, hash } = useLocation();
	const {
		partnerId,
		activeStep,
		clearStoredPathway,
		completedSteps,
		setActiveStep,
		setCompletedSteps,
		selectedPathway,
		arePathwaysLoading,
		startDate,
		endDate,
		emailSettings,
		announcementDate,
		reminderDate,
		segmentDetails,
		segment1Cohorts,
		segment2Cohorts,
		segment3Cohorts,
		hasNoCohortsSelected,
		cohortCount,
		setEditValues,
		nextTabRouting,
		isReadOnly,
		serverTimeZone,
		shouldUsePathwaysV2,
	} = useContext(PathwaysContext);

	const { segment_names, segment_values } = segmentDetails || {};
	const { segment_1_display_name, segment_2_display_name, segment_3_display_name } = segment_names || {};
	const { segment_1_values, segment_2_values, segment_3_values } = segment_values || {};
	const hasSegments =
		(segment_1_values && segment_1_values.length > 0) ||
		(segment_2_values && segment_2_values.length > 0) ||
		(segment_3_values && segment_3_values.length > 0);

	const canSeeCohorts = hasSegments;

	const [savePathway, { loading: saveLoading }] = useSaveSelectedPathway(
		partnerId,
		selectedPathway?.pack_class,
		emailSettings,
		announcementDate,
		reminderDate,
		startDate,
		canSeeCohorts
			? {
					segment_1_name: segment_1_display_name !== undefined ? segment_1_display_name : null,
					segment_2_name: segment_2_display_name !== undefined ? segment_2_display_name : null,
					segment_3_name: segment_3_display_name !== undefined ? segment_3_display_name : null,
					segment_1_values: segment1Cohorts !== undefined ? segment1Cohorts : null,
					segment_2_values: segment2Cohorts !== undefined ? segment2Cohorts : null,
					segment_3_values: segment3Cohorts !== undefined ? segment3Cohorts : null,
			  }
			: undefined,
		shouldUsePathwaysV2,
	);
	const [editPathway, { loading: editLoading }] = useEditPartnerPathway(
		partnerId,
		pathwayId,
		selectedPathway?.pack_class,
		startDate,
		announcementDate,
		reminderDate,
		canSeeCohorts
			? {
					segment_1_name: segment_1_display_name !== undefined ? segment_1_display_name : null,
					segment_2_name: segment_2_display_name !== undefined ? segment_2_display_name : null,
					segment_3_name: segment_3_display_name !== undefined ? segment_3_display_name : null,
					segment_1_values: segment1Cohorts !== undefined ? segment1Cohorts : null,
					segment_2_values: segment2Cohorts !== undefined ? segment2Cohorts : null,
					segment_3_values: segment3Cohorts !== undefined ? segment3Cohorts : null,
			  }
			: undefined,
	);
	const [deletePathway, { loading: deleteLoading }] = useDeletePartnerPathway(partnerId, pathwayId);

	const isEdit = pathname.includes('edit');
	const confettiRef = useRef<HTMLDivElement>(null);
	const [confettiIsShown, showConfetti] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showAbandonedModal, setShowAbandonedModal] = useState<boolean | undefined>();
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean | undefined>();
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean | undefined>();
	const [showSuccessModal, setShowSuccessModal] = useState<boolean | undefined>();
	const nextStep = getStep(completedSteps, activeStep || 'selection', canSeeCohorts, true);
	const prevStep = getStep(completedSteps, activeStep || 'selection', canSeeCohorts);

	useEffect(() => {
		if (pathwayId) setEditValues(pathwayId, isEdit);
	}, [pathwayId, setEditValues, isEdit]);

	async function onSubmitPathway(): Promise<void> {
		const saveHook = isEdit ? editPathway : savePathway;
		if (selectedPathway) {
			setIsLoading(true);
			try {
				await saveHook();
				logEvent('Partner Portal : Pathways : Submit : Success', {
					pathway_id: pathwayId,
					is_edit: isEdit,
					selected_pathway: selectedPathway?.pack_class,
				});
				clearStoredPathway();
				setShowConfirmationModal(false);
				if (isEdit) {
					dispatch(
						setBannerMessage({
							message: 'Pathway saved successfully',
							isError: false,
							flash: true,
						}),
					);
					redirect(`/${partnerId}/pathways`);
				} else {
					setShowSuccessModal(true);
					showConfetti(true);
				}
			} catch (err) {
				setShowConfirmationModal(false);
				setIsLoading(false);
			}
		}
	}

	async function onDeletePathway(): Promise<void> {
		if (selectedPathway) {
			setIsLoading(true);
			try {
				await deletePathway();
				logEvent('Partner Portal : Pathways : Delete : Success', {
					pathway_id: pathwayId,
					selected_pathway: selectedPathway?.pack_class,
				});
				clearStoredPathway();
				redirect(`/${partnerId}/pathways`);
			} catch (err) {
				setShowDeleteConfirmationModal(false);
				setIsLoading(false);
			}
		}
	}

	useEffect(() => {
		if (showSuccessModal) {
			const fadeOutElement = (element: HTMLDivElement, opacity: number): void => {
				if (opacity > 0) {
					const newOpacity = opacity - 0.05;
					// eslint-disable-next-line no-param-reassign
					element.style.opacity = newOpacity.toString();
					setTimeout(() => {
						fadeOutElement(element, newOpacity);
					}, 25);
				}
			};

			const hideConfetti = (elementToHide: HTMLDivElement | null): void => {
				if (!elementToHide) return;
				fadeOutElement(elementToHide, 1);
				setTimeout(() => {
					// eslint-disable-next-line no-param-reassign
					elementToHide.style.display = 'none';
					showConfetti(false);
				}, 500);
			};

			setTimeout(() => {
				hideConfetti(confettiRef?.current);
			}, 1000);
		}
	}, [showSuccessModal]);

	const closeModals = (): void => {
		setShowAbandonedModal(false);
		setShowConfirmationModal(false);
		setShowDeleteConfirmationModal(false);
		setShowSuccessModal(false);
	};

	useEffect(() => {
		if (isEdit) setActiveStep('review');
	}, [isEdit, setActiveStep]);

	useEffect(() => {
		if (hash) {
			const route: ActiveStep = hash.match(/[a-z]+$/g)[0];
			const prevStepFromRoute = getStep(completedSteps, route || 'selection', canSeeCohorts);
			const hasCompletedRoute = completedSteps[route];
			const hasCompletedPrevRoute = prevStepFromRoute && completedSteps[prevStepFromRoute];
			const canAccess = hasCompletedRoute || hasCompletedPrevRoute || isEdit;
			const newRoute = canAccess ? route : 'selection';
			setActiveStep(newRoute);
			history.push({ hash: `#${newRoute}` });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash, setActiveStep]);

	const handleNextClicked = useCallback((): void => {
		if (!activeStep) return;
		// Only log the step completion the first time they complete it
		if (!completedSteps[activeStep]) {
			logEvent('Partner Portal : Pathways : Completed Step', {
				step: activeStep,
			});
		}
		setCompletedSteps(completed => ({ ...completed, [activeStep]: true }));
		redirect(`/${partnerId}/pathways/${nextTabRouting}#${nextStep}`);
	}, [activeStep, completedSteps, logEvent, nextStep, partnerId, setCompletedSteps, nextTabRouting]);

	return (
		<Wrapper>
			<InitialPageAnalytics />
			<StepsNav
				isReadOnly={isReadOnly || undefined}
				activeStep={activeStep}
				completedSteps={completedSteps}
				selectedPathway={selectedPathway}
				startDate={startDate}
				endDate={endDate}
				canSeeCohorts={canSeeCohorts}
			/>
			<CreateContainer>
				{activeStep === 'selection' && <SelectProgram arePathwaysLoading={arePathwaysLoading} />}
				{activeStep === 'audience' && canSeeCohorts && <Audience canSeeCohorts={canSeeCohorts} />}
				{activeStep === 'schedule' && <Schedule />}
				{activeStep === 'review' && <Review canSeeCohorts={canSeeCohorts} />}
			</CreateContainer>
			<ButtonContainer>
				{/* Cancel button */}
				{!isReadOnly && activeStep === 'review' && (
					<CancelButton
						onPress={() => (isEdit ? redirect(`/${partnerId}/pathways`) : setShowAbandonedModal(true))}
					>
						{formatMessage(messages.cancelButton)}
					</CancelButton>
				)}

				{/* Back button */}
				{activeStep !== 'selection' && (activeStep !== 'review' || isReadOnly) && (
					<BackButton
						onPress={() =>
							isReadOnly
								? activeStep === 'review'
									? redirect(`/${partnerId}/pathways`)
									: redirect(`/${partnerId}/pathways/edit/${pathwayId}#review`)
								: redirect(`/${partnerId}/pathways/create#${prevStep}`)
						}
					>
						{formatMessage(messages.backButton)}
					</BackButton>
				)}

				{/* Next button */}
				{activeStep !== 'review' && !isReadOnly && (
					<ActionButton
						data-testid="next-step-button"
						isDisabled={activeStep === 'audience' ? hasNoCohortsSelected : !selectedPathway}
						onPress={handleNextClicked}
					>
						{formatMessage(messages.nextButton, {
							nextpathway: nextStep ? nextStep.charAt(0).toUpperCase() + nextStep.slice(1) : '',
						})}
					</ActionButton>
				)}

				{/* Delete button */}
				{!isReadOnly && isEdit && activeStep === 'review' && (
					<ActionButton
						data-testid="page-delete-pathway"
						backgroundColor="heartRed"
						onPress={() => {
							logEvent('Partner Portal : Pathways : Delete: Clicked', {
								selected_pathway: selectedPathway?.pack_class || '',
							});
							setShowDeleteConfirmationModal(true);
						}}
						isLoading={deleteLoading}
					>
						{formatMessage(messages.deleteButton)}
					</ActionButton>
				)}

				{/* Submit button */}
				{!isReadOnly && activeStep === 'review' && (
					<ActionButton
						data-testid="page-submit-pathway"
						isDisabled={!selectedPathway}
						onPress={() => {
							logEvent('Partner Portal : Pathways : Submit', {
								selected_pathway: selectedPathway?.pack_class || '',
							});
							setShowConfirmationModal(true);
						}}
						isLoading={editLoading || saveLoading || isLoading}
					>
						{isEdit ? formatMessage(messages.savePathway) : formatMessage(messages.launchPathway)}
					</ActionButton>
				)}
			</ButtonContainer>
			<Modal
				isOpen={showConfirmationModal}
				closeModal={() => closeModals()}
				title={
					isEdit
						? formatMessage(messages.confirmationModalEditTitle)
						: formatMessage(messages.confirmationModalCreateTitle)
				}
				footer={
					<>
						<ModalCancelButton onPress={() => closeModals()}>
							{formatMessage(messages.confirmationModalBackButton)}
						</ModalCancelButton>
						<Button
							data-testid="modal-submit-pathway"
							backgroundColor="blue3"
							onPress={onSubmitPathway}
							type="button"
						>
							{isEdit ? formatMessage(messages.savePathway) : formatMessage(messages.launchPathway)}
						</Button>
					</>
				}
			>
				{!isEdit && (
					<SuccessModalDescription>
						{shouldUsePathwaysV2 && emailSettings === 'off'
							? formatMessage(messages.confirmationModalPathwayWarningEmailsOff)
							: formatMessage(messages.confirmationModalPathwayWarning)}
					</SuccessModalDescription>
				)}
				<SuccessModalSection>
					<SuccessModalHeader>{formatMessage(messages.confirmationModalPathwayHeader)}</SuccessModalHeader>
					{selectedPathway?.title}
				</SuccessModalSection>
				{canSeeCohorts && (
					<SuccessModalSection>
						<SuccessModalHeader>{formatMessage(messages.confirmationModalAudienceHeader)}</SuccessModalHeader>
						{formatMessage(messages.confirmationModalAudience, { userCount: cohortCount })}
					</SuccessModalSection>
				)}
				<SuccessModalSchedule>
					<SuccessModalHeader>{formatMessage(messages.confirmationModalScheduleHeader)}</SuccessModalHeader>
					{formatMessage(messages.confirmationModalScheduleDuration, {
						durationDates: `${startDate?.toLocaleDateString(undefined, {
							year: '2-digit',
							month: 'numeric',
							day: 'numeric',
							timeZone: isEdit ? serverTimeZone : undefined,
						})} - ${endDate?.toLocaleDateString(undefined, {
							year: '2-digit',
							month: 'numeric',
							day: 'numeric',
						})}`,
					})}
					<br />
					{shouldUsePathwaysV2 && (
						<>
							{formatMessage(messages.confirmationModalEmailSettings, {
								emailSettings:
									emailSettings === 'off'
										? formatMessage(messages.confirmationModalEmailSettingsOff)
										: formatMessage(messages.confirmationModalEmailSettingsOn),
							})}
							<br />
						</>
					)}
					{formatMessage(messages.confirmationModalScheduleAnnouncement, {
						announcementDate:
							shouldUsePathwaysV2 && emailSettings === 'off'
								? 'None'
								: announcementDate?.toLocaleDateString(undefined, {
										year: '2-digit',
										month: 'numeric',
										day: 'numeric',
										hour: 'numeric',
										minute: '2-digit',
								  }),
					})}
					<br />
					{formatMessage(messages.confirmationModalScheduleReminder, {
						reminderDate:
							shouldUsePathwaysV2 && emailSettings === 'off'
								? 'None'
								: reminderDate?.toLocaleDateString(undefined, {
										year: '2-digit',
										month: 'numeric',
										day: 'numeric',
										hour: 'numeric',
										minute: '2-digit',
								  }),
					})}
				</SuccessModalSchedule>
			</Modal>
			<Modal
				isOpen={showAbandonedModal}
				closeModal={() => closeModals()}
				title={formatMessage(messages.abandonedModalTitle)}
				footer={
					<>
						<ModalCancelButton
							onPress={() => {
								clearStoredPathway();
								logEvent('Partner Portal : Pathways : Create : Cancelled');
								redirect(`/${partnerId}/pathways`);
								closeModals();
							}}
						>
							{formatMessage(messages.abandonedModalLeaveButton)}
						</ModalCancelButton>
						<Button backgroundColor="blue3" onPress={() => closeModals()} type="button">
							{formatMessage(messages.abandonedModalStayButton)}
						</Button>
					</>
				}
			>
				{formatMessage(messages.abandonedModalDescription)}
			</Modal>
			<Modal
				isOpen={showSuccessModal}
				closeModal={() => {
					closeModals();
					redirect(`/${partnerId}/pathways`);
				}}
				title={formatMessage(messages.successModalTitle)}
				footer={
					<Button backgroundColor="blue3" onPress={() => redirect(`/${partnerId}/pathways`)} type="button">
						{formatMessage(messages.successModalButton)}
					</Button>
				}
				additionalOverlay={confettiIsShown ? <Confetti bgSize="90" ref={confettiRef} /> : undefined}
			>
				{formatMessage(messages.successModalDescription)}
			</Modal>
			<Modal
				isOpen={showDeleteConfirmationModal}
				closeModal={() => closeModals()}
				title={formatMessage(messages.deleteConfirmationModalTitle)}
				footer={
					<>
						<ModalCancelButton onPress={() => closeModals()}>
							{formatMessage(messages.deleteConfirmationModalCancelButton)}
						</ModalCancelButton>
						<Button
							data-testid="modal-delete-pathway"
							backgroundColor="heartRed"
							onPress={onDeletePathway}
							type="button"
						>
							{formatMessage(messages.deleteConfirmationModalButton)}
						</Button>
					</>
				}
			>
				{formatMessage(messages.deleteConfirmationModalDescription1)}
				<br />
				<br />
				{formatMessage(messages.deleteConfirmationModalDescription2)}
			</Modal>
		</Wrapper>
	);
};

export default CreateAndEditPathway;
