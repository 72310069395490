import { defineMessages } from 'react-intl';

export default defineMessages({
	salesTrialAdminCopy: {
		id: 'nav.salesTrial.admin.copy',
		defaultMessage: 'Opportunity: Upgrade the Partner from Sales Trial to Premium, or delete the Partner.',
		description: 'Informing AMs that the partner is a Sales Trial Partner',
	},
	expiredSalesTrialPartner: {
		id: 'nav.salesTrial.partner.expired',
		defaultMessage:
			"We're sorry but your trial has expired. If you would like to learn more about Calm or regain access, please reach out to {calmContact} at <mailto>{email}</mailto>.",
		description: 'Informing Expires Sales Trial Partners that their trial has expired, but they can upgrade',
	},
	salesTrialPartner: {
		id: 'nav.salesTrial.partner',
		defaultMessage:
			'We hope you enjoy your trial of Calm! For any questions, please reach out to {calmContact} at <mailto>{email}</mailto>.',
		description: 'Informing Sales Trial partners that they can upgrade',
	},
});
