import DOMPurify from 'isomorphic-dompurify';
import omit from 'lodash/omit';
import Image from 'next/legacy/image';
import { ReactElement, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { fileFromModelValue, FormProps, OnChange, stringFromModelValue } from '@calm-web/use-form';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions';
import { srcLoader } from '@/utils/ui/imageLoader';
import { createUrl } from '@/utils/url';

import messages from './messages';
import {
	DefaultImg,
	DeleteButton,
	FlexContainer,
	FormContainer,
	ImageContainer,
	LogoText,
	UploadButton,
	UploadText,
} from './styles';

function UnifiedLogoUploader<FieldNames extends string>({
	formProps,
	disabled = false,
	fileFieldName,
	urlFieldName,
	analyticsEvent,
	onDelete,
	hideDelete,
	allowDuplicateSelect = false,
	additionalRequirements,
	maxImageSizeMB = 2,
	supportPNG = true,
	isDarkMode = false,
}: {
	formProps: FormProps<FieldNames>;
	disabled?: boolean;
	fileFieldName: FieldNames;
	urlFieldName: FieldNames;
	analyticsEvent: string;
	onDelete?: () => void;
	hideDelete?: boolean;
	allowDuplicateSelect?: boolean;
	additionalRequirements?: string[];
	maxImageSizeMB?: number;
	supportPNG?: boolean;
	isDarkMode?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const inputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	const imgIsLargerThan = (file: File, megaBytes: number): boolean => {
		const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
		return parseFloat(filesize) > megaBytes;
	};
	const fileInputProps = formProps.bindWithErrorProps(fileFieldName, 'file');
	const imageChange: OnChange = async e => {
		const target = e.target;
		if (target.files && target.files.length > 0) {
			const file = target.files[0];

			if (imgIsLargerThan(file, maxImageSizeMB)) {
				dispatch(
					setBannerMessage({
						message: `Image is too large. Please upload a file under ${maxImageSizeMB}MB.`,
						flash: true,
						isError: true,
					}),
				);
			} else {
				let sanitizedFile = file;
				if (file.type === 'image/svg+xml') {
					sanitizedFile = new File([DOMPurify.sanitize(await file.text())], file.name, {
						type: file.type,
					});
				}
				formProps.setProperty(fileFieldName, [sanitizedFile]);
				logEvent(analyticsEvent);
				if (allowDuplicateSelect) {
					target.value = '';
				}
			}
		} else {
			formProps.setProperty(urlFieldName, '');
			formProps.setProperty(fileFieldName, []);
		}
	};

	const uploadButtonClick = (): void => {
		if (inputRef && inputRef.current) {
			inputRef.current.click();
		}
	};

	const deleteButtonClick = (): void => {
		formProps.setProperty(urlFieldName, '');
		formProps.setProperty(fileFieldName, []);
		if (inputRef.current) {
			inputRef.current.value = '';
		}
		if (onDelete) {
			onDelete();
		}
	};

	const imageAcceptFormat = supportPNG ? 'image/png, image/svg+xml' : 'image/svg+xml';
	const logoImgFile = fileFromModelValue(formProps.model[fileFieldName]);
	const logoImg = createUrl(logoImgFile);
	const backupImg = stringFromModelValue(formProps.model[urlFieldName]);
	const imgSrc = logoImg || backupImg;

	return (
		<>
			<FlexContainer>
				{imgSrc ? (
					<ImageContainer
						$darkMode={isDarkMode}
						$disabled={disabled}
						onClick={uploadButtonClick}
						role="button"
					>
						<Image src={imgSrc} alt="Your Logo" layout="fill" objectFit="contain" loader={srcLoader} />
					</ImageContainer>
				) : (
					<DefaultImg $disabled={disabled} $background="gray1" onClick={uploadButtonClick} role="button">
						<LogoText color="gray6">{formatMessage(messages.partnerBrandingLogo)}</LogoText>
					</DefaultImg>
				)}
				{!disabled && (
					<FormContainer>
						<input
							{...omit(fileInputProps, ['isValid', 'showValidation'])}
							type="file"
							onChange={imageChange}
							ref={inputRef}
							style={{ display: 'none' }}
							accept={imageAcceptFormat}
							data-testid={`file-input-${fileInputProps.name}`}
						/>
						<>
							<UploadButton onClick={uploadButtonClick}>{formatMessage(messages.uploadButton)}</UploadButton>
							{hideDelete ? null : (
								<DeleteButton onClick={deleteButtonClick}>
									{formatMessage(messages.deleteButton)}
								</DeleteButton>
							)}
						</>
						<UploadText>
							{supportPNG
								? formatMessage(messages.imageRequirements, { maxImageSizeMB })
								: formatMessage(messages.imageRequirementsNoPNG, { maxImageSizeMB })}
						</UploadText>
						{additionalRequirements
							? additionalRequirements?.map((requirement, index) => (
									<UploadText key={index}>{requirement}</UploadText>
							  ))
							: null}
					</FormContainer>
				)}
			</FlexContainer>
		</>
	);
}

export default UnifiedLogoUploader;
