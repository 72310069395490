import { forwardRef, ReactElement, Ref, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { aggregationLevelToTimeUnit } from '@/hooks/api/reporting/utils';
import friendlyPercentage from '@/utils/friendlyPercentage';

import WidgetCard from '../WidgetCard';
import MoodGraph from './Graph';
import messages from './messages';

const MIN_MOOD_LIMIT = 30;

interface Props {
	isPrivacyError: boolean;
	isNoDataError: boolean;
}

function MoodCheckin({ isPrivacyError, isNoDataError }: Props, ref?: Ref<HTMLDivElement>): ReactElement {
	const { formatMessage } = useIntl();
	const { moodReport, signupsAndEngagementReport } = useContext(ReportingContext);
	const [startToEndLabel, setStartToEndLabel] = useState<string | undefined>();
	const { mood, aggregation_level } = moodReport?.data || {};
	const { activity_report } = signupsAndEngagementReport?.data || {};
	const { signups } = activity_report || {};
	const { mood_percent_change, mood_percent_positive, total_moods_collected } = mood || {};
	const hasNoMoodData = (!isPrivacyError && (total_moods_collected ?? 0) <= MIN_MOOD_LIMIT) || isNoDataError;
	const isLoading = moodReport?.loading || signupsAndEngagementReport?.loading; // TODO: remove dependency from S&E

	useEffect(() => {
		if (signups && aggregation_level) {
			setStartToEndLabel(`${signups.length} ${aggregationLevelToTimeUnit[aggregation_level]} change`);
		}
	}, [signups, aggregation_level]);

	return (
		<WidgetCard
			ref={ref}
			isLoading={isLoading}
			heading={formatMessage(messages.moodHeading)}
			subHeading="Gain insights into how your members truly feel with mood check-ins."
			primaryPercentage={mood_percent_positive ? `${mood_percent_positive.toFixed(1)}` : undefined}
			{...(typeof mood_percent_change !== 'undefined'
				? {
						secondaryText: startToEndLabel,
						secondaryPercentage: friendlyPercentage(mood_percent_change / 100, true),
				  }
				: {})}
			primaryText={<div>Positive sentiment of {total_moods_collected} moods collected</div>}
			content={<MoodGraph data={mood} />}
			isPrivacyError={isPrivacyError}
			isNoDataError={hasNoMoodData}
			placeholderTitle={hasNoMoodData ? formatMessage(messages.placeholderTitle) : undefined}
			placeholderSubtitle={hasNoMoodData ? formatMessage(messages.placeholderSubtitle) : undefined}
		/>
	);
}

export default forwardRef(MoodCheckin);
