import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { maxWidth, units } from '@calm-web/design-system';

import CircleMinus from 'icons/circle-minus.svg';

export const Wrapper = styled.div`
	display: flex;
	gap: ${units(2)};

	${maxWidth('desktopLarge')} {
		flex-wrap: wrap;
	}
`;

export const LanguageContainer = styled.div<{ isDisabled?: boolean }>`
	width: 94%;

	& div {
		margin: 0;
	}

	${ifProp(
		'isDisabled',
		css`
			& div {
				background-color: ${palette('gray2')};
			}
		`,
	)}
`;

export const LanguageRow = styled.div`
	display: flex;
	align-content: center;
	margin-bottom: ${units(2)};
`;

export const IconContainer = styled.div<{ isDisabled?: boolean }>`
	${ifProp(
		'isDisabled',
		css`
			visibility: hidden;
		`,
	)}
`;

export const CircleMinusStyled = styled(CircleMinus)`
	height: ${units(3)};
	width: ${units(3)};
	padding: 0;
	path {
		fill: ${palette('heartRed')} !important;
	}
`;
