import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

import { CalloutBase } from '@/components/ui/Callout/styles';

export const RadioButtonsContainer = styled.div`
	display: flex;
	height: ${units(7)};
	margin-bottom: ${units(1)};
`;

export const RadioButtonWrapper = styled.div`
	border: 1px solid ${palette('gray3')};
	box-sizing: border-box;
	border-radius: 8px;
	padding: ${units(2)};
	display: flex;
	margin-right: ${units(2)};
`;

export const Subtitle = styled.h6`
	margin-top: ${units(-0.5)};
	color: ${palette('gray5')};
	${fontSize(FontSizes.sm)};
	a {
		color: ${palette('buttonBlue')};
		text-decoration: underline;
	}
	margin-bottom: ${units(1)};
`;

export const NextButton = styled(Button)`
	margin-top: ${units(2)};
	width: 120px;
	background-color: ${palette('blue3')};
	color: ${palette('white')};
`;

export const TextButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`;

export const AlignLeft = styled.div`
	display: flex;
	justify-content: start;
`;

export const CoveredLives = styled(CalloutBase)`
	align-items: center;
	padding: ${units(8, 5)};
	margin-bottom: ${units(3)};
	text-align: center;
`;

export const CoveredLivesTitle = styled.div`
	${fontWeight(FontWeights.Demi)};
	margin-bottom: ${units(1)};
`;

export const CoveredLivesBody = styled.p`
	margin-bottom: ${units(1)};
`;
