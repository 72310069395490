import { FC, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { Banner, FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { B2BSelfServeNewContentDismissCookie } from '@/hooks/api/useShouldShowSelfServeNewContentBanner';
import CalmCookie from '@/utils/CalmCookie';

import messages from './messages';
import { StyledLink } from './styles';

const SelfServeNewContentBanner: FC = () => {
	const [hideBanner, setHideBanner] = useState(false);
	const { formatMessage } = useIntl();

	const handleBannerDismiss = async (): Promise<void> => {
		CalmCookie.set(B2BSelfServeNewContentDismissCookie, 'true');
		setHideBanner(true);
	};

	if (hideBanner) {
		return null;
	}

	return (
		<Banner
			Icon={null}
			backgroundColor="blue3"
			textColor="white"
			onDismiss={handleBannerDismiss}
			addMargins
			data-testid="self-serve-new-content-banner"
		>
			<StyledLink
				data-testid="self-serve-new-content-banner-link"
				target="_blank"
				href="https://info.calm.com/WR-2024-Navattic-Demo-Enterprise-Plan-Tour_ug.html"
			>
				<Text el="p" align="center" weight={FontWeights.Medium} size={FontSizes.sm} noMargin>
					{formatMessage(messages.bannerMessage, {
						bold: (...chunks: ReactNode[]) => <b>{chunks}</b>,
						underline: (...chunks: ReactNode[]) => <u>{chunks}</u>,
					})}
				</Text>
			</StyledLink>
		</Banner>
	);
};

export default SelfServeNewContentBanner;
