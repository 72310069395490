import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, units } from '@calm-web/design-system';

export const LineChartContainer = styled.div`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray6')};
	width: 100%;
	box-sizing: border-box;

	min-width: 200px;
`;

export const TooltipBadge = styled.div<{ $color?: string }>`
	width: 16px;
	height: 4px;
	background: ${props => props.$color};
	display: inline-block;
	margin-right: ${units(1)};
	position: relative;
	top: -2px;
`;
