/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	buttonText: {
		id: 'reporting.segmentation.buttonText',
		defaultMessage: 'Filter by segment',
		description: 'Text for the button that allows users to segment the reporting ui',
	},
	title: {
		id: 'reporting.segmentation.title',
		defaultMessage: '✨ New! Segmented Reporting ✨',
		description: 'Title for segmentation filter modal',
	},
	reset: {
		id: 'reporting.segmentation.reset',
		defaultMessage: 'reset filters',
		description: 'Text for segmentation modal reset filters button',
	},
	apply: {
		id: 'reporting.segmentation.apply',
		defaultMessage: 'Apply',
		description: 'Text for segmentation modal apply button',
	},
	description: {
		id: 'reporting.segmentation.description',
		defaultMessage:
			'View specific segments of your employees to see how Calm is being used in different groups in your organization.',
		description: 'Description message explaining how to view segmented reporting',
	},
	filterDirections: {
		id: 'reporting.segmentation.filterDirections',
		defaultMessage: 'Filter your report by segment',
		description: 'Directions explaining how to use filter selection',
	},
	results: {
		id: 'reporting.segmentation.results',
		defaultMessage: '{n} results',
		description: 'Number of results for segment selection',
	},
	noUsersInSegment: {
		id: 'reporting.segmentation.noUsersInSegment',
		defaultMessage: 'No data available for this segment',
		description: 'Message telling users that we have no reporting data available for segment',
	},
	privacyWarning: {
		id: 'reporting.segmentation.privacyWarning',
		defaultMessage:
			'Your selection cannot be applied for privacy reasons. Please make a selection that results in 10 or more employees',
		description: 'Message explaining to users that we cannot show them a report due to our privacy limit',
	},
	loading: {
		id: 'reporting.segmentation.loading',
		defaultMessage: 'Updating user list...',
		description: 'Message to display while we are fetching the updated results count for a segment',
	},
	tooManySegmentsWarning: {
		id: 'reporting.segmentation.tooManySegmentsWarning',
		defaultMessage:
			'Data may be inaccurate because filters only allow a max of {segmentLimit} values per segment.',
		description: 'Message warning users they have more segments than currently allowed.',
	},
	resultsCountError: {
		id: 'reporting.segmentation.resultsCountError',
		defaultMessage: "Sorry, we couldn't filter your users.",
		description:
			'Message warning users that there was an error when trying to get a count of users in a segment.',
	},
	segmentDetailsError: {
		id: 'reporting.segmentation.segmentDetailsError',
		defaultMessage: "Sorry, we couldn't load your segment list.",
		description: 'Message warning users that there was an error when trying to get their segment list.',
	},
	tryAgain: {
		id: 'reporting.segmentation.tryAgain',
		defaultMessage: 'Try again',
		description: 'Message telling users to try loading their data again',
	},
	selectAll: {
		id: 'reporting.segmentation.selectAll',
		defaultMessage: 'Select all',
		description: 'Button text for selecting all values in a segment',
	},
	selectNone: {
		id: 'reporting.segmentation.selectNone',
		defaultMessage: 'None',
		description: 'Button text for selecting none for values in a segment',
	},
	announcement: {
		id: 'reporting.segmentation.announcement',
		defaultMessage:
			'<b>New!</b>{linebreak} <text>Click here to select which segments you want to filter the page by.</text>',
		description: 'Announcement telling users about the new segmentation feature',
	},
});
