// Third Party

import { defineMessages } from 'react-intl';

export default defineMessages({
	active: {
		id: 'promotionOptions.active',
		defaultMessage: 'Active?',
		description: 'Label for checkbox whether promotion is active or not',
	},
	promotionNoTrial: {
		id: 'promotionOptions.promotionNoTrial',
		defaultMessage: 'No Trial Period',
		description: 'Description of a promotion not using a trial',
	},
	promotionTrialDurationSevenDays: {
		id: 'promotionOptions.promotionTrialDurationSevenDays',
		defaultMessage: 'Seven Days',
		description: 'Description of a trial length of seven days',
	},
	promotionTrialDurationFourteenDays: {
		id: 'promotionOptions.promotionTrialDurationFourteenDays',
		defaultMessage: 'Fourteen Days',
		description: 'Description of a trial length of fourteen days',
	},
	promotionTrialDurationOneMonth: {
		id: 'promotionOptions.promotionTrialDurationOneMonth',
		defaultMessage: 'One Month',
		description: 'Description of a trial length of one month',
	},
	promotionTrialDurationTwoMonths: {
		id: 'promotionOptions.promotionTrialDurationTwoMonths',
		defaultMessage: 'Two Months',
		description: 'Description of a trial length of two months',
	},
	promotionTrialDurationThreeMonths: {
		id: 'promotionOptions.promotionTrialDurationThreeMonths',
		defaultMessage: 'Three Months',
		description: 'Description of a trial length of three months',
	},
	promotionTrialDurationFourMonths: {
		id: 'promotionOptions.promotionTrialDurationFourMonths',
		defaultMessage: 'Four Months',
		description: 'Description of a trial length of four months',
	},
	promotionTrialDurationSixMonths: {
		id: 'promotionOptions.promotionTrialDurationSixMonths',
		defaultMessage: 'Six Months',
		description: 'Description of a trial length of six months',
	},
	promotionTrialDurationTwelveMonths: {
		id: 'promotionOptions.promotionTrialDurationTwelveMonths',
		defaultMessage: 'Twelve Months',
		description: 'Description of a trial length of twelve months',
	},
	title: {
		id: 'promotionOptions.title',
		defaultMessage: 'Configure Lead Gen Partnership Promotion',
		description: 'Description of the section focus',
	},
	percentOffTypeLabel: {
		id: 'promotionOptions.percentOffTypeLabel',
		defaultMessage: 'Discount percentage',
		description: 'Label for the percent off option',
	},
	maxRedemptionTypeLabel: {
		id: 'promotionOptions.maxRedemptionTypeLabel',
		defaultMessage: 'Max # of redemptions',
		description: 'Label for the max redemptions option',
	},
	maxRedemptionInputLabel: {
		id: 'promotionOptions.maxRedemptionInputLabel',
		defaultMessage: 'Enter Number',
		description: 'Label for the max redemptions option',
	},
	couponDurationTypeLabel: {
		id: 'promotionOptions.couponDurationTypeLabel',
		defaultMessage: 'Coupon frequency',
		description: 'Label for the percent off option',
	},
	partnerSpecificTermsLabel: {
		id: 'promotionOptions.percentOffTypeLabel',
		defaultMessage: 'Partner specific eligibility terms',
		description: 'Label for the terms to be displayed when the user redeems the offer',
	},
	extendedTrialTypeLabel: {
		id: 'promotionOptions.extendedTrialTypeLabel',
		defaultMessage: 'Trial Length',
		description: 'Label for the trial option',
	},
	percentOffInputLabel: {
		id: 'promotionOptions.percentOffInputLabel',
		defaultMessage: 'Enter number 0 - 100',
		description: 'Label for the percent off input field',
	},
	partnerTermsInputPlaceholder: {
		id: 'promotionOptions.partnerTermsInputPlaceholder',
		defaultMessage: 'Enter any terms required by the partner to claim the offer',
		description: 'Instructions for the user on what to put in the field',
	},
	offerDetails: {
		id: 'promotionOptions.offerDetails',
		defaultMessage: 'Offer Details: Trial length of {trialDuration} and {percentOff}% off {subDuration}',
		description: 'Message explaining result of partnership configuration',
	},
	offerDetailsNoTrial: {
		id: 'promotionOptions.offerDetailsNoTrial',
		defaultMessage: 'Offer Details: No trial period and {percentOff}% off {subDuration}',
		description: 'Message explaining result of partnership configuration without a trial',
	},
	couponDurationOnce: {
		id: 'promotionOptions.couponDurationOnce',
		defaultMessage: 'Once',
		description: 'Description of how many times the coupon can be used',
	},
	couponDurationRepeating: {
		id: 'promotionOptions.couponDurationRepeating',
		defaultMessage: 'Repeating',
		description: 'Description of how many times the coupon can be used',
	},
	couponDurationForever: {
		id: 'promotionOptions.couponDurationOnce',
		defaultMessage: 'Forever',
		description: 'Description of how many times the coupon can be used',
	},
	couponDurationInMonthsInputLabel: {
		id: 'promotionOptions.percentOffInputLabel',
		defaultMessage: 'Duration in months',
		description: 'Label for the percent off input field',
	},
	durationMonth: {
		id: 'promotionOptions.duration.month',
		defaultMessage: 'first {quantity, plural, one {month} other {{quantity} months}}',
		description: 'Label for month option in duration select',
	},
	durationYear: {
		id: 'promotionOptions.duration.year',
		defaultMessage: 'first {quantity, plural, one {year} other {{quantity} years}}',
		description: 'Label for year option in duration select',
	},
	durationForever: {
		id: 'promotionOptions.duration.forever',
		defaultMessage: 'every {duration}, forever',
		description: 'Label for month option in duration select',
	},
});
