import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { useIntl } from 'react-intl';

import { StyledCard } from '../WidgetCard/styles';
import messages from './messages';
import { Header, PlaceholderContainer, PlaceholderMessage, PlaceholderTitle, ResourceLink } from './styles';

function ReportingPlaceholder(
	{
		title,
		graphType,
		placeholderTitle,
		placeholderSubtitle,
	}: {
		title?: string;
		graphType: 'getStarted' | 'privacyError';
		placeholderTitle?: string;
		placeholderSubtitle?: string;
	},
	ref: Ref<HTMLDivElement>,
): ReactElement {
	const { formatMessage } = useIntl();

	function getPlaceholderTitle(): string | ReactNode {
		if (placeholderTitle) {
			return placeholderTitle;
		} else if (graphType === 'getStarted') {
			return formatMessage(messages.getStartedTitle);
		} else {
			// Privacy Error
			return formatMessage(messages.noDataTitle);
		}
	}

	function getPlaceholderSubtitle(): string | ReactNode {
		if (placeholderSubtitle) {
			return placeholderSubtitle;
		} else if (graphType === 'getStarted') {
			return formatMessage(messages.getStartedDescription, {
				resourcedoclink: (...chunks: ReactNode[]) => (
					<ResourceLink href="/resources" target="_blank" rel="noopener noreferrer">
						{chunks}
					</ResourceLink>
				),
			});
		} else {
			// Privacy Error
			return formatMessage(messages.noDataDescription);
		}
	}

	return (
		<StyledCard data-testid="reporting-placeholder" ref={ref}>
			<Header>{title}</Header>
			<PlaceholderContainer data-html2canvas-ignore="true">
				<PlaceholderTitle data-testid="error-title">{getPlaceholderTitle()}</PlaceholderTitle>
				<PlaceholderMessage data-testid="error-subtitle">{getPlaceholderSubtitle()}</PlaceholderMessage>
			</PlaceholderContainer>
		</StyledCard>
	);
}

export default forwardRef(ReportingPlaceholder);
