import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { ActiveStep, CompletedSteps, PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { Pack } from '@/types/content';

import messages from './messages';
import StepItem from './StepItem';
import { StepNav } from './styles';

interface Props {
	isReadOnly?: boolean;
	startDate: Date | null;
	endDate: Date | null;
	activeStep?: ActiveStep;
	completedSteps: CompletedSteps;
	selectedPathway?: Pack;
	canSeeCohorts?: boolean;
}

const StepsNav: FC<Props> = ({
	isReadOnly,
	activeStep = 'selection',
	completedSteps,
	selectedPathway,
	startDate,
	endDate,
	canSeeCohorts,
}) => {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { serverTimeZone, cohortCount, hasNoCohortsSelected } = useContext(PathwaysContext);

	const handleClick = (route: ActiveStep): void => {
		history.push({ hash: `#${route}` });
	};

	return (
		<StepNav>
			<StepItem
				completedSteps={completedSteps}
				activeStep={activeStep}
				name="selection"
				onClick={handleClick}
				disabled={isReadOnly}
			>
				{completedSteps.selection ? selectedPathway?.title : formatMessage(messages.progressBarSelection)}
			</StepItem>
			{canSeeCohorts && (
				<StepItem
					completedSteps={completedSteps}
					activeStep={activeStep}
					name="audience"
					onClick={handleClick}
					disabled={!completedSteps.selection}
				>
					{completedSteps.audience
						? formatMessage(messages.audienceSelected, { count: cohortCount })
						: formatMessage(messages.progressBarAudience)}
				</StepItem>
			)}
			<StepItem
				completedSteps={completedSteps}
				activeStep={activeStep}
				name="schedule"
				onClick={handleClick}
				disabled={!completedSteps.audience || isReadOnly || hasNoCohortsSelected || cohortCount === 0}
			>
				{completedSteps.schedule && startDate && endDate
					? `${startDate.toLocaleDateString(undefined, {
							month: 'numeric',
							day: 'numeric',
							year: '2-digit',
							timeZone: serverTimeZone,
					  })} - ${endDate.toLocaleDateString(undefined, {
							month: 'numeric',
							day: 'numeric',
							year: '2-digit',
							timeZone: serverTimeZone,
					  })}`
					: canSeeCohorts
					? formatMessage(messages.progressBarScheduleCohorts)
					: formatMessage(messages.progressBarSchedule)}
			</StepItem>
			<StepItem
				completedSteps={completedSteps}
				activeStep={activeStep}
				name="review"
				onClick={handleClick}
				disabled={
					!completedSteps.schedule ||
					(isReadOnly && activeStep !== 'audience') ||
					hasNoCohortsSelected ||
					cohortCount === 0
				}
			>
				{canSeeCohorts
					? formatMessage(messages.progressBarReviewCohorts)
					: formatMessage(messages.progressBarReview)}
			</StepItem>
		</StepNav>
	);
};

export default StepsNav;
