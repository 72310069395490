import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, units } from '@calm-web/design-system';

export const Subtitle = styled.div`
	${fontSize(FontSizes.sm)}
	color: ${palette('gray8')};
	margin: ${units(-1, 0, 2)};
`;

export const HelperLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: underline;
`;
