import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	maxWidth,
	TextButton,
	units,
} from '@calm-web/design-system';

import { Subtitle as ParentSubtitle } from '../styles';

export const InputWrapper = styled.div`
	width: 100%;
`;

export const DropzoneWrapper = styled.div`
	height: 160px;
	width: 100%;
	display: flex;
	border: 1px solid ${palette('gray3')};
	border-radius: 6px;
	justify-content: center;
	align-items: center;
	padding: ${units(3)};
	text-align: center;
	margin: ${units(2, 1)};
	cursor: pointer;
`;

export const CopyWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	${maxWidth('desktop')} {
		flex-wrap: wrap;
	}
`;

export const HiddenClientId = styled.input`
	width: 0;
	height: 0;
	display: block;
`;

export const SubscribeLinkLabel = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	color: ${palette('gray8')};
`;

export const SsoSignupLinkLabel = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	color: ${palette('gray8')};
	margin-top: ${units(2)};
`;

export const SubscribeLinkExplainer = styled.div`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray4')};
`;

export const SubscribeLinkPreview = styled.div`
	color: ${palette('gray4')};
	margin-top: ${units(0.75)};
	margin-bottom: ${units(2)};
`;

export const Subtitle = styled(ParentSubtitle)`
	margin-bottom: 0;
`;

export const QrDownloadLink = styled(TextButton)`
	margin-top: ${units(2)};
`;

export const CopyTemplateSection = styled.div`
	margin-top: ${units(1)};
`;

// The goal here is to push the element offscreen so it's not visible.
// https://stackoverflow.com/a/49053360/3117409
export const HideTemplate = styled.div`
	position: absolute;
	left: -999em;
`;
