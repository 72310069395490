import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontWeights, maxWidth, Text, TextAlign, TextElements, units } from '@calm-web/design-system';

export const GraphContainer = styled.div``;

export const MoodContainer = styled.span<{ columnWidths: string | undefined }>`
	display: grid;
	align-items: center;
	grid-template-columns: ${p => (p.columnWidths ? p.columnWidths : undefined)};
	grid-gap: ${units(0.25)};
	width: 98%;
	padding: ${units(0, 2, 2, 1)};

	${maxWidth('mobileLarge')} {
		width: unset;
		padding: unset;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}
`;

export const Mood = styled.div<{ gradientColor: string | undefined }>`
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	height: 120px;
	padding: ${units(1)};
	background: ${p => (p.gradientColor ? palette(p.gradientColor) : palette('blue3'))};
`;

export const MoodEmoji = styled.span``;

export const MoodText = styled(Text).attrs({
	weight: FontWeights.Medium,
	el: TextElements.Span,
	color: 'white',
	align: TextAlign.Center,
})`
	position: absolute;
	width: 100%;
	top: 0;
	font-size: 11px;
	background: ${palette('blackAlpha60')};
	height: 24px;
	line-height: 24px;
`;

export const MoodPercentage = styled(Text).attrs({
	weight: FontWeights.Medium,
	el: TextElements.Span,
	color: 'white',
	align: TextAlign.Center,
})`
	position: absolute;
	width: 100%;
	bottom: 0;
	font-size: 11px;
	margin-bottom: 12px;
	height: 12px;
	line-height: 12px;
`;
export const TooltipTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	td:not(:first-child) {
		padding-left: ${units(1)};
		text-align: right;
	}
`;
