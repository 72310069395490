import styled, { css } from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	Button,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	FormInput,
	minWidth,
	Notice,
	TextButton,
	units,
} from '@calm-web/design-system';

export const LoginWrapper = styled.div`
	width: 100%;
	left: 0;
	top: 0;
	z-index: ${theme('layout.navIndex')};
	min-height: 100%;
	flex-wrap: nowrap;
	${fontSize(FontSizes.base)};

	${minWidth('tablet')} {
		display: flex;
	}
`;

const Column = css`
	display: flex;
	width: 100%;
	height: auto;
	min-height: 100px;
	padding: ${units(8, 4.5)};
	align-items: center;

	${minWidth('tablet')} {
		width: 50%;
		min-height: 100vh;
	}
`;

export const LogoContainer = styled.div`
	background: ${palette('gradientCalmBrand')};
	${Column}
	justify-content: center;
	flex-wrap: wrap;
`;

export const LoginContainer = styled.div`
	background: ${palette('white')};
	${Column}
`;

export const LoginInner = styled.main`
	width: 100%;
	max-width: 484px;
	display: flex;
	flex-direction: column;
`;

export const Input = styled(FormInput)`
	margin-bottom: ${units(3)};
`;

export const Title = styled.div`
	margin-top: ${units(2)};
	text-align: center;
	width: 100%;
	color: ${palette('white')};
	${fontWeight(FontWeights.Medium)};
`;

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: ${palette('gradientCalmBrand')};
	margin: ${units(4)} auto;
`;

export const ForgotPassword = styled.div`
	margin-bottom: ${units(3)};
	width: 100%;
	text-align: center;
`;

export const PasswordLink = styled(TextButton)`
	margin-left: ${units(1)};
`;

export const SubmitButton = styled(Button)`
	width: 100%;
`;

export const ErrorNotice = styled(Notice)`
	margin-top: ${units(2)};
	cursor: pointer;
`;
