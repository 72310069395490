import { useDispatch } from 'react-redux';
import useSWRMutation from 'swr/mutation';

import { partnerEndpoint, useDefinedPartner } from '@/hooks/api/usePartner';
import { setBannerMessage } from '@/store/actions/setBannerMessage';

import { useApi } from './useApi';

function useTransitionSsoToEf(): { trigger: () => Promise<void>; isMutating: boolean; error?: Error } {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const partner = useDefinedPartner();

	const transitionEndpoint = `b2b/partners/${partner.id}/transition/eligibility-file`;
	const { trigger, isMutating, error } = useSWRMutation(partnerEndpoint(partner.id), async () => {
		try {
			await apiRequest({
				endpoint: transitionEndpoint,
				method: 'POST',
			});
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: 'Oops! We were unable to transition this partner.',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		}
	});

	return { trigger, isMutating, error };
}
export default useTransitionSsoToEf;
