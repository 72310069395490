import { ReactElement } from 'react';

import { Card, FormInput, FormSelect, IconButton, PrimaryButton } from '@calm-web/design-system';
import { CircleMinus } from '@calm-web/icons';

import { EligibilityFileFormatType } from '@/components/pages/Account/EligibilityFileFormat';
import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig from '@/hooks/api/useHealthConfig';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePermissions } from '@/hooks/auth/usePermissions';
import { EditHealthEligibilityValidatorFormProps } from '@/hooks/forms/useHealthEligibilityValidatorForm';
import { IntegrationType } from '@/types/store/reducers';

import { getEligibilityFieldOptions } from '../helpers';
import { Container, FieldInfo, FieldInfoText, Subtitle } from './styles';

function EligibilityValidator({
	index,
	formProps,
	removeEligibilityValidator,
	isSponsorCode,
	eligibilityFileFormat,
}: {
	index: number;
	formProps: EditHealthEligibilityValidatorFormProps;
	removeEligibilityValidator: () => void;
	isSponsorCode: boolean;
	eligibilityFileFormat: EligibilityFileFormatType | null | undefined;
}): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();

	const shouldShow =
		hasValidPermissions('eligibility_validator_validation_name', [actions.READ]) &&
		hasValidPermissions('eligibility_validator_validation_type', [actions.READ]) &&
		hasValidPermissions('eligibility_validator_validation_display_name', [actions.READ]);
	if (!shouldShow) {
		return null;
	}

	interface DropdownOption {
		value: string;
		label: string;
	}

	const eligibilityFieldOptions = getEligibilityFieldOptions(isSponsorCode, eligibilityFileFormat);

	const getFieldTypeOptions = (): DropdownOption[] => {
		const textOption = { value: 'text', label: 'Text' };
		const dateOption = { value: 'date', label: 'Date' };
		const emailOption = { value: 'email', label: 'Email' };

		const allFieldTypeOptions: DropdownOption[] = [textOption, dateOption, emailOption];

		// Field type options are filtered based on the eligibility field selected
		const fieldMappings: Map<string, DropdownOption[]> = new Map([
			['first_name', [textOption]],
			['last_name', [textOption]],
			['dob', [dateOption]],
			['email', [emailOption]],
			['subscriber_id', [textOption, emailOption]],
			['alternate_id', [textOption, emailOption]],
			['policy_id', [textOption, emailOption]],
			['group_id', [textOption, emailOption]],
			['partner_member_id', [textOption, emailOption]],
		]);

		const field = formProps.model.validation_name as string;
		if (isSponsorCode) {
			return [{ value: 'text', label: 'Text' }];
		}
		// if no field is selected yet, return all field types
		return fieldMappings.get(field) ?? allFieldTypeOptions;
	};

	return (
		<Container>
			<FieldInfo>
				<FieldInfoText>Eligibility Validation Field {index + 1}</FieldInfoText>
				<IconButton
					Icon={CircleMinus}
					color="error"
					aria-label="Remove Eligibility Validation Field"
					onPress={removeEligibilityValidator}
					isDisabled={!hasValidPermissions('eligibility_validator_validation_name', [actions.DELETE])}
				/>
			</FieldInfo>
			<FormSelect
				label="Field in Eligibility File"
				options={eligibilityFieldOptions}
				placeholder="Field"
				{...formProps.bindWithErrorProps('validation_name', 'text')}
				disabled={!hasValidPermissions('eligibility_validator_validation_name', [actions.UPDATE])}
				data-testid="eligibility-validator-field"
			/>
			<FormSelect
				label="Field Type"
				placeholder="Type"
				options={getFieldTypeOptions()}
				{...formProps.bindWithErrorProps('validation_type', 'text')}
				disabled={!hasValidPermissions('eligibility_validator_validation_type', [actions.UPDATE])}
				data-testid="eligibility-validator-type"
			/>
			<FormInput
				label="Display Text"
				{...formProps.bindWithErrorProps('validation_display_name', 'text')}
				disabled={!hasValidPermissions('eligibility_validator_validation_display_name', [actions.UPDATE])}
			/>
		</Container>
	);
}

export default function EligibilityValidators({
	formProps,
	addEligibilityValidator,
	removeEligibilityValidator,
	onFocus,
}: {
	formProps: EditHealthEligibilityValidatorFormProps[];
	addEligibilityValidator: () => void;
	removeEligibilityValidator: (index: number) => void;
	onFocus?: () => void;
}): ReactElement {
	const partner = useDefinedPartner();
	const healthConfig = useHealthConfig({ partnerId: partner.id }).data;
	const isSponsorCode = healthConfig?.integration_type === IntegrationType.GROUP_CODE;
	const eligibilityFileFormat = healthConfig?.eligibility_file_format;
	const [hasValidPermissions, actions] = usePermissions();

	const canAddValidator =
		hasValidPermissions('eligibility_validator_validation_name', [actions.CREATE]) &&
		hasValidPermissions('eligibility_validator_validation_type', [actions.CREATE]) &&
		hasValidPermissions('eligibility_validator_validation_display_name', [actions.CREATE]);
	const isAddHidden = (isSponsorCode && formProps.length) || formProps.length > 4 || !canAddValidator;
	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>Eligibility Information</CellTitle>
			<Subtitle>
				Members’ eligibility will be validated upon matching with these required fields during signup. You may
				have up to 5 eligibility validation fields.
			</Subtitle>
			{formProps.map((oneFormProps, index) => (
				<EligibilityValidator
					key={index}
					index={index}
					formProps={oneFormProps}
					removeEligibilityValidator={() => removeEligibilityValidator(index)}
					isSponsorCode={isSponsorCode}
					eligibilityFileFormat={eligibilityFileFormat}
				/>
			))}
			{isAddHidden ? null : (
				<PrimaryButton onPress={addEligibilityValidator}>Add Eligibility Validation Field</PrimaryButton>
			)}
		</Card>
	);
}
