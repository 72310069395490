import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal } from '@calm-web/design-system';

import messages from './messages';
import { CenteredContent, WarningIcon } from './styles';

interface EligibilityExceededModalProps {
	contractCoveredLives: number;
	eligibilityFileCoveredLives: number;
	partnerName: string;
	onClose: () => void;
	isOpen: boolean;
}

const EligibilityExceededModal = ({
	contractCoveredLives,
	eligibilityFileCoveredLives,
	partnerName,
	onClose,
	isOpen,
}: EligibilityExceededModalProps): ReactElement => {
	const { formatMessage } = useIntl();
	return (
		<Modal isOpen={isOpen} canClose closeModal={onClose} title={formatMessage(messages.title)}>
			<CenteredContent>
				<WarningIcon />
				<p>
					{formatMessage(messages.details, {
						eligibilityFileCoveredLives,
						contractCoveredLives,
						partnerName,
					})}
				</p>
				<p>{formatMessage(messages.ask)}</p>
				<Button
					backgroundColor="transparent"
					hideShadow
					textColor="blue3"
					onPress={onClose}
					data-testid="confirm-exceeded-maximum-covered-lives-button"
				>
					{formatMessage(messages.confirmation)}
				</Button>
			</CenteredContent>
		</Modal>
	);
};

export default EligibilityExceededModal;
