import Image from 'next/legacy/image';
import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';

import CalmLogoSquare from '@/../public/_n/images/calm-logo-square.png';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { notificationPreviewContent } from '@/hooks/api/usePathways';
import { srcLoader } from '@/utils/ui/imageLoader';

import messages from './messages';
import {
	CalmLogoText,
	LogoWrapper,
	NotificationContent,
	NotificationHeader,
	NotificationTime,
	PreviewDescription,
	PreviewTitle,
	PreviewWrapper,
} from './styles';

interface previewProps {
	daySelected: number;
}

function PushNotificationPreview({ daySelected }: previewProps): ReactElement {
	const { formatMessage } = useIntl();
	const { selectedPathway } = useContext(PathwaysContext);

	return (
		<PreviewWrapper>
			<NotificationContent>
				<NotificationHeader>
					<LogoWrapper>
						<Image height={20} width={20} src={CalmLogoSquare} alt="" loader={srcLoader} />
						<CalmLogoText>{formatMessage(messages.calmLogoText)}</CalmLogoText>
					</LogoWrapper>
					<NotificationTime>{formatMessage(messages.timeText)}</NotificationTime>
				</NotificationHeader>
				<PreviewTitle>
					{selectedPathway
						? notificationPreviewContent[selectedPathway.pack_class][daySelected].title
						: notificationPreviewContent['b2b-pathway-setting-boundaries'][daySelected].title}
				</PreviewTitle>
				<PreviewDescription>
					{selectedPathway
						? notificationPreviewContent[selectedPathway.pack_class][daySelected].description
						: notificationPreviewContent['b2b-pathway-setting-boundaries'][daySelected].description}
				</PreviewDescription>
			</NotificationContent>
		</PreviewWrapper>
	);
}

export default PushNotificationPreview;
