import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';
import InfoOutline from 'icons/info-outline.svg';
import SalesforceIcon from 'icons/salesforce-icon.svg';

export const ModalTitle = styled.h2`
	${fontWeight(FontWeights.Regular)};
	${fontSize(FontSizes.lg)};
`;
export const TitleSmall = styled.h3`
	color: ${palette('gray7')};
	margin: ${units(2, 0, 0.5, 0)};
	${fontWeight(FontWeights.Demi)};
	${fontSize(FontSizes.sm)};
`;

export const IconSpacing = styled.div``;

export const AccountId = styled.p`
	font-family: monospace;
	${fontWeight(FontWeights.Light)};
	color: ${palette('gray6')};
`;

export const NamePreview = styled.div`
	${fontSize(FontSizes.lg)};
`;

export const AccountPreviewBox = styled.div`
	padding: ${units(1, 2)};
	content: '${palette('gray1')};';
	background-color: ${palette('gray1')};
	border-radius: ${theme('layout.borderRadius')};
`;

export const ButtonGroup = styled.div`
	button + button {
		margin-left: ${units(1)};
	}
`;

export const FormDescription = styled.div``;

export const Body = styled.div`
	${fontSize(FontSizes.sm)};
	text-align: left;
	${fontWeight(FontWeights.Medium)};

	${FormDescription} {
		margin-bottom: ${units(0.5)};
	}

	${AccountPreviewBox} {
		margin-bottom: ${units(2)};
	}
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	${Body} {
		margin-bottom: ${units(2)};
	}

	${ModalTitle} {
		margin-bottom: ${units(5)};
	}

	${IconSpacing} {
		margin-bottom: ${units(3)};
	}
`;

export const PreviewContainer = styled.div`
	${boxShadow};
	width: 100%;
	border-radius: ${units(1)};
	margin-bottom: ${units(2)};
	padding: ${units(4)};
`;

export const SalesforceIconStyled = styled(SalesforceIcon)`
	width: ${units(7)};
`;

export const InfoLine = styled.div`
	display: flex;
	padding: ${units(2)};
	margin: ${units(4, 0)};
	align-items: center;
	${fontWeight(FontWeights.Light)};
	svg {
		width: ${units(3)};
		margin-right: ${units(1)};
	}
	background-color: ${palette('gray1')};
`;

export const InfoIcon = styled(InfoOutline)`
	width: ${units(3)};
	height: ${units(3)};
`;

export const WebLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: underline;
`;
