/* eslint-disable max-len */
// Third Party

import { defineMessages } from 'react-intl';

export default defineMessages({
	hello: {
		id: 'emailPreferences.benefitsEmail.hello',
		defaultMessage: 'Hello,',
		description: 'Link text for showing email sample',
	},
	intro: {
		id: 'emailPreferences.benefitsEmail.intro',
		defaultMessage:
			'{partnerName} has partnered with Calm to offer you free, 24/7 access to meditations, Sleep Stories, music and more self-care resources. And it works—84% of Calm users report better overall mental health.',
		description: 'First line of text in the benefits email',
	},
	description: {
		id: 'emailPreferences.benefitsEmail.description',
		defaultMessage: 'Activate your account today and take care of your mental well-being anytime, anywhere.',
		description: 'A prompt asking the user to give positive feedback about their app usage',
	},
	activateAccount: {
		id: 'emailPreferences.benefitsEmail.activateAccount',
		defaultMessage: 'Activate your free account now',
		description: 'CTA button text in the benefits email asking to activate their free account',
	},
	startExploring: {
		id: 'emailPreferences.benefitsEmail.startExploring',
		defaultMessage: 'Start Exploring',
		description: 'Header text for Start Exploring section',
	},
	kickstart: {
		id: 'emailPreferences.benefitsEmail.kickstart',
		defaultMessage:
			'We can help kickstart your day, find a quick reset, and fall asleep faster. Start exploring our most popular content when you create your free account.',
		description: 'Text in the benefits email explaining what a user can get from a Calm account',
	},
	pianoForSleep: {
		id: 'emailPreferences.benefitsEmail.pianoForSleep',
		defaultMessage: 'Piano for Sleep',
		description: 'Header text for Piano for Sleep section',
	},
	pianoForSleepDescription: {
		id: 'emailPreferences.benefitsEmail.pianoForSleepDescription',
		defaultMessage:
			'Sleep and rest will no longer feel elusive with these beautifully soothing piano tracks, created to help you drift off with ease.',
		description: 'Text in the benefits email describing piano music for sleep',
	},
	listenTonight: {
		id: 'emailPreferences.benefitsEmail.listenTonight',
		defaultMessage: 'Listen Tonight',
		description: 'CTA button text in the benefits email asking to listen to the piano music',
	},
	thankYou: {
		id: 'emailPreferences.benefitsEmail.thankYou',
		defaultMessage: 'Warmly,',
		description: 'Thank you message in benefits email',
	},
	signature: {
		id: 'emailPreferences.benefitsEmail.signature',
		defaultMessage: 'The Calm Team',
		description: 'Signature line in the benefits email',
	},
});
