import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Modal, PrimaryButton } from '@calm-web/design-system';

import { Partner } from '@/types/store/reducers';

import messages from './messages';
import { UpdatePrice } from './UpdatePrice';

const Container = styled.div``;

interface Props {
	coveredLives: number;
	partner: Partner;
	isAccountManager?: boolean;
}

export function UpdateCoverage({ coveredLives, partner, isAccountManager }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [showModal, setShowModal] = useState(false);

	const handleClick = (): void => setShowModal(true);
	const closeModal = (): void => setShowModal(false);

	const updateText = isAccountManager
		? formatMessage(messages.updateCoverage)
		: formatMessage(messages.buyMoreCoverage);
	return (
		<Container>
			<PrimaryButton onPress={handleClick} data-testid="buy-more-coverage-button">
				{updateText}
			</PrimaryButton>

			{showModal && (
				<Modal
					data-testid="buy-more-coverage-modal"
					isOpen
					closeModal={closeModal}
					title={formatMessage(messages.buyMoreCoverage)}
				>
					<UpdatePrice
						previousCoveredLives={coveredLives}
						onCancel={closeModal}
						onSubmit={closeModal}
						partnerId={partner.id}
						isAccountManager={isAccountManager}
					/>
				</Modal>
			)}
		</Container>
	);
}
