import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, ButtonSizes, fontSize, FontSizes, units } from '@calm-web/design-system';

import ChatFilled from 'icons/chat-filled.svg';
import InfoOutline from 'icons/info-outline.svg';

export const Subtitle = styled.a`
	color: ${palette('blue3')};
	margin-left: ${units(1)};
`;

export const SubtitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	${fontSize(FontSizes.sm)};
	margin-top: ${units(0.5)};
	margin-bottom: ${units(2)};
`;

export const InfoIcon = styled(InfoOutline)`
	path {
		fill: ${palette('blue3')};
	}
	width: ${units(2)};
	height: ${units(2)};
	position: relative;
	top: -1px;
`;

export const ModalText = styled.div`
	${fontSize(FontSizes.sm)};
`;

export const ModalUl = styled.ul`
	margin-top: ${units(1)};
`;

export const SeeAllFeedbackContainer = styled.div`
	width: 100%;
	margin-top: ${units(2)};
	text-align: center;
`;

export const SeeAllFeedbackButton = styled(Button).attrs({
	size: ButtonSizes.Sm,
	backgroundColor: 'blue3',
})``;

export const EmptyStateContainer = styled.div`
	width: 100%;
	text-align: center;
	padding: ${units(3)} 0;
`;

export const ChatBubbles = styled(ChatFilled)`
	display: block;
	margin: ${units(0.5)} auto ${units(3)};
`;
