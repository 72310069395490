import omit from 'lodash/omit';
import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal, PrimaryButton } from '@calm-web/design-system';
import useForm, { ModelValue } from '@calm-web/use-form';

import { Subtitle } from '@/components/ui/CellTitle';
import useTransitionSsoToEf from '@/hooks/api/useTransitionSsoToEf';
import { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { RenewalType } from '@/utils/SkuEnums';
import { getInitialSkuProps, isSelfServePlan } from '@/utils/SkuUtils';

import messages from '../messages';
import { SsoConversionStarter } from '../SsoConversion';
import transitionMessages from './messages';
import { ModalMainText, ModalSecondaryText, StyledSelect } from './styles';

interface Option {
	id: IntegrationType;
	value: IntegrationType;
	label: string;
	allowForSs: boolean;
}

const nameForIntegrationType = (integrationType: unknown): string => {
	return integrationType === IntegrationType.ACCESS_CODES
		? 'Access Codes'
		: integrationType === IntegrationType.GROUP_CODE
		? 'Group Codes'
		: 'Eligibility File';
};

function ModalMainMessage({
	integrationType,
	renewalType,
}: {
	integrationType: IntegrationType;
	renewalType: RenewalType;
}): ReactElement {
	const { formatMessage } = useIntl();
	if (integrationType === IntegrationType.ACCESS_CODES) {
		if (renewalType === RenewalType.Renewable) {
			return <ModalMainText>{formatMessage(transitionMessages.fromAcFixedDuration)}</ModalMainText>;
		}
		return <ModalMainText>{formatMessage(transitionMessages.fromAcContractDuration)}</ModalMainText>;
	}
	if (integrationType === IntegrationType.ELIGIBILITY_FILE) {
		if (renewalType === RenewalType.Renewable) {
			return <ModalMainText>{formatMessage(transitionMessages.fromEfFixedDuration)}</ModalMainText>;
		}
	}
	if (renewalType === RenewalType.Renewable) {
		return <ModalMainText>{formatMessage(transitionMessages.fromGcFixedDuration)}</ModalMainText>;
	}
	return <ModalMainText>{formatMessage(transitionMessages.fromGcContractDuration)}</ModalMainText>;
}

function ModalSecondaryMessage({
	newIntegrationType,
}: {
	newIntegrationType: ModelValue | undefined;
}): ReactElement {
	const { formatMessage } = useIntl();
	if (newIntegrationType === IntegrationType.ACCESS_CODES) {
		return <ModalSecondaryText>{formatMessage(transitionMessages.toAc)}</ModalSecondaryText>;
	}
	if (newIntegrationType === IntegrationType.ELIGIBILITY_FILE) {
		return <ModalSecondaryText>{formatMessage(transitionMessages.toEf)}</ModalSecondaryText>;
	}
	return <ModalSecondaryText>{formatMessage(transitionMessages.toGc)}</ModalSecondaryText>;
}

function ModalButton({
	setShowModal,
	isLoading,
	newIntegrationType,
}: {
	setShowModal: (showModal: boolean) => void;
	isLoading: boolean;
	newIntegrationType: ModelValue | undefined;
}): ReactElement {
	return (
		<Button
			data-testid="change-integration-type"
			backgroundColor="blue3"
			onPress={() => {
				setShowModal(true);
			}}
			isLoading={isLoading}
		>
			Change to {nameForIntegrationType(newIntegrationType)}
		</Button>
	);
}

function IntegrationSubtitle({
	newIntegrationType,
}: {
	newIntegrationType: ModelValue;
}): ReactElement | null {
	const { formatMessage } = useIntl();

	if (newIntegrationType === IntegrationType.ACCESS_CODES) {
		return <Subtitle>{formatMessage(messages.accessCodesDescription)}</Subtitle>;
	}
	if (newIntegrationType === IntegrationType.ELIGIBILITY_FILE) {
		return <Subtitle>{formatMessage(messages.eligibilityFileSubtitle)}</Subtitle>;
	}
	if (newIntegrationType === IntegrationType.GROUP_CODE) {
		return <Subtitle>{formatMessage(messages.groupCodesSubtitle)}</Subtitle>;
	}

	return null;
}

function ModalDetails({
	currentIntegrationType,
	newIntegrationType,
	isLoading,
	setShowModal,
	formProps,
}: {
	currentIntegrationType: IntegrationType;
	newIntegrationType: ModelValue | undefined;
	isLoading: boolean;
	setShowModal: (showModal: boolean) => void;
	formProps: EditPartnerFormProps;
}): ReactElement | null {
	if (!newIntegrationType) {
		return null;
	}

	if (newIntegrationType === IntegrationType.SSO) {
		return <SsoConversionStarter currentIntegrationType={currentIntegrationType} formProps={formProps} />;
	}

	return (
		<>
			<IntegrationSubtitle newIntegrationType={newIntegrationType} />
			<ModalButton
				setShowModal={setShowModal}
				newIntegrationType={newIntegrationType}
				isLoading={isLoading}
			/>
		</>
	);
}

function ModalFooter({
	onClickChangeIntegrationType,
	newIntegrationType,
	handleCancel,
	loading,
}: {
	onClickChangeIntegrationType: (newIntegrationType: IntegrationType) => void;
	newIntegrationType: ModelValue | undefined;
	handleCancel: () => void;
	loading?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<>
			<Button
				backgroundColor="white"
				textColor="blue3"
				data-testid="cancel-integration-change"
				onPress={handleCancel}
			>
				{formatMessage(messages.cancelButton)}
			</Button>
			<Button
				backgroundColor="blue3"
				data-testid="confirm-integration-change"
				isLoading={loading}
				onPress={() => {
					onClickChangeIntegrationType(newIntegrationType as IntegrationType);
				}}
			>
				Yes, change to {nameForIntegrationType(newIntegrationType)}
			</Button>
		</>
	);
}

export function SsoToEfTransition({
	partner,
	integrationType,
}: {
	partner: Partner;
	integrationType: IntegrationType;
}): ReactElement {
	const { renewalType } = getInitialSkuProps({
		vouched_plan_sku: partner?.vouched_plan_sku ?? undefined,
	});

	const [showModal, setShowModal] = useState(false);
	const { trigger, isMutating } = useTransitionSsoToEf();
	const onPress = (): void => {
		setShowModal(true);
	};

	const handleClick = async (): Promise<void> => {
		await trigger();
		setShowModal(false);
	};
	const handleCancel = (): void => {
		setShowModal(false);
	};

	return (
		<div>
			<PrimaryButton onPress={onPress} isLoading={isMutating}>
				Change to Eligibility File
			</PrimaryButton>
			<Modal
				data-testid="confirmation-modal"
				title="Are you sure?"
				footer={
					<ModalFooter
						onClickChangeIntegrationType={handleClick}
						loading={isMutating}
						handleCancel={handleCancel}
						newIntegrationType={IntegrationType.ELIGIBILITY_FILE}
					/>
				}
				isOpen={showModal}
				closeModal={() => setShowModal(false)}
			>
				<ModalMainMessage integrationType={integrationType} renewalType={renewalType} />
				<ModalSecondaryMessage newIntegrationType={IntegrationType.ELIGIBILITY_FILE} />
			</Modal>
		</div>
	);
}

function IntegrationChanger({
	partner,
	integrationType,
	onClickChangeIntegrationType,
	isLoading,
	formProps,
}: {
	partner: Partner;
	integrationType: IntegrationType;
	onClickChangeIntegrationType: (newIntegrationType: IntegrationType) => Promise<void>;
	isLoading: boolean;
	formProps: EditPartnerFormProps;
}): ReactElement {
	const transitionFormProps = useForm('changeIntegrationType', {
		initialModel: {
			newIntegrationType: IntegrationType.SSO,
		},
	});
	const [showModal, setShowModal] = useState(false);

	const { renewalType } = getInitialSkuProps({
		vouched_plan_sku: partner?.vouched_plan_sku ?? undefined,
	});

	const options: Option[] = [
		{
			id: IntegrationType.SSO,
			value: IntegrationType.SSO,
			label: 'Sync with an SSO Provider',
			allowForSs: false,
		},
		{
			id: IntegrationType.ACCESS_CODES,
			value: IntegrationType.ACCESS_CODES,
			label: 'Calm-created access codes',
			allowForSs: true,
		},
		{
			id: IntegrationType.ELIGIBILITY_FILE,
			value: IntegrationType.ELIGIBILITY_FILE,
			label: 'Eligibility file: .csv or .xlss',
			allowForSs: true,
		},
		{
			id: IntegrationType.GROUP_CODE,
			value: IntegrationType.GROUP_CODE,
			label: 'Group code: one code for all covered lives',
			allowForSs: false,
		},
	].filter(o => {
		const isNewType = o.id !== integrationType;
		if (!isNewType) {
			return false;
		}

		const isSs = isSelfServePlan(partner.vouched_plan_sku);
		const allowedForPartner = isSs ? isSs && o.allowForSs : true;
		if (!allowedForPartner) {
			return false;
		}

		return true;
	});

	const bindWithErrorProps = transitionFormProps.bindWithErrorProps('newIntegrationType', 'select');
	const handleModalConfirm = async (newIntegrationType: IntegrationType): Promise<void> => {
		await onClickChangeIntegrationType(newIntegrationType);
		transitionFormProps.setProperty('newIntegrationType', newIntegrationType);
		setShowModal(false);
	};

	const handleCancel = (): void => {
		setShowModal(false);
	};

	return (
		<div data-testid="integration-changer">
			<Subtitle id="integration-changer-label">
				<b data-testid="subtitle-text">
					Change integration from {nameForIntegrationType(integrationType)} to:
				</b>
			</Subtitle>
			<StyledSelect
				{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
				data-testid="select-integration-type"
				aria-labelledby="integration-changer-label"
				style={{ marginBottom: '0.5rem' }}
			>
				{options.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</StyledSelect>
			<ModalDetails
				currentIntegrationType={integrationType}
				newIntegrationType={transitionFormProps.model.newIntegrationType}
				setShowModal={setShowModal}
				isLoading={isLoading}
				formProps={formProps}
			/>

			<Modal
				data-testid="confirmation-modal"
				title="Are you sure?"
				footer={
					<ModalFooter
						onClickChangeIntegrationType={handleModalConfirm}
						newIntegrationType={transitionFormProps.model.newIntegrationType}
						handleCancel={handleCancel}
					/>
				}
				isOpen={showModal}
				closeModal={() => setShowModal(false)}
			>
				<ModalMainMessage integrationType={integrationType} renewalType={renewalType} />
				<ModalSecondaryMessage newIntegrationType={transitionFormProps.model.newIntegrationType} />
			</Modal>
		</div>
	);
}

export default IntegrationChanger;
