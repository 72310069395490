import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';

import { ApiResponse } from './types';

export interface SalesforceOwnerInfoType {
	id: string;
	name: string;
	email?: string;
}

export function useSalesforceAccountOwner(
	salesforceAccountId?: string,
): ApiResponse<SalesforceOwnerInfoType> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const requestUrl = salesforceAccountId
		? `b2b/salesforce/user?salesforce_account_id=${salesforceAccountId}`
		: '';

	const { data, error } = useSWR(
		requestUrl,
		async endpoint => {
			try {
				const response: { data: SalesforceOwnerInfoType } = await apiRequest({ endpoint });
				return response;
			} catch (err) {
				dispatch(
					setBannerMessage({
						message: 'Failed to retrieve salesforce account name',
						isError: true,
						flash: true,
					}),
				);
				throw err;
			}
		},
		{ errorRetryCount: 0 },
	);

	if (!salesforceAccountId) {
		return { loading: false, error: undefined };
	}

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data: data.data,
		error: undefined,
		loading: false,
	};
}
