import { Sidetab } from '@typeform/embed-react';
import Image from 'next/legacy/image';
import { FC, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { CalmLogo, CalmLogoColor, CalmTheme, FontWeights, Text } from '@calm-web/design-system';

import workshopsImg from '@/../public/_n/images/workshops-promotion.jpg';
import BackLink, { BackLinkDetails } from '@/components/ui/BackLink';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import useCanSeeReferral from '@/hooks/api/useCanSeeReferral';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { usePartner } from '@/hooks/api/usePartner';
import { useSalesforceAccount } from '@/hooks/api/useSalesforceAccount';
import { useSalesforceId } from '@/hooks/api/useSalesforceId';
import { useCalmHealthSftpIntegration } from '@/hooks/api/useSftpConfig';
import { useShouldShowSelfServeNewContentBanner } from '@/hooks/api/useShouldShowSelfServeNewContentBanner';
import { useAuth, usePermissions } from '@/hooks/auth';
import { useHasScrolled } from '@/hooks/layout/useHasScrolled';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { useSitewideBanner, useUser } from '@/hooks/store';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useShouldShowPulseBanner } from '@/hooks/useShouldShowPulseBanner';
import { useSitewideBannerDefaults } from '@/hooks/useSitewideBannerDefaults';
import { IntegrationType, Partner } from '@/types/store/reducers';
import CalmCookie from '@/utils/CalmCookie';
import { calmLogger } from '@/utils/calmLogger';
import { showFauxReportingPage } from '@/utils/partner';
import { isPartnerAdmin } from '@/utils/RBAC';
import { isCalmHealthProductSKU, isSelfServePlan } from '@/utils/SkuUtils';
import { srcLoader } from '@/utils/ui/imageLoader';

import AchPendingBanner from '../AchPendingBanner';
import AchPendingModal from '../AchPendingModal';
import BillingAddressBanner from './BillingAddressBanner';
import Breadcrumbs from './Breadcrumbs';
import messages from './messages';
import MobileNav from './MobileNav';
import NavLink from './NavLink';
import ReferralBanner from './ReferralBanner';
import ReferralPopup, { ReferralPopupV2 } from './ReferralPopup';
import SalesTrialBanner from './SalesTrialBanner';
import SelfServeNewContentBanner from './SelfServeNewContentBanner';
import SitewideBanner from './SitewideBanner';
import {
	AccountIcon,
	Background,
	ContentWrapper,
	GetSetUpIcon,
	GiftButton,
	GiftIcon,
	HomeIcon,
	ImageContainer,
	LoggedInEmail,
	LoggedInWrapper,
	LogoContainer,
	LogOutLink,
	MainContainer,
	MainContent,
	NavElementWrapper,
	NavLeft,
	NavLeftBottom,
	NavRightWrapper,
	NavTop,
	NavTopContainer,
	NavWrapper,
	PathwaysIcon,
	PathwaysText,
	PlanAndPaymentIcon,
	ReferralIcon,
	ReportingIcon,
	ReportingPreviewIcon,
	ResourcesIcon,
	Support,
	SupportIcon,
	SupportLink,
	UsersIcon,
	WorkshopsBannerContainer,
	WorkshopsButton,
	WorkshopsPromoImg,
	WorkshopsText,
} from './styles';

interface UsersLinkProps {
	partnerId: string;
}

export const WORKSHOPS_COOKIE_KEY = 'workshops-request';
export const WORKSOPS_BANNER_FF = 'b2b-workshops';

function UsersLink({ partnerId }: UsersLinkProps): ReturnType<FC> {
	const { data: partner } = usePartner(partnerId);
	const { data: integrationType } = useIntegrationType(partner?.id);
	const isChSftpOn = useCalmHealthSftpIntegration();

	if (!partner || !integrationType) {
		return null;
	}

	if (
		!partner.supports_eligibility_list &&
		(integrationType !== IntegrationType.ELIGIBILITY_FILE || !isChSftpOn)
	) {
		return null;
	}

	if (integrationType === IntegrationType.ACCESS_CODES) {
		return (
			<NavLink to={`/${partner.id}/access-codes`} id="access-codes-link" data-testid="access-codes-link">
				<UsersIcon />
				Access Codes
			</NavLink>
		);
	}
	return (
		<NavLink to={`/${partner.id}/users`} id="users-link" data-testid="users-link">
			<UsersIcon />
			Users
		</NavLink>
	);
}

interface Props {
	children: ReactNode;
}

interface NavTopBarProps {
	partnerId?: string;
	isSalesTrialPartner?: boolean;
	hideBreadcrumbs?: boolean;
	backLink?: BackLinkDetails;
	hasScrolled: boolean;
	partner?: Partner;
}

export function NavTopBar({
	partnerId,
	hideBreadcrumbs,
	backLink,
	hasScrolled,
	isSalesTrialPartner,
	partner,
}: NavTopBarProps): ReturnType<FC> {
	const [showReferralPopup, setShowReferralPopup] = useState(false);
	const location = useLocation();
	const pathname = location?.pathname;
	const { user } = useUser();
	const [authClient] = useAuth();
	const { data: { canSeeReferralV1, canSeeReferralV2Popup } = {} } = useCanSeeReferral();
	const showLogo =
		pathname.includes('groups') || (pathname.includes('create') && !pathname.includes('/pathways/create'));
	const shouldShowSelfServeNewContentBanner = useShouldShowSelfServeNewContentBanner();

	useSitewideBannerDefaults({ partnerId });

	const logout = useCallback(() => {
		if (!authClient) {
			return;
		}
		authClient.signOut().catch((err: Error) => {
			// TODO: better error handling at an application level
			calmLogger.error('Error in NavTopBar logout', {}, err);
		});
	}, [authClient]);

	const userIsPartnerAdmin = isPartnerAdmin(user.accessPolicy?.allowed_user_role);
	const { formatMessage } = useIntl();
	const isAdmin = Boolean(user?.accessPolicy?.isAdmin);
	const isSelfServe = isSelfServePlan(partner?.vouched_plan_sku);
	return (
		<NavTop withBorder={hasScrolled} aria-label="secondary">
			<NavTopContainer>
				{showLogo && (
					<LogoContainer>
						{partner?.logo_url ? (
							<ImageContainer>
								<Image
									src={partner.logo_url}
									alt="Your Logo"
									layout="fill"
									objectFit="contain"
									loader={srcLoader}
								/>
							</ImageContainer>
						) : (
							<CalmLogo
								href={isAdmin ? '/groups' : '/'}
								height={24}
								color={CalmLogoColor.Gradient}
								loader={srcLoader}
							/>
						)}
					</LogoContainer>
				)}
				<NavElementWrapper>
					{backLink ? (
						<BackLink url={backLink.url} text={backLink.text} />
					) : (
						!hideBreadcrumbs && <Breadcrumbs partnerId={partnerId} />
					)}
				</NavElementWrapper>
				{user?.email && authClient && (
					<NavRightWrapper>
						<LoggedInWrapper>
							<LoggedInEmail>{user.email}</LoggedInEmail>
							<LogOutLink onClick={logout} data-testid="logout-button">
								Log out
							</LogOutLink>
						</LoggedInWrapper>
						{canSeeReferralV1 && pathname !== '/groups' ? (
							<>
								<GiftButton
									aria-label="View referral details"
									onPress={(): void => setShowReferralPopup(true)}
									Icon={GiftIcon}
								/>
								{showReferralPopup && <ReferralPopup onDismiss={(): void => setShowReferralPopup(false)} />}
							</>
						) : null}
						{canSeeReferralV2Popup && partnerId && <ReferralPopupV2 partnerId={partnerId} />}
					</NavRightWrapper>
				)}
			</NavTopContainer>
			<SitewideBanner partnerId={partnerId} fullWidth={false} />
			{isSelfServe && shouldShowSelfServeNewContentBanner && <SelfServeNewContentBanner />}
			{isSalesTrialPartner && (
				<SalesTrialBanner
					isAdmin={isAdmin}
					b2bAccountId={partner?.b2b_account_id}
					contractEndDate={partner?.contract_expires_at}
				/>
			)}
			{isSelfServe && partnerId && <BillingAddressBanner />}

			{userIsPartnerAdmin && (
				<Sidetab
					id="csaxip9I"
					buttonColor={CalmTheme.palette.blue3}
					buttonText={formatMessage(messages.typeformButtonText)}
					width={320}
					iframeProps={{ title: 'Purchase Drivers Poll' }}
				/>
			)}
		</NavTop>
	);
}

interface SupportNavLinkProps {
	partnerId: string;
}

function SupportNavLink({ partnerId }: SupportNavLinkProps): ReturnType<FC> {
	const { data: partner } = usePartner(partnerId);
	if (!isSelfServePlan(partner?.vouched_plan_sku)) {
		return null;
	}

	return (
		<Support>
			<SupportIcon />
			<SupportLink href="https://support.calm.com/hc/en-us/articles/1260801520609" target="_blank">
				Help Center
			</SupportLink>
		</Support>
	);
}

const getBackLink = ({
	pageName,
	partnerId,
}: {
	pageName: string;
	partnerId: string;
}): BackLinkDetails | undefined => {
	if (pageName === 'reporting/feedback') {
		return {
			url: `/${partnerId}/reporting`,
			text: 'Reporting',
		};
	}
	if (pageName === 'account/sso') {
		return {
			url: `/${partnerId}/account`,
			text: 'Back to Partner Configuration',
		};
	}
	if (pageName.startsWith('account/health-sponsorships')) {
		return {
			url: { pathname: `/${partnerId}/account`, hash: '#healthConfig' },
			text: 'Back to Health Config',
		};
	}
	return undefined;
};

export function WorkshopsBanner(): ReactElement | null {
	const { partnerId } = useParams();
	const { data: partner } = usePartner(partnerId);
	const { data: salesforceAccountId } = useSalesforceId(partner?.b2b_account_id);
	const { data: salesforceAccountInfo } = useSalesforceAccount(salesforceAccountId);
	const { logEvent } = useAnalytics();
	const { user } = useUser();

	const { formatMessage } = useIntl();
	const { data: flagValues } = useFeatureFlags('b2b-workshops');

	const [hasPreviouslyRequestedWorkshops] = useState(() => Boolean(CalmCookie.get(WORKSHOPS_COOKIE_KEY)));
	const [hasRequestedWorkshops, setHasRequestedWorkshops] = useState(hasPreviouslyRequestedWorkshops);

	const workshopsUpsell = useCallback(() => {
		CalmCookie.set(WORKSHOPS_COOKIE_KEY, 'true');
		setHasRequestedWorkshops(true);

		logEvent('Partner Portal : Workshops Upsell', {
			partner_id: partnerId,
			partner_name: partner?.name ?? null,
			partner_salesforce_id: salesforceAccountId ?? null,
			requestor_name: user?.name ?? null,
		});
	}, [logEvent, partner?.name, partnerId, salesforceAccountId, user?.name]);

	if (flagValues?.[WORKSOPS_BANNER_FF] !== true) {
		return null;
	}
	if (hasPreviouslyRequestedWorkshops) {
		return null;
	}
	if (salesforceAccountInfo?.hasCalmWorkshops) {
		return null;
	}
	if (!salesforceAccountInfo) {
		// Check self-serve, which doesn't have an account yet
		// We still show the banner to them to hopefully upsell on workshops
		if (partner?.vouched_plan_sku !== 'b2b_selfserve_1y') {
			return null;
		}
	}

	return (
		<WorkshopsBannerContainer>
			<WorkshopsPromoImg src={workshopsImg.src} alt="workshops" />
			<WorkshopsText>
				{formatMessage(messages.workshopsBanner, {
					yellowTitle: (...chunks: ReactNode[]) => <span>{chunks}</span>,
				})}
			</WorkshopsText>
			<WorkshopsButton isDisabled={hasRequestedWorkshops} onPress={workshopsUpsell}>
				{hasRequestedWorkshops ? formatMessage(messages.requestSent) : formatMessage(messages.learnMore)}
			</WorkshopsButton>
		</WorkshopsBannerContainer>
	);
}

export default function Nav({ children }: Props): ReactElement {
	const { partnerId } = useParams();
	const location = useLocation();
	const pathname = location?.pathname;
	const [hasValidPermissions, actions] = usePermissions();
	const pageTitle = usePageTitle();
	const { data: partner } = usePartner(partnerId);
	const { data: integrationType } = useIntegrationType(partner?.id);
	const hasScrolled = useHasScrolled();
	const sitewideBanner = useSitewideBanner();
	const isSalesTrialPartner = partner?.category === 'SALES_TRIAL';
	const shouldShowPulseBanner = useShouldShowPulseBanner(partnerId, partner?.vouched_plan_sku);
	const navTopOffsetUnits = useMemo(() => {
		if (shouldShowPulseBanner && (sitewideBanner.bannerCode || isSalesTrialPartner)) {
			return 19;
		}

		if (sitewideBanner.bannerCode || isSalesTrialPartner || shouldShowPulseBanner) {
			return 15;
		}

		if (hasScrolled) {
			return 13;
		}
		return 9;
	}, [sitewideBanner.bannerCode, hasScrolled, isSalesTrialPartner, shouldShowPulseBanner]);

	const { data: { canSeeReferralV1, canSeeReferralV2 } = {} } = useCanSeeReferral();

	const pageName = pathname.replace('/', '').replace(`${partnerId}/`, '');
	const backLink = getBackLink({ pageName, partnerId });
	const hideBreadcrumbs = Boolean(backLink);
	const isSelfServe = isSelfServePlan(partner?.vouched_plan_sku);
	const { data: flagValues } = useFeatureFlags(
		'b2b-calm-health-reporting',
		'b2b-block-eligibility-endpoint',
		'b2b-calm-health-reporting-preview',
	);
	const isCalmHealthPartner = isCalmHealthProductSKU(partner?.product_sku);
	const canSeeHomepage = true;
	const canSeePathways = !isCalmHealthPartner;

	const hasCHReportingAccess = isCalmHealthPartner && hasValidPermissions('ch_reporting', [actions.READ]);

	const canSeeCHReporting =
		flagValues && flagValues['b2b-calm-health-reporting'] === true && hasCHReportingAccess;

	const canSeeCHReportingPreview =
		flagValues && flagValues['b2b-calm-health-reporting-preview'] === true && hasCHReportingAccess;

	const canSeeGetSetUp = integrationType !== IntegrationType.API && !canSeeHomepage;
	const blockEligibilityEndpoint = flagValues && flagValues['b2b-block-eligibility-endpoint'];
	const [isMobile] = useIsMobile();
	const hidePageTitle = pageTitle === '' || (pageTitle === 'Reporting' && isCalmHealthPartner);

	return (
		<NavWrapper $withLeftNav={!isMobile}>
			<Background />
			<AchPendingBanner />
			<MainContainer>
				{!isMobile && (
					<NavLeft>
						<nav aria-label="Main navigation">
							<LogoContainer>
								{partner?.logo_url ? (
									<ImageContainer>
										<Image
											src={partner.logo_url}
											alt="Your Logo"
											layout="fill"
											objectFit="contain"
											loader={srcLoader}
										/>
									</ImageContainer>
								) : (
									<CalmLogo height={24} color={CalmLogoColor.Gradient} loader={srcLoader} />
								)}
							</LogoContainer>
							{canSeeHomepage && !isCalmHealthPartner ? (
								<NavLink to={`/${partnerId}/home`} id="home-link" data-testid="home-link">
									<HomeIcon />
									Home
								</NavLink>
							) : null}
							{canSeeReferralV2 && <ReferralBanner partnerId={partnerId} />}
							{canSeeGetSetUp ? (
								<NavLink to={`/${partnerId}/getsetup`} id="getsetup-link" data-testid="getsetup-link">
									<GetSetUpIcon />
									Get set up
								</NavLink>
							) : null}
							{canSeeReferralV1 ? (
								<NavLink to={`/${partnerId}/referral`} id="referral-link" data-testid="referral-link">
									<ReferralIcon />
									Share Calm
								</NavLink>
							) : null}
							{isSelfServe && hasValidPermissions('update_plan_details', [actions.READ]) && (
								<NavLink to={`/${partnerId}/plan`} id="plan-link" data-testid="plan-link">
									<PlanAndPaymentIcon />
									Plan &amp; Payment
								</NavLink>
							)}
							{canSeeCHReportingPreview && (
								<NavLink
									to={`/${partnerId}/preview-reporting`}
									id="reporting-preview-link"
									data-testid="reporting-preview-link"
								>
									<ReportingPreviewIcon />
									Reporting Preview
								</NavLink>
							)}
							{(!isCalmHealthPartner || canSeeCHReporting || showFauxReportingPage(partnerId)) && (
								<NavLink to={`/${partnerId}/reporting`} id="reporting-link" data-testid="reporting-link">
									<ReportingIcon />
									Reporting
								</NavLink>
							)}
							{canSeePathways && (
								<NavLink to={`/${partnerId}/pathways`} id="pathways-link" data-testid="pathways-link">
									<PathwaysIcon />
									<PathwaysText>Pathways</PathwaysText>
								</NavLink>
							)}
							{blockEligibilityEndpoint ? null : <UsersLink partnerId={partnerId} />}
							<NavLink to={`/${partnerId}/account`} id="account-link" data-testid="account-link">
								<AccountIcon />
								Account
							</NavLink>
							{!isCalmHealthPartner && (
								<NavLink to={`/${partnerId}/resources`} id="resources-link" data-testid="resources-link">
									<ResourcesIcon />
									Resources
								</NavLink>
							)}
						</nav>
						<NavLeftBottom role="navigation" aria-label="Helpful resources">
							<WorkshopsBanner />
							{partnerId && <SupportNavLink partnerId={partnerId} />}
						</NavLeftBottom>
					</NavLeft>
				)}
				<ContentWrapper $withLeftNav={!isMobile}>
					<NavTopBar
						partnerId={partnerId}
						backLink={backLink}
						hideBreadcrumbs={hideBreadcrumbs}
						hasScrolled={hasScrolled}
						isSalesTrialPartner={isSalesTrialPartner}
						partner={partner}
					/>
					<MainContent navTopOffsetUnits={navTopOffsetUnits}>
						{!hidePageTitle && (
							<Text el="h1" styleAs="h3" weight={FontWeights.Medium}>
								{pageTitle}
							</Text>
						)}
						{children}
					</MainContent>
					{isMobile && <MobileNav partnerId={partnerId} isCalmHealthPartner={isCalmHealthPartner} />}
				</ContentWrapper>
				<AchPendingModal />
			</MainContainer>
		</NavWrapper>
	);
}
