/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	designateTitle: {
		id: 'getStarted.designate.title',
		defaultMessage: 'Designate who is eligible for the Calm benefit.',
		description: 'title for the Get Started Designate section',
	},
	designateSubtitle: {
		id: 'getStarted.designate.subtitle',
		defaultMessage:
			'Create and upload an Excel or .csv file listing the email addresses of the team members you’d like to make Calm available to. Once uploaded, each person listed in the file will be able to sign up for Calm.',
		description: 'subtitle for the Get Started Designate section',
	},
	oldDesignateNote: {
		id: 'getStarted.designate.oldNote',
		defaultMessage: 'Please note, this upload will not trigger an invitation email.',
		description: 'note for the Get Started Designate section',
	},
	designateNote: {
		id: 'getStarted.designate.note',
		defaultMessage:
			'If <benefitslink>Benefit Invite & Reminders</benefitslink> is turned on, uploading the Eligibility File will trigger an email series to all employees in the file 1 day after the file is uploaded.',
		description: 'note for the Get Started Designate section',
	},
	designateButton: {
		id: 'getStarted.designate.button',
		defaultMessage: 'Upload eligibility file',
		description: 'button text for the Get Started Designate section',
	},
	announceSubtitle: {
		id: 'getStarted.announce.subtitleInstruction',
		defaultMessage:
			"It's easy to send a launch announcement! You can copy your unique sign-up link, distribute a QR code to direct employees to your subscribe link, or get started with our copy-and-paste email template.",
		description: 'subtitle for the Get Started Announce section',
	},
	announceEligibility: {
		id: 'getStarted.announce.eligibility',
		defaultMessage:
			"The eligible person's email address so they can verify their eligibility. For example, if you uploaded work emails in the eligibility file, tell your team they will need their work email to sign-up for Calm.",
		description: 'link text for the Get Started Announce section',
	},
});
