import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Card, FormSelect, IconButton, PrimaryButton } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Languages } from '@/types/store/reducers';

import messages from '../TabRouter/sections/CalmAdmin/messages';
import { CircleMinusStyled, IconContainer, LanguageContainer, LanguageRow, Wrapper } from './styles';

interface Props {
	formProps: EditPartnerFormProps;
}

export default function AdditionalLanguages({ formProps }: Props): ReactElement | null {
	const { formatMessage } = useIntl();
	const [languageList, setLanguageList] = useState<string[]>(
		formProps.model.additional_languages as Languages[] | [],
	);

	const [languageOptions, setLanguageOptions] = useState<
		{ value: string; label: string; disabled?: boolean }[]
	>([
		{ value: 'es', label: 'Spanish' },
		{ value: 'fr', label: 'French' },
		// { value: 'de', label: 'German' },
		// { value: 'ja', label: 'Japanese' },
		// { value: 'ko', label: 'Korean' },
		// { value: 'pt', label: 'Portuguese' },
	]);

	const addLanguageRow = (): void => {
		setLanguageList([...languageList, '']);
	};

	const removeLanguageRow = (value: string, index: number): void => {
		// Remove value from formProp
		if (index === 0 && languageList.length === 1) {
			setLanguageList([]);
			formProps.setProperty('additional_languages', []);
		} else {
			const filteredLanguages = languageList.filter((_, langIndex) => index !== langIndex);
			setLanguageList(filteredLanguages);
			formProps.setProperty('additional_languages', filteredLanguages);
		}

		// Re-enable the option from the dropdown
		const adjustedOptions = languageOptions.map(option => {
			if (option.value === value && option.disabled) {
				delete option.disabled;
			}
			return option;
		});
		setLanguageOptions(adjustedOptions);
	};

	const setLanguage = (value: string, index: number): void => {
		// Set the formProp value
		languageList[index] = value;
		setLanguageList(languageList);
		formProps.setProperty('additional_languages', languageList);

		// Disabled the option from the dropdown
		const adjustedOptions = languageOptions.map(option => {
			if (option.value === value) {
				return { ...option, disabled: true };
			}
			delete option.disabled;
			return option;
		});
		setLanguageOptions(adjustedOptions);
	};

	return (
		<Wrapper>
			<Card>
				<CellTitle showAdminFlag>{formatMessage(messages.additionalLanguagesTitle)}</CellTitle>
				<Subtitle noBottomMargin>{formatMessage(messages.additionalLanguagesSubtitle)}</Subtitle>
				<br />
				<LanguageRow>
					<LanguageContainer isDisabled>
						<FormSelect
							name="english"
							value="English"
							onChange={() => {}}
							disabled
							label=""
							aria-label="English"
							options={[{ value: 'en', label: 'English' }]}
							data-testid="account-language-selector-english"
						/>
					</LanguageContainer>
					<IconContainer isDisabled>
						<IconButton aria-label="Remove user" Icon={CircleMinusStyled} />
					</IconContainer>
				</LanguageRow>
				{languageList &&
					languageList.map((lang: string, index: number) => {
						return (
							<LanguageRow data-testid={`account-select-language-row_${index}`} key={index}>
								<LanguageContainer>
									<FormSelect
										{...formProps.bindWithErrorProps('additional_languages', 'text')}
										name={lang}
										value={lang}
										onChange={e => setLanguage(e.target.value, index)}
										label=""
										aria-label="Choose a language"
										placeholder="Choose a language"
										options={languageOptions}
										data-testid={`account-language-selector_${index}`}
									/>
								</LanguageContainer>
								<IconContainer>
									<IconButton
										data-testid={`account-remove-language-button_${index}`}
										onPress={() => removeLanguageRow(lang, index)}
										aria-label="Remove user"
										Icon={CircleMinusStyled}
									/>
								</IconContainer>
							</LanguageRow>
						);
					})}
				{languageList && languageList.length < languageOptions.length && (
					<PrimaryButton onPress={addLanguageRow}>
						{formatMessage(messages.additionalLanguageButton)}
					</PrimaryButton>
				)}
			</Card>
		</Wrapper>
	);
}
