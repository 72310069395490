import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, maxWidth, Text, units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

export const Container = styled.div`
	position: absolute;
	display: flex;
	top: -${units(6.4)};
	right: 0;
	z-index: 1;
	align-items: center;

	${maxWidth('desktop')} {
		position: unset;
		flex-wrap: wrap;
		justify-content: end;
		gap: ${units(1)};
		// margin-bottom: ${units(2)};
	}
`;

export const DateRange = styled(Text).attrs({
	size: FontSizes.sm,
	weight: FontWeights.Medium,
	color: 'buttonSecondaryText',
})`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(85, 91, 228, 0.1);
	border-radius: ${units(0.8)};
	padding: ${units(0.2, 1)};
	cursor: pointer;
`;

export const InfoIcon = styled(InfoOutline)`
	path {
		fill: ${palette('blue3')};
	}
	width: ${units(2)};
	height: ${units(2)};
	position: relative;
	vertical-align: baseline;
`;
