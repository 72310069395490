export const ALLOWED_COUNTRIES = ['US', 'CA', 'GB'];
export const ALLOWED_COUNTRIES_LIST = [
	{ value: 'US', label: 'US' },
	{ value: 'CA', label: 'CA' },
	{ value: 'GB', label: 'GB' },
];

// temporary map to handle existing sponsorships with 3 char country codes
export const PREV_ALLOWED_COUNTRIES_MAP = new Map<string, string>([
	['USA', 'US'],
	['GBR', 'GB'],
	['CAN', 'CA'],
]);
