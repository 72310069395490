import { ReactElement, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';

import logoUploadMessages from './LogoUploader/messages';
import { Subtitle } from './LogoUploader/styles';
import UnifiedLogoUploader from './LogoUploader/UnifiedLogoUploader';
import messages from './messages';
import { Anchor, ContainerCard } from './styles';

function PartnerBranding({ formProps }: { formProps: EditPartnerFormProps }): ReactElement {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = [actions.UPDATE];
	const { hash } = useLocation();
	const ref = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		if (hash === '#upload-logo') {
			ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [hash, ref]);

	return (
		<ContainerCard>
			<Anchor ref={ref} />
			<CellTitle>{formatMessage(messages.brandingCardTitle)}</CellTitle>
			<Subtitle>{formatMessage(logoUploadMessages.customBrandingSubtitle)}</Subtitle>
			<UnifiedLogoUploader
				formProps={formProps}
				disabled={!hasValidPermissions('logo_url', requiredPermissions)}
				fileFieldName="uploadedLogo"
				urlFieldName="logoUrl"
				onDelete={() => {
					formProps.setProperty('dtcLogoUrl', '');
					formProps.setProperty('uploadedDtcLogo', []);
				}}
				analyticsEvent="Partner : Logo Upload : Selected"
			/>
		</ContainerCard>
	);
}

export default PartnerBranding;
