import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import FilterButton from '@/components/ui/FilterButton';
import DownloadIcon from 'icons/download.svg';

import messages from './messages';
import { DownloadButtonProps } from './types';

const DownloadButton: React.FC<DownloadButtonProps> = ({ isDownloadLoading, exportWholePageToPDF }) => {
	const { formatMessage } = useIntl();

	return (
		<FilterButton
			Icon={isDownloadLoading ? () => <Loader color="gray5" /> : DownloadIcon}
			onPress={exportWholePageToPDF}
			data-testid="download-report-button"
		>
			{formatMessage(messages.downloadButtonText)}
		</FilterButton>
	);
};

export default DownloadButton;
