import styled from 'styled-components';
import { palette } from 'styled-tools';

import { maxWidth, PaletteColor, Text, TextElements, units } from '@calm-web/design-system';

export const OuterLegend = styled.div`
	display: flex;
	margin-top: ${units(2)};

	${maxWidth('desktop')} {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const InnerLegend = styled.div`
	display: flex;
	align-items: center;
`;

export const ColorLabel = styled.div<{ $color?: string; $paletteColor?: PaletteColor }>`
	width: 16px;
	height: 4px;
	background: ${props => props.$paletteColor && palette(props.$paletteColor)};
	background: ${props => props.$color};
	margin-left: 16px;
	margin-right: 8px;
`;

export const TextLabel = styled(Text).attrs({ el: TextElements.Span, color: 'blackAlpha60' })`
	font-size: 13px;
	line-height: 133.3%;
`;
