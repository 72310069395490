import { DateTime } from 'luxon';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { SegmentDetails, useSegmentDetails } from '@/hooks/api/reporting/useSegmentDetails';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerSinglePathway, usePathways, usePathwayTimeline } from '@/hooks/api/usePathways';
import { Pack, PacksFeedResponse } from '@/types/content';
import { IntegrationType } from '@/types/store/reducers';
import { CalmError } from '@/utils/apiRequest/errors';
import { isEditable } from '@/utils/helpers';

export type ActiveStep = 'selection' | 'audience' | 'schedule' | 'review';
export type CompletedSteps = { [k in ActiveStep]: boolean };
export type SelectedCohorts = {
	segment_1_name: string | null;
	segment_2_name: string | null;
	segment_3_name: string | null;
	segment_1_values: string[] | null;
	segment_2_values: string[] | null;
	segment_3_values: string[] | null;
} | null;

interface PathwaysContextState {
	partnerId: string;
	partnerName: string;
	isSSO: boolean;
	subscriptionURL?: string;
	setActiveStep: Dispatch<SetStateAction<ActiveStep | undefined>>;
	partnerPlan: string;
	activeStep: ActiveStep | undefined;
	selectedPathway: Pack | undefined;
	pathways: PacksFeedResponse | undefined;
	arePathwaysLoading: boolean;
	completedSteps: CompletedSteps;
	emailSettings: string;
	announcementDate: Date | null;
	reminderDate: Date | null;
	startDate: Date | null;
	endDate: Date | null;
	segmentDetails: SegmentDetails | undefined;
	areSegmentsLoading: boolean;
	hasSegmentsError?: Error | CalmError;
	refetchSegments: () => void;
	segment1Cohorts: string[] | null;
	segment2Cohorts: string[] | null;
	segment3Cohorts: string[] | null;
	hasNoCohortsSelected: boolean;
	setHasNoCohortsSelected: Dispatch<SetStateAction<boolean>>;
	cohortCount: number;
	setCohortCount: Dispatch<SetStateAction<number>>;
	timelineData: Date[] | undefined;
	closestEligibleMonday: Date | null | undefined;
	pathwayId: string | undefined;
	isEdit: boolean;
	isReadOnly: boolean;
	nextTabRouting: string;
	serverTimeZone: string;
	defaultAnnouncementDate?: Date;
	defaultReminderDate?: Date;
	redemptionUrl: string;
	shouldUsePathwaysV2: boolean | undefined;
}

type PathwaysContextProps = PathwaysContextState & {
	setActiveStep: Dispatch<SetStateAction<ActiveStep | undefined>>;
	setCompletedSteps: Dispatch<SetStateAction<CompletedSteps>>;
	setEmailSettings: Dispatch<SetStateAction<string>>;
	setAnnouncementDate: Dispatch<SetStateAction<Date | null>>;
	setReminderDate: Dispatch<SetStateAction<Date | null>>;
	setStartDate: Dispatch<SetStateAction<Date | null>>;
	setEndDate: Dispatch<SetStateAction<Date | null>>;
	setSegment1Cohorts: Dispatch<SetStateAction<string[] | null>>;
	setSegment2Cohorts: Dispatch<SetStateAction<string[] | null>>;
	setSegment3Cohorts: Dispatch<SetStateAction<string[] | null>>;
	setTimelineData: Dispatch<SetStateAction<Date[] | undefined>>;
	setSelectedPathway: (pack: Pack) => void;
	savePathway: () => void;
	clearStoredPathway: () => void;
	setEditValues: (pathwayId: string, isEdit: boolean) => void;
};

const defaultCompletedSteps: CompletedSteps = {
	selection: false,
	audience: false,
	schedule: false,
	review: false,
};

const initialPathwaysState = {
	partnerId: '',
	partnerName: '',
	isSSO: false,
	redemptionUrl: '',
	subscriptionURL: undefined,
	partnerPlan: '',
	setActiveStep: () => {},
	activeStep: 'selection' as ActiveStep,
	setSelectedPathway: () => {},
	selectedPathway: undefined,
	clearStoredPathway: () => {},
	savePathway: () => {},
	pathways: undefined,
	arePathwaysLoading: false,
	closestEligibleMonday: null,
	completedSteps: defaultCompletedSteps,
	setCompletedSteps: () => {},
	emailSettings: 'on',
	setEmailSettings: () => {},
	announcementDate: null,
	setAnnouncementDate: () => {},
	reminderDate: null,
	setReminderDate: () => {},
	startDate: null,
	setStartDate: () => {},
	endDate: null,
	setEndDate: () => {},
	segmentDetails: undefined,
	areSegmentsLoading: false,
	hasSegmentsError: undefined,
	refetchSegments: () => {},
	segment1Cohorts: null,
	segment2Cohorts: null,
	segment3Cohorts: null,
	setSegment1Cohorts: () => {},
	setSegment2Cohorts: () => {},
	setSegment3Cohorts: () => {},
	hasNoCohortsSelected: false,
	setHasNoCohortsSelected: () => {},
	cohortCount: 0,
	setCohortCount: () => {},
	timelineData: undefined,
	setTimelineData: () => {},
	setEditValues: () => {},
	pathwayId: undefined,
	isEdit: false,
	isReadOnly: true,
	nextTabRouting: 'create',
	serverTimeZone: 'Etc/GMT-14',
	shouldUsePathwaysV2: false,
};

export const PathwaysContext = createContext<PathwaysContextProps>(initialPathwaysState);
export const DEFAULTS = {
	MIN_DAYS_BEFORE_START: {
		ANNOUNCEMENT: 2,
		REMINDER: 1,
	},
	MIN_DAYS_SKIP_WEEKEND: 4,
	TIME_FOR_EMAILS: 9,
};

const getDateValue = (stringDate?: string | null): Date | null => {
	return stringDate ? new Date(stringDate) : null;
};

const parseRawSavedPathwaysData = (rawSavedPathwaysData: string): PathwaysContextProps => {
	const parsed = JSON.parse(rawSavedPathwaysData);
	const dateKeys = [
		'announcementDate',
		'reminderDate',
		'startDate',
		'endDate',
		'closestEligibleMonday',
	] as const;
	dateKeys.forEach(dateKey => {
		parsed[dateKey] = getDateValue(parsed[dateKey]);
	});
	parsed.timelineData = parsed.timelineData?.map(getDateValue);
	return parsed;
};

interface ContextProviderProps {
	testData?: { emailSettings?: string; isEdit?: boolean; isReadOnly?: boolean };
	children: ReactNode | undefined;
}

const PathwaysContextProvider = ({ testData, children }: ContextProviderProps): JSX.Element => {
	const { data: flagValues } = useFeatureFlags('b2b-pathways-v2');
	const shouldUsePathwaysV2 = flagValues && flagValues['b2b-pathways-v2'] === true;
	const partner = useDefinedPartner();
	const { data: integrationType } = useIntegrationType(partner.id);
	const rawSavedPathwaysData = localStorage.getItem('pathwayData');
	const savedPathwaysData =
		rawSavedPathwaysData !== null ? parseRawSavedPathwaysData(rawSavedPathwaysData) : initialPathwaysState;
	const savedEmailSettings =
		savedPathwaysData && !savedPathwaysData.announcementDate && !savedPathwaysData.reminderDate
			? 'off'
			: 'on';
	const earliestStartDate = DateTime.now()
		.plus({ days: DEFAULTS.MIN_DAYS_BEFORE_START.ANNOUNCEMENT })
		.toJSDate();
	const closestMondayFromNow = DateTime.fromJSDate(earliestStartDate).startOf('week').toJSDate();
	const secondClosestMondayFromNow = DateTime.fromJSDate(closestMondayFromNow).plus({ weeks: 1 }).toJSDate();
	const closestEligibleMonday =
		closestMondayFromNow >= earliestStartDate ? closestMondayFromNow : secondClosestMondayFromNow;
	const isClosestEligibleMondayWithinMinWeekendDays =
		DateTime.fromJSDate(closestEligibleMonday).minus({
			days: DEFAULTS.MIN_DAYS_SKIP_WEEKEND,
		}) <= DateTime.now();
	const getAdjustedDate = (days: number): Date =>
		DateTime.fromJSDate(closestEligibleMonday)
			.minus({ days })
			.set({ hour: DEFAULTS.TIME_FOR_EMAILS })
			.toJSDate();
	const defaultAnnouncementDate = getAdjustedDate(
		isClosestEligibleMondayWithinMinWeekendDays
			? DEFAULTS.MIN_DAYS_BEFORE_START.ANNOUNCEMENT
			: DEFAULTS.MIN_DAYS_SKIP_WEEKEND,
	);
	const defaultReminderDate = getAdjustedDate(
		isClosestEligibleMondayWithinMinWeekendDays
			? DEFAULTS.MIN_DAYS_BEFORE_START.ANNOUNCEMENT - 1
			: DEFAULTS.MIN_DAYS_SKIP_WEEKEND - 1,
	);
	const [emailSettings, setEmailSettings] = useState<string>(
		testData?.emailSettings ? testData.emailSettings : savedEmailSettings || 'on',
	);
	const [announcementDate, setAnnouncementDate] = useState<Date | null>(
		savedPathwaysData?.announcementDate || defaultAnnouncementDate,
	);
	const [reminderDate, setReminderDate] = useState<Date | null>(
		savedPathwaysData?.reminderDate || defaultReminderDate,
	);
	const [startDate, setStartDate] = useState<Date | null>(
		savedPathwaysData?.startDate || closestEligibleMonday,
	);
	const [endDate, setEndDate] = useState<Date | null>(savedPathwaysData?.endDate);
	const [timelineData, setTimelineData] = useState<Date[] | undefined>(savedPathwaysData?.timelineData);
	const [pathwayId, setPathwayId] = useState<string | undefined>();
	const { data: pathways, loading: arePathwaysLoading } = usePathways();
	const [isEdit, setIsEdit] = useState<boolean>(testData?.isEdit !== undefined ? testData.isEdit : false);
	const [isReadOnly, setIsReadOnly] = useState<boolean>(
		testData?.isReadOnly !== undefined ? testData.isReadOnly : isEdit,
	);
	const [nextTabRouting, setNextTabRouting] = useState<string>('create');

	const [cohortCount, setCohortCount] = useState<number>(savedPathwaysData?.cohortCount || 0);

	const [hasNoCohortsSelected, setHasNoCohortsSelected] = useState<boolean>(
		savedPathwaysData?.hasNoCohortsSelected || false,
	);

	const { data: singlePathway } = usePartnerSinglePathway(pathwayId, partner.id);
	const [activeStep, setActiveStep] = useState<ActiveStep | undefined>(
		savedPathwaysData?.activeStep || 'selection',
	);
	const [selectedPathway, setSelectedPathway] = useState<Pack | undefined>(
		savedPathwaysData?.selectedPathway,
	);
	const [completedSteps, setCompletedSteps] = useState<CompletedSteps>(
		savedPathwaysData?.completedSteps || defaultCompletedSteps,
	);
	const { data: pathwayTimeline } = usePathwayTimeline(selectedPathway?.pack_class, startDate);
	const sourceFromEf = partner.supports_eligibility_list;
	const {
		loading: areSegmentsLoading,
		data: segmentDetails,
		error: hasSegmentsError,
		refetch: refetchSegments,
	} = useSegmentDetails(partner.id, sourceFromEf);
	const { segment_values } = segmentDetails || {};
	const { segment_1_values, segment_2_values, segment_3_values } = segment_values || {};

	const [segment1Cohorts, setSegment1Cohorts] = useState<string[] | null>(
		savedPathwaysData?.segment1Cohorts
			? savedPathwaysData?.segment1Cohorts
			: segment_1_values !== undefined
			? segment_1_values
			: null,
	);

	const [segment2Cohorts, setSegment2Cohorts] = useState<string[] | null>(
		savedPathwaysData?.segment2Cohorts
			? savedPathwaysData?.segment2Cohorts
			: segment_2_values !== undefined
			? segment_2_values
			: null,
	);

	const [segment3Cohorts, setSegment3Cohorts] = useState<string[] | null>(
		savedPathwaysData?.segment3Cohorts
			? savedPathwaysData?.segment3Cohorts
			: segment_3_values !== undefined
			? segment_3_values
			: null,
	);

	const pathwayData = {
		shouldUsePathwaysV2,
		activeStep,
		selectedPathway,
		pathways,
		completedSteps,
		emailSettings,
		announcementDate,
		reminderDate,
		startDate,
		endDate,
		timelineData,
		segment1Cohorts,
		segment2Cohorts,
		segment3Cohorts,
		cohortCount,
		hasNoCohortsSelected,
	};

	const clearStoredPathway = (): void => {
		localStorage.removeItem('pathwayData');
		setCompletedSteps(defaultCompletedSteps);
		setEmailSettings('on');
		setStartDate(closestEligibleMonday);
		setEndDate(null);
		setTimelineData(undefined);
		setReminderDate(defaultReminderDate);
		setAnnouncementDate(defaultAnnouncementDate);
		setSegment1Cohorts(null);
		setSegment2Cohorts(null);
		setSegment3Cohorts(null);
		setHasNoCohortsSelected(false);
		setActiveStep('selection');
		setIsEdit(false);
		setIsReadOnly(false);
		setNextTabRouting('create');
	};

	const savePathway = (): void => {
		clearStoredPathway();
	};

	const customSetSelectedPathway = (pathway: Pack): void => {
		if (!isEdit) {
			const updatedCompletedSteps = {
				...savedPathwaysData.completedSteps,
				audience: false,
				schedule: false,
				review: false,
			};
			// The amount of setState calls here definitely points to using a reducer, but I'm intentionally waiting on that since the possibility of changing this to a form is around the corner.
			setCompletedSteps(updatedCompletedSteps);
			setStartDate(closestEligibleMonday);
			setEndDate(null);
			setTimelineData(undefined);
			setSegment1Cohorts(null);
			setSegment2Cohorts(null);
			setSegment3Cohorts(null);
			setReminderDate(defaultReminderDate);
			setAnnouncementDate(defaultAnnouncementDate);
		}

		setSelectedPathway(pathway);
	};

	const setEditValues = (pathwayId: string, isEdit: boolean): void => {
		setPathwayId(pathwayId);
		setIsEdit(isEdit);
		const shouldShowEmails = !shouldUsePathwaysV2 || (shouldUsePathwaysV2 && emailSettings === 'on');
		if (isEdit && startDate) {
			setIsReadOnly(!isEditable(shouldShowEmails, startDate, announcementDate || undefined));
		}
		if (isEdit) setNextTabRouting(`edit/${pathwayId}`);
	};

	useEffect(() => {
		if (singlePathway) {
			const { announce_date, remind_date, start_date, pack_class, segments, total_users } = singlePathway;
			const editingPack = pathways?.feed.packs.find(pack => pack.pack_class === pack_class);
			setSelectedPathway(editingPack);

			// We need to take the translated strings from db, drop the timezone, and format back to Date
			const announcementParsed = announce_date;
			const reminderParsed = remind_date;
			const newAnnouncementDate = announcementParsed ? new Date(announcementParsed.split('.')[0]) : null;
			const newReminderDate = reminderParsed ? new Date(reminderParsed.split('.')[0]) : null;

			if (!announce_date && !remind_date) {
				setEmailSettings('off');
			} else {
				setEmailSettings('on');
			}

			setStartDate(new Date(`${start_date}T00:00:00`));
			setAnnouncementDate(newAnnouncementDate);
			setReminderDate(newReminderDate);
			setSegment1Cohorts(segments?.segment_1_values !== undefined ? segments?.segment_1_values : null);
			setSegment2Cohorts(segments?.segment_2_values !== undefined ? segments?.segment_2_values : null);
			setSegment3Cohorts(segments?.segment_3_values !== undefined ? segments?.segment_3_values : null);
			setCohortCount(total_users);
		}
	}, [singlePathway, pathways?.feed.packs]);

	useEffect(() => {
		if (pathwayTimeline) {
			setTimelineData(pathwayTimeline);
			const lastDate = pathwayTimeline?.slice(-1);
			if (lastDate) setEndDate(lastDate[0]);
		}
	}, [pathwayTimeline, setTimelineData, setEndDate]);

	const pathwayContextValues = {
		clearStoredPathway,
		partnerId: partner.id,
		partnerName: partner.name,
		isSSO: integrationType === IntegrationType.SSO,
		subscriptionURL: partner.web_renew_url,
		redemptionUrl: partner.redemption_url,
		partnerPlan: partner.vouched_plan_sku,
		closestEligibleMonday,
		setActiveStep,
		setSelectedPathway: customSetSelectedPathway,
		savePathway,
		arePathwaysLoading,
		setCompletedSteps,
		setEmailSettings,
		setAnnouncementDate,
		setReminderDate,
		setStartDate,
		setEndDate,
		segmentDetails,
		areSegmentsLoading,
		hasSegmentsError,
		refetchSegments,
		setSegment1Cohorts,
		setSegment2Cohorts,
		setSegment3Cohorts,
		setHasNoCohortsSelected,
		setCohortCount,
		setTimelineData,
		setEditValues,
		pathwayId,
		isEdit,
		isReadOnly,
		nextTabRouting,
		serverTimeZone: 'Etc/GMT-14',
		defaultAnnouncementDate,
		defaultReminderDate,
		...pathwayData,
	};

	useEffect(() => {
		localStorage.setItem('pathwayData', JSON.stringify(pathwayData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activeStep,
		selectedPathway,
		pathways,
		completedSteps,
		emailSettings,
		announcementDate,
		reminderDate,
		startDate,
		endDate,
		segment1Cohorts,
		segment2Cohorts,
		segment3Cohorts,
		cohortCount,
		hasNoCohortsSelected,
		timelineData,
		shouldUsePathwaysV2,
	]);

	return <PathwaysContext.Provider value={pathwayContextValues}>{children}</PathwaysContext.Provider>;
};

export default PathwaysContextProvider;
