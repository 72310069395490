/* eslint-disable max-len */
// Third Party

import { defineMessages } from 'react-intl';

export default defineMessages({
	scheduleHeader: {
		id: 'emailPreferences.manualSurvey.scheduleHeader',
		defaultMessage: 'Schedule Feedback Survey',
		description: 'Header text defining the manual survey section',
	},
	scheduleDescription: {
		id: 'emailPreferences.manualSurvey.scheduleDescription',
		defaultMessage: 'You can schedule automatic feedback surveys to your covered lives.',
		description: 'Description text describing what the section does',
	},
	seeASampleTemplate: {
		id: 'emailPreferences.manualSurvey.seeASampleTemplate',
		defaultMessage: 'See a sample template',
		description: 'Button text for seeing a feedback survey email template',
	},
	pastDateHeader: {
		id: 'emailPreferences.manualSurvey.pastDateHeader',
		defaultMessage: 'Past',
		description: 'Header for all past dates listed',
	},
	futureDateHeader: {
		id: 'emailPreferences.manualSurvey.futureDateHeader',
		defaultMessage: 'Upcoming',
		description: 'Header for the next upcoming date listed',
	},
	scheduleButton: {
		id: 'emailPreferences.manualSurvey.scheduleButton',
		defaultMessage: 'Schedule',
		description: 'Button text to schedule a survey email',
	},
	emailTemplate: {
		id: 'emailPreferences.manualSurvey.emailTemplate',
		defaultMessage: 'Email Template',
		description: 'Modal header text for copyable email template',
	},
	saveText: {
		id: 'emailPreferences.manualSurvey.saveButton',
		defaultMessage: 'Save Survey Configuration',
		description: 'Save button text',
	},
});
