import Image from 'next/legacy/image';
import { FC } from 'react';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { StyledLoader } from '@/components/pages/EapPromotion/styles';
import { GetDtcImageResponse } from '@/hooks/api/useUnifiedBrandingUpload';
import { srcLoader } from '@/utils/ui/imageLoader';

import {
	Content,
	EapSubTitle,
	EapTitle,
	EllipseGroup,
	EllipseIcon,
	HeartIcon,
	Hero,
	ImageWrapper,
	LeftChevron,
	PhoneOutline,
	SubHeading,
	Subtitle,
	TitleRow,
	Wrapper,
} from './styles';

interface Props {
	imageUrl: string;
	getDtcImage: () => GetDtcImageResponse;
	dtcLogoUrl?: string;
}

const InAppBrandingPreview: FC<Props> = ({ imageUrl, getDtcImage, dtcLogoUrl }) => {
	const { newDtcImageUrl, defaultImageUrl } = getDtcImage();

	return (
		<Wrapper>
			<PhoneOutline />
			<LeftChevron />
			<EllipseGroup>
				<EllipseIcon />
				<EllipseIcon />
				<EllipseIcon />
			</EllipseGroup>
			<Hero>
				<StyledLoader color="gray5" />
				<Image
					src={newDtcImageUrl || dtcLogoUrl || defaultImageUrl}
					layout="fill"
					objectFit="cover"
					alt="Your employer branded for work hero image"
					loader={srcLoader}
				/>
			</Hero>
			<Content>
				<TitleRow>
					<Text el="h2" size={FontSizes.base} weight={FontWeights.Medium} color="white" noMargin>
						Work Life
					</Text>
					<HeartIcon />
				</TitleRow>
				<Subtitle el="span" size={FontSizes.sm} color="white">
					Wellness tools for your workday
				</Subtitle>
				<SubHeading el="h3" size={FontSizes.base} weight={FontWeights.Medium} color="white">
					Your Employee Benefits
				</SubHeading>
				<ImageWrapper>
					<StyledLoader color="gray5" />
					<Image src={imageUrl} alt="EAP image theme" width={201} height={134} loader={srcLoader} />
				</ImageWrapper>

				<EapTitle el="p" color="white" noMargin weight={FontWeights.Medium}>
					Explore all of your employee benefits
				</EapTitle>
				<EapSubTitle el="p" color="white" noMargin>
					Courtesy of your HR team
				</EapSubTitle>
			</Content>
		</Wrapper>
	);
};

export default InAppBrandingPreview;
