import { HiddenSelect, useSelect } from '@react-aria/select';
import { Item } from '@react-stately/collections';
import { useSelectState } from '@react-stately/select';
import { Key, useRef } from 'react';

import FilterButton from '@/components/ui/FilterButton';
import ListBox from '@/components/ui/ListBox';
import Popover from '@/components/ui/Popover';

import { Container } from './styles';
import { DropDownProps, Option, SelectProps } from './types';

const Select: React.FC<SelectProps> = props => {
	// Create state based on the incoming props
	const state = useSelectState(props);

	const ref = useRef(null);
	// Get props for child elements from useSelect
	const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, ref);

	return (
		<Container>
			<div {...labelProps}>{props.label}</div>
			<HiddenSelect state={state} triggerRef={ref} label={props.label} name={props.name} />
			<FilterButton
				tooltipArrowTranslation={201}
				tooltipText={props.tooltipText}
				Icon={props.Icon}
				buttonRef={ref}
				{...triggerProps}
			>
				<span {...valueProps}>
					{state.selectedItem ? `Showing: ${state.selectedItem.rendered}` : 'Select an option'}
				</span>
			</FilterButton>
			{state.isOpen && (
				<Popover isOpen={state.isOpen} onClose={state.close}>
					<ListBox {...menuProps} state={state} />
				</Popover>
			)}
		</Container>
	);
};

const DropDown: React.FC<DropDownProps> = ({
	options,
	selected,
	setSelected,
	Icon,
	ariaLabel,
	disabledKeys,
	tooltipText,
}) => {
	return (
		<Select
			aria-label={ariaLabel}
			items={options}
			selectedKey={selected}
			onSelectionChange={(selected: Key) => setSelected(selected)}
			Icon={Icon}
			disabledKeys={disabledKeys}
			tooltipText={tooltipText}
		>
			{(item: Option) => <Item key={item.name}>{item.name}</Item>}
		</Select>
	);
};

export default DropDown;
