import { FC, ReactElement, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useSitewideBanner } from '@/hooks/store';
import { SitewideBannerCodes } from '@/types/store/reducers';

import { Banner, BannerTextButton, InfoIcon } from './styles';

function SsoConfigIncompleteBanner({
	partnerId,
	dataTestId,
}: {
	partnerId: string;
	dataTestId?: string;
}): ReactElement {
	const history = useHistory();
	const onClickOpenConfigurationForm = (): void => {
		history.push({
			pathname: `/${partnerId}/account/sso`,
		});
	};
	return (
		<div data-testid={dataTestId}>
			This partner’s SSO Configuration form is not complete. Complete it from the Account Details page or{' '}
			<BannerTextButton onClick={onClickOpenConfigurationForm} data-testid="open-configuration-form-link">
				click here to open the form.
			</BannerTextButton>
		</div>
	);
}

function SitewideBanner({
	partnerId,
	fullWidth,
}: {
	partnerId?: string;
	fullWidth: boolean;
}): ReturnType<FC> {
	const { bannerCode, bannerInfo } = useSitewideBanner();

	const content = useMemo(() => {
		if (partnerId && bannerCode === SitewideBannerCodes.SSO_CONFIG_INCOMPLETE) {
			return <SsoConfigIncompleteBanner dataTestId="sso-incomplete-banner" partnerId={partnerId} />;
		}
		if (bannerCode === SitewideBannerCodes.ELIGIBILITY_FILE_PROCESSING) {
			return (
				<div data-testid="eligibility-file-processing-banner">
					Eligibility file is being processed. Check back later for the status of your upload.
				</div>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerCode, bannerInfo, partnerId]);

	if (!content) {
		return null;
	}

	return (
		<Banner fullWidth={fullWidth}>
			<InfoIcon />
			{content}
		</Banner>
	);
}

export default SitewideBanner;
