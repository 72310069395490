import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Card, fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const ScreeningCard = styled(Card)`
	${fontWeight(FontWeights.Medium)};
	line-height: 18px;
`;

export const CheckboxDetails = styled.div`
	${fontSize(FontSizes.sm)};
	margin-left: ${units(3)};
	margin-bottom: ${units(1)};
	color: ${palette('gray5')};
	display: flex;
	flex-direction: column;
`;
