import { ReactElement } from 'react';

import { Card } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import Subtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import DatePicker from '@/components/ui/DatePicker';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';
import { iso8601Date, optionalDateFromIso8601DateForDatePicker } from '@/utils/helpers';

import { DateColumn, TwoColumnWrapper } from '../PlanDetails/styles';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit?: boolean;
	partner?: Partner;
}

export default function MarketingDates({ formProps }: Props): ReactElement {
	const userAcquisitionSendDate = optionalDateFromIso8601DateForDatePicker(
		stringFromModelValue(formProps.model.userAcquisitionSendDate),
	);
	const endOfServiceSendDate = optionalDateFromIso8601DateForDatePicker(
		stringFromModelValue(formProps.model.endOfServiceSendDate),
	);

	const handleDateChange =
		(name: 'userAcquisitionSendDate' | 'endOfServiceSendDate'): ((d: Date | null) => void) =>
		(d: Date | null) => {
			if (!d) {
				return formProps.setProperty(name, '');
			}
			return formProps.setProperty(name, iso8601Date(d));
		};

	return (
		<>
			<Card>
				<CellTitle showAdminFlag>Marketing Dates</CellTitle>
				<TwoColumnWrapper>
					<DateColumn>
						<div>
							<CellTitle id="ua-send-date-label">User Acquisition Send Date</CellTitle>
							<Subtitle>
								This is the date the partner will start sending user acquisition communications.
							</Subtitle>
						</div>
						<DatePicker
							startDate={userAcquisitionSendDate}
							onChange={handleDateChange('userAcquisitionSendDate')}
							dataTestId="ua-send-date-picker"
							labelledBy="ua-send-date-label"
						/>
					</DateColumn>
					<DateColumn>
						<div>
							<CellTitle id="end-service-date-label">End of Service Send Date</CellTitle>
							<Subtitle>
								This is the date the partner will start sending end of service communications.
							</Subtitle>
						</div>
						<DatePicker
							startDate={endOfServiceSendDate}
							onChange={handleDateChange('endOfServiceSendDate')}
							dataTestId="end-service-date-picker"
							labelledBy="end-service-date-label"
						/>
					</DateColumn>
				</TwoColumnWrapper>
			</Card>
		</>
	);
}
