/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	shareIconsCopyLinkText: {
		id: 'shareIcons.copyLinkText',
		defaultMessage: 'Copy invite link',
		description: 'The text: Copy link',
	},
});
