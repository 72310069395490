import copy from 'copy-to-clipboard';
import { FC, FormEvent, ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { FontSizes, FormInput, Loader, Modal, SecondaryButton, TextButton } from '@calm-web/design-system';
import useForm, {
	fileFromModelValue,
	ModelValue,
	OnChange,
	stringFromModelValue,
	validation,
} from '@calm-web/use-form';

import CellContainer from '@/components/ui/CellContainer';
import ChecklistItem from '@/components/ui/Checklist';
import { StyledChecklist } from '@/components/ui/Checklist/styles';
import FileUpload from '@/components/ui/FileUpload';
import { EnvConfig } from '@/env_config';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { updatePartnerCache, usePartner } from '@/hooks/api/usePartner';
import {
	SSOConfig,
	SSOConfigParams,
	useDownloadSamlMetadata,
	useSaml,
	useUpdateSaml,
} from '@/hooks/api/useSaml';
import { setBannerMessage } from '@/store/actions/setBannerMessage';
import { Partial } from '@/types/partial';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { getErrorMessage, isCalmError, isErrorWithMessage } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';
import DownloadIcon from 'icons/download.svg';

import messages from './messages';
import {
	CloseModalButton,
	ContentContainer,
	DescriptionBox,
	DescriptionHeader,
	DownloadLinkContainer,
	List,
	LoaderContainer,
	ModalContent,
	ModalLabel,
	ModalRow,
	PrimaryLink,
	SegmentColumn,
	SegmentRow,
	SegmentTitle,
	StyledTextArea,
	SubmitButton,
	Title,
	UploadButtonContainer,
} from './styles';
import { SSOSetupFormProps } from './types';

function valuesDontMatch(
	currentSegment?: ModelValue,
	segmentOne?: ModelValue,
	segmentTwo?: ModelValue,
): boolean {
	return Boolean(currentSegment === '' || (currentSegment !== segmentOne && currentSegment !== segmentTwo));
}

function validateCorrespondingSegement(
	currentSegment?: ModelValue,
	correspondingSegment?: ModelValue,
): boolean {
	const currentDisplay = stringFromModelValue(currentSegment);
	const currentName = stringFromModelValue(correspondingSegment);
	return (currentDisplay === '' && currentName === '') || (currentDisplay !== '' && currentName !== '');
}

function validateSegments(
	currentSegment?: ModelValue,
	segmentOne?: ModelValue,
	segmentTwo?: ModelValue,
	correspondingSegment?: ModelValue,
): boolean {
	return (
		valuesDontMatch(currentSegment, segmentOne, segmentTwo) &&
		validateCorrespondingSegement(currentSegment, correspondingSegment)
	);
}

function Description(): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<DescriptionBox>
			<DescriptionHeader>{formatMessage(messages.descriptionHeader)}</DescriptionHeader>
			<div>{formatMessage(messages.descriptionBody)}</div>
		</DescriptionBox>
	);
}

function Form({ partner, initialConfig }: { partner: Partner; initialConfig: SSOConfig }): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [updateSaml, { loading }] = useUpdateSaml(partner.id);
	const [downloadMetadata, { loading: isDownloading }] = useDownloadSamlMetadata(partner.id);
	const relayStateUrl = EnvConfig.ssoRelayStateUrl;
	const dispatch = useDispatch();

	const SEGMENT_NAME_ERROR = 'Please enter valid segments';

	const formProps: SSOSetupFormProps = useForm('ssoConfig', {
		initialModel: {
			authenticationUrl: partner.web_renew_url ?? '',
			entityId: initialConfig.entity_id,
			ssoUrl: initialConfig.sso_url,
			certificate: initialConfig.signature_cert ?? '',
			certificateFile: '',
			segmentOneName: initialConfig.segments?.segment_1_name ?? '',
			segmentTwoName: initialConfig.segments?.segment_2_name ?? '',
			segmentThreeName: initialConfig.segments?.segment_3_name ?? '',
			segmentOneDisplayName: initialConfig.segments?.segment_1_display_name ?? '',
			segmentTwoDisplayName: initialConfig.segments?.segment_2_display_name ?? '',
			segmentThreeDisplayName: initialConfig.segments?.segment_3_display_name ?? '',
		},
		validation: {
			ssoUrl: validation.validateOrFail([
				{
					rules: [validation.validURL],
					errorResult: 'Please enter a valid URL',
				},
			]),
			entityId: validation.validateOrFail([
				{
					rules: [validation.minLength(1)],
					errorResult: 'Please enter an IdP Entity Id',
				},
			]),
			authenticationUrl: validation.validateOrFail([
				{
					rules: [validation.validURL],
					errorResult: 'Please enter a valid URL',
				},
			]),
			certificate: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							Boolean((!value && props.model.certificateFile) || (value && !props.model.certificateFile)),
					],
					errorResult: 'Please enter a certificate or upload a certificate file',
				},
			]),
			segmentOneName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentTwoName,
								props.model.segmentThreeName,
								props.model.segmentOneDisplayName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
			segmentTwoName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentOneName,
								props.model.segmentThreeName,
								props.model.segmentTwoDisplayName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
			segmentThreeName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentOneName,
								props.model.segmentTwoName,
								props.model.segmentThreeDisplayName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
			segmentOneDisplayName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentTwoDisplayName,
								props.model.segmentThreeDisplayName,
								props.model.segmentOneName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
			segmentTwoDisplayName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentOneDisplayName,
								props.model.segmentThreeDisplayName,
								props.model.segmentTwoName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
			segmentThreeDisplayName: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							validateSegments(
								value,
								props.model.segmentOneDisplayName,
								props.model.segmentTwoDisplayName,
								props.model.segmentThreeName,
							),
					],
					errorResult: SEGMENT_NAME_ERROR,
				},
			]),
		},
	});

	const onFileChange = formProps.bindInput('certificateFile', 'file').onChange;
	const handleFileChange: OnChange = e => {
		const newFile = e?.target?.files?.[0];
		formProps.setProperty('certificateFile', newFile ? [newFile] : []);
		onFileChange(e);
		formProps.setProperty('certificate', '');
	};

	function validateForm(): void {
		if (formProps.validation.isValid) {
			return;
		}
		const errorMessage =
			stringFromModelValue(
				Object.keys(formProps.validation.fields)
					.map(fieldName => {
						const typedFieldName = fieldName as keyof typeof formProps.validation.fields;
						return stringFromModelValue(formProps.validation.fields[typedFieldName]?.errors);
					})
					.filter(Boolean) as string[],
			) ?? 'Please check you have filled out all fields properly';
		throw new Error(`${errorMessage}`);
	}

	async function handleSubmit(e: FormEvent): Promise<void> {
		e.preventDefault();
		try {
			validateForm();
			const {
				entityId,
				ssoUrl,
				certificate,
				certificateFile,
				authenticationUrl,
				segmentOneName,
				segmentTwoName,
				segmentThreeName,
				segmentOneDisplayName,
				segmentTwoDisplayName,
				segmentThreeDisplayName,
			} = formProps.model;
			const webRenewUrl = stringFromModelValue(authenticationUrl);
			const updateSamlData: Partial<SSOConfigParams> = {
				webRenewUrl,
				entityId: stringFromModelValue(entityId),
				ssoUrl: stringFromModelValue(ssoUrl),
				certificate: stringFromModelValue(certificate),
				certificateFile: fileFromModelValue(certificateFile),
				segments: {
					segment_1_name: stringFromModelValue(segmentOneName),
					segment_2_name: stringFromModelValue(segmentTwoName),
					segment_3_name: stringFromModelValue(segmentThreeName),
					segment_1_display_name: stringFromModelValue(segmentOneDisplayName),
					segment_2_display_name: stringFromModelValue(segmentTwoDisplayName),
					segment_3_display_name: stringFromModelValue(segmentThreeDisplayName),
				},
			};

			await updateSaml(updateSamlData);
			await updatePartnerCache({
				...partner,
				web_renew_url: webRenewUrl || '',
			});
			dispatch(
				setBannerMessage({
					message: 'SSO settings saved',
					isError: false,
					flash: true,
				}),
			);
		} catch (err) {
			calmLogger.error('Unable to save SSO setting', {}, err);
			const message = (() => {
				if (isErrorWithMessage(err)) {
					return err.message;
				}
				if (isCalmError(err)) {
					return err.data?.error?.code;
				}
				return getErrorMessage(err);
			})();
			dispatch(
				setBannerMessage({
					message: `Unable to save SSO settings: ${message}`,
					isError: true,
					flash: true,
				}),
			);
		}
	}

	const certificateFile = fileFromModelValue(formProps.model.certificateFile);
	const certificateProps = formProps.bindWithErrorProps('certificate', 'text', true);

	return (
		<form onSubmit={handleSubmit} data-testid="sso-input-form">
			<StyledChecklist>
				<ChecklistItem stepCount={1} title={formatMessage(messages.authenticationUrlTitle)}>
					<div>{formatMessage(messages.authenticationUrlDescription)}</div>
					<FormInput
						{...formProps.bindWithErrorProps('authenticationUrl', 'text', true)}
						label={formatMessage(messages.authenticationUrlLabel)}
						data-testid="authentication-url-input"
					/>
				</ChecklistItem>
				<ChecklistItem stepCount={2} title={formatMessage(messages.samlUniqueIdTitle)}>
					<List>
						<li>{formatMessage(messages.samlUniqueIdDescription1)}</li>
						<li>{formatMessage(messages.samlUniqueIdDescription2)}</li>
					</List>
				</ChecklistItem>
				<ChecklistItem stepCount={3} title={formatMessage(messages.metadataTitle)}>
					<div>{formatMessage(messages.metadataDescription1)}</div>
					<DownloadLinkContainer>
						<SecondaryButton Icon={DownloadIcon} onPress={downloadMetadata} isDisabled={isDownloading}>
							{formatMessage(messages.metadataDownloadLink)}
						</SecondaryButton>
					</DownloadLinkContainer>
					{formatMessage(messages.metadataDescription2)}
					<PrimaryLink onClick={(): void => setShowUploadModal(true)}>
						{formatMessage(messages.metadataViewLink)}
					</PrimaryLink>
				</ChecklistItem>
				<ChecklistItem stepCount={4} title={formatMessage(messages.samlTitle)}>
					<FormInput
						{...formProps.bindWithErrorProps('ssoUrl', 'text', true)}
						label={formatMessage(messages.idpSSOLabel)}
						data-testid="sso-url-input"
					/>
					<FormInput
						{...formProps.bindWithErrorProps('entityId', 'text', true)}
						label={formatMessage(messages.idpEntityIdLabel)}
						data-testid="entity-id-input"
					/>
				</ChecklistItem>
				<ChecklistItem stepCount={5} title={formatMessage(messages.certificateTitle)}>
					<div>{formatMessage(messages.certificatePasteDescription)}</div>
					<StyledTextArea
						{...certificateProps}
						label={formatMessage(messages.certificateTextAreaPlaceholder)}
						data-testid="certificate-text-input"
					/>
					<div>{formatMessage(messages.certificateUploadDescription)}</div>
					<UploadButtonContainer>
						<FileUpload
							file={certificateFile}
							onChange={handleFileChange}
							id="sso-form-certificate-file"
							accept={['.pem', '.der']}
							name="certificateFile"
							testid="certificate-file-input"
							buttonText={formatMessage(messages.certificateUploadButton)}
							showNewFileLabel={false}
						/>
					</UploadButtonContainer>
				</ChecklistItem>
				<ChecklistItem stepCount={6} title={formatMessage(messages.relayStateUrlTitle)}>
					<div>{relayStateUrl}</div>
					<TextButton
						hideUnderline
						size={FontSizes.sm}
						onClick={() => {
							copy(relayStateUrl, {
								format: 'text/plain',
								onCopy: () => {
									dispatch(
										setBannerMessage({
											message: 'Relay state url copied to clipboard',
											isError: false,
											flash: true,
										}),
									);
								},
							});
						}}
					>
						{formatMessage(messages.copyURLButtonText)}
					</TextButton>
				</ChecklistItem>
				<ChecklistItem stepCount={7} title={formatMessage(messages.samlAttributeTitle)}>
					<div>{formatMessage(messages.samlAttributeDescription)}</div>
					<div>
						<SegmentRow>
							<SegmentColumn>
								<SegmentTitle>Attributes</SegmentTitle>
							</SegmentColumn>
							<SegmentColumn>
								<SegmentTitle>Display Name</SegmentTitle>
							</SegmentColumn>
						</SegmentRow>
						<SegmentRow>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentOneName', 'text', true)}
									label={formatMessage(messages.segmentAttributeName, {
										n: 1,
									})}
									data-testid="segment-1-name-input"
								/>
							</SegmentColumn>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentOneDisplayName', 'text', true)}
									label={formatMessage(messages.segmentDisplayName, {
										n: 1,
									})}
									data-testid="segment-1-display-name-input"
								/>
							</SegmentColumn>
						</SegmentRow>
						<SegmentRow>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentTwoName', 'text', true)}
									label={formatMessage(messages.segmentAttributeName, {
										n: 2,
									})}
									data-testid="segment-2-name-input"
								/>
							</SegmentColumn>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentTwoDisplayName', 'text', true)}
									label={formatMessage(messages.segmentDisplayName, {
										n: 2,
									})}
									data-testid="segment-2-display-name-input"
								/>
							</SegmentColumn>
						</SegmentRow>
						<SegmentRow>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentThreeName', 'text', true)}
									label={formatMessage(messages.segmentAttributeName, {
										n: 3,
									})}
									data-testid="segment-3-name-input"
								/>
							</SegmentColumn>
							<SegmentColumn>
								<FormInput
									{...formProps.bindWithErrorProps('segmentThreeDisplayName', 'text', true)}
									label={formatMessage(messages.segmentDisplayName, {
										n: 3,
									})}
									data-testid="segment-3-display-name-input"
								/>
							</SegmentColumn>
						</SegmentRow>
					</div>
				</ChecklistItem>
			</StyledChecklist>
			<SubmitButton type="submit" isLoading={loading} isDisabled={loading} data-testid="submit-sso-button">
				{formatMessage(messages.saveButtonText)}
			</SubmitButton>
			{showUploadModal && (
				<Modal
					isOpen
					canClose={false}
					closeModal={(): void => setShowUploadModal(false)}
					footer={
						<CloseModalButton onPress={(): void => setShowUploadModal(false)}>
							{formatMessage(messages.modalCloseButton)}
						</CloseModalButton>
					}
					aria-labelledby="modal-sso-setup-label"
				>
					<ModalContent>
						<ModalLabel id="modal-sso-setup-label">{formatMessage(messages.modalSsoLabel)}</ModalLabel>
						<ModalRow>
							<div>{initialConfig.assertion_consumer_service_url}</div>
							<TextButton
								hideUnderline
								onClick={() => {
									copy(initialConfig.assertion_consumer_service_url, {
										format: 'text/plain',
										onCopy: () => {
											dispatch(
												setBannerMessage({
													message: 'SSO URI copied to clipboard',
													isError: false,
													flash: true,
												}),
											);
										},
									});
								}}
							>
								copy
							</TextButton>
						</ModalRow>
						<ModalLabel>{formatMessage(messages.modalEntityIdLabel)}</ModalLabel>
						<ModalRow>
							<div>{initialConfig.audience_uri}</div>
							<TextButton
								hideUnderline
								onClick={() => {
									copy(initialConfig.audience_uri, {
										format: 'text/plain',
										onCopy: () => {
											dispatch(
												setBannerMessage({
													message: 'Identity ID copied to clipboard',
													isError: false,
													flash: true,
												}),
											);
										},
									});
								}}
							>
								copy
							</TextButton>
						</ModalRow>
					</ModalContent>
				</Modal>
			)}
		</form>
	);
}

function Content({ partner }: { partner: Partner }): ReactElement {
	const { formatMessage } = useIntl();
	const { data: samlConfig, loading: isLoadingSaml, error } = useSaml(partner.id);

	if (isLoadingSaml) {
		return (
			<LoaderContainer>
				<Loader color="black" />
			</LoaderContainer>
		);
	}

	if (error || !samlConfig) {
		return <div>Unable to load Partner SAML</div>;
	}

	return (
		<ContentContainer>
			<Title>{formatMessage(messages.title)}</Title>
			<Description />
			<Form partner={partner} initialConfig={samlConfig} />
		</ContentContainer>
	);
}

export default function SSOSetup(): ReturnType<FC> {
	const { partnerId } = useParams();
	const { data: partner } = usePartner(partnerId);
	const { data: integrationType } = useIntegrationType(partner?.id);

	if (!partner || !integrationType) {
		return null;
	}

	if (integrationType !== IntegrationType.SSO) {
		return <Redirect to={`/${partnerId}/account`} />;
	}
	return (
		<CellContainer>
			<Content partner={partner} />
		</CellContainer>
	);
}
