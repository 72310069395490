import styled from 'styled-components';
import { palette } from 'styled-tools';

import { maxWidth, Text, units } from '@calm-web/design-system';

import LeftChevronSvg from 'icons/chevron-left-centered.svg';
import EllipseSvg from 'icons/ellipse-filled.svg';
import HeartOutlineSvg from 'icons/heart-outline.svg';
import PhoneOutlineSvg from 'icons/phone-outline.svg';

export const Wrapper = styled.div`
	position: relative;
	height: ${units(75)};
	width: ${units(34.5)};

	${maxWidth('desktop')} {
		margin: 0 auto;
	}
`;

export const TitleRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const HeartIcon = styled(HeartOutlineSvg)`
	height: ${units(2.5)};
	max-width: ${units(2.5)};
	path {
		fill: ${palette('white')};
	}
`;
export const LeftChevron = styled(LeftChevronSvg)`
	position: absolute;
	top: ${units(6)};
	left: ${units(3)};
	height: ${units(2.5)};
	max-width: ${units(2.5)};
	path {
		fill: ${palette('white')};
	}
`;
export const EllipseGroup = styled.div`
	position: absolute;
	top: ${units(5)};
	right: ${units(3)};
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${units(4)};
	width: ${units(4)};
	border-radius: ${units(4)};
	background-color: ${palette('transparentBlackBackground')};
`;
export const EllipseIcon = styled(EllipseSvg)`
	width: 6px;
	height: 3px;
	circle {
		fill: ${palette('white')};
	}
`;

export const SubHeading = styled(Text)`
	font-size: ${units(2)};
	margin-top: ${units(3)};
`;

export const Subtitle = styled(Text)`
	font-size: ${units(1.5)};
`;
export const EapTitle = styled(Text)`
	font-size: 11px;
	line-height: unset;
	margin-top: ${units(1)};
`;
export const EapSubTitle = styled(Text)`
	font-size: 10px;
	line-height: 16px;
`;

export const PhoneOutline = styled(PhoneOutlineSvg)`
	position: absolute;
	height: 100%;
`;
export const Hero = styled.div`
	height: 44%;
	border-top-left-radius: ${units(6)};
	border-top-right-radius: ${units(6)};
	overflow: hidden;
	position: relative;
	background: ${palette('gray1')};
	z-index: -1;
	display: flex;
	justify-content: center;
`;
export const ImageWrapper = styled.div`
	margin-top: ${units(1)};
	position: relative;
	width: 201px;
	height: 134px;
	background: ${palette('gray1')};
	border-radius: ${units(2)};
	img {
		border-radius: ${units(2)};
	}
`;

export const Content = styled.div`
	padding: ${units(3, 2)};
	background: ${palette('gradientTabBlue')};
	border-bottom-left-radius: ${units(6)};
	border-bottom-right-radius: ${units(6)};
	height: 56%;
	min-width: 100%;
`;
