import { DateTime } from 'luxon';
import { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Banner, Modal, RadioGroup } from '@calm-web/design-system';

import { DEFAULTS, PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import DatePicker from '@/components/ui/DatePicker';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePathwayTimeline } from '@/hooks/api/usePathways';
import redirect from '@/utils/redirect';
import DocumentIcon from 'icons/tos.svg';

import AnnouncementEmailPreview from '../AnnouncementEmailPreview';
import createMessages from '../messages';
import ReminderEmailPreview from '../ReminderEmailPreview';
import { ErrorMessage } from '../styles';
import messages from './messages';
import {
	AnnouncementContainer,
	CloseButton,
	DatePickerWrapper,
	Description,
	EmailConfigContainer,
	EmailPickerWrapper,
	EmailWrapper,
	Header,
	Label,
	RadioContainer,
	ReminderContainer,
	ResourceLink,
	ScheduleContainer,
	WeekendWarning,
	Wrapper,
} from './styles';

const OPTIMAL_DAYS_BEFORE_START = {
	ANNOUNCEMENT: 7,
	REMINDER: 3,
};

const DEFAULT_TIME_FOR_EMAILS = 9; // Sets the local user time for emails to be sent
const isOnWeekend = (date: Date | null): boolean => {
	if (date) {
		const dayIndex = date.getDay();
		return dayIndex === 0 || dayIndex === 6;
	}
	return false;
};

function Schedule(): ReactElement | null {
	const partner = useDefinedPartner();
	const { formatMessage } = useIntl();
	const {
		startDate,
		endDate,
		closestEligibleMonday,
		emailSettings,
		setEmailSettings,
		announcementDate,
		setAnnouncementDate,
		reminderDate,
		setReminderDate,
		setStartDate,
		setTimelineData,
		setEndDate,
		selectedPathway,
		isEdit,
		isReadOnly,
		partnerId,
		defaultAnnouncementDate,
		defaultReminderDate,
		shouldUsePathwaysV2,
	} = useContext(PathwaysContext);
	const { data: pathwayTimeline } = usePathwayTimeline(selectedPathway?.pack_class, startDate);
	const { email_config_can_send_benefit_reminders } = partner;

	useEffect(() => {
		setTimelineData(pathwayTimeline);
		const lastDate = pathwayTimeline?.slice(-1);
		if (lastDate) setEndDate(lastDate[0]);
	}, [pathwayTimeline, setEndDate, setTimelineData]);

	const [isAnnouncementPreviewModalOpen, setIsAnnouncementPreviewModalOpen] = useState<boolean>(false);
	const [isReminderPreviewModalOpen, setIsReminderPreviewModalOpen] = useState<boolean>(false);
	const hasSelectedAnnouncementWeekend = useMemo(() => {
		return isOnWeekend(announcementDate);
	}, [announcementDate]);
	const hasSelectedReminderWeekend = useMemo(() => {
		return isOnWeekend(reminderDate);
	}, [reminderDate]);
	const currentDateMilliseconds = new Date().getTime();
	const difference = startDate ? startDate?.getTime() - currentDateMilliseconds : 0;
	const dayDelta = Math.ceil(difference / (1000 * 60 * 60 * 24));

	const emailOptions = [
		{
			value: 'on',
			id: 'shouldSendEmails',
			label: 'Send emails from Calm',
		},
		{
			value: 'off',
			id: 'shouldNotSendEmails',
			label: 'No emails from Calm',
		},
	];

	const onDateChange = (dates: [Date | null, Date | null]): void => {
		const [start] = dates;
		if (!start) {
			return;
		}
		setStartDate(start);

		const optimalAnnounceDate =
			DateTime.now().plus({ days: OPTIMAL_DAYS_BEFORE_START.ANNOUNCEMENT }).toJSDate() < start
				? DateTime.fromJSDate(start)
						.minus({ days: OPTIMAL_DAYS_BEFORE_START.ANNOUNCEMENT })
						.plus({ hours: DEFAULT_TIME_FOR_EMAILS })
						.toJSDate()
				: defaultAnnouncementDate;
		setAnnouncementDate(optimalAnnounceDate ?? null);
		const optimalReminderDate =
			DateTime.now().plus({ days: OPTIMAL_DAYS_BEFORE_START.ANNOUNCEMENT }).toJSDate() < start
				? DateTime.fromJSDate(start)
						.minus({ days: OPTIMAL_DAYS_BEFORE_START.REMINDER })
						.plus({ hours: DEFAULT_TIME_FOR_EMAILS })
						.toJSDate()
				: defaultReminderDate;
		setReminderDate(optimalReminderDate ?? null);
	};

	if (isEdit && isReadOnly) {
		redirect(`/${partnerId}/pathways`);
		return <ErrorMessage>{formatMessage(createMessages.errorMessage)}</ErrorMessage>;
	}

	return (
		<Wrapper>
			<Modal
				isOpen={isAnnouncementPreviewModalOpen}
				closeModal={() => setIsAnnouncementPreviewModalOpen(false)}
				title="Announcement Email Preview"
				footer={
					<>
						<CloseButton onPress={() => setIsAnnouncementPreviewModalOpen(false)}>
							{formatMessage(messages.closeButtonText)}
						</CloseButton>
					</>
				}
			>
				<AnnouncementEmailPreview />
			</Modal>
			<Modal
				isOpen={isReminderPreviewModalOpen}
				closeModal={() => setIsReminderPreviewModalOpen(false)}
				title="Reminder Email Preview"
				footer={
					<>
						<CloseButton onPress={() => setIsReminderPreviewModalOpen(false)}>
							{formatMessage(messages.closeButtonText)}
						</CloseButton>
					</>
				}
			>
				<ReminderEmailPreview />
			</Modal>
			<ScheduleContainer>
				<Header>{formatMessage(messages.scheduleHeading)}</Header>
				<Description>{formatMessage(messages.scheduleDescription)}</Description>
				<DatePickerWrapper>
					<Label id="pathways-schedule-date-label">Pathway schedule</Label>
					<DatePicker
						labelledBy="pathways-schedule-date-label"
						minDate={closestEligibleMonday}
						rangeStartDate={startDate}
						rangeEndDate={endDate}
						onChange={onDateChange}
						selectsRange
						inline
						monthsShown={2}
						showCalendarIcon={false}
						disableKeyboardNavigation
						filterDate={(date: Date) => {
							const dayNumber = date.getDay();
							return dayNumber === 1;
						}}
					/>
				</DatePickerWrapper>
			</ScheduleContainer>
			<EmailWrapper>
				{shouldUsePathwaysV2 && (
					<EmailConfigContainer>
						<Header>{formatMessage(messages.emailConfigHeading)}</Header>
						{formatMessage(messages.emailConfigDescription)}
						<RadioContainer>
							<RadioGroup
								name="emailSettings"
								aria-label="Will Calm send Pathway emails?"
								asCards
								value={emailSettings}
								onChange={e => setEmailSettings(e.target.value)}
								options={emailOptions}
							/>
						</RadioContainer>
					</EmailConfigContainer>
				)}
				{((shouldUsePathwaysV2 && emailSettings === 'on') || !shouldUsePathwaysV2) && (
					<>
						<AnnouncementContainer>
							<Header>{formatMessage(messages.announcementHeading)}</Header>
							{formatMessage(messages.announcementDescription)}
							{hasSelectedAnnouncementWeekend && (
								<WeekendWarning>{formatMessage(messages.weekendWarning)}</WeekendWarning>
							)}
							<EmailPickerWrapper>
								<Label id="announcement-date-label">Announcement email date and time</Label>
								<DatePicker
									disabled={dayDelta < 7}
									labelledBy="announcement-date-label"
									showTimeSelect
									timeIntervals={60}
									startDate={announcementDate}
									minDate={startDate ? DateTime.now().plus({ days: 2 }).toJSDate() : null}
									maxDate={
										reminderDate
											? DateTime.fromJSDate(reminderDate).minus({ days: 1 }).toJSDate()
											: startDate
											? DateTime.fromJSDate(startDate)
													.minus({ days: DEFAULTS.MIN_DAYS_BEFORE_START.ANNOUNCEMENT })
													.toJSDate()
											: null
									}
									minTime={new Date(new Date().setHours(5))}
									maxTime={new Date(new Date().setHours(23))}
									onChange={(date: Date) => setAnnouncementDate(date)}
									dateFormat="MMMM d, yyyy, h:mm aa"
									width="18rem"
								/>
							</EmailPickerWrapper>
							<ResourceLink Icon={DocumentIcon} onPress={() => setIsAnnouncementPreviewModalOpen(true)}>
								{formatMessage(messages.announcementLink)}
							</ResourceLink>
						</AnnouncementContainer>
						<ReminderContainer>
							<Header>{formatMessage(messages.reminderHeading)}</Header>
							{formatMessage(messages.reminderDescription)}
							{hasSelectedReminderWeekend && (
								<WeekendWarning>{formatMessage(messages.weekendWarning)}</WeekendWarning>
							)}
							<EmailPickerWrapper>
								<Label id="reminder-date-label">Reminder email date and time</Label>
								<DatePicker
									disabled={dayDelta < 7}
									labelledBy="reminder-date-label"
									showTimeSelect
									timeIntervals={60}
									startDate={reminderDate}
									minDate={
										announcementDate
											? DateTime.fromJSDate(announcementDate).plus({ days: 1 }).toJSDate()
											: DateTime.now().plus({ days: 2 }).toJSDate()
									}
									maxDate={
										startDate
											? DateTime.fromJSDate(startDate)
													.minus({ days: DEFAULTS.MIN_DAYS_BEFORE_START.REMINDER })
													.toJSDate()
											: null
									}
									minTime={new Date(new Date().setHours(5))}
									maxTime={new Date(new Date().setHours(23))}
									onChange={(date: Date) => setReminderDate(date)}
									dateFormat="MMMM d, yyyy, h:mm aa"
									width="18rem"
								/>
							</EmailPickerWrapper>
							<ResourceLink Icon={DocumentIcon} onPress={() => setIsReminderPreviewModalOpen(true)}>
								{formatMessage(messages.reminderLink)}
							</ResourceLink>
						</ReminderContainer>
					</>
				)}
				{shouldUsePathwaysV2 && emailSettings === 'off' && (
					<>
						<AnnouncementContainer>
							<Header>{formatMessage(messages.announcementHeading)}</Header>
							{formatMessage(messages.announcementDescriptionSettingsOff)}
							<br />
							<ResourceLink Icon={DocumentIcon} onPress={() => setIsAnnouncementPreviewModalOpen(true)}>
								{formatMessage(messages.announcementLink)}
							</ResourceLink>
						</AnnouncementContainer>
						<ReminderContainer>
							<Header>{formatMessage(messages.reminderHeading)}</Header>
							{formatMessage(messages.reminderDescriptionSettingsOff)}
							<br />
							<ResourceLink Icon={DocumentIcon} onPress={() => setIsReminderPreviewModalOpen(true)}>
								{formatMessage(messages.reminderLink)}
							</ResourceLink>
						</ReminderContainer>
					</>
				)}
			</EmailWrapper>
			{shouldUsePathwaysV2 && !email_config_can_send_benefit_reminders && emailSettings === 'on' && (
				<Banner addMargins backgroundColor="yellow">
					{formatMessage(messages.bannerText, {
						underlinedtext: (...chunks: ReactNode[]) => <u>{chunks}</u>,
					})}
				</Banner>
			)}
		</Wrapper>
	);
}

export default Schedule;
