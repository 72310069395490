import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Banner, Card, Loader } from '@calm-web/design-system';

import CellSubtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import { useSalesforceId } from '@/hooks/api/useSalesforceId';

import SalesforceAccountInfo, { ConnectSalesforceAccount } from './AccountInfo';
import messages from './messages';

interface SalesforceDetailsProps {
	onSuccess: (b2bAccountId: string) => void;
	onDisconnect: () => void;
	b2bAccountId?: string;
}

interface SalesforceContentProps extends SalesforceDetailsProps {
	salesforceAccountId?: string;
}

const SalesforceContent: FC<SalesforceContentProps> = ({
	salesforceAccountId,
	b2bAccountId,
	onSuccess,
	onDisconnect,
}) => {
	const { formatMessage } = useIntl();
	return (
		<>
			{salesforceAccountId ? (
				<>
					<CellSubtitle>{formatMessage(messages.subtitle)}</CellSubtitle>

					<SalesforceAccountInfo
						salesforceAccountId={salesforceAccountId}
						b2bAccountId={b2bAccountId}
						onSuccess={onSuccess}
						onDisconnect={onDisconnect}
					/>
				</>
			) : (
				<ConnectSalesforceAccount b2bAccountId={b2bAccountId} onSuccess={onSuccess} />
			)}
		</>
	);
};

const SalesforceDetails: FC<SalesforceDetailsProps> = ({ onSuccess, onDisconnect, b2bAccountId }) => {
	const { formatMessage } = useIntl();

	const { data: salesforceAccountId, error, loading } = useSalesforceId(b2bAccountId);
	if (loading) return <Loader color="gray1" />;

	return (
		<Card>
			<CellTitle showAdminFlag>{formatMessage(messages.title)}</CellTitle>
			{error ? (
				<Banner backgroundColor="error" textColor="white">
					{formatMessage(messages.requestFailureCopy)}
				</Banner>
			) : (
				<SalesforceContent
					salesforceAccountId={salesforceAccountId}
					b2bAccountId={b2bAccountId}
					onSuccess={onSuccess}
					onDisconnect={onDisconnect}
				/>
			)}
		</Card>
	);
};

export default SalesforceDetails;
