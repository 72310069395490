import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal } from '@calm-web/design-system';

import { RecordObject } from '@/hooks/api/usePartnerEmailRecords';

import messages from './messages';
import { CancelButton, IconWrapper, ModalContent, ModalText, PrimaryModalText, WarningIcon } from './styles';

const EmailRecordsModals: React.FC<{
	onConfirm: () => void;
	onDismiss: () => void;
	shouldShowNextRequestModal: boolean;
	shouldShowLastRequestModal: boolean;
	pastDates: RecordObject[] | undefined;
	futureDate: string | undefined;
	isLoading: boolean;
}> = ({
	onConfirm,
	onDismiss,
	shouldShowNextRequestModal,
	shouldShowLastRequestModal,
	pastDates,
	futureDate,
	isLoading,
}) => {
	const { formatMessage } = useIntl();
	const pastDateSentAt = pastDates?.[0]?.sent_at;
	const pastDateSentAtString = useMemo(() => {
		return pastDateSentAt
			? new Date(pastDateSentAt).toLocaleDateString(undefined, {
					month: '2-digit',
					day: '2-digit',
			  })
			: null;
	}, [pastDateSentAt]);
	return (
		<>
			{shouldShowLastRequestModal && pastDates && (
				<Modal
					isOpen
					closeModal={onDismiss}
					title={formatMessage(messages.lastModalTitle)}
					footer={
						<>
							<CancelButton type="button" onClick={onDismiss}>
								{formatMessage(messages.modalCancel)}
							</CancelButton>
							<Button backgroundColor="blue3" onPress={onConfirm} isLoading={isLoading} type="button">
								{formatMessage(messages.modalProceed)}
							</Button>
						</>
					}
				>
					<ModalContent>
						<IconWrapper>
							<WarningIcon />
						</IconWrapper>
						<PrimaryModalText>
							{formatMessage(messages.lastModalParagraph1, {
								newDate: pastDateSentAtString,
							})}
						</PrimaryModalText>
						<br />
						<ModalText>{formatMessage(messages.lastModalParagraph2)}</ModalText>
					</ModalContent>
				</Modal>
			)}
			{shouldShowNextRequestModal && futureDate && (
				<Modal
					isOpen
					closeModal={onDismiss}
					title={formatMessage(messages.nextModalTitle)}
					footer={
						<>
							<CancelButton type="button" onClick={onDismiss}>
								{formatMessage(messages.modalCancel)}
							</CancelButton>
							<Button backgroundColor="blue3" onPress={onConfirm} isLoading={isLoading} type="button">
								{formatMessage(messages.modalProceed)}
							</Button>
						</>
					}
				>
					<ModalContent>
						<IconWrapper>
							<WarningIcon />
						</IconWrapper>
						<PrimaryModalText>
							{formatMessage(messages.nextModalParagraph1, {
								newDate: futureDate,
							})}
						</PrimaryModalText>
						<br />
						<ModalText>{formatMessage(messages.nextModalParagraph2)}</ModalText>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default EmailRecordsModals;
