import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Banner } from '@calm-web/design-system';

import { SalesforceOwnerInfoType, useSalesforceAccountOwner } from '@/hooks/api/useSalesforceAccountOwner';
import { useSalesforceId } from '@/hooks/api/useSalesforceId';

import messages from './messages';
import { BannerWrapper } from './styles';

interface SalesTrialBannerProps {
	isAdmin?: boolean;
	b2bAccountId?: string;
	contractEndDate?: string;
}
interface NonAdminBannerProps {
	owner?: SalesforceOwnerInfoType;
	contractEndDate?: string;
}

const NonAdminBanner: FC<NonAdminBannerProps> = ({ owner, contractEndDate }) => {
	const { formatMessage } = useIntl();
	const devEngOwner = owner?.name.includes('Data Eng');
	const genericSalesEmail = 'sales@calm.com';
	const today = new Date();
	const isExpired = contractEndDate ? new Date(contractEndDate) < today : false;
	const unmanagedAccount = !owner?.name || !owner?.email || devEngOwner;
	const email = unmanagedAccount ? genericSalesEmail : owner.email;
	const calmContact = unmanagedAccount ? 'us' : `your account representative, ${owner.name},`;

	const msg = isExpired ? messages.expiredSalesTrialPartner : messages.salesTrialPartner;
	const bannerMsg = formatMessage(msg, {
		mailto: (...chunks: ReactNode[]) => <a href={`mailto:${chunks}`}>{chunks}</a>,
		calmContact,
		email,
	});

	return (
		<Banner addMargins backgroundColor="buttonLime">
			{bannerMsg}
		</Banner>
	);
};

const SalesTrialBanner: FC<SalesTrialBannerProps> = ({ isAdmin, b2bAccountId, contractEndDate }) => {
	const { formatMessage } = useIntl();
	const { data: salesforceAccountId } = useSalesforceId(b2bAccountId);
	const { data: owner } = useSalesforceAccountOwner(salesforceAccountId);

	return (
		<BannerWrapper>
			{isAdmin ? (
				<Banner backgroundColor="yellow" addMargins>
					{formatMessage(messages.salesTrialAdminCopy)}
				</Banner>
			) : (
				<NonAdminBanner owner={owner} contractEndDate={contractEndDate} />
			)}
		</BannerWrapper>
	);
};

export default SalesTrialBanner;
