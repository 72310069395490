import { useCallback, useEffect, useState } from 'react';
import { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { clearActionPlansCache } from '@/hooks/api/useActionPlan';
import { useIsAdmin } from '@/hooks/auth/useAuth';
import { useUser } from '@/hooks/store';
import { User } from '@/types/store/reducers';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';
import { partnerRoles } from '@/utils/RBAC';

import { useFeatureFlags } from './useFeatureFlags';

export enum PartnerMilestoneType {
	SUBSCRIPTION_LINK_COPIED = 'SUBSCRIPTION_LINK_COPIED',
	RESOURCE_VIEWED = 'RESOURCE_VIEWED',
	REPORTING_VIEWED = 'REPORTING_VIEWED',
	MONTHLY_ENGAGEMENT = 'MONTHLY_ENGAGEMENT',
	ZOOM_APP_INSTALLED = 'ZOOM_APP_INSTALLED',
	ADMIN_INVITED = 'ADMIN_INVITED',
	ADMIN_LOGIN = 'ADMIN_LOGIN',
	SIGNUPS_ABOVE_BENCHMARK = 'SIGNUPS_ABOVE_BENCHMARK',
	SIGNUPS_BELOW_BENCHMARK_EMAIL = 'SIGNUPS_BELOW_BENCHMARK_EMAIL',
	SIGNUPS_BELOW_BENCHMARK_NON_EMAIL = 'SIGNUPS_BELOW_BENCHMARK_NON_EMAIL',
	ENGAGEMENT_ABOVE_BENCHMARK = 'ENGAGEMENT_ABOVE_BENCHMARK',
	ENGAGEMENT_BELOW_BENCHMARK = 'ENGAGEMENT_BELOW_BENCHMARK',
	EF_UPLOADED = 'EF_UPLOADED',
	SSO_INTEGRATED = 'SSO_INTEGRATED',
	API_INTEGRATED = 'API_INTEGRATED',
	GROUP_CODES_INTEGRATED = 'GROUP_CODES_INTEGRATED',
	ACCESS_CODES_INTEGRATED = 'ACCESS_CODES_INTEGRATED',
	EMPLOYER_LOGO_UPLOADED = 'EMPLOYER_LOGO_UPLOADED',
	LAUNCH_ANNOUNCEMENT_SENT = 'LAUNCH_ANNOUNCEMENT_SENT',
}

interface RecordMilestoneState {
	loading: boolean;
	error: CalmError | Error | undefined;
}
type RecordMilestoneFunction = (input: {
	eventName: PartnerMilestoneType;
	partnerId: string;
	userOverride?: User;
	isDismiss?: boolean;
}) => Promise<void>;
type RecordMilestoneAction = [RecordMilestoneFunction, RecordMilestoneState];

export function useRecordMilestone(): RecordMilestoneAction {
	const apiRequest = useApi();
	const isAdmin = useIsAdmin();
	const { user: appStateUser } = useUser();

	const [error, setError] = useState<CalmError | Error | undefined>();
	const [loading, setLoading] = useState(false);
	const { data: flagValues, loading: flagLoading } = useFeatureFlags('b2b-partner-homepage');
	const canSeeHomepage = flagValues && flagValues['b2b-partner-homepage'] === true;

	const [alreadyRecorded, setAlreadyRecorded] = useState(false);

	const recordMilestone: RecordMilestoneFunction = useCallback(
		async ({ eventName, partnerId, userOverride, isDismiss }) => {
			try {
				// We never want to record milestones for internal admins
				if (isAdmin) {
					return;
				}

				if (flagLoading) return;
				if (alreadyRecorded) return;
				setAlreadyRecorded(true);

				// The login milestone is triggered before the updated user can be reached through AppState.
				// This allows for the caller to inject the correct user.
				const user = userOverride ?? appStateUser;
				if (!user.accessPolicy) {
					return;
				}

				const isSalesTrialAdmin = user?.accessPolicy?.allowed_user_role === partnerRoles.salesTrialAdmin;

				// In the Control variation, we only record milestones for Sales Trials,
				// but with the homepage experiment we record them for partner admins as well
				if (!canSeeHomepage && !isSalesTrialAdmin) return;

				setLoading(true);
				const endpoint = `b2b/partners/${partnerId}/milestones`;
				const accessToken = user?.accessToken;
				const customHeaders = {
					Authorization: accessToken || '',
				};
				const body = { event: eventName, is_skip: isDismiss };
				await apiRequest({
					endpoint,
					method: 'POST',
					body,
					customHeaders,
				});

				await clearMilestonesCache(partnerId);
				await clearActionPlansCache(partnerId);
			} catch (err) {
				setError(getCalmErrorOrError(err));
				setAlreadyRecorded(false);
				throw err;
			} finally {
				setLoading(false);
			}
		},
		[appStateUser, alreadyRecorded, apiRequest, canSeeHomepage, flagLoading, isAdmin],
	);

	return [recordMilestone, { loading, error }];
}

export function useRecordMilestoneOnLoad(partnerId: string, eventName: PartnerMilestoneType): void {
	const [recordMilestone] = useRecordMilestone();

	useEffect(() => {
		const record = (): Promise<void> => recordMilestone({ eventName, partnerId });

		record().catch(err => {
			calmLogger.error('Error when trying to record milestone', {}, err);
		});
	}, [eventName, recordMilestone, partnerId]);
}

export function clearMilestonesCache(partnerId: string): Promise<void> {
	return mutate(`b2b/partners/${partnerId}/milestones`, undefined, true);
}
