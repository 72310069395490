import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { ApiResponse } from '@/hooks/api/types';
import { useApi } from '@/hooks/api/useApi';
import { FeatureFlagNames, useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { setBannerMessage } from '@/store/actions';
import { IntegrationType } from '@/types/store/reducers';
import { CalmError, getCalmErrorOrError, isCalmError } from '@/utils/apiRequest/errors';
import { isCalmHealthProductSKU, isSelfServePlan } from '@/utils/SkuUtils';

import { useIntegrationType } from './useIntegrationType';

const { SFTP_INTEGRATION_FF, CALM_HEALTH_SFTP_INTEGRATION } = FeatureFlagNames;

export function useShouldShowSftp(): ApiResponse<boolean> {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(SFTP_INTEGRATION_FF, CALM_HEALTH_SFTP_INTEGRATION);
	const partner = useDefinedPartner();
	const isCalmHealth = isCalmHealthProductSKU(partner.product_sku);
	const { data: integrationType, loading } = useIntegrationType(partner.id);

	if (loading || flagLoading || !flagValues || flagError) {
		return { data: undefined, error: flagError, loading: flagLoading || loading };
	}

	const isSftpIntegrationEnabled = !!flagValues[SFTP_INTEGRATION_FF];
	const isEligibilityFileIntegrationType = integrationType === IntegrationType.ELIGIBILITY_FILE;
	const isSelfServe = isSelfServePlan(partner.vouched_plan_sku);
	const isCalmHealthSftpEnabled = !!flagValues[CALM_HEALTH_SFTP_INTEGRATION];

	const baseCriteria = isSftpIntegrationEnabled && isEligibilityFileIntegrationType && !isSelfServe;
	const shouldShowSftp = isCalmHealth ? baseCriteria && isCalmHealthSftpEnabled : baseCriteria;
	const showSftpEwsOverride = partner.slug === 'ews';

	return { data: showSftpEwsOverride || shouldShowSftp, error: undefined, loading: false };
}

function determineErrorBannerMessage(error: CalmError | Error): string {
	const isCalmErr = isCalmError(error);
	if (isCalmErr) {
		if (error.data?.error?.code === 'invalid_ssh_key') {
			return 'Your SSH Key is invalid.';
		}
		if (error.data?.error?.code === 'invalid_field') {
			return 'Your SSH Key is not in OpenSSH format.';
		}
	}

	return 'Oops! We were unable to upload your SSH key.';
}

export interface SftpConfig {
	email_recipients: string[];
	sftp_username?: string;
}
export function useSftpConfig(): ApiResponse<SftpConfig> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const partner = useDefinedPartner();
	const baseEndpoint = `b2b/partners/${partner.id}/sftp-config`;

	const { data, error, isLoading } = useSWR(
		baseEndpoint,
		async endpoint => {
			try {
				const response = await apiRequest({
					endpoint,
					method: 'GET',
				});

				return response.data.sftp_config;
			} catch (err) {
				const error = getCalmErrorOrError(err);
				const status = isCalmError(error) ? error?.status : undefined;

				if (status === 404) {
					return { sftp_username: '', email_recipients: [] };
				}
				dispatch(
					setBannerMessage({
						message: 'Oops! We were unable to fetch your SFTP config.',
						isError: true,
						flash: true,
					}),
				);
				throw err;
			}
		},
		{ revalidateOnFocus: false },
	);
	return { data, loading: isLoading, error };
}

type addRecipientsFunction = (input: { email_recipients: string[] }) => Promise<SftpConfig>;

export function useAddSftpEmailRecipients(): [addRecipientsFunction, ApiResponse<SftpConfig>] {
	const apiRequest = useApi();
	const partner = useDefinedPartner();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	const configEndpoint = `b2b/partners/${partner.id}/sftp-config`;
	const { trigger, isMutating, error, data } = useSWRMutation(
		configEndpoint,

		async (endpoint: string, { arg }: { arg: { email_recipients: string[] } }) => {
			try {
				const { email_recipients } = arg;
				const body = { email_recipients: email_recipients };
				const response = await apiRequest({
					endpoint,
					method: 'POST',
					body,
				});
				logEvent('Partner : SFTP Update Status Recipients : Success');
				return response.data;
			} catch (err) {
				logEvent('Partner : SFTP Update Status Recipients : Error');
				const error = getCalmErrorOrError(err);
				dispatch(
					setBannerMessage({
						message: 'Oops! We were unable to update the email recipient list.',
						isError: true,
						flash: true,
					}),
				);
				throw error;
			}
		},
	);
	return [trigger, { data, loading: isMutating, error }];
}

type UploadSshKeyFunction = (input: { sshKey: string }) => Promise<SftpConfig>;

export function useUploadSshKey(): [UploadSshKeyFunction, ApiResponse<SftpConfig>] {
	const apiRequest = useApi();
	const partner = useDefinedPartner();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	const configEndpoint = `b2b/partners/${partner.id}/sftp-config`;
	const { trigger, isMutating, error, data } = useSWRMutation(
		configEndpoint,
		async (_url: string, { arg }: { arg: { sshKey: string } }) => {
			try {
				const endpoint = `b2b/partners/${partner.id}/sftp-config/ssh-key`;
				const body = { ssh_key: arg.sshKey };
				const response = await apiRequest({
					endpoint,
					method: 'POST',
					body,
				});
				logEvent('Partner : SFTP SSH Key Update : Success');
				return response.data;
			} catch (err) {
				logEvent('Partner : SFTP SSH Key Update : Error');
				const error = getCalmErrorOrError(err);
				dispatch(
					setBannerMessage({
						message: determineErrorBannerMessage(error),
						isError: true,
						flash: true,
					}),
				);

				throw error;
			}
		},
	);

	return [trigger, { data, loading: isMutating, error }];
}

export function useCalmHealthSftpIntegration(): boolean {
	const { data: flagValues, loading: flagLoading } = useFeatureFlags(CALM_HEALTH_SFTP_INTEGRATION);
	return Boolean(flagValues && !flagLoading && !!flagValues[CALM_HEALTH_SFTP_INTEGRATION]);
}
