import styled from 'styled-components';

import { FormInput, IconButton } from '@calm-web/design-system';
import { ChevronRight } from '@calm-web/icons';

export const OrderBox = styled.div`
	flex: 0.6 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const OrderInput = styled(FormInput).attrs({ resizable: false, maxLength: 2 })`
	margin-bottom: 0;

	input {
		height: 24px;
		font-size: 16px;
		text-align: center;
		padding-bottom: 2px;
	}
`;

export const SmallIconButton = styled(IconButton).attrs({ color: 'blue3' })<{ isDisabled: boolean }>`
	padding: 0;
	min-width: 12px;
	min-height: 12px;
	${({ isDisabled }) => isDisabled && 'background: none;'}
`;

export const RaisePriorityIcon = styled(ChevronRight)`
	transform: rotate(-90deg);
`;

export const LowerPriorityIcon = styled(ChevronRight)`
	transform: rotate(90deg);
`;
