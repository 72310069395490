/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.eligibilityFile.uploadSuccessModal.title',
		defaultMessage: 'Successfully Uploaded',
		description: 'Title shown in the successful EF upload modal.',
	},
	closeModal: {
		id: 'users.eligibilityFile.uploadSuccessModal.close',
		defaultMessage: 'Close',
		description: 'Close button cta copy on the successful EF upload modal.',
	},
	heading: {
		id: 'users.eligibilityFile.uploadSuccessModal.heading',
		defaultMessage: 'Changes',
		description: 'Heading that would describe the changes made with the uploaded file.',
	},
	noChanges: {
		id: 'users.eligibilityFile.uploadSuccessModal.noChanges',
		defaultMessage: 'No changes made to current user list',
		description: 'No changes message.',
	},
	usersAdded: {
		id: 'users.eligibilityFile.uploadSuccessModal.addedUsers',
		defaultMessage: '{added} users <changeAction>added</changeAction>',
		description: 'Users added copy.',
	},
	usersRemoved: {
		id: 'users.eligibilityFile.uploadSuccessModal.removedUsers',
		defaultMessage: '{removed} users <changeAction>removed</changeAction>',
		description: 'Users removed copy.',
	},
	segmentsAdded: {
		id: 'users.eligibilityFile.uploadSuccessModal.addedSegments',
		defaultMessage: '{addedSegmentCount} segments <changeAction>added</changeAction>',
		description: 'Segments added copy.',
	},
	segmentsRemoved: {
		id: 'users.eligibilityFile.uploadSuccessModal.removedSegments',
		defaultMessage: '{deletedSegmentCount} segments <changeAction>removed</changeAction>',
		description: 'Segments removed copy.',
	},
	removedReminder: {
		id: 'users.eligibilityFile.uploadSuccessModal.removedReminder',
		defaultMessage:
			'Reminder: anyone removed will lose access at the end of the month. Uploaded the wrong file? Take a deep breath and upload the correct file now! Email your account manager if you need assistance.',
		description: 'Reminder shown if users were remove from the EF.',
	},
	duplicatesCopy: {
		id: 'users.eligibilityFile.uploadSuccessModal.duplicates',
		defaultMessage: 'Duplicates were detected and consolidated',
		description: 'Duplicates message copy.',
	},
});
