import omit from 'lodash/omit';
import { ReactElement } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Card, Loader, Text } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { useIsChPremiumEnabled } from '@/hooks/featureFlags/useIsChPremiumEnabled';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { ProductSKUType } from '@/utils/SkuEnums';

import messages from './messages';

function getProductSKUOptions(isChPremiumEnabled: boolean): {
	value: ProductSKUType;
	label: MessageDescriptor;
}[] {
	return [
		{
			value: ProductSKUType.C4B_DIRECT,
			label: messages.productSKUTypeC4BDirectSelection,
		},
		{
			value: ProductSKUType.C4B_PAYOR_BASE,
			label: messages.productSKUTypeC4BPayorBaseSelection,
		},
		{
			value: ProductSKUType.C4B_PAYOR_BUYUP,
			label: messages.productSKUTypeC4BPayorBuyupSelection,
		},
		{
			value: ProductSKUType.CH_DIRECT,
			label: isChPremiumEnabled
				? messages.productSKUTypeCHBaseSelection
				: messages.productSKUTypeCHDirectSelection,
		},
		{
			value: ProductSKUType.CH_PAYOR_BASE,
			label: messages.productSKUTypeCHPayorBaseSelection,
		},
		...(isChPremiumEnabled
			? []
			: [
					{
						value: ProductSKUType.CH_PAYOR_BUYUP,
						label: messages.productSKUTypeCHPayorBuyupSelection,
					},
			  ]),
		...(isChPremiumEnabled
			? [
					{
						value: ProductSKUType.CH_PREMIUM,
						label: messages.productSKUTypeCHPremiumSelection,
					},
			  ]
			: []),
	];
}

function getProductSkuMessage(productSku: ProductSKUType, isChPremiumEnabled: boolean): MessageDescriptor {
	switch (productSku) {
		case ProductSKUType.C4B_DIRECT:
			return messages.productSKUTypeC4BDirectSelection;
		case ProductSKUType.C4B_PAYOR_BASE:
			return messages.productSKUTypeC4BPayorBaseSelection;
		case ProductSKUType.C4B_PAYOR_BUYUP:
			return messages.productSKUTypeC4BPayorBuyupSelection;
		case ProductSKUType.CH_DIRECT:
			return isChPremiumEnabled
				? messages.productSKUTypeCHBaseSelection
				: messages.productSKUTypeCHDirectSelection;
		case ProductSKUType.CH_PAYOR_BASE:
			return messages.productSKUTypeCHPayorBaseSelection;
		case ProductSKUType.CH_PAYOR_BUYUP:
			return messages.productSKUTypeCHPayorBuyupSelection;
		case ProductSKUType.CH_PREMIUM:
			return messages.productSKUTypeCHPremiumSelection;
	}
}

export function ProductSKUTypesSelector({
	formProps,
	isChPremiumEnabled,
	disabled = false,
}: {
	formProps: EditPartnerFormProps;
	isChPremiumEnabled: boolean;
	disabled: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const bindWithErrorProps = formProps.bindWithErrorProps('productSKUType', 'select');

	return (
		<>
			<Subtitle>{formatMessage(messages.productSKUTypesSubtitle)}</Subtitle>
			<div>
				<select
					{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
					id="productSKUType"
					placeholder="Select a product SKU"
					aria-labelledby="product-sku-label"
					disabled={disabled}
				>
					{getProductSKUOptions(isChPremiumEnabled).map(({ value, label }) => {
						return (
							<option key={value} value={value}>
								{formatMessage(label)}
							</option>
						);
					})}
				</select>
			</div>
		</>
	);
}

function ProductSKUTypes({
	formProps,
	disabled = false,
	isEdit = false,
}: {
	formProps: EditPartnerFormProps;
	disabled: boolean;
	isEdit: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { isChPremiumEnabled, loading, error } = useIsChPremiumEnabled();

	return (
		<Card>
			<div>
				<CellTitle showAdminFlag id="product-sku-label">
					{formatMessage(messages.productSKUTypesTitle)}
				</CellTitle>
				{loading ? (
					<Loader color="gray1" />
				) : error ? (
					<Text>{formatMessage(messages.errorLoadingProductSKUTypes)}</Text>
				) : isEdit ? (
					<Text>
						{formatMessage(
							getProductSkuMessage(formProps.model.productSKUType as ProductSKUType, isChPremiumEnabled),
						)}
					</Text>
				) : (
					<ProductSKUTypesSelector
						formProps={formProps}
						disabled={disabled}
						isChPremiumEnabled={isChPremiumEnabled}
					/>
				)}
			</div>
		</Card>
	);
}

export default ProductSKUTypes;
