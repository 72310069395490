import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Modal, ModalWidth } from '@calm-web/design-system';

import { UpdatePrice } from '@/components/pages/Plan/PlanDetails/UpdateCoverage/UpdatePrice';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions/setBannerMessage';
import AddAdminIcon from 'icons/add-admin.svg';

import messages from './messages';
import { AdditionalCoverage, IncreaseButton, Wrapper } from './styles';

export default function IncreaseCoverage({
	partnerId,
	coveredLives,
}: {
	partnerId: string;
	coveredLives: number;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleIncreaseCoverageClick = (): void => setIsModalOpen(true);

	const handleCancel = (): void => setIsModalOpen(false);
	const handleSubmit = (): void => {
		logEvent('Partner Portal : Update coverage : Upgrade complete');

		dispatch(
			setBannerMessage({
				message: 'Upgrade complete!',
				isError: false,
				flash: true,
			}),
		);
		setIsModalOpen(false);
	};

	return (
		<Wrapper>
			{isModalOpen && (
				<Modal
					width={ModalWidth.Extra}
					isOpen
					closeModal={handleCancel}
					title={formatMessage(messages.additionalCoveragePrompt)}
					aria-label={formatMessage(messages.increaseCoverageTitle)}
					canClose
				>
					<AdditionalCoverage>
						<UpdatePrice
							previousCoveredLives={coveredLives}
							onCancel={handleCancel}
							onSubmit={handleSubmit}
							partnerId={partnerId}
						/>
					</AdditionalCoverage>
				</Modal>
			)}
			<IncreaseButton
				textColor="blue3"
				onPress={handleIncreaseCoverageClick}
				backgroundColor="transparent"
				hideShadow
				Icon={AddAdminIcon}
			>
				{formatMessage(messages.increaseCoverageButtonText)}
			</IncreaseButton>
		</Wrapper>
	);
}
