import NextJSImage from 'next/image';
import styled from 'styled-components';

import { FontSizes, FontWeights, Text, TextAlign, units } from '@calm-web/design-system';
import { Mail, Phone } from '@calm-web/icons';

import LeftChevronSvg from 'icons/chevron-left-centered.svg';

export const ViewPort = styled.div`
	position: relative;
	overflow: hidden;
	height: 508px;
	border-bottom-left-radius: ${units(4)};
	border-bottom-right-radius: ${units(4)};
	margin: ${units(0, 1)};
`;

export const LeftChevron = styled(LeftChevronSvg)<{ $isDark?: boolean }>`
	position: absolute;
	top: ${units(4.5)};
	left: ${units(3)};
	height: ${units(3.5)};
	max-width: ${units(3.5)};
	path {
		stroke: ${p => (p.$isDark ? 'white' : '#0060a9')};
		stroke-width: 4;
	}
	background: ${p => (p.$isDark ? 'linear-gradient(180deg, #223261 0%, #223263 100%)' : 'white')};
	border-radius: ${units(2)};
	padding: ${units(0.75)};
`;

export const CHLogo = styled.div`
	position: absolute;
	top: ${units(5)};
	left: ${units(14.5)};
`;

export const Background = styled.div<{ $isDark?: boolean }>`
	border-radius: ${units(6)};
	height: 100%;
	background: ${p =>
		p.$isDark
			? 'linear-gradient(180deg, #2B3B72 0%, #1F54B2 100%)'
			: 'linear-gradient(180deg, #e2f6ff 0%, #fdf7f9 100%)'};
	position: absolute;
	z-index: -1;
	margin: 0 auto;
	left: 0;
	right: 0;
`;

export const Header = styled.div`
	width: 100%;
	height: 64px;
	margin-bottom: 18px;
`;

export const Heading = styled(Text).attrs({
	el: 'h1',
	weight: FontWeights.Medium,
	align: TextAlign.Center,
	size: FontSizes.base,
})<{ $isDark?: boolean }>`
	line-height: ${units(3)};
	margin: 0 ${units(3)};
	color: ${p => p.$isDark && 'white'};
`;

export const SubHeading = styled(Text).attrs({
	el: 'h2',
	weight: FontWeights.Regular,
	align: TextAlign.Center,
})<{ $isDark?: boolean }>`
	font-size: ${units(1.375)};
	line-height: ${units(2.25)};
	margin: ${units(1)} ${units(2)} ${units(2)} ${units(2)};
	color: ${p => p.$isDark && '#FFFFFFB2'};
`;

export const WhiteBox = styled.div<{ $isDark?: boolean; $boxIndex?: number }>`
	position: relative;
	background: ${p =>
		p.$isDark
			? p.$boxIndex === 0
				? 'linear-gradient(180deg, #1F366E 0%, #1D3C7B 100%)'
				: 'linear-gradient(180deg, #1C3E80 0%, #1A428B 100%)'
			: 'white'};
	border-radius: ${units(2)};
	padding: ${units(1.5)} ${units(2)};
	margin: ${units(1)} ${units(3)} ${units(2)} ${units(3)};
`;

export const Image = styled(NextJSImage).attrs({ fill: true })`
	object-fit: contain;
`;

export const ImageContainer = styled.div`
	position: relative;
	height: ${units(3)};
	margin: 0 auto;
`;

export const GrayLine = styled.div`
	background: #0000001a;
	height: 1px;
	width: 100%;
	margin: ${units(1.5)} 0;
`;

export const Label = styled(Text).attrs({
	el: 'p',
	weight: FontWeights.Medium,
	TextAlign: TextAlign.Left,
})<{ $isDark?: boolean }>`
	font-size: ${units(1.25)};
	color: ${p => (p.$isDark ? '#FFFFFFB2' : '#1a1a2cb2')};
	line-height: ${units(1.25)};
`;

export const Value = styled(Text).attrs({
	el: 'p',
	weight: FontWeights.Demi,
	TextAlign: TextAlign.Left,
})<{ $isDark?: boolean }>`
	color: ${p => (p.$isDark ? 'white' : '#1a1a2c')};
	font-size: ${units(1.5)};
	line-height: ${units(1.5)};
`;

export const Title = styled(Text).attrs({ el: 'h2', size: FontSizes.sm, weight: FontWeights.Medium })<{
	$isDark?: boolean;
}>`
	line-height: ${units(3.5)};
	margin: 0 ${units(3)};
	color: ${p => p.$isDark && 'white'};
`;

export const EligibilityHeader = styled(Text).attrs({
	el: 'p',
	weight: FontWeights.Demi,
	TextAlign: TextAlign.Left,
})<{ $isDark?: boolean }>`
	font-size: ${units(1.625)};
	color: ${p => (p.$isDark ? 'white' : '#1a1a2c')};
	line-height: ${units(1.625)};
`;

export const EligibilitySubHeader = styled(Text).attrs({
	el: 'p',
	weight: FontWeights.Medium,
	TextAlign: TextAlign.Left,
})<{ $isDark?: boolean }>`
	font-size: ${units(1.1875)};
	color: ${p => (p.$isDark ? '#FFFFFFB2' : '#1a1a2cb2')};
	line-height: ${units(1.5)};
`;

export const SupportContainer = styled.div`
	display: flex;
`;

export const PhoneIcon = styled(Phone)<{ $isDark?: boolean }>`
	position: relative;
	left: -12px;
	top: 14px;
	path {
		fill: ${p => p.$isDark && '#FFFFFFB2'};
	}
`;

export const EmailIcon = styled(Mail)`
	position: relative;
	left: -12px;
	top: 14px;
	path {
		fill: #1a1a2cb2;
	}
`;

export const RightChevron = styled(LeftChevronSvg)<{ $isDark?: boolean }>`
	position: relative;
	width: 10%;
	top: ${units(1)};
	height: ${units(2.5)};
	max-width: ${units(2.5)};
	path {
		fill: ${p => (p.$isDark ? '#FFFFFFB2' : '#1a1a2cb2')};
	}
	transform: rotate(180deg);
`;
