/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'ssoSetup.title',
		defaultMessage: 'IdP & SAML Configuration',
		description: 'Title for the SSO set up page',
	},
	descriptionHeader: {
		id: 'ssoSetup.descriptionHeader',
		defaultMessage:
			"The IdP and SAML Configuration form is a technical document intended for you to complete with the assistance of your partner's IT admin.",
		description: 'Button to take users back to the partner configuration page',
	},
	descriptionBody: {
		id: 'ssoSetup.descriptionBody',
		defaultMessage: 'We recommend completing this form with them on a video call while screensharing.',
		description: 'Button to take users back to the partner configuration page',
	},
	authenticationUrlTitle: {
		id: 'ssoSetup.authenticationUrlTitle',
		defaultMessage: 'Enter your partner’s authentication URL',
		description: 'Title for authentication url section',
	},
	authenticationUrlDescription: {
		id: 'ssoSetup.authenticationUrlDescription',
		defaultMessage:
			'This is the page they are hosting where eligible employees will authenticate their subscriptions with their SSO provider.',
		description: 'Description for authentication url section',
	},
	authenticationUrlLabel: {
		id: 'ssoSetup.authenticationUrlLabel',
		defaultMessage: 'Authentication URL',
		description: 'Label for authentication url form field',
	},
	samlUniqueIdTitle: {
		id: 'ssoSetup.samlUniqueIdTitle',
		defaultMessage: 'Ask your partner to set up the unique identifier SAML attribute.',
		description: 'Title for SAML unique identifier section',
	},
	samlUniqueIdDescription1: {
		id: 'ssoSetup.samlUniqueIdDescription1',
		defaultMessage:
			'Identify a SAML attribute that can uniquely identify a user in their SSO provider. For example, "EmployeeID" or "PeopleKey"',
		description: 'First bullet point describing SAML unique identifier',
	},
	samlUniqueIdDescription2: {
		id: 'ssoSetup.samlUniqueIdDescription2',
		defaultMessage: 'Set the SAML attribute as Subject NameID in the SAML response of their IdP.',
		description: 'First bullet point describing SAML unique identifier',
	},
	metadataTitle: {
		id: 'ssoSetup.metadataTitle',
		defaultMessage: 'Send this metadata file to your partner’s IT admin',
		description: 'Title for section explaining what to do with metadata file',
	},
	metadataDescription1: {
		id: 'ssoSetup.metadataDescription1',
		defaultMessage: 'They’ll use this file to help you complete task 3 in this list.',
		description: 'Message describing how partners will use the metadata file.',
	},
	metadataDownloadLink: {
		id: 'ssoSetup.metadataDownloadLink',
		defaultMessage: 'download metadata.xml',
		description: 'Text for the link to download metadata file',
	},
	metadataDescription2: {
		id: 'ssoSetup.metadataDescription2',
		defaultMessage: 'Are you being asked for our Single Sign-on URL, Audience URI or SP Entity ID?',
		description: 'Message describing when account managers would want to view the metadata file',
	},
	metadataViewLink: {
		id: 'ssoSetup.metadataViewLink',
		defaultMessage: 'Click here to view and copy our metadata',
		description: 'Link for allowing account managers to view our metadata file',
	},
	samlTitle: {
		id: 'ssoSetup.samlTitle',
		defaultMessage:
			'Ask your partner to send you the following SAML metadata from their SSO provider and enter them below',
		description: 'Title for SAML metadata that we receive from the partner section',
	},
	idpEntityIdLabel: {
		id: 'ssoSetup.idpEntityIdLabel',
		defaultMessage: 'IdP Entity Id',
		description: 'Label for section asking IdP Entity Id',
	},
	idpSSOLabel: {
		id: 'ssoSetup.idpSSOLabel',
		defaultMessage: 'IdP Single Sign-on URL',
		description: 'Label for section asking IdP SSO URL',
	},
	certificateTitle: {
		id: 'ssoSetup.certificateTitle',
		defaultMessage: 'Ask your partner to send you the contents of their IdP signature certificate.',
		description: 'Title for signature certificate section',
	},
	certificatePasteDescription: {
		id: 'ssoSetup.certificatePasteDescription',
		defaultMessage: 'Paste the contents of the signature into the text area below.',
		description: 'Description for text area for pasting signature certificate',
	},
	certificateTextAreaPlaceholder: {
		id: 'ssoSetup.certificateTextAreaPlaceholder',
		defaultMessage: 'Paste IdP signature certificate contents here',
		description: 'Placeholder for text area where account managers can paste signature certificate',
	},
	certificateUploadDescription: {
		id: 'ssoSetup.certificateUploadDescription',
		defaultMessage:
			'Alternatively, if they send you the file, upload the certificate using the button below.',
		description: 'Message explaining button for uploading certificate',
	},
	certificateUploadButton: {
		id: 'ssoSetup.certificateUploadButton',
		defaultMessage: 'Upload encoded public key certificate (PEM / DER)',
		description: 'Text for button for uploading certificate',
	},
	relayStateUrlTitle: {
		id: 'ssoSetup.relayStateUrlTitle',
		defaultMessage:
			'Send this URL to your partner and ask them to use it to configure the relay state in their IdP instance.',
		description: 'Title for relay state url section',
	},
	samlAttributeTitle: {
		id: 'ssoSetup.samlAttributeTitle',
		defaultMessage:
			'Optional: From your SAML: add up to 3 additional SAML Attribute Names for segmented reporting',
		description: 'Title for SAML attribute section',
	},
	samlAttributeDescription: {
		id: 'ssoSetup.samlAttributeDescription',
		defaultMessage:
			'Ask your HR adminisitrator if they would like to segment their reporting data by any other additional SAML Attribute Names you have available. Use the Display Name field to give the attribute a user-friendly name on the Partner Portal reporting page.',
		description: 'Description for SAML attribute section',
	},
	segmentAttributeName: {
		id: 'ssoSteup.segmentAttributeName',
		defaultMessage: 'Segment {n} Attribute Name',
		description: 'Placeholder for segment attribute name input',
	},
	segmentDisplayName: {
		id: 'ssoSteup.segmentDisplayName',
		defaultMessage: 'Segment {n} Display Name',
		description: 'Placeholder for segment display name input',
	},
	copyURLButtonText: {
		id: 'ssoSetup.copyURLButtonText',
		defaultMessage: 'copy URL',
		description: 'Text for copy url button',
	},
	saveButtonText: {
		id: 'ssoSetup.saveButtonText',
		defaultMessage: 'Save Configuration',
		description: 'Text for button for saving SSO set up form',
	},
	modalEntityIdLabel: {
		id: 'ssoSetup.modalEntityIdText',
		defaultMessage: 'SP Entity ID (sometimes also known as Audience URI)',
		description: 'Label for entity id on modal',
	},
	modalSsoLabel: {
		id: 'ssoSetup.modalSsoLabel',
		defaultMessage: 'Single Sign-on URI',
		description: 'Label for SSO URI on modal',
	},
	modalCloseButton: {
		id: 'ssoSetup.modalCloseButton',
		defaultMessage: 'close dialog',
		description: 'Button text for closing the metadata details modal',
	},
});
