import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Button, FormInput, Loader } from '@calm-web/design-system';
import useForm, { stringFromModelValue, validation } from '@calm-web/use-form';

import { EnvConfig } from '@/env_config';
import { SALESFORCE_ID_LENGTH, useSalesforceAccount } from '@/hooks/api/useSalesforceAccount';
import { useConnectSalesforceId } from '@/hooks/api/useSalesforceId';

import infoMessages from '../AccountInfo/messages';
import messages from './messages';
import {
	AccountId,
	AccountPreviewBox,
	Body,
	ButtonGroup,
	FormDescription,
	IconSpacing,
	InfoIcon,
	InfoLine,
	ModalContent,
	ModalTitle,
	NamePreview,
	PreviewContainer,
	SalesforceIconStyled,
	TitleSmall,
	WebLink,
} from './styles';

interface AccountPreviewProps {
	salesforceAccountId: string;
}

interface ModalContentProps {
	b2bAccountId?: string;
	closeModal: () => void;
	onSuccess: (b2bAccountId: string) => void;
}

const AccountPreview: FC<AccountPreviewProps> = ({ salesforceAccountId }) => {
	const { formatMessage } = useIntl();
	const { data: salesforceAccountInfo, loading } = useSalesforceAccount(salesforceAccountId);

	if (loading) {
		return <Loader color="gray1" />;
	}

	return (
		<>
			<TitleSmall>{formatMessage(messages.currentSalesforceId)}</TitleSmall>
			<AccountId>{salesforceAccountId}</AccountId>
			{salesforceAccountInfo?.name && <div>{salesforceAccountInfo.name}</div>}
		</>
	);
};

const SalesforceAccountPreview: FC<{ salesforceAccountId?: string; hasTouched?: boolean }> = ({
	salesforceAccountId,
	hasTouched,
}) => {
	const { formatMessage } = useIntl();
	const { data: salesforceAccountInfo, loading } = useSalesforceAccount(salesforceAccountId);

	if (!hasTouched || !salesforceAccountId) {
		return null;
	}

	if (salesforceAccountId.length !== SALESFORCE_ID_LENGTH) {
		return null;
	}

	if (loading) {
		return <Loader color="gray1" />;
	}

	if (!salesforceAccountInfo?.name) {
		return <AccountPreviewBox>{formatMessage(messages.noAccount)}</AccountPreviewBox>;
	}

	return (
		<AccountPreviewBox>
			<div>{formatMessage(messages.accountFound)}:</div>
			<NamePreview>{salesforceAccountInfo?.name}</NamePreview>
			<div>{formatMessage(messages.savePrompt)}</div>
		</AccountPreviewBox>
	);
};

export const ConnectSalesforceIdModal: FC<ModalContentProps> = ({ b2bAccountId, closeModal, onSuccess }) => {
	const { formatMessage } = useIntl();
	const [connectSalesforceId, { loading }] = useConnectSalesforceId(b2bAccountId);
	const formProps = useForm('b2b-account', {
		initialModel: {
			salesforceId: '',
		},
		validation: {
			salesforceId: validation.validateOrFail([
				{
					rules: [
						validation.required,
						validation.minLength(SALESFORCE_ID_LENGTH),
						validation.maxLength(SALESFORCE_ID_LENGTH),
					],
					errorResult: formatMessage(messages.salesforceIdMinLengthError),
				},
			]),
		},
	});

	const handleConnect = async (): Promise<void> => {
		const salesforceId = stringFromModelValue(formProps.model.salesforceId);
		if (!salesforceId) return;
		try {
			const account = await connectSalesforceId(salesforceId);
			const { id: b2bAccountId } = account;
			onSuccess(b2bAccountId);
			closeModal();
		} catch {
			// Error message was already dispatched by connectSalesforceId
		}
	};

	return (
		<ModalContent>
			<ModalTitle>{formatMessage(infoMessages.modalConnectTitle)}</ModalTitle>
			<div>
				<IconSpacing>
					<SalesforceIconStyled />
				</IconSpacing>
				<Body>
					<FormDescription>
						<b>{formatMessage(infoMessages.modalConnectTitle)}</b>
						<div>{formatMessage(messages.addSalesforceIdDescription)}</div>
						{process.env.CALM_ENV === 'dev' ? (
							<InfoLine>
								<InfoIcon />
								{formatMessage(messages.salesforceDevNTestAccountDescription, {
									accountId: EnvConfig.salesforceTestAccountId,
								})}
							</InfoLine>
						) : (
							<InfoLine>
								<InfoIcon />
								{formatMessage(messages.salesforceProdTestAccountDescription, {
									accountId: EnvConfig.salesforceTestAccountId,
									slackLink: (...chunks: ReactNode[]) => (
										<WebLink
											href={'https://calmdotcom.slack.com/archives/C03QE1YHCAC'}
											target="_blank"
											rel="noopener noreferrer"
										>
											{chunks}
										</WebLink>
									),
								})}
							</InfoLine>
						)}
					</FormDescription>
					<FormInput
						{...formProps.bindWithErrorProps('salesforceId', 'text')}
						label={formatMessage(messages.salesforceIdPlaceholder)}
						data-testid="salesforce-id-input"
						autoComplete="off"
					/>
					<SalesforceAccountPreview
						salesforceAccountId={stringFromModelValue(formProps.model.salesforceId)}
						hasTouched={formProps.dirtyState.salesforceId?.hasTouched}
					/>
				</Body>
				<ButtonGroup>
					<Button
						type="button"
						borderColor="blue3"
						textColor="blue3"
						onPress={closeModal}
						backgroundColor="transparent"
						size="sm"
					>
						{formatMessage(messages.cancelCTA)}
					</Button>
					<Button
						backgroundColor="blue3"
						data-testid="save-salesforce-accountid-changes"
						onPress={handleConnect}
						isDisabled={loading || !formProps.validation.isValid}
						isLoading={loading}
						size="sm"
					>
						{formatMessage(messages.connectCTA)}
					</Button>
				</ButtonGroup>
			</div>
		</ModalContent>
	);
};

const AccountActionModalContent: FC<
	ModalContentProps &
		AccountPreviewProps & {
			onDisconnect: () => void;
		}
> = ({ salesforceAccountId, closeModal, onDisconnect }) => {
	const { formatMessage } = useIntl();

	const handleDisconnect = async (): Promise<void> => {
		await onDisconnect();
		closeModal();
	};

	return (
		<ModalContent>
			<ModalTitle>{formatMessage(infoMessages.modalDisconnectTitle)}</ModalTitle>
			<PreviewContainer>
				<SalesforceIconStyled />
				<AccountPreview salesforceAccountId={salesforceAccountId} />
			</PreviewContainer>
			<Body>{formatMessage(messages.updateCopy)}</Body>
			<ButtonGroup>
				<Button backgroundColor="buttonFuschia" onPress={handleDisconnect} size="sm" type="button">
					{formatMessage(messages.disconnectCTA)}
				</Button>
				<Button
					borderColor="blue3"
					textColor="blue3"
					onPress={closeModal}
					backgroundColor="transparent"
					size="sm"
					type="button"
				>
					{formatMessage(messages.cancelCTA)}
				</Button>
			</ButtonGroup>
		</ModalContent>
	);
};

export default AccountActionModalContent;
