import { EligibilityFileFormatType } from '@/components/pages/Account/EligibilityFileFormat';
import { HealthSponsorship } from '@/types/health';

export const raiseValue = (value: string): string => {
	return (parseInt(value) + 1).toString();
};

export const lowerValue = (value: string): string => {
	return (parseInt(value) - 1).toString();
};

export const isDefaultSponsorship = (sponsorship: HealthSponsorship): boolean =>
	sponsorship.assignment_rules?.[0]?.operator === 'true';

export const getEligibilityFieldOptions = (
	isSponsorCode: boolean,
	eligibilityFileFormat: EligibilityFileFormatType | null | undefined,
): { value: string; label: string }[] => {
	if (isSponsorCode) {
		return [{ value: 'sponsor_code', label: 'Sponsorship Code' }];
	}
	const firstName = { value: 'first_name', label: 'First Name' };
	const lastName = { value: 'last_name', label: 'Last Name' };
	const dob = { value: 'dob', label: 'Date of Birth' };
	const email = { value: 'email', label: 'Email Address' };
	const partnerMemberId = { value: 'partner_member_id', label: 'Partner User ID' };
	const subscriberId = { value: 'subscriber_id', label: 'Subscriber Id' };
	const alternateId = { value: 'alternate_id', label: 'Alternate Id' };
	const policyId = { value: 'policy_id', label: 'Policy Id/Policy Number' };
	const groupId = { value: 'group_id', label: 'Group Id/Group Number' };

	switch (eligibilityFileFormat) {
		case EligibilityFileFormatType.DIRECT_DEFAULT:
			return [firstName, lastName, dob, email, partnerMemberId];
		case EligibilityFileFormatType.SLIM:
			return [email, partnerMemberId];
		case EligibilityFileFormatType.EVERNORTH:
			return [firstName, lastName, dob, email, partnerMemberId, subscriberId, alternateId, policyId, groupId];
		case EligibilityFileFormatType.OPTUM:
			return [firstName, lastName, dob, email, partnerMemberId, subscriberId, alternateId, policyId, groupId];
	}
	return [];
};
