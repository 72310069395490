import { ReactElement, ReactNode } from 'react';

import { FontWeights } from '@calm-web/design-system';

import AdminFlag from '@/components/ui/AdminFlag';

import { Subtitle, Title } from './styles';

interface Props {
	children: ReactNode;
	showAdminFlag?: boolean;
	bold?: boolean;
	className?: string;
	id?: string;
	['data-testid']?: string;
}

export default function CellTitle({
	children,
	showAdminFlag,
	bold,
	className,
	id,
	['data-testid']: dataTestId,
}: Props): ReactElement {
	const weight = bold ? FontWeights.Medium : FontWeights.Regular;
	return (
		<Title weight={weight} className={className} id={id} data-testid={dataTestId}>
			{children}
			{showAdminFlag && <AdminFlag />}
		</Title>
	);
}

export { Subtitle };
