// Third Party

import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import StickySaveButton from '@/components/pages/Account/StickySaveButton';
import DatePicker from '@/components/ui/DatePicker';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import {
	RecordObject,
	usePartnerEmailRecords,
	useSavePartnerEmailRecord,
} from '@/hooks/api/usePartnerEmailRecords';
import { useManualSurveyForm } from '@/hooks/forms/usePartnerForm';

import EmailRecordsModals from '../../EmailRecordsModals';
import messages from './messages';
import {
	Container,
	DateHeader,
	DateRow,
	Description,
	Header,
	PastDateList,
	PastDates,
	ScheduleContainer,
	ScheduleDate,
	UpcomingDate,
	UpcomingDates,
} from './styles';

const campaignId = 'b2b_partner_survey_workflow';

export default function ManualSurveyButton({
	isShowingPartnerSave,
}: {
	isShowingPartnerSave: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const partner = useDefinedPartner();
	const {
		formProps: manualFormProps,
		isPartnerFormDirty: isManualPartnerFormDirty,
		hasTouchedPartnerForm: hasTouchedManualPartnerForm,
	} = useManualSurveyForm();
	const [shouldShowLastRequestModal, setShouldShowLastRequestModal] = useState<boolean>(false);
	const [shouldShowNextRequestModal, setShouldShowNextRequestModal] = useState<boolean>(false);

	// Hook calls
	const { loading: emailRecordsLoading, data: emailRecords } = usePartnerEmailRecords({
		campaignId,
	});

	const { past_records, future_record } = emailRecords || {};

	const [saveNewRecord, { loading }] = useSavePartnerEmailRecord(campaignId);

	const saveSurveyDate = async (): Promise<void> => {
		try {
			if (manualFormProps.model.manualSurveyDate) {
				const manualSurveyDate = stringFromModelValue(manualFormProps.model.manualSurveyDate);
				const newDate = manualSurveyDate ? new Date(manualSurveyDate) : null;
				if (newDate) {
					await saveNewRecord({
						new_date: newDate,
					});
					manualFormProps.resetAllDirtyStates();
					setShouldShowNextRequestModal(false);
					setShouldShowLastRequestModal(false);
				}
			}
		} catch (error) {
			//...
		}
	};

	const canSubmitEmailRecords = (): void => {
		const pastDateWithinMonth: Array<RecordObject> = [];
		const newManualSurveyDateString = stringFromModelValue(manualFormProps.model.manualSurveyDate);
		const newManualSurveyDate = newManualSurveyDateString
			? DateTime.fromISO(newManualSurveyDateString).toJSDate()
			: null;

		// Loop through past dates and check/return if they are within 30 days of new date
		past_records?.forEach((record: RecordObject) => {
			if (record.sent_at && newManualSurveyDate) {
				const msBetweenPastDates = Math.abs(
					new Date(record.sent_at).getTime() - newManualSurveyDate.getTime(),
				);
				const daysBetweenPastDates = msBetweenPastDates / (24 * 60 * 60 * 1000);
				if (daysBetweenPastDates < 30) pastDateWithinMonth.push(record);
			}
		});

		// Check if future date is within 30 days from new date
		let futureDateWithinMonth = false;
		if (future_record && newManualSurveyDate) {
			const msBetweenFutureDate = Math.abs(
				newManualSurveyDate.getTime() - new Date(future_record?.scheduled_send_date).getTime(),
			);
			const daysBetweenFutureDate = msBetweenFutureDate / (24 * 60 * 60 * 1000);
			futureDateWithinMonth = daysBetweenFutureDate < 30;
		}

		if (pastDateWithinMonth.length > 0) {
			// if past dates are within 30 days, show last request modal
			setShouldShowLastRequestModal(true);
		} else if (futureDateWithinMonth) {
			// if future date is within 30 days, show next request modal
			setShouldShowNextRequestModal(true);
		} else {
			// If no issues, send to save
			saveSurveyDate().catch(() => {});
		}
	};

	const dateVal = manualFormProps?.model?.manualSurveyDate
		? new Date(manualFormProps.model.manualSurveyDate as string)
		: undefined;

	if (emailRecordsLoading) return <Loader color="gray1" />;

	return (
		<Container>
			<EmailRecordsModals
				onDismiss={() => {
					setShouldShowNextRequestModal(false);
					setShouldShowLastRequestModal(false);
				}}
				onConfirm={saveSurveyDate}
				isLoading={loading}
				pastDates={past_records}
				futureDate={
					future_record
						? new Date(future_record.scheduled_send_date).toLocaleDateString(undefined, {
								month: '2-digit',
								day: '2-digit',
						  })
						: undefined
				}
				shouldShowNextRequestModal={shouldShowNextRequestModal}
				shouldShowLastRequestModal={shouldShowLastRequestModal}
			/>
			<ScheduleContainer>
				<Header id="manual-survey-date-label">{formatMessage(messages.scheduleHeader)}</Header>
				<Description>{formatMessage(messages.scheduleDescription)}</Description>
				<ScheduleDate>
					<DatePicker
						placement="top"
						startDate={dateVal}
						minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
						onChange={(date: Date) => manualFormProps.setProperty('manualSurveyDate', date.toISOString())}
						dataTestId="manual-survey-date-picker"
						labelledBy="manual-survey-date-label"
					/>
				</ScheduleDate>
			</ScheduleContainer>
			<UpcomingDates>
				<DateHeader>{formatMessage(messages.futureDateHeader)}</DateHeader>
				<UpcomingDate>
					<DateRow data-testid="future-date">
						{future_record
							? new Date(future_record.scheduled_send_date).toLocaleDateString(undefined, {
									month: '2-digit',
									day: '2-digit',
									year: '2-digit',
							  })
							: undefined}
					</DateRow>
				</UpcomingDate>
			</UpcomingDates>
			<PastDates>
				<DateHeader>{formatMessage(messages.pastDateHeader)}</DateHeader>
				<PastDateList>
					{past_records &&
						past_records.map((record: RecordObject, index: number) => {
							return record.sent_at ? (
								<DateRow key={index} data-testid={`past-date-${index}`}>
									{new Date(record.sent_at).toLocaleDateString(undefined, {
										month: '2-digit',
										day: '2-digit',
										year: '2-digit',
									})}
								</DateRow>
							) : null;
						})}
				</PastDateList>
			</PastDates>
			<StickySaveButton
				isFormDirty={isManualPartnerFormDirty}
				hasTouchedForm={hasTouchedManualPartnerForm}
				isLoading={loading}
				dataTestId="partner-survey-save-btn"
				onPress={() => {
					// Send click analytics
					logEvent('Partner Portal : Account Page : Manual Survey Button : Clicked', {
						partner_id: partner.id,
						campaign_id: campaignId,
					});
					canSubmitEmailRecords();
				}}
				accountForSecondButton={isShowingPartnerSave}
			>
				{formatMessage(messages.saveText)}
			</StickySaveButton>
		</Container>
	);
}
