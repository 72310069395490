import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { Banner, Button, fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const BannerWrapper = styled.div<{ $isVisible: boolean }>`
	max-height: ${p => (p.$isVisible ? 'auto' : '0')};
	opacity: ${p => (p.$isVisible ? 1 : 0)};
	position: relative;
	transform: translate3d(0, ${p => (p.$isVisible ? '0' : '-4px')}, 0);
	transition: ${p => (p.$isVisible ? theme('animation.transition') : 'none')};
	visibility: ${p => (p.$isVisible ? 'visible' : 'hidden')};
	width: 100%;
`;

export const PathwaysBanner = styled(Banner).attrs({
	backgroundColor: 'white',
	textColor: 'black',
	Icon: null,
})`
	padding-bottom: 0;
	padding-left: 0;
	padding-right: ${units(4)};
	border-radius: ${units(1)};
	margin-bottom: ${units(6)};
`;

export const BannerContent = styled.div`
	display: grid;
	grid-template-columns: 25% 70%;
	align-items: center;
	gap: ${units(2)};
	padding: ${units(1, 0)};
`;

export const BannerText = styled.div`
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Regular)};
	line-height: 27px;
`;

export const BannerHeader = styled.div`
	${fontWeight(FontWeights.Demi)};
	margin-bottom: ${units(1)};
`;

export const BannerImage = styled.div`
	height: 200px;
`;

export const Text = styled.div`
	margin-bottom: ${units(1)};
`;

export const HelpLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: none;
`;

export const Heading = styled.div<{ hasDismissedBanner: boolean }>`
	${fontSize(FontSizes.lg)};
	${fontWeight(FontWeights.Medium)};
	padding-bottom: ${units(2)};
	display: flex;
	justify-content: space-between;
	align-items: center;

	${ifProp(
		'hasDismissedBanner',
		css`
			margin-top: ${units(5)};
		`,
	)}
`;

export const CreateButton = styled(Button)``;
