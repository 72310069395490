import Image from 'next/legacy/image';
import { ReactElement, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Loader, Modal } from '@calm-web/design-system';

import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { ProgramOrGuideItem } from '@/types/content';
import redirect from '@/utils/redirect';
import { srcLoader } from '@/utils/ui/imageLoader';

import Resources from '../../Resources';
import SampleContentCards from '../../SampleContentCards';
import createMessages from '../messages';
import { ErrorMessage } from '../styles';
import messages from './messages';
import {
	BannerImage,
	CenterContentContainer,
	CheckIcon,
	ClockIcon,
	CreateWrapper,
	ImagePlaceholder,
	LinkToModal,
	MoreToCome,
	PlaceholderContainer,
	PlaceholderContent,
	PlaceholderTitle,
	Program,
	ProgramContent,
	ProgramDescription,
	ProgramDetailContainer,
	ProgramImage,
	ProgramListContainer,
	ProgramTitle,
	SampleContent,
	SampleContentHeader,
	SampleProgramDescription,
	SampleProgramTitle,
	SampleResources,
	SuggestionTextBox,
	ThanksContainer,
	ThanksText1,
	ThanksText2,
} from './styles';

interface SelectProgramProps {
	arePathwaysLoading: boolean;
}

interface SuggestionContentProps {
	hasSubmitted: boolean;
}

const SelectProgram: React.FC<SelectProgramProps> = ({ arePathwaysLoading }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { setSelectedPathway, selectedPathway, pathways, isEdit, isReadOnly, partnerId } =
		useContext(PathwaysContext);
	const [shouldShowIdeaModal, setShouldShowIdeaModal] = useState<boolean>(false);
	const [suggestionContent, setSuggestionContent] = useState<string>('');
	const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
	const [isMobile] = useIsMobile();
	const activePathway = pathways?.feed.packs.filter(pack => pack.title === selectedPathway?.title);

	const onSubmitPathwaySuggestion = (): void => {
		// Log suggestiongs with amplitude for now
		logEvent('Pathways : Content Suggestion', {
			suggestion: suggestionContent,
		});
		setSuggestionContent('');
		setSubmitSuccess(true);
	};

	// Show submit button until partner submits content, then show close button
	const PathwaySuggestionButton = ({ hasSubmitted }: SuggestionContentProps): ReactElement => {
		if (hasSubmitted) {
			return (
				<Button
					backgroundColor="blue3"
					onPress={() => {
						setShouldShowIdeaModal(false);
						setSubmitSuccess(false);
						setSuggestionContent('');
					}}
				>
					{formatMessage(messages.suggestIdeaModalClose)}
				</Button>
			);
		}

		return (
			<Button
				backgroundColor="blue3"
				legacyOnClick={onSubmitPathwaySuggestion}
				type="button"
				isDisabled={!suggestionContent}
			>
				{formatMessage(messages.suggestIdeaModalSubmit)}
			</Button>
		);
	};

	if (isEdit && isReadOnly) {
		redirect(`/${partnerId}/pathways`);
		return <ErrorMessage>{formatMessage(createMessages.errorMessage)}</ErrorMessage>;
	}

	return (
		<CreateWrapper>
			<Modal
				isOpen={shouldShowIdeaModal}
				closeModal={() => {
					setShouldShowIdeaModal(false);
					setSubmitSuccess(false);
					setSuggestionContent('');
				}}
				title={formatMessage(messages.suggestIdeaModalTitle)}
				footer={<PathwaySuggestionButton hasSubmitted={submitSuccess} />}
			>
				{submitSuccess ? (
					<ThanksContainer>
						<CheckIcon />
						<ThanksText1>{formatMessage(messages.suggestIdeaModalThanksText1)}</ThanksText1>
						<ThanksText2>{formatMessage(messages.suggestIdeaModalThanksText2)}</ThanksText2>
					</ThanksContainer>
				) : (
					<>
						{formatMessage(messages.suggestIdeaModalDescription)}
						<SuggestionTextBox
							aria-label="pathwaysSuggestionContent"
							value={suggestionContent}
							name="suggestionBox"
							noValidation
							onChange={e => setSuggestionContent(e.target.value)}
							placeholder={formatMessage(messages.suggestIdeaModalPlaceholder)}
						/>
					</>
				)}
			</Modal>
			<ProgramListContainer>
				{!arePathwaysLoading && pathways ? (
					<>
						{pathways.feed.packs.map((pathway, index) => {
							const imageSize = Math.min(pathway.image?.height ?? 100, pathway.image?.width ?? 100);
							return (
								<Program
									key={index}
									data-testid={`pathway-item-${index}`}
									onClick={() => {
										setSelectedPathway(pathway);
										logEvent('Partner Portal : Pathway : Selected', {
											selected_pathway: pathway.pack_class,
										});
									}}
									active={selectedPathway?.id === pathway.id}
								>
									{pathway.image ? (
										<ProgramImage>
											<Image
												src={pathway.image.url}
												height={imageSize}
												width={imageSize}
												alt=""
												loader={srcLoader}
											/>
										</ProgramImage>
									) : (
										<ImagePlaceholder />
									)}
									<ProgramContent>
										<ProgramTitle>{pathway.title}</ProgramTitle>
										<ProgramDescription>{pathway.subtitle}</ProgramDescription>
									</ProgramContent>
								</Program>
							);
						})}
						<MoreToCome>
							<ProgramImage>
								<ClockIcon />
							</ProgramImage>
							<ProgramContent>
								<ProgramTitle>{formatMessage(messages.moreToComeHeader)}</ProgramTitle>
								<ProgramDescription>
									{formatMessage(messages.moreToComeBody, {
										suggestionmodal: (...chunks: ReactNode[]) => (
											<LinkToModal onClick={() => setShouldShowIdeaModal(true)}>{chunks}</LinkToModal>
										),
									})}
								</ProgramDescription>
							</ProgramContent>
						</MoreToCome>
					</>
				) : (
					<Loader color="gray5" />
				)}
			</ProgramListContainer>
			{!isMobile && (
				<ProgramDetailContainer>
					{selectedPathway ? (
						<>
							{activePathway && activePathway[0] && activePathway[0].banner_image && (
								<BannerImage>
									<Image
										src={activePathway[0].banner_image.url as string}
										height={activePathway[0].banner_image.height}
										width={activePathway[0].banner_image.width}
										alt=""
										loader={srcLoader}
									/>
								</BannerImage>
							)}
							<SampleProgramTitle>{selectedPathway.title}</SampleProgramTitle>
							<SampleProgramDescription>{selectedPathway.subtitle}</SampleProgramDescription>
							<SampleContent>
								<SampleContentHeader>{formatMessage(messages.sampleContentHeader)}</SampleContentHeader>
								<SampleContentCards
									items={selectedPathway.items as ProgramOrGuideItem[]} // Assume items are ProgramOrGuideItem
								/>
							</SampleContent>
							<SampleResources>
								<Resources header={formatMessage(messages.sampleResourcesHeader)} />
							</SampleResources>
						</>
					) : (
						<CenterContentContainer>
							<PlaceholderContainer>
								<PlaceholderTitle>{formatMessage(messages.placeholderTitle)}</PlaceholderTitle>
								<PlaceholderContent>{formatMessage(messages.placeholderContent)}</PlaceholderContent>
							</PlaceholderContainer>
						</CenterContentContainer>
					)}
				</ProgramDetailContainer>
			)}
		</CreateWrapper>
	);
};

export default SelectProgram;
